import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ECheckoutSections, EStoreTypes, EAddressType } from '../../../configurations/common';
import { IPointOfContact, IPriceDisputingPerItem, ISystemDetails } from '../../../models/common.models';
import { OrderReviewService } from '../services/order-review.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppUtils } from '../../../utils/app.utils';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { ImageUtils } from '../../../utils/image.utils';
import { AddressUtils } from '../../../utils/address.utils';
import { IAddressData, ICheckoutFormData, IPaymentsData, IShipmentMethod } from '../../../models/checkout.models';
import { ICartItemWithDetail } from '../../../models/cart.models';
import { map, take, takeUntil } from 'rxjs/operators';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { TaxUtils } from '../../../utils/tax.utils';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import * as ValidationPatterns from '../../../configurations/validations';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutFormComponent implements OnInit {
  @Input() checkoutAddresses: IAddressData[];
  @Input() cartItemsWithDetails: ICartItemWithDetail[];
  @Input() cartHasTotalPriceToPayZero: boolean = false;
  @Input() currency: string;
  @Input() pointOfContact: IPointOfContact;
  @Input() systemDetails: ISystemDetails;
  @Input() cartId: string;
  @Input() poNumber: string;
  @Input() selectedShipmentMethod: IShipmentMethod;
  @Input() priceDisputingPerItems: IPriceDisputingPerItem[];
  @Input() payments: IPaymentsData[];
  @Input() defaultPayment: string;
  @Input() isReloadInProgress: boolean = false;
  @Input() isCartStatusWaiting: boolean = false;
  @Output() setIsSubmitButtonDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadData: EventEmitter<void> = new EventEmitter<void>();
  @Output() checkoutFormData: EventEmitter<ICheckoutFormData> = new EventEmitter<ICheckoutFormData>();

  protected readonly AppUtils = AppUtils;
  protected readonly EStoreTypes = EStoreTypes;
  protected readonly ImageUtils = ImageUtils;
  protected readonly AddressUtils = AddressUtils;
  protected readonly TaxUtils = TaxUtils;
  protected readonly ECheckoutSections = ECheckoutSections;
  protected readonly EAddressType = EAddressType;

  orderReviewForm: UntypedFormGroup;

  protected visibleSections$: Observable<string[]> = new Observable<string[]>();
  private unsubscribe$: Subject<void> = new Subject<void>();

  // Discounts
  isDiscountOperationInProgress: boolean;
  discountHasError: boolean;
  protected isDiscountSectionOpened: boolean;

  constructor(
    protected orderReviewService: OrderReviewService,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private checkoutFacade: CheckoutFacade,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.visibleSections$ = this.configurationFacade.getDynamicCheckoutSections();
  }

  ngOnInit(): void {
    this._initializeForm();
    this._setupCachedData();
  }

  /**
   * Set up cached data for purchase order number and order email copy from state.
   * @private
   */
  private _setupCachedData(): void {
    forkJoin([
      this.checkoutFacade.selectSapPoNumber(),
      this.checkoutFacade.selectOrderCopyEmail(),
    ]).pipe(
      map(([sapPoNumber, orderCopyMail]) => [sapPoNumber, orderCopyMail]),
      take(1),
    ).subscribe(([sapPoNumber, orderCopyMail]) => {
      this.orderReviewForm.patchValue({
        purchaseOrderNumber: sapPoNumber,
      });
      this.orderReviewForm.patchValue({
        orderEmailCopy: orderCopyMail ?? '',
      });
    });
  }

  /**
   * Initialize form with default values.
   * @private
   */
  private _initializeForm(): void {
    this.orderReviewForm = this.formBuilder.group({
      paymentMethod: [this.defaultPayment,
        this.orderReviewService.isFormFieldRequired(ECheckoutSections.PAYMENT_METHODS) ? [Validators.required] : []],
      purchaseOrderNumber: [this._setPurchaseOrderNumber(), (this.orderReviewService.isFormFieldRequired(ECheckoutSections.PO_NUMBER) ?
        [ValidationPatterns.noEmptySpaceOnTheBeginning, Validators.required] : [])],
      orderEmailCopy: ['', [Validators.email,
        ...(this.orderReviewService.isFormFieldRequired(ECheckoutSections.DELIVERY_ADDRESS_AND_INSTRUCTIONS) ?
          [Validators.required] : [])]],
      discount: ['', this.orderReviewService.isFormFieldRequired(ECheckoutSections.DISCOUNTS) ? [Validators.required] : []],
      taxNumber: ['', this.orderReviewService.isFormFieldRequired(ECheckoutSections.BILLING_TAX_NUMBER) ? [Validators.required] : []],
    });

    //emitting because of init state of the form
    this.setIsSubmitButtonDisabled.emit(!this.orderReviewForm.valid);
    this.checkoutFormData.emit(this.orderReviewForm.value);

    this._checkFormValidity();
    this._checkFormChanges();
  }

  /**
   * Set given payment to form.
   * @param {string} payment
   * @private
   */
  setPayment(payment: string): void {
    this.orderReviewForm.patchValue({
      paymentMethod: payment,
    });
  }

  /**
   * check if cart has poNumber set
   * in case yes: set it in form
   * but in case the cart has total price to pay 0, set spacial poNumber from translation "CONTRACT"
   * "CONTRACT" has higher priority
   * @private
   */
  private _setPurchaseOrderNumber(): string {
    if (this.cartHasTotalPriceToPayZero && AppUtils.isStoreActive(EStoreTypes.US)) {
      let contractTranslation: string = '';
      this.configurationFacade.getTranslationByKey('order-review.default-purchase-order-number')
        .pipe(take(1))
        .subscribe(translation => {
          contractTranslation = translation;
        });
      return contractTranslation;
    }

    return this.poNumber ?? '';
  }

  /**
   * Checks the form validity and emits the validity status.
   * @private
   */
  private _checkFormValidity(): void {
    this.orderReviewForm.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setIsSubmitButtonDisabled.emit(!this.orderReviewForm.valid);
    });
  }

  /**
   * Checks the form value changes and emits the values.
   * @private
   */
  private _checkFormChanges(): void {
    this.orderReviewForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.checkoutFormData.emit(this.orderReviewForm.value);
    });
  }

  /**
   * Generating title for separate sections which are configured via arakh.
   * @param {string} section
   * @returns {string}
   */
  generateSectionTitle(section: string): string {
    return `order-review.section-title-${section}`;
  }

  /**
   * Determine if input is touched and has error.
   * @param {string} inputName
   * @param {string} errorCode
   * @returns {boolean}
   */
  inputIsTouchedAndHasError(inputName: string, errorCode: string): boolean {
    return this.orderReviewForm.get(inputName).touched &&
      this.orderReviewForm.get(inputName).hasError(errorCode);
  }

  /**
   * Check if purchase order number is filled but cart has total price to pay zero.
   * @returns {boolean}
   */
  purchaseOrderNumberIsFilledButCartHasTotalPriceToPayZero(): boolean {
    return Boolean(this.orderReviewForm.value.purchaseOrderNumber && this.cartHasTotalPriceToPayZero);
  }

  // *** Discounts section ***
  /**
   * Show form field for entering discount code to user.
   */
  openDiscountSection(): void {
    this.isDiscountSectionOpened = true;
  }

  /**
   * Disable order submit button while adding discount is still in progress.
   * Make call to update cart in BE with new discount.
   * When call is successful:
   * - enable submit order button
   * - update totals and discounts in order review service to update info in sidebar
   * - clear discount form field
   * - set correct values for isDiscountOperationInProgress and discountHasError
   */
  addDiscount(): void {
    this.setIsSubmitButtonDisabled.emit(true);
    this.isDiscountOperationInProgress = true;
    this.marketingFacade.postVoucher(this.cartId, this.orderReviewForm.value.discount).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: response => {
        this.setIsSubmitButtonDisabled.emit(false);
        this.reloadData.emit();
        this.orderReviewService.updateTotalsAndDiscounts(response.data.attributes.totals, response.data.attributes.discounts);
        AppUtils.setFormValue({key: 'discount', value: ''}, this.orderReviewForm);
        this.isDiscountOperationInProgress = false;
        this.discountHasError = false;
      },
      error: () => {
        this.setIsSubmitButtonDisabled.emit(false);
        this.reloadData.emit();
        this.discountHasError = true;
        this.isDiscountOperationInProgress = false;
      },
    });
  }

  /**
   * Check if discount button should be disabled:
   * - if adding of discount is in progress
   * - if form field for discount is empty
   * @returns {boolean}
   */
  isDiscountButtonDisabled(): boolean {
    return this.isDiscountOperationInProgress || this.orderReviewForm.value.discount === '';
  }

  /**
   * Check names of all available payment providers
   * @returns {string[]}
   */
  getPaymentProviders(): string[] {
    return this.payments?.map(payment => payment?.paymentProviderName);
  }
}
