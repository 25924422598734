import { ICPQAddress } from '../models/cpq.models';
import { IContractParams } from '../models/catalog.models';

export class CpqUtils {
  static groupBom(response: any): any {
    return response.bom.reduce((acc, bomEntity) => {
      const item = response.pricing.rows.find(pricing => pricing.PartNumber === bomEntity.attributes.SKU);
      const groupIndex = acc.findIndex(group => group.name === bomEntity.attributes.groupDescription);
      if (groupIndex !== -1) {
        acc[groupIndex].items.push({
          name: bomEntity.description,
          price: item.ListPrice,
        });
      } else {
        acc.push({
          name: bomEntity.attributes.groupDescription,
          totalPrice: 0,
          currency: item.Currency,
          items: [{
            name: bomEntity.description,
            price: item.ListPrice,
          }],
        });
      }
      acc[groupIndex !== -1 ? groupIndex : acc.length - 1].totalPrice += item?.ListPrice ?? 0;
      return acc;
    }, []);
  }

  static cpqAddressToString(address: ICPQAddress): string {
    if (!address) {
      return '';
    }
    const addressDetails = [
      address.address1,
      address.city,
      address.state,
      address.zipCode,
    ].filter(Boolean).join(', ');
    return (address.name ?? '') + (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  static mergeCpqItems(items: any): any {
    return items.reduce((acc, next) => {
      const existingItemIndex = acc.findIndex(item => item.attributes.sku === next.attributes.sku);
      if (existingItemIndex !== -1) {
        acc[existingItemIndex] = {
          ...acc[existingItemIndex],
          items: [...acc[existingItemIndex].items, next]
        };
      } else {
        acc.push({
          attributes: {
            sku: next.attributes.sku,
            name: next.attributes.name,
            imageSets: next.attributes.imageSets,
          },
          items: [next]
        });
      }
      return acc;
    }, []);
  }

  static filterOutEmptyParams(params: IContractParams): IContractParams{
    return {
    ...Object.keys(params)
    .filter(key => !!params[key])
    .reduce((obj, key) => ({ ...obj, [key]: params[key] }), {})
    };
  }
}
