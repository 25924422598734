import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAddress, ISystemDetails } from '../../models/common.models';
import { ICart } from '../../models/cart.models';
import { MarketingFacade } from '../../facades/marketing.facade';
import { Subject } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';
import { takeUntil } from 'rxjs/operators';
import { ISelectSoldTo } from '../../models/soldTo-selection.models';
import { SoldToAccountsFacade } from '../../facades/sold-to.facade';
import { EInstalledBaseTabs } from '../../configurations/common';
import { CatalogActions } from '../../actions';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';

@Component({
  selector: 'app-equipment-selection',
  templateUrl: './equipment-selection.component.html',
  styleUrls: ['./equipment-selection.component.scss'],
})
export class EquipmentSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() systemDetails: ISystemDetails;
  @Input() loading: boolean;
  @Input() currentCart: ICart;
  @Input() isInCurrentCartComponent: boolean = false;
  @Output() updatedPricesAfterChangeSoldTo = new EventEmitter<any>();
  isBusinessPartner: boolean = false;
  showChangeSoldToPopup: boolean = false;
  soldToAccounts: IAddress[] = [];
  isAnySoldToAccount: boolean = false;
  activeSoldToAccount: {company: string, id: string};
  reloadPrices: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private soldToAccountFacade: SoldToAccountsFacade,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.selectSoldToAccountsFromStore();
    this.selectIsCustomerBusinessPartner();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentCart) {
      this.activeSoldToAccount = {
        company: changes?.currentCart?.currentValue?.attributes?.soldToAddress?.company ?? '',
        id: changes?.currentCart?.currentValue?.attributes?.soldToAddress?.sapId ?? '',
      };
      this.updatedPricesAfterChangeSoldTo.emit(this.reloadPrices);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isEquipmentSelected(): boolean {
    return !!this.systemDetails?.siemensEquipmentId && !!this.systemDetails?.materialNumber && !!this.systemDetails?.nameEnUs;
  }

  redirectToMyEquipmentPage(): void {
    const productSku = this.activatedRoute.snapshot.paramMap.get('sku');
    const redirectTo = this.activatedRoute.snapshot.url?.slice(1).map(segment => segment.path).join('/');
    let commands = '/my-installed-base';

    let queryParams: any = {
      tab: EInstalledBaseTabs.EQUIPMENT,
      lastSku: productSku || undefined,
    };

    if (this.isBusinessPartner) {
      commands = '/equipment-selection';
      queryParams = {};
    }

    if (redirectTo) {
      queryParams.redirect = redirectTo;
    }

    this.router.navigate([commands], {
      queryParams: queryParams,
    }).then();
  }

  removeEquipmentSelection(): void {
    if (this.currentCart.attributes.totalItemsQuantity === 0) {
      this.loading = true;

      this.marketingFacade.updateCartById(this.currentCart.id, {systemDetails: {} as ISystemDetails});

      //clear query params/filters after removing selected equipment
      const currentUrl = this.router.url.split('?')[0];
      this.router.navigate([currentUrl]);
      this.loading = false;
    }
  }

  togglePopupForChangeSoldToAddress(value): void {
    this.showChangeSoldToPopup = value;
  }

  handleChangeSoldTo(value: boolean) {
    this.reloadPrices = value;
    this.store.dispatch(CatalogActions.setCachedProductsToBeReloaded());
  }

  selectedSoldToAccountFromModal(selectedSoldToAccount: ISelectSoldTo) {
    const selected = this.soldToAccounts?.find(a =>
      a.id == selectedSoldToAccount.id
      && a.sapId == selectedSoldToAccount.value);
    this.activeSoldToAccount = {
      company: selected?.company,
      id: selected?.sapId,
    };
    this.updateCartDataWithSystemDetails(this.currentCart.id, selected);
  }

  selectSoldToAccountsFromStore(): void {
    this.soldToAccountFacade.selectSoldToAccounts().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(soldToAccounts => {
      this.soldToAccounts = soldToAccounts;
      this.isAnySoldToAccount = this.soldToAccounts?.length > 1;
    });
  }

  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  private updateCartDataWithSystemDetails(cartId: string, soldToAddress?: IAddress): void {
    if (soldToAddress) {
      this.marketingFacade.updateCartById(cartId, {soldToAddress: soldToAddress});
    }
    this.showChangeSoldToPopup = false;
  }
}
