import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IconUtils } from '../../../../../utils/icon.utils';
import {
  genericDateSortOption,
  genericNumberSortOption,
  genericTextSortOption,
} from '../../../../../configurations/sort-options';
import { ArrayUtils } from '../../../../../utils/array.utils';
import { SortByComponent } from '../../../../../shared/sort-by/sort-by.component';
import { AdvancedFilter } from '../../../../../models/advanced-filter';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MarketingFacade } from '../../../../../facades/marketing.facade';
import { Subject } from 'rxjs';
import { EFeatureToggles, EMessageType } from '../../../../../configurations/common';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationFacade } from '../../../../../facades/configuration.facade';
import { CustomerFacade } from '../../../../../facades/customer.facade';
import { IMessage } from '../../../../../models/common.models';

@Component({
  selector: 'app-spare-part-list',
  templateUrl: './spare-part-list.component.html',
  styleUrls: ['./spare-part-list.component.scss'],
})
export class SparePartListComponent implements OnInit, OnDestroy {
  isSapP40Enable: boolean = false;
  tabsOpened = [] as Array<string>;

  @Input() orders: any[];
  @Input() filters: AdvancedFilter[];
  @Input() searchValue: string;
  @Input() isModalInProgress: boolean = false;

  currentCartId: string;
  addItemToCartModalActive: boolean = false;
  flNumberOfReorder: string = '';
  orderId: string = '';
  routeOrderId: string = '';
  itemsToAdd: Array<any>;
  isPriceDisputingActive: boolean = false;

  @ViewChildren(SortByComponent)
  sortByComponent: QueryList<SortByComponent>;
  currentSort: any = {};

  headers = [
    {
      label: 'spare-part-list.order-id',
      class: 'order-id width-15',
      sortOptions: genericTextSortOption('id'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.date-ordered-by',
      class: 'order-date width-20',
      sortOptions: genericDateSortOption('attributes.createdAt'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.equipment',
      class: 'order-equipment width-25',
      sortOptions: genericTextSortOption('attributes.systemDetails.siemensEquipmentId'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.order-status',
      class: 'order-status width-15',
      sortOptions: genericTextSortOption('mappedStateDisplayName'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.total',
      class: 'order-total-price width-15',
      sortOptions: genericNumberSortOption('attributes.totals.grandTotal'),
      showOnMobile: true,
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private marketingFacade: MarketingFacade,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit() {
    this.selectCurrentCartId();
    this.selectIsPriceDisputingActive();

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.routeOrderId = params['orderId'] ?? '';
      this.toggleTab(this.routeOrderId);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCurrentCartId(): void {
    this.marketingFacade.selectCartId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(id => {
      this.currentCartId = id;
    });
  }

  sort(option: any): void {
    this.orders = ArrayUtils.sortByAttribute(
      [...this.orders],
      option.value,
      option.reversed,
      option?.time,
      option?.numeric,
    );

    this.sortByComponent.forEach(component => component.active = component.isOpen ? option : '');
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex(tab => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  getIcon(status: string): string {
    return IconUtils.getStatusIcon(status);
  }

  /**
   * Determine if order has disputed price.
   *
   * @param {string} orderId
   * @return {boolean}
   */
  hasOrderDisputedPrice(orderId: string): boolean {
    return this.orders?.find(order => order.id === orderId)?.attributes?.items?.filter(item => item.priceDisputing?.isSet).length > 0;
  }

  /**
   * Initialize message component with price disputing data.
   *
   * @param {string} orderId
   * @return {IMessage}
   */
  setPriceDisputingDataForMessageComponent(orderId: string): IMessage {
    return {
      type: EMessageType.WARNING,
      title: 'purchase-activity.disputed-pricing.title',
      description: 'purchase-activity.disputed-pricing.description',
      descriptionData: this.getPriceDisputedItemsIds(orderId),
    };
  }

  /**
   * Get price disputing from given order and return as comma separated string of price disputed item ids.
   *
   * @private
   * @param {string} orderId
   * @return {string}
   */
  private getPriceDisputedItemsIds(orderId: string): string {
    return this.orders?.find(order => order?.id === orderId)?.attributes?.items?.filter(item =>
      item?.priceDisputing?.isSet,
    )?.map(item => item?.sku)?.join(', ');
  }

  /**
   * Select if price disputing functionality is active (based on the company roles).
   *
   * @private
   *
   */
  private selectIsPriceDisputingActive(): void {
    this.customerFacade.isPriceDisputingActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isPriceDisputingActive => {
      this.isPriceDisputingActive = isPriceDisputingActive;
    });
  }
}
