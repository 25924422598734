import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EFeatureToggles, EGlueResource, EStoreFeatures, EStoreTypes, EUserRoles } from '../../configurations/common';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { ICartRule } from '../../models/cart.models';
import { ICheckoutUpdate } from '../../models/checkout.models';
import { IAddress } from '../../models/common.models';
import { State } from '../../reducers';
import * as ShopCartSelectors from '../../reducers/shop-cart.reducer';
import { I18nService } from '../../services';
import { AddressUtils } from '../../utils/address.utils';
import { AppUtils } from '../../utils/app.utils';
import { FileType, FileUtils } from '../../utils/file.utils';
import { ObjectUtils } from '../../utils/object.utils';
import { IAddressSelectBox } from '../../models/customer.models';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
})
export class DeliveryDetailsComponent implements OnInit, OnDestroy {
  userRoles: EUserRoles[];
  isUserRolesLoading: boolean = true;
  hasApproverAccess: boolean = false;
  isCheckoutQuoteApprovalMandatory: boolean = false;
  isJpStore: boolean = false;
  isAuStore: boolean = false;
  customerAddresses: IAddressSelectBox[] = [];
  addresses: IAddressSelectBox[] = [];
  businessAddresses: IAddressSelectBox[] = [];
  creatingOrderInProgress: boolean = false;
  creatingPreviewInProgress: boolean = false;
  cartItems: any;
  isCartEmpty: boolean = true;
  cartId: string;
  showApprovalModal: boolean = false;
  cartRules: Array<ICartRule>;
  inProcessCartId: string;
  businessAddressesLoading: boolean = false;
  isQuoteRequestActive: boolean = false;
  itemWithoutPrice: boolean = false;
  excludeTax: boolean = false;
  successModalMessage: string = '';

  isOnContactDetailsSection: boolean = false;
  orderDetailsData: any;
  contactDetailsData: any;
  cartItemsWithDetails: any;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private checkoutFacade: CheckoutFacade,
    private store: Store<State>,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
    this.isAuStore = AppUtils.isStoreActive(EStoreTypes.AU);
    this.isQuoteRequestActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.QUOTE_REQUEST);

    this.selectCustomerCompanyRoles();
    this.selectCompanyRolesLoading();
    this.selectIsCartEmpty();
    this.loadCartData();
    this.customerFacade.beginGetBusinessUnitsAction();
    this.selectCustomerAddresses();
    this.selectBusinessAddresses();
    this.selectCartItemsSubscription();
    this.selectExcludeTax();
    this.selectIsQuoteApprovalForCheckoutRequired();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCustomerCompanyRoles(): void {
    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(roles => {
      this.userRoles = roles;
      this.hasApproverAccess = this.checkRolesForAccess(roles);
    });
  }

  selectCompanyRolesLoading(): void {
    this.customerFacade.selectCompanyRolesLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isRolesLoading => {
      this.isUserRolesLoading = isRolesLoading;
    });
  }

  selectIsCartEmpty(): void {
    this.marketingFacade.selectIsCartEmpty().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCartEmpty = state;
    });
  }

  checkRolesForAccess(roles: EUserRoles[]): boolean {
    if (roles.includes(EUserRoles.Viewer) && roles.length === 1) {
      this.router.navigate([this.i18nService.getCurrentLocale()]);
    }

    return (roles.includes(EUserRoles.Approver) || roles.includes(EUserRoles.Admin));
  }

  selectBusinessAddresses(): void {
    this.businessAddressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.businessAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, addresses, 'business');
        this.businessAddressesLoading = false;
      }
    });
  }

  selectCustomerAddresses(): void {
    this.customerFacade.selectCustomerAddresses().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  createOrder(contactDetailsData: any): void {
    this.contactDetailsData = contactDetailsData;
    const shippingAddress: IAddress = this.getShippingAddress();

    const checkoutUpdate: ICheckoutUpdate = {
      data: {
        type: EGlueResource.CHECKOUT_UPDATE,
        attributes: {
          idCart: this.cartId,
          isAddressSavingSkipped: true,
          systemDetails: {
            ...this.orderDetailsData.systemDetails,
          },
          systemDetailsPerItem: [
            ...this.orderDetailsData.systemDetailsPerItem,
          ],
          pointOfContact: {
            ...this.contactDetailsData.pointOfContact,
          },
          approverDetails: {
            approverId: this.contactDetailsData.approverData.approver.value,
            comment: this.contactDetailsData.approverData.commentForApprover,
            dueDate: null,
            firstName: this.contactDetailsData.approverData.approver.firstName,
            lastName: this.contactDetailsData.approverData.approver.lastName,
          },
          shippingAddress,
        },
      },
    };

    this.creatingOrderInProgress = true;
    this.checkoutFacade.postCheckoutUpdate(checkoutUpdate).pipe(
      take(1),
    ).subscribe({
      next: _ => {
        this.generateQuotePdf();
        this.creatingOrderInProgress = false;
        this.showApprovalModal = true;
        this.generateSuccessModalMessage();

        if (this.userRoles.includes(EUserRoles.Buyer) || this.isCheckoutQuoteApprovalMandatory) {
          this.analyticsService.trackCart('order.placed');
          this.inProcessCartId = this.cartId;
          // TODO: logic duplication - move to one effect
          this.marketingFacade.createEmptyCart();
          return;
        }

        this.checkoutFacade.actionPutCartIdOrderApprove(this.inProcessCartId);
        this.router.navigate(['/order-approval/', this.cartId]).then();
      },
      error: _ => {
        this.creatingOrderInProgress = false;
      },
    });
  }

  getPreviewQuotePdf(): void {
    const shippingAddress: IAddress = this.getShippingAddress();

    this.creatingPreviewInProgress = true;
    this.checkoutFacade.postCheckoutUpdate({
      data: {
        type: EGlueResource.CHECKOUT_UPDATE,
        attributes: {
          idCart: this.cartId,
          shippingAddress,
        },
      },
    }).pipe(
      take(1),
    ).subscribe({
      next: _ => {
        this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, true).pipe(
          take(1),
        ).subscribe({
          next: file => {
            FileUtils.saveAndOpenFile(file, FileType.PDF, `PREVIEW_details_${this.cartId}`);
          },
          complete: () => this.creatingPreviewInProgress = false,
        });
      },
      error: _ => {
        this.creatingOrderInProgress = false;
      },
    });
  }

  backToHomepage(): void {
    this.router.navigate(
      [this.i18nService.getCurrentLocale()],
      {queryParams: {lastCartId: this.inProcessCartId}},
    ).then();
  }

  proceedToContactDetails(orderDetailsData: any): void {
    this.orderDetailsData = orderDetailsData;
    this.isOnContactDetailsSection = true;
  }

  proceedToOrderDetails(): void {
    this.isOnContactDetailsSection = false;
  }

  requestQuoteFeatureActive(): boolean {
    return this.isQuoteRequestActive && this.itemWithoutPrice;
  }

  private getShippingAddress(): IAddress {
    const deliveryAddress = this.orderDetailsData.deliveryAddress;

    const shippingAddress: IAddress = {
      idCustomerAddress: deliveryAddress.idCustomerAddress,
      idCompanyUnitAddress: deliveryAddress.idCompanyUnitAddress,
      salutation: 'Ms',
      firstName: deliveryAddress?.firstName ? deliveryAddress.firstName : 'empty',
      lastName: deliveryAddress?.lastName ? deliveryAddress.lastName : 'empty',
      address1: deliveryAddress?.address1 ? deliveryAddress.address1 : 'empty',
      address2: deliveryAddress?.address2 ? deliveryAddress.address2 : 'empty',
      address3: '',
      zipCode: deliveryAddress?.zipCode ? deliveryAddress.zipCode : 'empty',
      city: deliveryAddress?.city ? deliveryAddress.city : 'empty',
      iso2Code: deliveryAddress?.iso2Code ? deliveryAddress.iso2Code : 'DE',
      company: deliveryAddress?.company ? deliveryAddress.company : 'empty',
      phone: deliveryAddress?.phone ? deliveryAddress.phone : 'empty',
      country: deliveryAddress?.country ? deliveryAddress.country : 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
      isCustom: deliveryAddress?.isCustom ?? false,
    };

    // handle BU address iso2Code
    if (shippingAddress.country === 'empty') {
      shippingAddress.country = AddressUtils.getDeliveryCountryByIsoCode(shippingAddress.iso2Code)?.name || 'empty';
    }

    return shippingAddress;
  }

  private generateQuotePdf(): void {
    if (this.isJpStore && this.cartId) {
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, false).pipe(take(1))
        .subscribe(file => {
          FileUtils.saveAndOpenFile(file, FileType.PDF, `Cart_details_${this.cartId}`);
        });
    }
  }

  private selectCartItemsSubscription(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      take(1),
    ).subscribe(cartItems => {
      if (cartItems && cartItems.length > 0 && !this.cartItemsWithDetails?.length) {
        this.cartItemsWithDetails = cartItems;
      }
    });
  }

  private selectExcludeTax() {
    this.excludeTax = this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXCLUDE_TAX);
  }

  private loadCartData(): void {
    this.store.select(ShopCartSelectors.selectCartItems).pipe(
      take(1),
    ).subscribe(cartItems => {
      if (!cartItems) {
        return;
      }

      this.cartItems = ObjectUtils.deepClone(cartItems.data.attributes);
      this.cartRules = cartItems.included.filter(include => include.type === 'cart-rules');
      this.analyticsService.setProducts(cartItems);
    });

    this.marketingFacade.selectCartId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(cartId => {
        this.cartId = cartId;
        this.analyticsService.setCartId(this.cartId);
      });
    this.analyticsService.trackPageReady('delivery details', PageTypes.DELIVERY_DETAILS_PAGE, 'concrete-products');
  }


  /**
   * Generate message for modal that pops up after click on generate quote button. Approver role has URL for redirect
   * to order-approval page only when APPROVER_HYPERLINK feature toggle is enabled.
   */
  generateSuccessModalMessage(): void {
    let messageKey: string = 'checkout-delivery-details.approval-success-message';
    const messageUrl: string = this.i18nService.getCurrentLocale() + '/order-approval/' + this.cartId;
    let messageArgs: object = {};
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.APPROVER_HYPERLINK).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: boolean) => {
      if (value && this.userRoles.includes(EUserRoles.Approver)) {
        messageKey = 'checkout-delivery-details.approval-success-message-approver';
        messageArgs = {navigationToHospitalOrder: messageUrl};
      }
    });
    this.i18nService.getTranslationByKey(messageKey, messageArgs)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((translation: string) => this.successModalMessage = translation);
  }

  selectIsQuoteApprovalForCheckoutRequired(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_QUOTE_APPROVAL_MANDATORY).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCheckoutQuoteApprovalMandatory = state;
    });
  }
}
