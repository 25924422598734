import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { filter } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandler } from './error/GlobalErrorHandler';
import { WindowService } from './services/window.service';

import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { reducers } from './reducers';
import { AppEffects } from './effects/app.effects';
import { ShopCartEffects } from './effects/shop-cart.effects';
import { CatalogEffects } from './effects/catalog.effects';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageMaintenanceComponent } from './components/page-maintenance/page-maintenance.component';
import { HomeComponent } from './components/home/home.component';
import { ProductPageComponent } from './components/product-page/product-page.component';
import { CatalogComponent } from './components/page-catalog/catalog.component';
import { CatalogProductsComponent } from './components/page-catalog/catalog-products/catalog-products.component';
import { HelpMeChooseComponent } from './components/page-catalog/catalog-products/help-me-choose/help-me-choose.component';
import { CategoryTreeComponent } from './components/category-tree/category-tree.component';
import { CategoryTreeChildComponent } from './components/category-tree/category-tree-child/category-tree-child.component';

import { I18nModule } from './i18n/i18n.module';
import { InstallBaseComponent } from './components/install-base/install-base.component';
import { PageMyOrdersComponent } from './components/page-my-orders/page-my-orders.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopCartComponent } from './components/page-shop-cart/shop-cart/shop-cart.component';
import { CartItemsComponent } from './components/page-shop-cart/shop-cart/cart-items/cart-items.component';
import { SparePartDeliveryDetailsComponent } from './components/spare-part-delivery-details/spare-part-delivery-details.component';
import { SparePartContactDetailsSectionComponent } from './components/spare-part-delivery-details/spare-part-contact-details-section/spare-part-contact-details-section.component';
import { SparePartOrderDetailsSectionComponent } from './components/spare-part-delivery-details/spare-part-order-details-section/spare-part-order-details-section.component';
import { DeliveryDetailsComponent } from './components/delivery-details/delivery-details.component';
import { MyOrderDetailComponent } from './components/page-my-orders/my-order-detail/my-order-detail.component';
import { MyOrderHistDetailComponent } from './components/page-my-orders/my-order-hist-detail/my-order-hist-detail.component';
import { SparePartOrderReviewComponent } from './components/spare-part-order-review/spare-part-order-review.component';
import { OrderApproveComponent } from './components/order-approve/order-approve.component';

import { localStorageSync } from 'ngrx-store-localstorage';
import { OrderSummarySuccessComponent } from './components/order-summary-success/order-summary-success.component';
import { NavigationEnd, Router, UrlHandlingStrategy } from '@angular/router';
import { DatePipe, LocationStrategy, registerLocaleData } from '@angular/common';
import { LanguageUrlStrategy } from './routing/LanguageUrlStrategy';
import { LanguageLocationStrategy } from './routing/LanguageLocationStrategy';
import { OrderTrackingHistoryComponent } from './components/order-tracking-history/order-tracking-history.component';
import { RequestQuoteComponent } from './components/page-request-quote/request-quote.component';
import { PageRequestTrackingHistoryComponent } from './components/page-request-tracking-history/page-request-tracking-history.component';
import { MyRequestDetailsComponent } from './components/page-my-orders/my-request-details/my-request-details.component';

import localePt from '@angular/common/locales/pt';
import localeJa from '@angular/common/locales/ja';
import localeFr from '@angular/common/locales/fr';
import { PageCmsComponent } from './components/page-cms/page-cms.component';
import { ContentBannerComponent } from './components/page-cms/cms-components/content-banner/content-banner.component';
import { TermsComponent } from './components/page-terms/terms.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { CustomerEffects } from './effects/customer.effects';
import { StoreSelectionComponent } from './components/page-store-selection/store-selection.component';
import { StoreSelectionMapComponent } from './components/page-store-selection/store-selection-map/store-selection-map.component';
import { PendingOrdersListComponent } from './components/page-my-orders/pending-orders-list/pending-orders-list.component';
import { PreviousOrdersListComponent } from './components/page-my-orders/previous-orders-list/previous-orders-list.component';
import { PreviousRequestsListComponent } from './components/page-my-orders/previous-requests-list/previous-requests-list.component';
import { ShopCategoriesComponent } from './components/shop-categories/shop-categories.component';
import { FeaturedProductComponent } from './components/featured-product/featured-product.component';
import { SlickCarouselComponent, SlickItemDirective } from './shared/slick-carousel/slick.component';
import { WishlistsComponent } from './components/page-wishlists/wishlists.component';
import { WishlistComponent } from './components/page-wishlists/wishlist/wishlist.component';
import { WishlistEditComponent } from './components/page-wishlists/wishlist-edit/wishlist-edit.component';
import { WishlistEffects } from './effects/wishlist.effects';
import { WishlistRemoveComponent } from './components/page-wishlists/wishlist-remove/wishlist-remove.component';
import { RequestDetailsSectionComponent } from './components/page-request-quote/request-details-section/request-details-section.component';
import { ContactDetailsSectionComponent } from './components/page-request-quote/contact-details-section/contact-details-section.component';
import { OrderDetailsSectionComponent } from './components/delivery-details/order-details-section/order-details-section.component';
import { OrderContactDetailsSectionComponent } from './components/delivery-details/order-contact-details-section/order-contact-details-section.component';
import { MyContractsComponent } from './components/page-my-installed-base/my-contracts/my-contracts.component';
import { FunctionalLocationsListComponent } from './components/page-my-installed-base/functional-locations-list/functional-locations-list.component';
import { ContractDetailComponent } from './components/page-my-installed-base/contract-detail/contract-detail.component';
import { ContractStepsBarComponent } from './components/contract-steps-bar/contract-steps-bar.component';
import { StoreConfigurationEffects } from './effects/store-configuration.effects';
import { GuidedQuestionsComponent } from './components/page-guided-questions/guided-questions.component';
import { RecommendationsComponent } from './components/page-guided-questions/page-recommendations/recommendations.component';
import { ServiceConfigurationComponent } from './components/page-service-configuration/service-configuration.component';
import { ConfigurationComponent } from './components/page-service-configuration/configuration/configuration.component';
import { CpqEffects } from './effects/cpq.effects';
import { PriceListComponent } from './components/page-service-configuration/price-list/price-list.component';
import { ConfigurationSummaryComponent } from './components/page-configuration-summary/configuration-summary.component';
import { ConfigurationSummaryListComponent } from './components/page-configuration-summary/configuration-summary-list/configuration-summary-list.component';
import { ChangeLogComponent } from './components/page-service-configuration/change-log/change-log.component';
import { SystemDetailComponent } from './components/page-quote-review-finalize/quote-summary/system-detail/system-detail.component';
import { SystemPreviewComponent } from './components/page-quote-review-finalize/quote-summary/system-preview/system-preview.component';
import { ThankYouForQuoteComponent } from './components/page-thank-you-for-quote/thank-you-for-quote.component';
import { QuoteSummaryComponent } from './components/page-quote-review-finalize/quote-summary/quote-summary.component';
import { PreviousQuotesListComponent } from './components/page-my-orders/previous-quotes-list/previous-quotes-list.component';
import { MyQuoteDetailsComponent } from './components/page-my-orders/my-quote-details/my-quote-details.component';
import { ContractDetailsComponent } from './components/page-service-configuration/contract-details/contract-details.component';
import { AlmostDoneComponent } from './components/page-quote-review-finalize/page-almost-done/almost-done.component';
import { PagePurchaseActivityComponent } from './components/page-purchase-activity/page-purchase-activity.component';
import { ServiceListComponent } from './components/page-purchase-activity/service-purchase-activity/service-list/service-list.component';
import { ServiceDetailsComponent } from './components/page-purchase-activity/service-purchase-activity/service-details/service-details.component';
import { ServiceAdditionalDetailsComponent } from './components/page-purchase-activity/service-purchase-activity/service-additional-details/service-additional-details.component';
import { MultiCartComponent } from './components/page-shop-cart/multi-cart/multi-cart.component';
import { CurrentCartComponent } from './components/page-shop-cart/multi-cart/current-cart/current-cart.component';
import { CurrentQuoteComponent } from './components/page-shop-cart/multi-cart/current-quote/current-quote.component';
import { OtherCartComponent } from './components/page-shop-cart/multi-cart/other-cart/other-cart.component';
import { OtherQuoteComponent } from './components/page-shop-cart/multi-cart/other-quote/other-quote.component';
import { PageShopCartComponent } from './components/page-shop-cart/page-shop-cart.component';
import { PageQuoteReviewFinalizeComponent } from './components/page-quote-review-finalize/page-quote-review-finalize.component';
import { AddQuoteModalComponent } from './components/page-my-installed-base/add-quote-modal/add-quote-modal.component';
import { PageContactUsComponent } from './components/page-contact-us/page-contact-us.component';
import { PageContactUsGeneralComponent } from './components/page-contact-us-general/page-contact-us-general.component';
import { ContactUsSubmitModalComponent } from './components/page-contact-us/contact-us-submit-modal/contact-us-submit-modal.component';
import { ConflictModalComponent } from './components/page-service-configuration/conflict-modal/conflict-modal.component';
import { CpqDocumentDownloadComponent } from './components/cpq-document-download/cpq-document-download.component';
import { FullPageLoaderComponent } from './components/full-page-loader/full-page-loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyEquipmentComponent } from './components/page-my-installed-base/my-equipment/my-equipment.component';
import { MyInstalledBaseComponent } from './components/page-my-installed-base/my-installed-base.component';
import { PageMyProfileComponent } from './components/page-my-profile/page-my-profile.component';
import { PersonalDataComponent } from './components/page-my-profile/personal-data/personal-data.component';
import { AccountSettingsComponent } from './components/page-my-profile/account-settings/account-settings.component';
import { AddItemModalComponent } from './shared/add-item-modal/add-item-modal.component';
import { RemoveItemFromOrderModalComponent } from './shared/remove-item-from-order-modal/remove-item-from-order-modal.component';
import { BasicProductPdpComponent } from './components/product-page/basic-product-pdp/basic-product-component-pdp.component';
import { ConfigurableProductPdpComponent } from './components/product-page/configurable-product-pdp/configurable-product-pdp.component';
import { SparePartProductPdpComponent } from './components/product-page/spare-part-product-pdp/spare-part-product-pdp.component';
import { BasicProductComponent } from './components/page-catalog/catalog-products/basic-product/basic-product.component';
import { SparePartProductComponent } from './components/page-catalog/catalog-products/spare-part-product/spare-part-product.component';
import { ConfigurableProductComponent } from './components/page-catalog/catalog-products/configurable-product/configurable-product.component';
import { OrderThankYouPageComponent } from './components/order-thank-you-page/order-thank-you-page.component';
import { ServicePurchaseActivityComponent } from './components/page-purchase-activity/service-purchase-activity/service-purchase-activity.component';
import { SparePartPurchaseActivityComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-purchase-activity.component';
import { SparePartOrderHistoryComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-order-history/spare-part-order-history.component';
import { SparePartListComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-order-history/spare-part-list/spare-part-list.component';
import { SparePartItemDetailsComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-order-history/spare-part-list/spare-part-item-details/spare-part-item-details.component';
import { SparePartHybrisOrderHistoryComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-hybris-order-history/spare-part-hybris-order-history.component';
import { SparePartHybrisListComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-hybris-order-history/spare-part-hybris-list/spare-part-hybris-list.component';
import { SparePartHybrisItemDetailsComponent } from './components/page-purchase-activity/spare-part-purchase-activity/spare-part-hybris-order-history/spare-part-hybris-list/spare-part-hybris-item-details/spare-part-hybris-item-details.component';
import { FilterByAttributesPipe } from './shared/pipes/filter-by-attributes.pipe';
import { SearchFieldPipe } from './shared/pipes/search-field.pipe';
import { SparePartOrderDetailsComponent } from './components/spare-part-order-details/spare-part-order-details.component';
import { SparePartHybrisOrderDetailsComponent } from './components/spare-part-hybris-order-details/spare-part-hybris-order-details.component';
import { InstallBaseEffects } from './effects/install-base.effects';
import { OrdersEffects } from './effects/orders.effects';
import { HybrisOrdersEffects } from './effects/hybris-orders.effects';
import { CheckoutEffects } from './effects/checkout.effect';
import { FormatDatePipe } from './shared/pipes/format-date.pipe';
import { FormatTimePipe } from './shared/pipes/format-time.pipe';
import { FormatDateTimePipe } from './shared/pipes/format-date-time.pipe';
import { RemoveLeadingZeroPipe } from './shared/pipes/remove-leading-zero.pipe';
import { HomepageEffects } from './effects/homepage.effects';
import { AddPartsToCartComponent } from './components/page-shop-cart/multi-cart/current-cart/add-parts-to-cart/add-parts-to-cart.component';
import { MyEquipmentSelectionComponent } from './components/my-equipment-selection/my-equipment-selection.component';
import { MyEquipmentSelectionContractDetailComponent } from './components/my-equipment-selection/my-equipment-selection-contract-detail/my-equipment-selection-contract-detail.component';
import { MyEquipmentSelectionListComponent } from './components/my-equipment-selection/my-equipment-selection-list/my-equipment-selection-list.component';
import { PriceDisputingComponent } from './components/page-shop-cart/multi-cart/current-cart/price-disputing/price-disputing.component';
import { DeliveryAddressesComponent } from './components/page-my-profile/delivery-addresses/delivery-addresses.component';
import { DeliveryAddressComponent } from './components/page-my-profile/delivery-addresses/delivery-address/delivery-address.component';
import { AddEditDeliveryAddressModalComponent } from './components/page-my-profile/delivery-addresses/delivery-address/add-edit-delivery-address-modal/add-edit-delivery-address-modal.component';
import { AuthService } from './services';
import { HarmonizedPageRequestQuoteComponent } from './components/harmonized-page-request-quote/harmonized-page-request-quote.component';
import { RequestQuote1SectionComponent } from './components/harmonized-page-request-quote/request-quote-1-section/request-quote-1-section.component';
import { RequestQuote2SectionComponent } from './components/harmonized-page-request-quote/request-quote-2-section/request-quote-2-section.component';
import { HarmonizedPageDeliveryDetailsComponent } from './components/harmonized-page-delivery-details/harmonized-page-delivery-details.component';
import { DeliveryDetails1SectionComponent } from './components/harmonized-page-delivery-details/delivery-details-1-section/delivery-details-1-section.component';
import { DeliveryDetails2SectionComponent } from './components/harmonized-page-delivery-details/delivery-details-2-section/delivery-details-2-section.component';
import { DynamicCheckoutApproverFormComponent } from './components/harmonized-page-delivery-details/delivery-details-2-section/dynamic-checkout-approver-form/dynamic-checkout-approver-form.component';
import { CheckoutFormComponent } from './components/harmonized-page-order-review/checkout-form/checkout-form.component';
import { HarmonizedPageOrderReviewComponent } from './components/harmonized-page-order-review/harmonized-page-order-review.component';
import { TermsAndConsentsComponent } from './components/harmonized-page-order-review/terms-and-consents/terms-and-consents.component';
import { AddressesSectionComponent } from './components/harmonized-page-order-review/checkout-form/addresses-section/addresses-section.component';
import { TotalsAndDiscountsComponent } from './components/harmonized-page-order-review/totals-and-discounts/totals-and-discounts.component';
import { MyEquipmentComponentsComponent } from './components/page-my-installed-base/contract-detail/my-equipment-components/my-equipment-components.component';
import { HarmonizedPageOrderThankYouComponent } from './components/harmonized-page-order-thank-you/harmonized-page-order-thank-you.component';
import { OrderSummaryComponent } from './components/harmonized-page-order-review/checkout-form/order-summary/order-summary.component';

registerLocaleData(localePt);
registerLocaleData(localeJa);
registerLocaleData(localeFr);

export const notifyMedallia = (windowService: WindowService, router: Router) => {
  const w = windowService.nativeWindow;

  if (!w) {
    return;
  }

  router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    filter(() => w.KAMPYLE_ONSITE_SDK && typeof w.KAMPYLE_ONSITE_SDK.updatePageView === 'function'),
  ).subscribe(() => {
    w.KAMPYLE_ONSITE_SDK.updatePageView();
  });
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'orderApprove',
      'search',
      {
        cpq: ['selectedInstalledBase', 'selectedSystem', 'changeLog', 'lastSubmittedOrder'],
      },
      {
        catalog: ['queryParams', 'installedBase'],
      },
      {
        customer: ['companyRoles'],
      },
    ],
    rehydrate: true,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    PageMaintenanceComponent,
    HomeComponent,
    ProductPageComponent,
    InstallBaseComponent,
    PageMyOrdersComponent,
    ShopCartComponent,
    CartItemsComponent,
    SparePartDeliveryDetailsComponent,
    SparePartContactDetailsSectionComponent,
    SparePartOrderDetailsSectionComponent,
    DeliveryDetailsComponent,
    MyOrderDetailComponent,
    MyOrderHistDetailComponent,
    SparePartOrderReviewComponent,
    OrderApproveComponent,
    OrderSummarySuccessComponent,
    OrderTrackingHistoryComponent,
    CatalogComponent,
    CatalogProductsComponent,
    HelpMeChooseComponent,
    BasicProductComponent,
    SparePartProductComponent,
    ConfigurableProductComponent,
    BasicProductPdpComponent,
    SparePartProductPdpComponent,
    ConfigurableProductPdpComponent,
    CategoryTreeComponent,
    CategoryTreeChildComponent,
    PageCmsComponent,
    ContentBannerComponent,
    TermsComponent,
    PageErrorComponent,
    PageRequestTrackingHistoryComponent,
    RequestQuoteComponent,
    MyRequestDetailsComponent,
    StoreSelectionComponent,
    StoreSelectionMapComponent,
    PendingOrdersListComponent,
    PreviousOrdersListComponent,
    PreviousRequestsListComponent,
    ShopCategoriesComponent,
    FeaturedProductComponent,
    SlickCarouselComponent,
    SlickItemDirective,
    WishlistsComponent,
    WishlistComponent,
    WishlistEditComponent,
    WishlistRemoveComponent,
    RequestDetailsSectionComponent,
    ContactDetailsSectionComponent,
    OrderDetailsSectionComponent,
    OrderContactDetailsSectionComponent,
    MyContractsComponent,
    FunctionalLocationsListComponent,
    ContractDetailComponent,
    ContractStepsBarComponent,
    GuidedQuestionsComponent,
    RecommendationsComponent,
    ServiceConfigurationComponent,
    ConfigurationComponent,
    PriceListComponent,
    ConfigurationSummaryComponent,
    ConfigurationSummaryListComponent,
    ChangeLogComponent,
    PriceListComponent,
    ThankYouForQuoteComponent,
    PreviousQuotesListComponent,
    MyQuoteDetailsComponent,
    ContractDetailsComponent,
    AlmostDoneComponent,
    QuoteSummaryComponent,
    SystemDetailComponent,
    SystemPreviewComponent,
    AlmostDoneComponent,
    PagePurchaseActivityComponent,
    ServiceListComponent,
    ServiceDetailsComponent,
    ServiceAdditionalDetailsComponent,
    MultiCartComponent,
    CurrentCartComponent,
    CurrentQuoteComponent,
    OtherCartComponent,
    OtherQuoteComponent,
    PageShopCartComponent,
    PageQuoteReviewFinalizeComponent,
    AddQuoteModalComponent,
    PageContactUsComponent,
    PageContactUsGeneralComponent,
    ContactUsSubmitModalComponent,
    ConflictModalComponent,
    CpqDocumentDownloadComponent,
    FullPageLoaderComponent,
    MyEquipmentComponent,
    MyInstalledBaseComponent,
    PageMyProfileComponent,
    PersonalDataComponent,
    AccountSettingsComponent,
    AddItemModalComponent,
    RemoveItemFromOrderModalComponent,
    OrderThankYouPageComponent,
    ServicePurchaseActivityComponent,
    SparePartPurchaseActivityComponent,
    SparePartOrderHistoryComponent,
    SparePartListComponent,
    SparePartItemDetailsComponent,
    SparePartHybrisOrderHistoryComponent,
    SparePartHybrisListComponent,
    SparePartHybrisItemDetailsComponent,
    SparePartOrderDetailsComponent,
    SparePartHybrisOrderDetailsComponent,
    AddPartsToCartComponent,
    MyEquipmentSelectionComponent,
    MyEquipmentSelectionContractDetailComponent,
    MyEquipmentSelectionListComponent,
    PriceDisputingComponent,
    DeliveryAddressesComponent,
    DeliveryAddressComponent,
    AddEditDeliveryAddressModalComponent,
    HarmonizedPageRequestQuoteComponent,
    RequestQuote1SectionComponent,
    RequestQuote2SectionComponent,
    HarmonizedPageDeliveryDetailsComponent,
    DeliveryDetails1SectionComponent,
    DeliveryDetails2SectionComponent,
    DynamicCheckoutApproverFormComponent,
    HarmonizedPageOrderReviewComponent,
    CheckoutFormComponent,
    TermsAndConsentsComponent,
    AddressesSectionComponent,
    TotalsAndDiscountsComponent,
    MyEquipmentComponentsComponent,
    HarmonizedPageOrderThankYouComponent,
    OrderSummaryComponent,
  ],
  imports: [
    I18nModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AppEffects,
      ShopCartEffects,
      CatalogEffects,
      CustomerEffects,
      WishlistEffects,
      StoreConfigurationEffects,
      CpqEffects,
      InstallBaseEffects,
      OrdersEffects,
      HybrisOrdersEffects,
      CheckoutEffects,
      HomepageEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 200, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    DatePipe,
    FilterByAttributesPipe,
    SearchFieldPipe,
    FormatDatePipe,
    FormatTimePipe,
    FormatDateTimePipe,
    RemoveLeadingZeroPipe,
    WindowService,
    AuthService,
    {provide: UrlHandlingStrategy, useClass: LanguageUrlStrategy},
    {provide: LocationStrategy, useClass: LanguageLocationStrategy},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: APP_INITIALIZER,
      useFactory: (windowService, router) => () => notifyMedallia(windowService, router),
      deps: [WindowService, Router],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
