<form [formGroup]="customerPreferencesForm" (ngSubmit)="submitForm()" (change)="formChange()">
  <div class="row mr-sm-0">
    <div class="col-12 col-lg-6 mb-lg-0">
      <ng-container *ngIf="preferredPaymentMethodToggle">
        <ng-container *ngFor="let preferredPayment of customerPreferencesExtended.preferredPayment">
          <div class="payment-method form-group">
            <h6 class="margin-top-0">{{ preferredPayment.label }}</h6>
            <ng-container *ngFor="let paymentPreference of preferredPayment.preferences">
              <div class="form-control">
                <input
                    type="radio"
                    class="radio"
                    [formControlName]="preferredPayment.id"
                    [id]="paymentPreference.id"
                    [value]="paymentPreference.key"/>
                <label class="input-label" [for]="paymentPreference.id">{{ paymentPreference.value }}</label>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class="user-rights form-group">
        <h6>{{ 'my-profile.user-rights' | translate }}</h6>
        <app-textfield
            [form]="customerPreferencesForm"
            [modelValue]="customerPreferencesForm.value.userRoles"
            [inputName]="'userRole'"
            [readOnly]="true"
            label="{{ 'my-profile.form.user-role' | translate }}">
        </app-textfield>
      </div>
      <div class="request-support form-group">
        <h6>{{ 'my-profile.action-link.request-support' | translate }}</h6>
        <div class="action-links">
          <a *ngIf="(isBusinessPartner$ | async) === false"
             (click)="showModalReportWrongAddress()"
             [ngClass]="{'disabled': addressesLoading || isSelectedCustomAddress()}"
             [attr.data-test]="'report-wrong-address'"
             [title]="'my-profile.action-link.report-wrong-address' | translate" class="button button--link">
            <span class="icon-arrow-right color-orange"></span>
            <span>{{ 'my-profile.action-link.report-wrong-address' | translate }}</span>
          </a>
          <a [routerLink]="['/contact']" [queryParams]="{'selected-topic': 'missing-equipment'}"
             [attr.data-test]="'missing-equipment'"
             [title]="'my-profile.action-link.report-missing-equipment' | translate" class="button button--link">
            <span class="icon-arrow-right color-orange"></span>
            <span>{{ 'my-profile.action-link.report-missing-equipment' | translate }}</span>
          </a>
          <a [routerLink]="['/contact']" [queryParams]="{'selected-topic': 'general-request'}"
             [attr.data-test]="'contact-us'"
             [title]="'my-profile.action-link.contact-us' | translate" class="button button--link">
            <span class="icon-arrow-right color-orange"></span>
            <span>{{ 'my-profile.action-link.contact-us' | translate }}</span>
          </a>
          <a [routerLink]="['/contact']" [queryParams]="{'selected-topic': 'different-role'}"
             [attr.data-test]="'request-different-role'"
             [title]="'my-profile.action-link.request-different-role' | translate" class="button button--link">
            <span class="icon-arrow-right color-orange"></span>
            <span>{{ 'my-profile.action-link.request-different-role' | translate }}</span>
          </a>
          <a (click)="showDeleteModal = true"
             [attr.data-test]="'cancel-access'"
             [title]="'my-profile.action-link.cancel-access' | translate" class="button button--link">
            <span class="icon-arrow-right color-orange"></span>
            <span>{{ 'my-profile.action-link.cancel-access' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 bg-color-gray-tint-5">
      <div class="email-notifications form-group">
        <h6 class="margin-top-0">{{ 'my-profile.email-notifications' | translate }}</h6>
        <ng-container *ngFor="let notification of customerPreferencesExtended.notifications">
          <div class="email-notifications--group">
            <p class="group-name">{{ notification.label }}</p>
            <div class="group-controls">
              <ng-container *ngFor="let notificationPreference of notification.preferences">
                <div class="form-control">
                  <input
                      type="radio"
                      class="radio"
                      [formControlName]="notification.id"
                      [id]="notificationPreference.id"
                      [value]="notificationPreference.key"/>
                  <label class="input-label"
                         [for]="notificationPreference.id">{{ notificationPreference.value }}</label>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row form-group-button margin-top-1">
    <div class="col-12">
      <ng-container *ngIf="formSubmitted">
        <span *ngIf="formSubmittedSuccessfully">
          <span class="icon-check icon-check__circle"></span>
          {{ 'my-profile.form.form-submitted-successfuly' | translate }}
        </span>
        <span *ngIf="!formSubmittedSuccessfully">
          <span class="icon-close icon-close__circle"></span>
          {{ 'my-profile.form.form-submitted-unsuccessfuly' | translate }}
        </span>
      </ng-container>
      <button type="submit"
              *ngIf="!updatingDataInProgress; else loadingSpinner"
              class="button button--primary"
              [disabled]="isFormPristine"
              [attr.data-test]="'button-saveChanges'">
        {{ 'my-profile.button.save-changes' | translate }}
      </button>
      <ng-template #loadingSpinner>
        <div class="spinner">
          <div class="loading-animation"></div>
        </div>
      </ng-template>
    </div>
  </div>
</form>
<app-modal-confirm
    [type]="iconType.WARNING"
    modalTitle="{{ 'my-profile.modal.delete-account-title' | translate }}"
    notifyText="{{ 'my-profile.modal.delete-account-text' | translate }}"
    cancelBtnText="{{ 'my-profile.button.cancel' | translate }}"
    primaryBtnText="{{ 'my-profile.button.proceed' | translate }}"
    [showModalConfirm]="showDeleteModal"
    (closeModalConfirm)="showDeleteModal = false"
    (primaryActionConfirm)="deleteAccount()">
</app-modal-confirm>
<app-report-wrong-address-by-type-modal
    *ngIf="isSapStore"
    [showModal]="showReportWrongAddressModal"
    (closeModal)="showReportWrongAddressModal = false">
</app-report-wrong-address-by-type-modal>
<app-report-wrong-address-modal
    *ngIf="!isSapStore"
    [addressesList]="getAddressesListReportWrongAddress()"
    [resetAddressList] ="resetAddressesList"
    [showModal]="showReportWrongAddressModal"
    (closeModal)="showReportWrongAddressModal = false">
</app-report-wrong-address-modal>
