import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IPriceDisputingPerItem, ISystemDetails } from '../../../models/common.models';
import { ICustomerCheckoutData } from '../../../models/customer.models';
import { EFeatureToggles, EUserRoles } from '../../../configurations/common';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../../models/cart.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { ImageUtils } from '../../../utils/image.utils';
import { CartUtils } from '../../../utils/cart.utils';
import { DynamicCheckoutFormComponent } from '../../../shared/dynamic-checkout-form/dynamic-checkout-form.component';

@Component({
  selector: 'app-delivery-details-1-section',
  templateUrl: './delivery-details-1-section.component.html',
  styleUrl: './delivery-details-1-section.component.scss',
})
export class DeliveryDetails1SectionComponent implements OnInit, OnDestroy {
  protected readonly ImageUtils = ImageUtils;
  protected readonly CartUtils = CartUtils;
  private unsubscribe$: Subject<void> = new Subject<void>();

  isDeliveryDetailsPage1FormValid: boolean = false;
  deliveryDetails1FormInputs$: Observable<string[]> = new Observable<string[]>();
  deliveryDetailsFormRequiredInputs: string[] = [];
  deliveryDetailsFormReadOnlyInputs$: Observable<string[]> = new Observable<string[]>();

  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  @Input() isSectionDisplayed: boolean = false;
  @Input() cartId: string;
  @Input() cartAttributes: ICartAttributes;
  @Input() cartItemsWithDetails: Array<ICartItemWithDetail>;
  @Input() cartRules: Array<ICartRule>;
  @Input() systemDetails: ISystemDetails;
  @Input() userRoles: EUserRoles[];
  @Input() userData: ICustomerCheckoutData;
  @Input() isBusinessPartner: boolean = false;
  @Input() isSaturdayShipment: boolean = false;
  @Input() isPriceDisputingActive: boolean = false;
  @Input() isPreselectedShipToAddressCustom: boolean = false;
  @Input() isPriceDisputingSetInCart: boolean = false;
  @Input() priceDisputingPerItem: IPriceDisputingPerItem[] = [];

  @Output() proceedToDeliveryDetails2Section: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(DynamicCheckoutFormComponent) dynamicCheckoutForm: DynamicCheckoutFormComponent;

  constructor(private configurationFacade: ConfigurationFacade) {
  }

  /**
   * Initializes the component by getting necessary Arakh configs/feature toggles
   * for the component and its child component (dynamic-checkout-form).
   */
  ngOnInit(): void {
    this.deliveryDetails1FormInputs$ = this.configurationFacade.getDynamicDeliveryDetailsFormConfiguration(1);
    this._selectDeliveryDetailsFormRequiredInputs();
    this.deliveryDetailsFormReadOnlyInputs$ = this.configurationFacade.getDynamicDeliveryDetailsFormReadOnlyInputs();
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
  }

  /**
   * Cleans up the component by caching the delivery details 1 form data and completing the unsubscribe$ subject.
   */
  ngOnDestroy(): void {
    this.dynamicCheckoutForm.cacheCheckoutFormData();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Caches the delivery details 1 form data and emits an event to proceed to the delivery details 2 section.
   */
  proceedToDeliveryDetails2SectionEmit(): void {
    this.dynamicCheckoutForm.cacheCheckoutFormData();
    this.proceedToDeliveryDetails2Section.emit(true);
  }

  /**
   * Selects the required inputs for the delivery details 1 form based on the Arakh config.
   *
   * @private
   */
  private _selectDeliveryDetailsFormRequiredInputs(): void {
    this.configurationFacade.getDynamicDeliveryDetailsFormRequiredInputs().pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.deliveryDetailsFormRequiredInputs = value;
    });
  }
}
