import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '../../../analytics/analytics.service';
import { AuthorizationFacade } from '../../../facades/authorization.facade';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { WishlistFacade } from '../../../facades/wishlist.facade';
import { ICatalogProduct, IProductLabel } from '../../../models/abstract-product.models';
import { IContractParams, IInstallBaseQueryParams, IItemToAddData, IProductData } from '../../../models/catalog.models';
import { IconType } from '../../../models/settings.model';
import { ICart } from '../../../models/cart.models';
import { ISystemDetails } from '../../../models/common.models';
import { EFeatureToggles, EInstalledBaseTabs, EUserRoles } from '../../../configurations/common';
import { CustomerFacade } from '../../../facades/customer.facade';
import { requireProductsData } from '../catalog-data';
import { IBaseWishlist } from '../../../models/wishlist.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-catalog-products',
  templateUrl: './catalog-products.component.html',
  styleUrls: ['./catalog-products.component.scss'],
})
export class CatalogProductsComponent implements OnInit, OnDestroy, OnChanges {
  iconType = IconType;
  itemToAdd: IItemToAddData[] = [];
  wishlists: IBaseWishlist[] = [];
  hasCartContract: boolean = false;
  itemsInQueueToCart: Array<string> = [];
  addNewModalActive: boolean = false;
  addItemToCartModalActive: boolean = false;
  showModalSelectEquipment: boolean = false;
  hasRequiredOfferings: boolean = false;
  requiredProducts: string[] = requireProductsData;
  pricingGroupToggle: boolean = false;
  productPricingGroup: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() isUsStore: boolean;
  @Input() isCaStore: boolean;
  @Input() isSparePartsEnabled: boolean;
  @Input() isCpqEnabled: boolean;
  @Input() currentCart: ICart;
  @Input() currentCartId: string;
  @Input() systemDetails: ISystemDetails;
  @Input() products: Array<ICatalogProduct>;
  @Input() labels: IProductLabel[];
  @Input() layoutType: string;
  @Input() productsLoading: boolean;
  @Input() activeRoute: string;
  @Input() contractParams: IContractParams;
  @Input() loadingCartDataInProgress: boolean;
  companyRoles: EUserRoles[];
  isMyInstalledBaseFlowEnabled: boolean = false;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private authorizationFacade: AuthorizationFacade,
    private marketingFacade: MarketingFacade,
    private wishlistFacade: WishlistFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.selectIsMyInstalledBaseFlowEnabled();
    this.getCompanyRolesSubscription();
    this.selectIsUserLoggedIn();
    this.selectItemsInQueueToCart();
    this.selectPricingGroupToggle();
    if (this.isUsStore) {
      this.selectHasCartContract();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.products) {
      this.setHasRequiredOfferings();
    }
  }

  /**
   * Method to add to cart
   * @param {ICatalogProduct} product
   */
  addToCart(product: ICatalogProduct): void {
    const systemDetails: ISystemDetails = this.systemDetails;
    const concreteSku: string = this.getItemConcreteSku(product);

    let data: IProductData = {
      sku: concreteSku || product.abstractSku,
      name: product.abstractName || product.abstractSku,
      metaKeywords: product.attributes?.metaKeywords,
      systemDetails: null,
      systemDetailsPerItem: [],
      subsequentMaterial: this.getSubsequentMaterial(product),
    };

    if (this.isItemInProgress(product) || this.loadingCartDataInProgress) {
      return;
    }

    if (this.hasCartContract) {
      this.itemToAdd = [{
        data: data,
        isConcrete: !!concreteSku,
        quantity: 1,
      }];
      this.addItemToCartModalActive = true;
      return;
    }

    if (this.isMyInstalledBaseFlowEnabled) {
      if (!systemDetails?.siemensEquipmentId) {
        this.productPricingGroup = product?.attributes?.sap_p40_material_pricing_group_3;
        this.showModalSelectEquipment = true;
        return;
      }
    }

    this.marketingFacade.addProductToCart(data, !!concreteSku);
    this.analyticsService.setProducts(data);
    this.analyticsService.trackCart('cart.add');
  }

  /**
   * Check if product has subsequent material
   *
   * @param {ICatalogProduct} product
   *
   * @return {boolean}
   */
  isSubsequentMaterialAvailable(product: ICatalogProduct): boolean {
    return !!(product?.attributes?.sap_p40_sales_status === '02'
      && product?.attributes?.sap_p40_subsequent_material);
  }

  /**
   * Get subsequent material for product
   * Handle multiple cases: no subsequent material/one subsequent material
   * Add leading zeros to subsequent material number if needed
   *
   * @param {ICatalogProduct} product
   *
   * @return {string[]}
   */
  getSubsequentMaterial(product: ICatalogProduct): string[] {
    if (this.isSubsequentMaterialAvailable(product)) {
      return [product.attributes.sap_p40_subsequent_material.padStart(8, '0')];
    }

    return [];
  }

  /**
   * Method to handle select Contract
   * @param {ICatalogProduct} product
   */
  handleSelectContract(product: ICatalogProduct): void {
    if (this.getContractParams()) {
      this.marketingFacade.startContractConfiguration(product.abstractSku, this.contractParams['fl-number'], this.contractParams['rel-product-sysivk']);
      return;
    }

    this.router.navigate(['/my-installed-base'], {
      queryParams: {
        selectedService: product.abstractSku,
        tab: EInstalledBaseTabs.CONTRACTS,
      },
    });
  }

  /**
   * Method to show HelpMeChoose
   * @returns {boolean}
   */
  showHelpMeChoose(): boolean {
    return !!this.getContractParams() && !!this.products.find(product => product?.productConfigurationInstance)
      && !this.activeRoute.includes('search-results') && !this.productsLoading && this.hasRequiredOfferings;
  }

  /**
   * Method to get company role
   * @returns {Subscription}
   */
  getCompanyRolesSubscription(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles().subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  /**
   * Method to select equipment
   */
  selectEquipment(): void {
    let commands: string = '/my-installed-base';
    let tab: EInstalledBaseTabs = EInstalledBaseTabs.EQUIPMENT;

    if (this.companyRoles.includes(EUserRoles.BusinessPartnerApprover) ||
      this.companyRoles.includes(EUserRoles.BusinessPartnerBuyer)) {
      commands = '/equipment-selection';
      tab = null;
    }

    let queryParams: IInstallBaseQueryParams = {
      tab: tab,
    };

    if (this.pricingGroupToggle) {
      queryParams = {
        ...queryParams,
        pricing_group: this.productPricingGroup,
      };
    }
    this.router.navigate([commands], {
      queryParams: queryParams,
    }).then();
  }

  /**
   * Method to create wishlist
   */
  wishlistCreated(): void {
    this.wishlistFacade.isNewWishlistCreated().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isNewWishlistCreated => {
      this.addNewModalActive = !isNewWishlistCreated;
    });
  }

  /**
   * Method to get wishlist from store
   * @private
   */
  private getWishlistsFromStore(): void {
    this.wishlistFacade.selectUsersWishlists().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(wishlists => {
      this.wishlists = wishlists;
    });
  }

  /**
   * Method to select if user is logged in
   * @private
   */
  private selectIsUserLoggedIn(): void {
    this.authorizationFacade.selectIsUserLoggedIn().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.wishlistFacade.dispatchUsersWishlistRetrieve();
        this.getWishlistsFromStore();
      }
    });
  }

  /**
   * Method to select if cart has a contract
   * @private
   */
  private selectHasCartContract(): void {
    this.marketingFacade.selectHasCartContract().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.hasCartContract = value;
    });
  }

  /**
   * Method to select if items are in queue to cart
   * @private
   */
  private selectItemsInQueueToCart(): void {
    this.marketingFacade.selectItemsInQueueToCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(itemsInQueueToCart => {
      this.itemsInQueueToCart = itemsInQueueToCart;
    });
  }

  /**
   * Method to select Pricing Group toggle
   * @private
   */
  private selectPricingGroupToggle(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_PRICING_GROUP).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.pricingGroupToggle = value;
    });
  }

  /**
   * Method to get item concrete SKU
   * @param {ICatalogProduct} product
   * @returns {string | undefined}
   * @private
   */
  private getItemConcreteSku(product: ICatalogProduct): string | undefined {
    if (!product?.attributeMap?.product_concrete_ids) {
      return undefined;
    }

    return product.attributeMap.product_concrete_ids[0];
  }

  /**
   * Method to check if item is in progress
   * @param {ICatalogProduct} product
   * @returns {boolean}
   * @private
   */
  private isItemInProgress(product: ICatalogProduct): boolean {
    const concreteSku: string = this.getItemConcreteSku(product);
    return this.itemsInQueueToCart.includes(concreteSku) || this.itemsInQueueToCart.includes(product.abstractSku);
  }

  /**
   * Method to get contract params
   * @returns {IContractParams | null}
   * @private
   */
  private getContractParams(): IContractParams | null {
    if (!this.contractParams || Object.keys(this.contractParams).find(key => this.contractParams[key] === '')) {
      return null;
    }

    return this.contractParams;
  }

  /**
   * Method to set HasRequiredOfferings
   * @private
   */
  private setHasRequiredOfferings(): void {
    const requiredProducts: ICatalogProduct[] = this.products.filter(product => this.requiredProducts.includes(product.abstractSku));
    this.hasRequiredOfferings = requiredProducts.length > 0;
  }

  /**
   * Method for retrieving IsMyInstalledBaseFlowEnabled feature toggle value
   * @private
   */
  private selectIsMyInstalledBaseFlowEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isMyInstalledBaseFlowEnabled => {
      this.isMyInstalledBaseFlowEnabled = isMyInstalledBaseFlowEnabled;
    });
  }
}
