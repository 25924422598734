import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ICheckoutInput,
  ICustomerCheckout,
  IConsentCheckbox,
  IAddressData,
  IShipmentMethod,
  ICheckoutFormData,
  IPaymentsData,
  IPaymentProviderName,
} from '../../models/checkout.models';
import { IAddress, IPointOfContact, IPriceDisputingPerItem } from '../../models/common.models';
import {
  ECartStatus,
  EDeliveryInputNames,
  EFeatureToggles,
  EGlueResource,
  EStoreTypes,
} from '../../configurations/common';
import { IconType } from '../../models/settings.model';
import { Observable, Subject, combineLatest } from 'rxjs';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '../../analytics/analytics.service';
import { MarketingFacade } from '../../facades/marketing.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { map, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { AppUtils } from '../../utils/app.utils';
import { OrderReviewService } from './services/order-review.service';
import { I18nService } from '../../services';
import { PayloadUtils } from '../../utils/payload.utils';
import { CartUtils } from '../../utils/cart.utils';
import { ISapMessage } from '../../models/sap.model';
import { ICart, ICartItemWithDetail, ICartRule } from '../../models/cart.models';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { TaxUtils } from '../../utils/tax.utils';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { ShopCartActions } from '../../actions';
import { OrdersUtils } from '../../utils/orders.utils';
import { CheckoutFormComponent } from './checkout-form/checkout-form.component';
import { AddressUtils } from '../../utils/address.utils';

@Component({
  selector: 'app-harmonized-page-order-review',
  templateUrl: './harmonized-page-order-review.component.html',
  styleUrls: ['./harmonized-page-order-review.component.scss'],
})
export class HarmonizedPageOrderReviewComponent implements OnInit, OnDestroy {
  protected readonly AppUtils = AppUtils;
  protected readonly EStoreTypes = EStoreTypes;
  protected readonly IconType = IconType;
  private unsubscribe$ = new Subject<void>();

  // Content generation
  termsAndConsentsCheckboxes: IConsentCheckbox[] = [];
  checkoutAddresses: IAddressData[] = [];
  protected isSidebarTitleVisible$: Observable<boolean> =
              this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_SIDEBAR_TITLE);
  protected isSidebarSubtitleVisible$: Observable<boolean> =
              this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_SIDEBAR_SUBTITLE);

  // Cart related variables
  cartId: string;
  cart: ICart;
  cartItemsWithDetails: ICartItemWithDetail[];
  cartRules: ICartRule[];
  cartSapMessages: ISapMessage[] = [];
  isCartLoading: boolean = true;
  cartHasTotalPriceToPayZero: boolean = false;
  taxPercentage: number = 0;
  priceDisputingPerItems: IPriceDisputingPerItem[] = [];
  selectedShipmentMethod: IShipmentMethod;
  billingAddress: IAddress;

  // Operations
  isUnavailableShipmentMethodModalOpen: boolean = false;
  isSubmitButtonDisabled: boolean = false;

  // General data
  isSapP40Enabled$: Observable<boolean> = new Observable<boolean>();
  cacheSapPoNumberAndCopyEmail: boolean = true;
  checkoutFormData: ICheckoutFormData;
  payments: IPaymentsData[] = [];
  defaultPayment: string;
  arePaymentsLoading: boolean = true;
  isPlacingOrderInProgress: boolean = false;
  isReloadInProgress: boolean = false;

  isCartStatusWaiting: boolean = false;

  @ViewChild(CheckoutFormComponent) checkoutForm: CheckoutFormComponent;

  constructor(
    private checkoutFacade: CheckoutFacade,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    private translateService: TranslateService,
    protected orderReviewService: OrderReviewService,
    protected i18nService: I18nService,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.isSapP40Enabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40);
    this._getCurrentCartId();
    this._getCurrentCartData();
    this._selectPriceDisputingPerItems();
  }

  ngOnDestroy(): void {
    if (this.cacheSapPoNumberAndCopyEmail) {
      this.checkoutFacade.setSapPoNumber(this.checkoutFormData?.purchaseOrderNumber);
      this.checkoutFacade.setOrderEmailCopy(this.checkoutFormData?.orderEmailCopy);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Reload data after adding/removing the voucher (mainly for reloading payments).
   */
  reloadData(): void {
    this.isReloadInProgress = true;
    this._getCurrentCartData();
  }

  /**
   * Get current cart id from URL.
   * @private
   */
  private _getCurrentCartId(): void {
    this.route.params.subscribe(params => {
      if (params.orderId) {
        this.cartId = params.orderId;
      }
    });
  }

  /**
   * Fetch data from following glue resources:
   * CART_ITEMS, CONCRETE_PRODUCTS, CONCRETE_PRODUCT_IMAGE_SETS, VOUCHERS, CART_RULES, RFQ_PRODUCTS, SAP_MESSAGES,
   * SAP_ITEM_AVAILABILITIES, SHIPMENTS.
   * @private
   */
  private _getCurrentCartData(): void {
    this.marketingFacade.getCartItems(this.cartId)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.isCartLoading = false;
          this.cart = response.data;
          this.isCartStatusWaiting = this.cart.attributes.approverDetails.status === ECartStatus.WAITING;
          this.cartItemsWithDetails = CartUtils.mapAvailabilitiesToCartItems(
            PayloadUtils.getItemsWithDetailsFromInclude(response.included),
            response.included);
          this.cartRules = response.included
            .filter(responseInclude => responseInclude.type === EGlueResource.CART_RULES);
          this.cartSapMessages = response.included
            .filter(responseInclude => responseInclude.type === EGlueResource.SAP_MESSAGES);
          this.selectedShipmentMethod = response.included
            .filter(responseInclude => responseInclude.type === EGlueResource.SHIPMENTS)[0]
            .attributes?.selectedShipmentMethod;
          this.taxPercentage = TaxUtils.getTaxPercentage(this.cartItemsWithDetails);

          this.getPayments();

          this.termsAndConsentsCheckboxes =
            this.orderReviewService.createTermsAndConsentsCheckboxes(this.cart.attributes.taxDisclaimer);
          this.checkoutAddresses = this.orderReviewService.checkoutAddressesInitialization(this.cart);
          this.orderReviewService.updateTotalsAndDiscounts(this.cart.attributes.totals, this.cart.attributes.discounts);
          this.cartHasTotalPriceToPayZero = this.cart?.attributes.totals.priceToPay === 0;

          if (!this.cart.attributes.billingAddress) {
            this._getBusinessAddress();
          }

          //Dispatch update shop-cart application state with fresh data.
          if (!this.isCartStatusWaiting) {
            this.store.dispatch(ShopCartActions.loadCartItemsSuccess({cartItems: response}));
          }

          this.analyticsService.setCartId(this.cartId);
          this.analyticsService.setProducts(this.cart);
          this.analyticsService.trackPageReady('spare part - order review', PageTypes.ORDER_REVIEW, 'concrete-products');
        },
        error: () => {
          this.router.navigate([this.i18nService.getCurrentLocale()]).then();
          this.isCartLoading = false;
        },
      });
  }

  /**
   * Fetch all available payments.
   */
  getPayments(): void {
    this.checkoutFacade.postCheckoutData({
      data: {
        type: 'checkout-data',
        attributes: {
          idCart: this.cartId,
        },
      },
    }).pipe(take(1)).subscribe(availablePayments => {
      let payments: IPaymentsData[] = availablePayments?.data?.attributes?.paymentProviders?.map(paymentProvider =>
        paymentProvider?.paymentMethods?.map(paymentMethod => {
          return {
            paymentProviderName: paymentProvider?.paymentProviderName,
            paymentMethodName: paymentMethod?.paymentMethodName,
          };
        }),
      ).flat();

      combineLatest([
        this.configurationFacade.getDynamicCheckoutPaymentMethods$(),
        this.configurationFacade.getDynamicCheckoutPaymentMethodDefault$(),
        this.configurationFacade.getTranslationByKey('config.invoice_payment_limit'),
      ]).pipe(take(1)).subscribe(([visiblePayments, defaultPayment, invoicePaymentLimit]) => {
        payments = payments.filter(payment => {
          return payment?.paymentProviderName
            && payment?.paymentMethodName
            && visiblePayments?.includes(payment.paymentProviderName);
        });

        if (this.cart.attributes.totals.priceToPay <= invoicePaymentLimit) {
          payments = payments.filter(payment => payment.paymentProviderName !== IPaymentProviderName.DUMMY);
        }

        this.defaultPayment = payments.some(payment => payment.paymentProviderName === defaultPayment)
          ? defaultPayment
          : payments[0]?.paymentProviderName;

        this.payments = payments;
        this.arePaymentsLoading = false;

        if (this.isReloadInProgress) {
          this.isReloadInProgress = false;

          // if user has already preselected a different payment method which is still available after reload
          // then do not change the preselection
          if (!payments.some(payment => payment.paymentProviderName === this.checkoutForm.defaultPayment)) {
            this.checkoutForm.setPayment(this.defaultPayment);
          }
        }
      });
    });
  }

  /**
   * Fetch price disputing per item as array of IPriceDisputingPerItem.
   * @private
   */
  private _selectPriceDisputingPerItems(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItems => {
        this.priceDisputingPerItems = priceDisputingPerItems;
      });
  }

  // *** Form operations ***

  /**
   * Set if submit button should be disabled (for example while processing discounts in child components).
   * @param {ICheckoutFormData} value
   */
  setCheckoutFormData(value: ICheckoutFormData): void {
    this.checkoutFormData = value;
  }


  /**
   * Form is valid when:
   * - cart doesn't contain error messages from SAP
   * - status of form is "VALID"
   * - all required checkboxes are checked (requirement is specified in arakh)
   * - all shown addresses based on arakh configuration are valid
   * @returns {boolean}
   */
  formIsValid(): boolean {
    return this.orderReviewService.checkHasNoErrorMessages(this.cartSapMessages)
      && this._checkAllRequiredCheckboxes()
      && this._checkAllAddressesAreValid()
      && !this.isSubmitButtonDisabled
      && !this.isReloadInProgress;
  }

  /**
   * Check if all shown addresses are valid.
   * @returns {boolean}
   * @private
   */
  private _checkAllAddressesAreValid(): boolean {
    return this.checkoutAddresses
      .every(address => address?.isAddressValid);
  }

  /**
   * Check if all required checkboxes are checked (requirement is specified in arakh).
   * @returns {boolean}
   * @private
   */
  private _checkAllRequiredCheckboxes(): boolean {
    return this.termsAndConsentsCheckboxes
      .every(checkbox => !checkbox.isRequired || (checkbox.isRequired && checkbox.isChecked));
  }

  /**
   * Set if submit button should be disabled (for example while processing discounts in child components).
   * @param {boolean} value
   */
  setIsSubmitButtonDisabled(value: boolean): void {
    this.isSubmitButtonDisabled = value;
  }

  /**
   * Open shipment unavailable modal if shipment.validation.expired error is returned from postCheckout.
   */
  showUnavailableShipmentMethodModal(): void {
    this.isUnavailableShipmentMethodModalOpen = true;
  }

  /**
   * Close shipment unavailable modal and redirect to delivery details page with address ID to be preselected.
   */
  closeUnavailableShipmentMethodModal(): void {
    this.isUnavailableShipmentMethodModalOpen = false;
    this.router.navigate(['/delivery-details'],
      {
        queryParams: {
          'redirect-to': EDeliveryInputNames.SHIP_TO_ADDRESS,
        },
      }).then();
  }

  /**
   * Called after submitting order. After receiving price of shipment method via getShipmentMethodPrice() function of
   * CheckoutFacade, checkoutInputRequest is created and passed to postCheckout() function of CheckoutFacade.
   */
  proceedToSummaryPage(): void {
    this.isSubmitButtonDisabled = true;
    this.isPlacingOrderInProgress = true;
    const customerData: ICustomerCheckout = {
      'attentionTo': null,
      'email': null,
      'salutation': null,
      'firstName': null,
      'lastName': null,
      'idCustomer': 0,
      'customerReference': null,
      'uuidCompanyUser': null,
    };

    this.checkoutFacade.getShipmentMethodPrice(this.cartId)
      .pipe(take(1))
      .subscribe(shipmentData => {
        const selectedPayment = this.payments.find(
          payment => payment.paymentProviderName === this.checkoutFormData.paymentMethod,
        );

        const checkoutInputRequest: ICheckoutInput = {
          type: 'checkout',
          attributes: {
            idCart: this.cartId,
            isAddressSavingSkipped: true,
            payments: [
              {
                paymentMethodName: selectedPayment.paymentMethodName,
                paymentProviderName: selectedPayment.paymentProviderName,
              },
            ],
            shipment: {
              idShipmentMethod: this.selectedShipmentMethod.id,
            },
            billingAddress: this.cart.attributes.billingAddress
              ? AddressUtils.updateEmptyAddressFields(this.cart.attributes.billingAddress)
              : this._createBillingAddressFromBusinessUnit(),
            termsAccessTime: new Date(),
            consentedTerms: this.termsAndConsentsCheckboxes
              .filter(checkbox => checkbox.isChecked)
              .map(checkbox => checkbox.name),
            customer: shipmentData?.data?.attributes?.customer as ICustomerCheckout ? shipmentData?.data?.attributes?.customer : customerData,
            sapPoNumber: this.checkoutFormData.purchaseOrderNumber,
            taxNumber: this.checkoutFormData.taxNumber,
            orderEmailCopy: this.checkoutFormData.orderEmailCopy || undefined,
          },
        };

        this.postCheckout(checkoutInputRequest);
        this.customerFacade.clearDeliveryPagesData(this.cartId);
        this.cacheSapPoNumberAndCopyEmail = false;
        LocalStorageUtils.clearKey('opal');
      });
  }

  /**
   * Get billing address from customer business unit.
   * @private
   */
  private _getBusinessAddress(): void {
    this.customerFacade.getBusinessUnits().pipe(
      map((payload) => {
        return PayloadUtils.getIncludedData(payload.data[0], payload.included, 'company-business-unit-addresses')[0];
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(address => {
      this.billingAddress = address.attributes;
    });
  }


  /**
   * Create billing address.
   * @returns {IAddress}
   * @private
   */
  private _createBillingAddressFromBusinessUnit(): IAddress {
    return {
      idCustomerAddress: this.billingAddress.idCustomerAddress,
      idCompanyUnitAddress: this.billingAddress.idCompanyUnitAddress,
      salutation: 'Mr',
      firstName: 'empty',
      lastName: 'empty',
      address1: (this.billingAddress.address1) ? this.billingAddress.address1 : 'empty',
      address2: (this.billingAddress.address2) ? this.billingAddress.address2 : 'empty',
      zipCode: (this.billingAddress.zipCode) ? this.billingAddress.zipCode : 'empty',
      city: (this.billingAddress.city) ? this.billingAddress.city : 'empty',
      iso2Code: (this.billingAddress.iso2Code) ? this.billingAddress.iso2Code : 'empty',
      phone: (this.billingAddress.phone) ? this.billingAddress.phone : 'empty',
      company: 'empty',
      state: (this.billingAddress.state) ? this.billingAddress.state : 'empty',
      country: (this.billingAddress.country) ? this.billingAddress.country : 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
    };
  }

  /**
   * Post checkout data.
   * @param {ICheckoutInput} checkoutInputRequest
   */
  postCheckout(checkoutInputRequest: ICheckoutInput): void {
    this.checkoutFacade.postCheckout({data: checkoutInputRequest})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: checkout => {
          if (checkout.data.id !== this.cartId) {
            this.isPlacingOrderInProgress = false;
            this.analyticsService.setProducts(this.cartItemsWithDetails);
            this.analyticsService.trackCart('cart.order');
            this.marketingFacade.createEmptyCart();
            this.customerFacade.setPointOfContact({} as IPointOfContact);

            //temporary condition to check if the call is working on DEV, as it is not working locally
            if (this.translateService.instant('feature_toggle.call_cc_avenue') === 'true'
              || checkout.data.attributes.payments[0].paymentProviderName === IPaymentProviderName.CC_AVENUE
            ) {
              this.checkoutFacade.getOnlinePaymentData(checkout.data.id).pipe(take(1)).subscribe(data => {
                OrdersUtils.postToCCAvenue(data.data[0].attributes, this.translateService.instant('config.ccavenue_url'))
              });
              return;
            }

            this.router.navigate(['/order-thank-you-page/', checkout.data.id]);
          } else {
            this.isPlacingOrderInProgress = false;
            this.marketingFacade.createEmptyCart();
            this.customerFacade.setPointOfContact({} as IPointOfContact);
            this.orderReviewService.showNotificationAndRedirectToHomePage();
          }
        },
        error: error => {
          this.isPlacingOrderInProgress = false;
          this.isSubmitButtonDisabled = false;
          if (error instanceof HttpErrorResponse && error.status === 504) {
            this.marketingFacade.deleteCart(this.cartId);
            this.orderReviewService.showNotificationAndRedirectToHomePage();
          }
          if ((error?.error?.errors as any[]).find(error => error.detail === 'shipment.validation.expired')) {
            this.showUnavailableShipmentMethodModal();
          } else {
            this.orderReviewService.showNotificationAndRedirectToHomePage();
          }
        },
      });
  }

  /**
   * Show payment condition message when invoice payment is selected.
   * @returns {boolean}
   */
  showInvoicePaymentConditionMessage(): boolean {
    return this.checkoutFormData.paymentMethod === IPaymentProviderName.DUMMY;
  }
}
