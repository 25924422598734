<div class="add-to-cart">
  <h6 [attr.data-test]="'title-add-to-cart-add-another-item-to-selected-cart'">
    {{ 'add-parts-to-cart.add-another-item-to-selected-cart' | translate }}
  </h6>
  <div class="cart-items-list-container">
    <div class="list-header">
      <div class="header-col flex-3" [attr.data-test]="'title-add-to-cart-item-name-or-material-number'">
        {{ 'add-part-to-cart.item-name-or-material-number' | translate }}
      </div>
      <div *ngIf="checkIfProductIsAvailableForOnlinePurchase()"
           class="header-col flex-1"
           [attr.data-test]="'title-add-to-cart-quantity'">{{ 'add-part-to-cart.quantity' | translate }}
      </div>
      <div class="header-col flex-1 blank"
           [ngClass]="{'flex-2': !checkIfProductIsAvailableForOnlinePurchase() && !isItemInProgress()}">
      </div>
    </div>
    <div class="item-row">
      <div class="search-bar item-col flex-3">
        <app-search-bar (product)="product = $event"
                        (loadingButton)="loadingButton = $event"
                        [disabled]="isItemInProgress()"
                        [cartSearch]="true">
        </app-search-bar>
      </div>
      <div *ngIf="checkIfProductIsAvailableForOnlinePurchase()" class="quantity item-col flex-1">
        <form (submit)="updateProductQuantity(itemQuantity.value)">
          <input #itemQuantity
                 type="number"
                 name="itemQuantity"
                 class="item-quantity"
                 [value]="quantity"
                 min="1"
                 max="99"
                 step="1"
                 oninput="this.value=this.value.slice(0,2)"
                 required
                 (change)="updateProductQuantity(itemQuantity.value)"
                 [attr.data-test]="'desc-itemQuantity-add-to-cart'"/>
        </form>
      </div>
      <div class="add-to-cart-button item-col flex-1"
           [ngClass]="{'flex-2': !checkIfProductIsAvailableForOnlinePurchase() && !isItemInProgress()}">
        <ng-container *ngIf="checkIfProductIsAvailableForOnlinePurchase() && !isItemInProgress()">
          <button class="button button--secondary icon-basket button--cart icon__mobile"
                  [disabled]="!product || isItemInProgress() || disabledAddButton"
                  [attr.data-test]="'button-addToCart-add-to-cart'"
                  (click)="addProductToCart()">
            {{ 'app-product-page.add-to-cart' | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="isItemInProgress()">
          <div class="btn-spinner">
            <div class="loading-animation"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!checkIfProductIsAvailableForOnlinePurchase() && !isItemInProgress()">
          <div class="notification__header" [attr.data-test]="'desc-productIsNotAvailable'">
            <p class="font-weight-bold product-not-available">
              <span class="icon-info icon-info__circle icon-orange-white"></span>
              {{ 'app-product-page.product-is-not-available-for-online-purchase' | translate }}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
