@for (element of visibleSections$ | async; track element; let i = $index) {
  <section [class]="'section-' + element">
    <h6 class="section-title" [attr.data-test]="'title-' + element">
      {{ i + 1 }} - {{ generateSectionTitle(element) | translate }}
    </h6>
    @switch (element) {
      @case (ECheckoutSections.ADDRESSES) {
        <app-addresses-section
            [checkoutAddresses]="checkoutAddresses"
            [systemDetails]="systemDetails"
            [selectedShipmentMethodName]="selectedShipmentMethod.name"
            [cartId]="cartId"
            [isCartStatusWaiting]="isCartStatusWaiting"
        />
      }
      @case (ECheckoutSections.PAYMENT_METHODS) {
        <div class="section-content-container">
          <div class="payment-method-option">
            @if (isReloadInProgress) {
              <div class="loading-animation"></div>
            } @else {
              <app-radio-buttons
                  [form]="orderReviewForm"
                  [values]="getPaymentProviders()"
                  [formControlName]="'paymentMethod'">
              </app-radio-buttons>
            }
          </div>
        </div>
      }
      @case (ECheckoutSections.DISCOUNTS) {
        <div class="section-content-container">
          @if (!AppUtils.isSapStore() && !AppUtils.isStoreActive(EStoreTypes.JP)) {
            @if (!isDiscountSectionOpened) {
              <button class="button button button-add-code" type="button"
                      (click)="openDiscountSection()"
                      [attr.data-test]="'button-addCode'">
                {{ 'order-review.add-discount' | translate }}
              </button>
            }
            @if (isDiscountSectionOpened) {
              <div class="discount-container">
                <div class="checkout__field">
                  <app-textfield
                      class="checkout__textfield"
                      [form]="orderReviewForm"
                      [modelValue]="orderReviewForm.value.discount"
                      [markInvalid]="discountHasError"
                      [cssStyles]="{wrapper: 'textfield-with-prefix textfield-discount'}"
                      [inputName]="'discount'"
                      label="{{ 'order-review.discount-input-label' | translate }}"
                      [readOnly]="isDiscountOperationInProgress"
                      (keydown.enter)="addDiscount()">
                  </app-textfield>
                  <div class="checkout__alerts">
                    @if (orderReviewForm.get('discount').touched && this.discountHasError) {
                      <small class="textfield__hint" [attr.data-test]="'hint-invalidCode'">
                        {{ 'order-review.invalid-code' | translate }}
                      </small>
                    }
                  </div>
                </div>
                <div class="add-discount-button-container">
                  <button
                      type="button"
                      class="button button add-discount__btn"
                      [ngClass]="{'in-progress': isDiscountOperationInProgress}"
                      (click)="addDiscount()"
                      [disabled]="isDiscountButtonDisabled()"
                      [attr.data-test]="'button-applyDiscount'">
                    @if (isDiscountOperationInProgress) {
                      <span class="loading-animation"></span>
                    } @else {
                      <span>{{ 'order-review.apply-discount' | translate }}</span>
                    }
                  </button>
                </div>
              </div>
            }
          }
        </div>
      }
      @case (ECheckoutSections.PO_NUMBER) {
        <div class="section-content-container">
          <div class="po-number-container">
            <app-textfield
                class="purchase-order-number"
                label="{{ 'order-review.enter-your-order-number' | translate }}"
                [form]="orderReviewForm"
                [modelValue]="orderReviewForm.value.purchaseOrderNumber"
                [inputName]="'purchaseOrderNumber'"
                [isRequired]="orderReviewService.isFormFieldRequired('po_number')"
                (fieldFilled)="AppUtils.setFormValue($event, orderReviewForm)">
            </app-textfield>
            @if (orderReviewService.isFormFieldRequired('po_number')
            || purchaseOrderNumberIsFilledButCartHasTotalPriceToPayZero()) {
              <p class="disclaimer--mandatory-fields">
                <small [attr.data-test]="'desc-PONumberField'">
                  {{
                    (purchaseOrderNumberIsFilledButCartHasTotalPriceToPayZero()
                        ? 'order-review.default-purchase-order-number-notification'
                        : 'order-review.mandatory-fields-required') | translate
                  }}
                </small>
              </p>
            }
          </div>
        </div>
      }
      @case (ECheckoutSections.DELIVERY_ADDRESS_AND_INSTRUCTIONS) {
        <div class="copy-confirmation-mail-container section-content-container">
          <div class="copy-confirmation-mail-container__label">
            {{ 'order-review.copy-of-confirmation-mail' | translate }}
          </div>
          <app-textfield
              label="{{ 'order-review.enter-email-optional' | translate }}"
              [inputType]="'email'"
              [inputName]="'orderEmailCopy'"
              [form]="orderReviewForm"
              [modelValue]="orderReviewForm.value.orderEmailCopy"
              [isRequired]="orderReviewService.isFormFieldRequired('delivery_address_and_instructions')">
          </app-textfield>
          <div class="checkout__alerts">
            @if (inputIsTouchedAndHasError('orderEmailCopy', 'email')) {
              <small class="textfield__hint">
                {{ 'request-details.email-pattern' | translate }}
              </small>
            }
          </div>
          @if (orderReviewService.isFormFieldRequired('delivery_address_and_instructions')) {
            <p class="disclaimer--mandatory-fields">
              <small [attr.data-test]="'desc-EmailCopyField'">
                {{ 'order-review.mandatory-fields-required' | translate }}
              </small>
            </p>
          }
        </div>
      }
      @case (ECheckoutSections.BILLING_TAX_NUMBER) {
        <div class="section-content-container">
          <div class="form-group">
            <app-textfield
                class=""
                [form]="orderReviewForm"
                [cssStyles]=""
                [inputName]="'taxNumber'"
                label="{{ 'order-review.billing-tax-number-type' | translate }}"
                [isRequired]="orderReviewService.isFormFieldRequired('billing_tax_number')"
                [modelValue]="orderReviewForm.value.taxNumber"
                (fieldFilled)="AppUtils.setFormValue($event, orderReviewForm)">
            </app-textfield>
            <div class="checkout__alerts">
              @if (inputIsTouchedAndHasError('taxNumber', 'pattern')) {
                <small class="textfield__hint" [attr.data-test]="'hint-modalMaxCharacters'">
                  {{ 'order-review.max-characters' | translate: {charNumber: 30} }}
                </small>
              }
            </div>
            @if (orderReviewService.isFormFieldRequired('billing_tax_number')) {
              <p class="disclaimer--mandatory-fields">
                <small [attr.data-test]="'desc-EmailCopyField'">
                  {{ 'order-review.mandatory-fields-required' | translate }}
                </small>
              </p>
            }
          </div>
        </div>
      }
      @case (ECheckoutSections.ORDER_SUMMARY) {
        <app-order-summary
            [pointOfContact]="pointOfContact"
            [selectedShipmentMethod]="selectedShipmentMethod"
            [cartItemsWithDetails]="cartItemsWithDetails"
            [currency]="currency"
            [cartId]="cartId"
            [priceDisputingPerItems]="priceDisputingPerItems"
            [isCartStatusWaiting]="isCartStatusWaiting"/>
      }
    }
  </section>
}
