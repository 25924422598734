import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import * as ValidationPatterns from '../../../configurations/validations';
import { ISelectEvent } from '../../../models/common.models';
import { ICustomerExtended } from '../../../models/customer.models';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit {
  @Input() customerData: ICustomerExtended;

  customerDataForm: UntypedFormGroup;
  languages: IBaseConfig[];
  onlineServiceUrl = environment.onlineServiceUrl;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  setFormValue(event: ISelectEvent): void {
    this.customerDataForm.patchValue({
      [event.key]: event.value
    });
  }

  private initializeForm(): void {
    this.languages = environment.preferredLanguages;
    
    this.customerDataForm = this.formBuilder.group({
      firstName: ['', [Validators.required, ValidationPatterns.onlyLetters]],
      lastName: ['', [Validators.required, ValidationPatterns.onlyLetters]],
      address: ['', [Validators.required]],
      city: [''],
      zip: [''],
      state: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, ValidationPatterns.phonePattern]],
      preferredLanguage: ['']
    });

    this.customerDataForm.patchValue(this.customerData);
    this.customerDataForm.disable();
  }

}
