import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as ValidationPatterns from '../../configurations/validations';
import { CpqFacade } from '../../facades/cpq.facade';
import { IAddress, ISelectEvent } from '../../models/common.models';
import { IWrongAddressForCpq } from '../../models/cpq.models';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ArrayUtils } from '../../utils/array.utils';
import { EAddressType, EGlueResource } from '../../configurations/common';

@Component({
  selector: 'app-report-wrong-address-by-type-modal',
  templateUrl: './report-wrong-address-by-type-modal.component.html',
  styleUrls: ['./report-wrong-address-by-type-modal.component.scss'],
})
export class ReportWrongAddressModalByTypeComponent implements OnInit, OnDestroy {
  wrongAddressForm: UntypedFormGroup;
  dataToSend: IWrongAddressForCpq;
  addressTypes: Array<{name: string, value: string}> = [];
  addresses: IAddress[] = [];
  addressesByType: Array<{name: string, value: string}>;
  addressSelectBoxPlaceholder = 'addresses.address';
  loading = false;
  addressesLoading = false;
  maxLengthComment = 500;

  private unsubscribe$ = new Subject<void>();

  @Input() showModal: boolean;
  @Output() closeModal = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cpqFacade: CpqFacade,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  formIsValid(): boolean {
    return this.wrongAddressForm.status === 'VALID';
  }

  resetForm(): void {
    this.closeModal.emit();
    this.wrongAddressForm.reset();
  }

  setAddressTypeFormValue(event: ISelectEvent): void {
    this.addressSelectBoxPlaceholder = `addresses.${event.value}-address`;

    this.wrongAddressForm.patchValue({
      addressType: event.value,
    });

    this.getAddressesByType(event.value);
  }

  setFormValue(event: ISelectEvent): void {
    this.wrongAddressForm.patchValue({
      [event.key]: event.value,
    });
  }

  reportAddress(): void {
    if (this.formIsValid()) {
      const addressToReport = this.addresses.find(address => address.sapId === this.wrongAddressForm.value.address);
      const addressToReportFormatted = `${addressToReport.sapId} - ${addressToReport.name}  
                                        (${addressToReport.address1}, ${addressToReport.city}, 
                                        ${addressToReport.state}, ${addressToReport.zipCode})`;

      this.dataToSend = {
        type: EGlueResource.CPQ_ADDRESS_REPORT_WRONG,
        attributes: {
          addressType: this.wrongAddressForm.value.addressType,
          address: addressToReportFormatted,
          comment: this.wrongAddressForm.value.comment,
          functionalLocations: ['-'],
          quoteUuid: '-',
        },
      };

      this.cpqFacade.postCpqWrongAddress({data: this.dataToSend}).pipe(
        take(1),
      ).subscribe(
        () => this.postDataActions(),
      );
    }

    this.wrongAddressForm.reset();
  }

  private postDataActions(): void {
    this.loading = false;
    this.closeModal.emit();

    this.cpqFacade.showAdditionalDeliverableNotification(
      'success',
      'report-wrong-address.reported',
      [{
        key: 'report-wrong-address.reported-desc',
        params: {},
        css: [],
      }],
      [],
    );
  }

  private initializeForm(): void {
    this.wrongAddressForm = this.formBuilder.group({
      addressType: ['', Validators.required],
      address: ['', Validators.required],
      comment: ['', [Validators.required, Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });

    Object.values(EAddressType).forEach(item => {
      this.addressTypes.push({
        name: this.translate.instant('addresses.' + item),
        value: item,
      });
    });
  }

  private getAddressesByType(addressType: string): void {
    this.addressesLoading = true;
    this.addressesByType = [];

    this.cpqFacade.getCpqWrongAddress(addressType).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        const allAddresses = addresses.data[0].attributes?.addresses;
        const addressesWithNames = allAddresses.filter(address => address.name !== null);
        this.addresses = ArrayUtils.uniqueObjects(addressesWithNames, 'sapId');

        this.addresses.forEach(address => {
          const itemName = `${address.sapId} - ${address.name.toUpperCase()}`;
          this.addressesByType.push({name: itemName, value: address.sapId});
        });

        this.addressesLoading = false;
      }
    });
  }
}
