import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectUtils } from '../../utils/object.utils';
import { IEquipmentFilter } from '../../models/equipment-filter.model';
import { ISystemDetails } from '../../models/common.models';
import { IEquipmentComponentFilter } from '../../models/equipment-component-filter.model';

@Component({
  selector: 'app-marked-filters',
  templateUrl: './marked-filters.component.html',
  styleUrls: ['./marked-filters.component.scss'],
})
export class MarkedFiltersComponent implements OnInit, OnChanges {
  activeFilters = [];
  showBPRewoke: boolean = false;

  @Input() filters: Array<any>;
  @Input() equipmentFilter: IEquipmentFilter;
  @Input() equipmentComponentFilter: IEquipmentComponentFilter;
  @Input() equipmentComponentFilterActive: boolean;
  @Input() emptyCatalog: boolean;
  @Input() systemDetails: ISystemDetails;
  @Output() deselectFilterOption = new EventEmitter<any>();

  constructor(
    private router: Router,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters?.currentValue || changes.equipmentFilter?.currentValue) {
      this.initFilters();
    }
    if (this.emptyCatalog && (this.systemDetails !== undefined)) {
      this.initFilters();
    }
    this.initFilters();
  }

  ngOnInit(): void {
    const urlTree = this.router.parseUrl(this.router.url);
    this.initFilters();
  }

  initFilters(): void {
    const mergedActiveFilters = [];
    if (this.emptyCatalog) {
      const urlTree = this.router.parseUrl(this.router.url);
      if (urlTree.queryParams['fl-number']) {
        let flName = 'noName';
        let sysIvk = 'noIvk';
        if (this.systemDetails !== undefined) {
          flName = this.systemDetails.nameEnUs;
          sysIvk = this.systemDetails.siemensEquipmentId;
        }
        mergedActiveFilters.push({
          name: flName,
          entities: [sysIvk],
        });
      }
    }
    if (this.equipmentFilter &&
      this.equipmentFilter.isApplicable &&
      this.equipmentFilter.activeValue?.length > 0 &&
      !this.equipmentComponentFilterActive) {
      mergedActiveFilters.push({
        name: this.equipmentFilter.name,
        entities: this.mapRelProductSysivkToValueArray(),
      });
    }
    // Add condition for equipmentComponentFilter
    if (this.equipmentComponentFilter &&
      this.equipmentComponentFilter.isApplicable
      && this.equipmentComponentFilterActive) {
      mergedActiveFilters.push({
        name: this.equipmentComponentFilter.name,
        entities: this.mapRelSparepartIvkToValueArray(),
      });
    }
    this.filters.forEach(filter => {
      if (filter.activeValue) {
        mergedActiveFilters.push({
          name: filter.name,
          entities: Array.isArray(filter.activeValue) ? filter.activeValue : [filter.activeValue],
        });
      }
    });
    this.activeFilters = mergedActiveFilters;
  }

  mapRelSparepartIvkToValueArray(): Array<string> {
    return this.equipmentComponentFilter.activeValue?.map(
      activeValue => this.equipmentComponentFilter?.values?.find(value => value['rel-sparepart-ivk'] === activeValue).value,
    );
  }

  mapValueToRelSparepartSysivk(option: string): string | string[] {
    return this.equipmentComponentFilter?.values?.find(value => value.value === option)['rel-sparepart-ivk'];
  }

  mapRelProductSysivkToValueArray(): Array<string> {
    return this.equipmentFilter.activeValue?.map(
      activeValue => this.equipmentFilter?.values?.find(value => value['rel-product-sysivk'] === activeValue).value,
    );
  }

  mapValueToRelProductSysivk(option: string): string {
    return this.equipmentFilter?.values?.find(value => value.value === option)['rel-product-sysivk'];
  }

  filterResults(filter, option, routeUrl: string): void {
    const pageUrl = decodeURIComponent(routeUrl.split('?')[0]);
    const urlTree = this.router.parseUrl(routeUrl);

    if (this.emptyCatalog && filter !== 'rel-sparepart-ivk') {
      delete urlTree.queryParams['rel-product-sysivk'];
      delete urlTree.queryParams['fl-number'];
      delete urlTree.queryParams['rel-sparepart-ivk'];

      urlTree.queryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, filter);
    }

    let queryParamsArray = [];
    //handle case where params are separated by comma for equipmentComponentFilter
    if (filter === 'rel-sparepart-ivk' && urlTree.queryParams[filter]) {
      queryParamsArray = urlTree.queryParams[filter].split(',');
    } else {
      queryParamsArray = urlTree.queryParams[filter];
    }
    if (Array.isArray(queryParamsArray)) {
      if (filter === 'rel-product-sysivk') {
        queryParamsArray = queryParamsArray.filter(queryParam => queryParam !== this.mapValueToRelProductSysivk(option));
      } else if (filter === 'rel-sparepart-ivk') {
        queryParamsArray = queryParamsArray.filter(queryParam => queryParam !== this.mapValueToRelSparepartSysivk(option));
      } else {
        queryParamsArray = queryParamsArray.filter(queryParam => queryParam !== option);
      }

      if (queryParamsArray.length === 0 && filter === 'rel-sparepart-ivk') {
        delete urlTree.queryParams[filter];
      } else {
        urlTree.queryParams[filter] = queryParamsArray.join(',');
      }
    } else {
      if (filter === 'rel-product-sysivk') {
        if (urlTree.queryParams[filter] === this.mapValueToRelProductSysivk(option)) {
          delete urlTree.queryParams[filter];
        }
      } else if (filter === 'rel-sparepart-ivk') {
        if (urlTree.queryParams[filter] === this.mapValueToRelSparepartSysivk(option)) {
          delete urlTree.queryParams[filter];
        }
      } else {
        if (urlTree.queryParams[filter] === option) {
          delete urlTree.queryParams[filter];
        }
      }
    }

    const url = this.router.createUrlTree(
      [pageUrl],
      {queryParams: {...urlTree.queryParams, page: '1'}},
    );
    this.router.navigateByUrl(url.toString()).then();
  }
}
