<div class="my-equipment">
  <app-access-control [description]="true">
    <ng-container ngProjectAs="[slot][content]">
      <div class="spare-parts-agreements" [appLoadingAnimation]="installBaseLoading">
        <div class="advanced-search">
          <div class="advanced-search--header">
            <button
                class="button"
                [ngClass]="advancedSearchDropdownIsOpened ? 'icon-arrow-top' : 'icon-arrow-down'"
                [attr.data-test]="'button-advancedSearch'"
                (click)="clearSelectedFiltersOptions()">
              {{ 'my-contracts.advanced-search' | translate }}
            </button>
            <div class="advanced-search--search">
              <div class="search-field textfield textfield--with-prefix">
                <span class="textfield__prefix icon-search"></span>
                <input
                    id="search"
                    name="search"
                    type="search"
                    class="textfield__input search-field__input"
                    spellcheck="false"
                    autocomplete="off"
                    placeholder="{{ 'my-contracts.search' | translate }}"
                    [attr.data-test]="'input-search'"
                    [disabled]="searchLoading"
                    [(ngModel)]="searchValue">
                <label for="search" class="search-bar__label"></label>
              </div>
            </div>
          </div>
          <div class="advanced-search--body"
               *ngIf="advancedSearchDropdownIsOpened">
            <div class="scroll">
              <div class="filters">
                <div class="filter" *ngFor="let filter of contractFilters">
                  <div> {{ 'advanced-filter.' + filter.name | translate }} </div>
                  <div class="search-field textfield textfield--with-prefix">
                    <span class="textfield__prefix icon-search"></span>
                    <input
                        [id]="'item_search' + filter.name"
                        [name]="'search' + filter.name"
                        type="search"
                        class="textfield__input search-field__input"
                        spellcheck="false"
                        autocomplete="off"
                        placeholder="{{ 'advanced-filter.search'  | translate }} {{ 'advanced-filter.' + filter.name | translate }}"
                        [attr.data-test]="'input-filterName-' + filter.name"
                        [(ngModel)]="filterSearchValue[filter.name]">
                    <label for="search" class="search-bar__label"></label>
                  </div>
                  <div class="selection" *ngIf="filter.options">
                    <div class="select-option">
                      <input
                          class="checkbox"
                          type="checkbox"
                          [id]="'select-all-' + filter.name"
                          [name]="'checkbox-all-' + filter.name"
                          [checked]="allOptionsAreSelected[filter.name]"
                          [attr.data-test]="'input-filterOptions'"
                          (change)="selectAllFilteredOptions(filter.name)">
                      <label [for]="'select-all-' + filter.name">{{ 'my-contracts.select-all' | translate }}</label>
                    </div>
                    <div *ngFor="let option of filter.options | searchFilter: filterSearchValue[filter.name]">
                      <div *ngIf="option" class="select-option" [attr.data-test]="'checkbox-' + option">
                        <input
                            class="checkbox"
                            type="checkbox"
                            [id]="'select-' + option"
                            [name]="'checkbox-' + option"
                            [checked]="selectedOptions[filter.name].includes(option)"
                            [attr.data-test]="'input-filterOptions-' + option"
                            (change)="selectOption(filter.name, option)">
                        <label [for]="'select-' + option">{{ option }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button class="button button--standard"
                      (click)="clearSelectedFiltersOptions()"
                      [attr.data-test]="'button-cancelFilter'">
                {{ 'my-contracts.cancel-button-name' | translate }}
              </button>
              <button class="button button--primary"
                      (click)="applySelectedFiltersOptions()"
                      [attr.data-test]="'button-applyFilter'">
                {{ 'my-contracts.apply-button-name' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="contract-filter-panel">
          <div class="contract-filter-panel--selected-filters mobile">
            <ng-container *ngFor="let filter of contractFilters">
              <p class="contract-filter-panel--selected-filter" *ngFor="let selected of filter.selected">
                {{ selected }}
                <span class="icon icon-close" (click)="unselectOptionForFilter(selected, filter.name)"></span>
              </p>
            </ng-container>
          </div>
          <div class="contract-filter-panel--filters">
            <button
                class="contract-filter-panel--filters-mobile mobile button"
                [ngClass]="active ? 'icon-arrow-up-small' : 'icon-arrow-down-small'"
                [attr.data-test]="'button-activeDropdown'"
                (click)="activeDropdown()">
              {{ 'my-contracts.categories-and-filters' | translate }}
            </button>
            <div
                class="contract-filter-panel--filters-desktop"
                [ngClass]="active ? 'is-open' : ''"
                (window:resize)="onResize($event)">
              <app-dropdown
                  *ngFor="let filter of contractFilters;  last as isLast"
                  [cssStyles]="{ dropdown: active ? 'filters':  'user-identifier', toggle: active ? 'filters-toggle': 'user-identifier__toggle-button' }"
                  [dropDownName]="filter.name"
                  [isCheckbox]=true
                  [hideOnMenuClick]=false
                  [keepActive]=false
                  [checkboxOptions]="filter.options"
                  [checkboxName]=filter.name
                  (filters)="setSelectedOptionsForFilter($event, filter.name)">
                <ng-container ngProjectAs="[slot][toggle]">
                  <div class="dropdown desktop">
                    <h6 class="dropdown--name" [attr.data-test]="'title-filterName'">
                      {{ 'advanced-filter.' + filter.name | translate }}
                    </h6>
                    <h6 class="icon-arrow-down"></h6>
                  </div>
                  <div class="dropdown-toggle mobile" [ngClass]="isLast ? '' : 'dropdown-border'">
                    <span class="dropdown-toggle--name" [attr.data-test]="'title-filterNameMobile'">
                      {{ 'advanced-filter.' + filter.name | translate }}
                    </span>
                    <span class="icon-arrow-down"></span>
                  </div>
                </ng-container>
              </app-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 agreements-selectable-equipment" *ngIf="isConsumableEquipmentPurchaseEnabled else oldUi">
          <div class="col-12 agreements-selectable-equipment">
            <div class="agreements-info selectable-equipment">
              <div class="agreements-info__title">
                <h5 class="text">{{ 'my-equipment.displayed-equipment' | translate }}</h5>
                <span class="icon-check icon-check__circle"></span>
              </div>
              <div class="agreements-info__desc" *ngIf="displayedEquipments?.length > 0 else noEquipments">
                <p class="text" [attr.data-test]="'desc-selectableEquipment1'">
                  {{ 'my-equipment.displayed-equipment-desc1' | translate }}
                </p>
                <p class="text" [attr.data-test]="'desc-selectableEquipment2'">
                  {{ 'my-equipment.displayed-equipment-desc2' | translate }}
                  <app-tooltip
                      [useBothTogglers]="true"
                      linkCss="fl-text"
                      link="{{ 'my-contracts.find-functional-location' | translate }}"
                      text="{{ 'my-contracts.find-functional-location-tooltip-text' | translate }}">
                  </app-tooltip>
                  {{ 'my-equipment.displayed-equipment-desc3' | translate }}
                </p>
              </div>
              <ng-template #noEquipments>
                <p class="agreements-info__no-agreements">
                  {{ 'my-equipment.no-displayed-equipment' | translate }}
                </p>
              </ng-template>
            </div>
            <ng-container *ngIf="displayedEquipments?.length > 0">
              <app-functional-locations-list
                  [isCartOperationInProgress]="isCartOperationInProgress"
                  [loadingCartDataInProgress]="loadingCartDataInProgress"
                  [agreements]="displayedEquipments"
                  [loading]="installBaseLoading"
                  [searchValue]="searchValue"
                  [filters]="contractFilters"
                  [currentCartId]="currentCartId"
                  [cartItems]="cartItems"
                  [productSku]="productSku"
                  [soldToId]="soldTo"
                  [isEquipment]="true"
                  [selectableEquipment]="true"
                  [isReorderPending]="isReorderPending"
                  [companyRoles]="companyRoles"
                  [isConsumableEquipmentPurchaseEnabled]="isConsumableEquipmentPurchaseEnabled">
              </app-functional-locations-list>
            </ng-container>
          </div>
        </div>
        <ng-template #oldUi>
          <div class="col-12 agreements-selectable-equipment">
            <div class="agreements-info selectable-equipment">
              <div class="agreements-info__title">
                <h5 class="text">{{ 'my-equipment.selectable-equipment' | translate }}</h5>
                <span class="icon-check icon-check__circle"></span>
              </div>
              <div class="agreements-info__desc" *ngIf="sparePartsEnabledEquipments?.length > 0">
                <p class="text" [attr.data-test]="'desc-selectableEquipment1'">
                  {{ 'my-equipment.selectable-equipment-desc1' | translate }}
                </p>
                <p class="text" [attr.data-test]="'desc-selectableEquipment2'">
                  {{ 'my-equipment.selectable-equipment-desc2' | translate }}
                  <app-tooltip
                      [useBothTogglers]="true"
                      linkCss="fl-text"
                      link="{{ 'my-contracts.find-functional-location' | translate }}"
                      text="{{ 'my-contracts.find-functional-location-tooltip-text' | translate }}">
                  </app-tooltip>
                  {{ 'my-equipment.selectable-equipment-desc3' | translate }}
                </p>
              </div>
              <p *ngIf="sparePartsEnabledEquipments?.length === 0" class="agreements-info__no-agreements">
                {{ 'my-equipment.no-selectable-equipment' | translate }}
              </p>
            </div>
            <ng-container *ngIf="sparePartsEnabledEquipments?.length > 0">
              <app-functional-locations-list
                  [isCartOperationInProgress]="isCartOperationInProgress"
                  [loadingCartDataInProgress]="loadingCartDataInProgress"
                  [agreements]="sparePartsEnabledEquipments"
                  [loading]="installBaseLoading"
                  [searchValue]="searchValue"
                  [filters]="contractFilters"
                  [currentCartId]="currentCartId"
                  [cartItems]="cartItems"
                  [productSku]="productSku"
                  [soldToId]="soldTo"
                  [isEquipment]="true"
                  [selectableEquipment]="true"
                  [isReorderPending]="isReorderPending"
                  [companyRoles]="companyRoles">
              </app-functional-locations-list>
            </ng-container>
          </div>
          <ng-container *ngIf="(otherEquipmentEnabled$ | async) && !soldTo">
            <div class="col-12 agreements-other-equipment">
              <div class="agreements-info other-equipment">
                <div class="agreements-info__title">
                  <h5 class="text">{{ 'my-equipment.other-equipment' | translate }}</h5>
                </div>
                <div class="agreements-info__desc" *ngIf="otherEquipment?.length > 0">
                  <p class="text" [attr.data-test]="'desc-otherSelectableEquipment1'">
                    {{ 'my-equipment.other-equipment-desc1' | translate }}
                  </p>
                  <p class="text" [attr.data-test]="'desc-otherSelectableEquipment2'">
                    {{ 'my-equipment.other-equipment-desc2' | translate }}
                    <app-tooltip
                        [useBothTogglers]="true"
                        linkCss="fl-text"
                        link="{{ 'my-contracts.find-functional-location' | translate }}"
                        text="{{ 'my-contracts.find-functional-location-tooltip-text' | translate }}">
                    </app-tooltip>
                    {{ 'my-equipment.other-equipment-desc3' | translate }}
                  </p>
                </div>
                <p *ngIf="otherEquipment?.length === 0" class="agreements-info__no-agreements">
                  {{ 'my-equipment.no-other-equipment' | translate }}
                </p>
              </div>
              <ng-container *ngIf="otherEquipment?.length > 0">
                <app-functional-locations-list
                    [agreements]="otherEquipment"
                    [loading]="installBaseLoading"
                    [searchValue]="searchValue"
                    [filters]="contractFilters"
                    [cartItems]="cartItems"
                    [isEquipment]="true"
                    [selectableEquipment]="false">
                </app-functional-locations-list>
              </ng-container>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </app-access-control>
</div>
