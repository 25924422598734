import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, filter, skipWhile, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { IModalitiesAndMaterialNumbers } from '../../../models/cpq.models';
import { DateUtils } from '../../../utils/date.utils';
import { ICart, ICartUpdateRequestAttributes } from '../../../models/cart.models';
import { IAddress } from '../../../models/common.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { AppActions, CatalogActions } from '../../../actions';
import { IconType } from '../../../models/settings.model';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { EMultiCartTabs, ESparePartsOrderHistoryTabs, EStoreTypes, EUserRoles } from '../../../configurations/common';
import { ISelectSoldTo } from '../../../models/soldTo-selection.models';
import { SoldToAccountsFacade } from '../../../facades/sold-to.facade';
import { FormatDatePipe } from '../../../shared/pipes/format-date.pipe';
import { EquipmentUtils } from '../../../utils/equipment.utils';
import { CustomerFacade } from '../../../facades/customer.facade';
import { AppUtils } from '../../../utils/app.utils';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { IShsEquipmentData, IShsEquipmentDataAttributes } from '../../../models/installedbase.models';

@Component({
  selector: 'app-my-equipment-selection-contract-detail',
  templateUrl: './my-equipment-selection-contract-detail.component.html',
  styleUrls: ['./my-equipment-selection-contract-detail.component.scss'],
})
export class MyEquipmentSelectionContractDetailComponent implements OnInit, OnChanges {
  @Input() additionalData: any;
  @Input() agreement: IShsEquipmentData;
  @Input() cartItems: any[] = [];
  @Input() companyRoles: EUserRoles[];
  @Input() currentCartId: string;
  @Input() isCartOperationInProgress: boolean;
  @Input() isReorderPending: boolean = false;
  @Input() loadingCartDataInProgress: boolean;
  @Input() modalitiesAndMaterialNumbers: IModalitiesAndMaterialNumbers[];
  @Input() productSku: string = '';
  @Input() selectableEquipment: boolean = false;
  @Input() soldToIdFromParams: number;
  @Input() soldToInactive: boolean = false;
  @Input() userCarts: Array<ICart> = [];


  addItemOperationInProgress: boolean = false;
  additionalAttributes = [
    {
      attribute: 'soldToId',
      label: 'my-contracts.sold-to-id',
      tooltip: 'my-contracts.sold-to-id-description-tooltip-text',
    },
    {
      attribute: 'soldToAddress',
      label: 'my-contracts.sold-to-address',
    },
  ];
  attributes = [
    {
      attribute: 'contractName',
      label: 'my-contracts.contract-name',
    },
    {
      attribute: 'contractStartDate',
      label: 'my-contracts.contract-start',
    },
    {
      attribute: 'contractExpirationDate',
      label: 'my-contracts.contract-end',
    },
    {
      attribute: 'startupDate',
      label: 'my-contracts.startup-date',
    },
    {
      attribute: 'contractEndOfSupport',
      label: 'my-contracts.end-of-support',
      tooltip: 'my-contracts.end-of-support-description-tooltip-text',
    },
    {
      nestedAttribute: 'company',
      attribute: 'shipToAddress',
      label: 'my-contracts.customer',
    },
  ];
  equipmentSelectionInProgress: boolean = false;
  iconType = IconType;
  inactive: boolean = false;
  isAddQuoteModalOpen: boolean = false;
  isEquipmentCompatible: boolean = true;
  isLoading: boolean = true;
  lastSku: string;
  loading: boolean = false;
  orderId: string;
  redirect: string;
  showModalChangeEquipmentOrCreateNewCart: boolean = false;
  showModalSelectSoldToAccount: boolean = false;
  soldToAccounts: IAddress[];
  selectedSoldToAccount: IAddress = null;
  showModalBecauseMissingSoldTo: boolean = false;
  showModalEosEquipment: boolean = false;
  isUsStore: boolean = false;
  isBusinessPartner: boolean = false;


  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private catalogFacade: CatalogFacade,
    private soldToAccountFacade: SoldToAccountsFacade,
    private formatDatePipe: FormatDatePipe,
    private customerFacade: CustomerFacade,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.selectIsCustomerBusinessPartner();
    this.isAddItemOperationInProgress();

    if (this.soldToIdFromParams) {
      this.attributes = [...this.attributes, ...this.additionalAttributes];
    }

    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.lastSku = params['lastSku'];
      this.orderId = params['orderId'];
      this.redirect = params['redirect'];
      this.selectEquipmentCompatibility();
    });

    this.getSoldToAccounts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {agreement, cartItems} = changes;
    if (agreement?.currentValue || cartItems?.currentValue) {
      this.checkHasItemInCart();
    }
  }

  isAddItemOperationInProgress(): void {
    this.marketingFacade.isAddItemOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addItemOperationInProgress => this.addItemOperationInProgress = addItemOperationInProgress);
  }

  selectEquipmentCompatibility(): void {
    if (this.selectableEquipment && this.lastSku) {
      this.catalogFacade.getAbstractProductDataFromApi(this.lastSku).pipe(take(1)).subscribe(product => {
        this.isEquipmentCompatible =
          product.data.attributes.attributes?.sap_p40_modality_id === this.agreement.attributes?.modality;
      });
    }
  }

  getSoldToAccounts(): void {
    this.loading = true;
    this.soldToAccountFacade.selectSoldToAccounts()
      .pipe(
        filter((data: IAddress[]): boolean => data !== null),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data: IAddress[]) => {
        this.soldToAccounts = data;
        if (this.soldToAccounts.length === 1) {
          this.showModalBecauseMissingSoldTo = !this.soldToAccounts[0].name;
        } else if (this.soldToAccounts.length === 0) {
          this.showWarning();
        }
        this.loading = false;
      });
  }

  /**
   * @private
   */
  private showWarning(): void {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'issue-with-data.title',
      messages: [{
        key: 'issue-with-data.message',
      }],
    });
  }

  selectFlWithEndOfSupportCheck(): void {
    if (
      this.isUsStore
      && this.isBusinessPartner
      && DateUtils.isAfterEndOfSupport(this.agreement?.attributes?.endOfSupport)
    ) {
      this.showModalEosEquipment = true;
    } else {
      this.selectSoldToAccountAfterSelectedFl();
    }
  }

  private selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  selectSoldToAccountAfterSelectedFl(): void {
    if (this.soldToAccounts?.length > 1 || this.showModalBecauseMissingSoldTo) {
      this.showModalSelectSoldToAccount = true;
    } else {
      this.selectedSoldToAccount = this.selectDefaultSoldToAccount();
      this.startEquipmentSelection();
    }
  }

  selectedSoldToAccountFromModal(selectedSoldToAccount: ISelectSoldTo): void {
    this.selectedSoldToAccount = this.soldToAccounts.find(a =>
      a.id == selectedSoldToAccount.id
      && a.sapId == selectedSoldToAccount.value);
    this.startEquipmentSelection();
  }

  selectDefaultSoldToAccount(): IAddress {
    return this.soldToAccounts?.length > 0 ? this.soldToAccounts[0] : null;
  }

  startEquipmentSelection(): void {
    const {functionalLocation} = this.agreement.attributes;
    this.equipmentSelectionInProgress = true;
    if (this.userCarts && this.currentCartId) {
      let showNotification: boolean = false;
      const currentCart: ICart = this.userCarts.find((cart: ICart): boolean => cart.id === this.currentCartId);
      const cartWithSystemDetails: ICart = this.userCarts.find(
        (cart: ICart): boolean => cart.attributes.systemDetails?.siemensEquipmentId === functionalLocation,
      );
      this.store.dispatch(CatalogActions.setCachedProductsToBeReloaded());
      if (this.isReorderPending) {
        this.updateReorderWithSystemDetails();
        this.updateCartDataWithSystemDetails(this.currentCartId, this.selectedSoldToAccount);
        return;
      }

      if (!!cartWithSystemDetails && !!currentCart) {
        if (currentCart.attributes.systemDetails?.siemensEquipmentId === functionalLocation) {
          this.updateCartDataWithSystemDetails(currentCart.id, this.selectedSoldToAccount);
          this.redirectToCatalogOrPDP();
          return;
        } else {
          if (currentCart.attributes.totalItemsQuantity !== 0 || currentCart.attributes.hasContractInCart) {
            showNotification = true;
          }
          this.switchDefaultCart(cartWithSystemDetails.id, showNotification);
          return;
        }
      } else {
        if (currentCart) {
          if (!currentCart.attributes?.totalItemsQuantity) {
            this.updateCartDataWithSystemDetails(currentCart.id, this.selectedSoldToAccount);
            return;
          }
          if (currentCart.attributes.hasContractInCart) {
            this.createNewCartForEquipment();
            return;
          }
          if (!currentCart.attributes.hasContractInCart && currentCart.attributes.totalItemsQuantity !== 0) {
            this.showModalChangeEquipmentOrCreateNewCart = true;
          }
        } else {
          this.createNewCartForEquipment();
          return;
        }
      }
    }
  }

  getAttribute(attribute: string, nestedAttribute?: string): string {
    let attributeValue: any;
    attributeValue = this.additionalAttributes.some(item => item.attribute === attribute) ?
      this.additionalData[attribute] : EquipmentUtils.getAttribute(this.agreement, attribute, nestedAttribute);

    const datesAttributes: string[] = ['contractStartDate', 'contractExpirationDate', 'startupDate', 'contractEndOfSupport'];
    if (datesAttributes.includes(attribute) && attributeValue !== '-') {
      attributeValue = DateUtils.isValidDate(attributeValue) ? this.formatDatePipe.transform(attributeValue) : '-';
    }
    return attributeValue;
  }

  getCurrentCart(): ICart {
    return this.userCarts.find(cart => cart.id === this.currentCartId);
  }

  disableAgreement(): boolean {
    return this.soldToInactive || (this.agreement?.hasOwnProperty('compatible'));
  }

  openSparePartsViewer(): void {
    window.open(`${environment.sparePartsViewerUrl}&matnum=${this.agreement.attributes.materialNumber}`, '_blank');
  }

  checkHasItemInCart(): void {
    this.inactive = false;
    if (!this.agreement || !this.cartItems || this.cartItems?.length === 0) {
      return;
    }
    this.inactive = !!this.cartItems.find(item =>
      item.attributes.systemDetails?.siemensEquipmentId === this.agreement.attributes?.functionalLocation);
  }

  closeModalChangeEquipmentOrCreateNewCart(): void {
    this.showModalChangeEquipmentOrCreateNewCart = false;
    this.equipmentSelectionInProgress = false;
  }

  createNewCartForEquipment(): void {
    this.createNewCartAndUpdateDataWithSystemDetails(true);
  }

  changeEquipmentForCurrentCart(): void {
    this.showModalChangeEquipmentOrCreateNewCart = false;
    this.updateCartDataWithSystemDetails(this.currentCartId, this.selectedSoldToAccount);
  }

  private createNewCartAndUpdateDataWithSystemDetails(showNotification: boolean = false): void {
    const systemDetails = EquipmentUtils.convertEquipmentToSystemDetails(this.agreement);
    let attributes: ICartUpdateRequestAttributes = {systemDetails};
    if (this.selectedSoldToAccount) {
      attributes = {...attributes, soldToAddress: this.selectedSoldToAccount};
    }
    this.marketingFacade.createEmptyCart(attributes);
    this.marketingFacade.selectCartId().pipe(
      skipWhile(id => !id || id === this.currentCartId),
      take(1),
    ).subscribe(_ => {
      if (showNotification) {
        this.showNotificationYourCartWasChanged();
      }

      this.redirectToCatalogOrPDP();
    });
  }

  private updateReorderWithSystemDetails(): void {
    const systemDetails: IShsEquipmentDataAttributes = {
      ...this.agreement.attributes,
    } as IShsEquipmentDataAttributes;

    this.marketingFacade.updateReorderWithSystemDetailsForFunctionalLocation(systemDetails);
  }

  private updateCartDataWithSystemDetails(cartId: string, soldTo?: IAddress): void {
    const systemDetails = EquipmentUtils.convertEquipmentToSystemDetails(this.agreement);
    if (soldTo) {
      this.marketingFacade.updateCartById(cartId, {systemDetails, soldToAddress: soldTo});
    } else {
      this.marketingFacade.updateCartById(cartId, {systemDetails});
    }

    if (this.isReorderPending) {
      this.marketingFacade.updateReorderWithSystemDetails(systemDetails);
      this.redirectToPurchaseActivity();
    } else {
      this.redirectToCatalogOrPDP();
    }
  }

  private switchDefaultCart(
    cartId: string,
    showNotification: boolean = false,
  ): void {
    let updateRequestAttributes: ICartUpdateRequestAttributes = {};
    updateRequestAttributes.systemDetails = EquipmentUtils.convertEquipmentToSystemDetails(this.agreement);
    if (this.selectedSoldToAccount) {
      updateRequestAttributes.soldToAddress = this.selectedSoldToAccount;
    }

    this.marketingFacade.switchDefaultCart(cartId, updateRequestAttributes);

    if (showNotification) {
      this.showNotificationYourCartWasChanged();
    }
    this.redirectToCatalogOrPDP();
  }

  private showNotificationYourCartWasChanged(): void {
    this.configurationFacade.appendNotification({
      type: 'success',
      title: 'shop-cart.your-cart-was-changed-title',
      messages: [{
        key: 'shop-cart.your-cart-was-changed-text',
      }],
      actions: [
        {
          type: '',
          label: 'shop-cart.close',
          css: ['button', 'button--secondary'],
        },
        {
          type: AppActions.redirectToShopCart.type,
          label: 'shop-cart.go-to-cart',
          css: ['button', 'button--primary'],
        },
      ],
    });
  }

  private redirectToCatalogOrPDP(): void {
    const {materialNumber, siemensEquipmentId} = this.agreement.attributes;
    const catalogQueryParams = {
      'fl-number': siemensEquipmentId,
      'rel-product-sysivk': materialNumber,
    };

    if (!this.redirect || this.redirect.includes('catalog')) {
      this.router.navigate(['/catalog/parts'], {queryParams: catalogQueryParams}).then();
      return;
    }
    this.router.navigate([this.redirect], {}).then();
  }

  private redirectToPurchaseActivity(): void {
    this.marketingFacade.confirmReorder();
    this.router.navigate(
      ['/purchase-activity'],
      {
        queryParams: {
          tab: EMultiCartTabs.PARTS,
          subtab: ESparePartsOrderHistoryTabs.ORDERS,
          orderId: this.orderId,
        },
      },
    ).then();
  }

  isUserSparePartsViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.SPCViewer);
  }

  /**
   * @returns {boolean}
   */
  disableSelectEquipmentButton(): boolean {
    return this.isCartOperationInProgress || this.loadingCartDataInProgress || this.addItemOperationInProgress || this.soldToAccounts?.length === 0;
  }
}
