import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject, Subscription } from 'rxjs';

import { CpqUtils } from '../../utils/cpq.utils';
import { CpqFacade } from '../../facades/cpq.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { CpqActions } from '../../actions';
import { IChange, ICpqResponseResponse, ILastChangedOption, IPricingSummary } from '../../models/cpq.models';
import { ISelectEvent } from '../../models/common.models';
import { IContractParams } from '../../models/catalog.models';
import { EConfigurationStatus } from '../../configurations/cpq';
import { UiUtils } from '../../utils/ui.utils';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-service-configuration',
  templateUrl: './service-configuration.component.html',
  styleUrls: ['./service-configuration.component.scss'],
})
export class ServiceConfigurationComponent implements OnInit, OnDestroy {
  serviceParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': '',
    selectedService: '',
  };
  configurationSteps = [];
  serviceConfigurations = [];
  currentStep = 0;
  saveInProgress = false;
  showSaveDone = false;
  priceList = [];
  activeSummary = 'price-list';
  priceSummary: IPricingSummary;
  cartId: string;
  initializeInProgress = true;
  configId: string;
  changeLog: Array<IChange>;
  showNewChangeMarker = false;
  currentBom: Array<any> = [];
  selectedSystem: any;
  lastChangedOption: ILastChangedOption;
  conflictModalActive = false;
  conflict: ICpqResponseResponse;
  customerBusinessUnitName: string;
  configuratorInitialized = false;
  hasDiscounts = false;
  quoteItemsQuantity = 0;
  initializationSubscription: Subscription;
  showAlmostThere = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private cpqFacade: CpqFacade,
    private marketingFacade: MarketingFacade,
    private router: Router,
    private configFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectChangeLog();
    this.selectShowChangeLogTab();
    this.selectUndoLastOptionCommit();
    this.initializationSubscription = this.initializeConfiguration();
    this.updateLoader(5000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeConfiguration(): Subscription {
    return combineLatest([
      this.marketingFacade.selectCart(),
      this.cpqFacade.selectSelectedSystem(),
    ]).pipe(takeUntil(this.unsubscribe$), map(([cart, selectedSystem]) => (
      {cart, selectedSystem}),
    )).pipe(debounceTime(0)).subscribe(data => {
      if (Object.values(data).every(x => x !== null)) {
        if (data.cart.attributes.totalItemsQuantity === 0) {
          this.cpqFacade.serviceConfigPageRedirectWhenCartEmpty();
          return;
        }
        this.cartId = data.cart.id;
        this.hasDiscounts = data.cart.attributes.discounts.length > 0;
        this.quoteItemsQuantity = data.cart.attributes.totalItemsQuantity;
        this.selectedSystem = data.selectedSystem;
        this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
          this.serviceParams['fl-number'] = params['fl-number'] ?? '';
          this.serviceParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
          this.serviceParams.selectedService = params.selectedService ?? '';
          this.serviceParams.step = params.step ?? '';
          this.configId = params.configId ?? '';
          this.selectConfigurationType();
          this.initializationSubscription.unsubscribe();
        });
      }
    });
  }

  goToTheNextStep(): void {
    const stepIndex = this.currentStep + 1;
    const nextQuestionIndex = stepIndex < this.configurationSteps.length ? stepIndex : this.configurationSteps.length;
    this.changeStep(nextQuestionIndex);
  }

  goBackToPreviousStep(): void {
    const stepIndex = this.currentStep - 1;
    const previousQuestionIndex = stepIndex > 0 ? stepIndex : 0;
    this.changeStep(previousQuestionIndex);
  }

  changeStep(stepIndex: number): void {
    if (this.currentStep !== stepIndex) {
      this.currentStep = stepIndex;
      this.showSaveDone = false;
      UiUtils.scrollToTop();
      if (stepIndex === this.configurationSteps.length) {
        this.router.navigate(['configuration-summary'], {
          queryParams: {
            'fl-number': this.serviceParams['fl-number'],
            'rel-product-sysivk': this.serviceParams['rel-product-sysivk'],
            selectedService: this.serviceParams.selectedService,
            configId: this.configId,
          },
        }).then();
      }
    }
  }

  configChanged(event: any): void {
    const dataFromTextField = event.inputType === 'textField';

    if ((dataFromTextField && event.value === event.oldValue) || (!dataFromTextField && event.name === event.oldValue.name)) {
      return;
    }

    const changes = [{
      markerNew: false,
      type: 'old',
      userAction: true,
      label: `${event.inputDescription} ${dataFromTextField ? event.oldValue : event.oldValue.description}`,
    }, {
      markerNew: true,
      type: 'new',
      userAction: true,
      label: `${event.inputDescription} ${dataFromTextField ? event.value : event.description}`,
    }];
    this.updateChangeLog(changes);
    this.lastChangedOption = {
      parameter: event.key,
      value: dataFromTextField ? event.oldValue : event.oldValue.name,
      label: event.inputDescription,
    };
    this.commitChange(event.key, dataFromTextField ? event.value : event.name);
  }

  toggleSummaryTab(name: string): void {
    this.activeSummary = name;
    this.showNewChangeMarker = false;

    if (this.activeSummary !== 'change-log') {
      this.changeLog = this.changeLog.map(log => {
        return {
          ...log,
          markerNew: false,
        };
      });
    }
    this.cpqFacade.updateChangeLog(this.changeLog);
  }

  getNextBtnLabel(): string {
    return this.currentStep === this.configurationSteps.length - 1
      ? 'service-configuration.review-summary' : 'service-configuration.next-step-btn';
  }

  handleContractChange(event: Array<any> | ISelectEvent): void {
    if (Array.isArray(event)) {
      this.commitBothContractChanges(event);
      return;
    }
    this.commitContractChange(event.key, event.value);
  }

  acceptConflict(): void {
    const {parameter, value} = this.conflict;
    this.saveInProgress = true;
    this.conflictModalActive = false;
    this.acceptConflictChange(parameter, value);
  }

  private initializeCpq(): void {
    this.cpqFacade.postCqpInitialize(this.cartId, this.selectedSystem.sku, this.serviceParams['fl-number'])
      .pipe(take(1)).subscribe({
      next: (response) => {
        const {cpqResponse} = response.data.attributes;
        this.configId = cpqResponse.configId;
        if (this.hasDiscounts) {
          this.recalculateCpq();
        } else {
          this.initializeCpqDefaults();
        }
      },
      error: (err) => {
        if (err?.error?.errors.find(entity => entity.code === 194)) {
          this.marketingFacade.refreshCart(this.cartId);
        }
        this.configFacade.redirectToLastPage();
        this.initializeInProgress = false;
      },
    });
  }

  private initializeCpqDefaults(): void {
    this.cpqFacade.postCpqContractDetailsDefaults(this.cartId, this.selectedSystem.sku, this.configId)
      .pipe(take(1)).subscribe({
      next: response => {
        const {cpqResponse} = response.data.attributes;
        this.setConfiguration(cpqResponse);
        this.currentBom = cpqResponse.bom;
        this.initializeInProgress = false;
        this.cpqFacade.serviceConfigPageShowFLAddedNotification(this.cartId, this.quoteItemsQuantity);
      }, error: () => {
        this.configFacade.redirectToLastPage();
        this.initializeInProgress = false;
      },
    });
  }

  private recalculateCpq(): void {
    this.cpqFacade.postCpqRecalculate(this.cartId).pipe(take(1)).subscribe({
      next: () => {
        this.initializeCpqDefaults();
      },
      error: () => {
        this.initializeInProgress = false;
      },
    });
  }

  private reconfigureCpq(): void {
    this.cpqFacade.postCqpReconfigure(this.cartId, this.selectedSystem.sku, this.configId)
      .pipe(take(1)).subscribe({
      next: (response) => {
        const {cpqResponse} = response.data.attributes;
        this.setConfiguration(cpqResponse);
        this.currentBom = cpqResponse.bom;
        this.initializeInProgress = false;
      },
      error: () => {
        this.initializeInProgress = false;
        this.configFacade.redirectToLastPage();
      },
    });
  }

  private setConfiguration(cpqResponse: any): void {
    const addOnStep = cpqResponse.steps.find(step => step.name === 'addOnStep');
    this.serviceConfigurations = addOnStep?.rootGroup?.members ?? this.serviceConfigurations;
    this.configurationSteps = this.serviceConfigurations.reduce((acc, next, i) => {
      if (next.hasVisibleParameters && !next.name.includes('diastack_include')) {
        acc.push({key: i, value: next.description});
      }
      return acc;
    }, []);
    this.configurationSteps.push({key: this.configurationSteps.length, value: 'Contract details'});
    this.priceList = CpqUtils.groupBom(cpqResponse);
    this.priceSummary = cpqResponse.pricing.summary;
    this.setConfigId(cpqResponse.configId);
    if (this.serviceParams.step === 'contract-details') {
      this.currentStep = this.configurationSteps.find(item => item.value === 'Contract details').key;
    }

    if (cpqResponse.response.status === EConfigurationStatus.RESOLVABLE) {
      this.conflict = cpqResponse.response;
      this.conflictModalActive = true;
    }
    this.configuratorInitialized = true;
  }

  private setConfigId(configId: string): void {
    this.configId = configId;
    const urlTree = this.router.parseUrl(this.router.url);
    const url = this.router.createUrlTree(
      ['/service-configuration'],
      {queryParams: {...urlTree.queryParams, configId}},
    ).toString();

    this.router.navigateByUrl(url).then();
  }

  private getNewBomProducts(bom): Array<any> {
    return bom.reduce((acc, newBomEntity) => {
      const bomFound = this.currentBom.find(currentBomEntity => currentBomEntity.attributes.SKU === newBomEntity.attributes.SKU);
      if (!bomFound) {
        acc.push({
          markerNew: true,
          type: 'new',
          userAction: false,
          label: newBomEntity.description,
        });
      }
      return acc;
    }, []);
  }

  private detectBomChangesAndUpdateLog(bom: Array<any>, showNotification = true): void {
    const newBomProducts = this.getNewBomProducts(bom);
    this.currentBom = bom;
    if (newBomProducts.length > 0) {
      if (showNotification) {
        const prefix = 'service-configuration.';
        this.cpqFacade.showAdditionalDeliverableNotification(
          'warning',
          prefix + 'additional-deliverable-modal-title',
          [
            {
              key: prefix + 'additional-deliverable-modal-message1',
              params: {name: this.lastChangedOption.label},
              css: [],
            },
            {
              key: prefix + (newBomProducts.length === 1
                ? 'additional-deliverable-modal-message2' : 'additional-deliverable-modal-message3'),
              params: {count: newBomProducts.length},
              css: ['font-weight-600'],
            },
          ],
          [
            {
              type: CpqActions.undoLastOptionCommit.type,
              label: prefix + 'undo',
              css: [],
            },
            {
              type: CpqActions.showChangeLogTab.type,
              label: prefix + 'view-change-log',
              css: ['button--primary'],
            },
          ],
        );
      }
    }
  }

  private selectUndoLastOptionCommit(): void {
    this.cpqFacade.selectUndoLastOptionCommit().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(undoLastOptionCommit => {
        if (undoLastOptionCommit) {
          this.unCommitChange(this.lastChangedOption.parameter);
          this.cpqFacade.undoLastOptionCommit();
        }
      },
    );
  }

  private selectShowChangeLogTab(): void {
    this.cpqFacade.selectShowChangeLogTab().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(showChangeLogTab => {
        if (showChangeLogTab) {
          this.toggleSummaryTab('change-log');
          this.cpqFacade.updateShowChangeLogTab();
        }
      },
    );
  }

  private selectChangeLog(): void {
    this.cpqFacade.selectChangeLog().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(changeLog => {
        this.changeLog = changeLog;
        this.updateNewChangeMarker();
      },
    );
  }

  private selectConfigurationType(): void {
    if (this.configId) {
      this.reconfigureCpq();
    } else {
      this.initializeCpq();
    }
  }

  private updateChangeLog(changes: Array<any>, clearAllChecksWithoutNewest = false): void {
    this.changeLog = this.changeLog.map((log, index) => {
      let markerNew;
      if (clearAllChecksWithoutNewest && index === 0) {
        markerNew = log.markerNew;
      } else {
        markerNew = this.activeSummary === 'change-log' ? false : log.markerNew;
      }
      return {
        ...log,
        markerNew,
      };
    });
    changes.forEach(change => {
      this.changeLog.unshift(change);
    });
    this.updateNewChangeMarker();
    this.cpqFacade.updateChangeLog(this.changeLog);
  }

  private updateNewChangeMarker(): void {
    this.showNewChangeMarker = !!this.changeLog.find(entry => entry.markerNew) && this.activeSummary !== 'change-log';
  }

  private commitChange(parameter: string, value: string): void {
    this.saveInProgress = true;
    this.cpqFacade.postCqpCommit(this.cartId, this.selectedSystem.sku, this.configId, parameter, value)
      .pipe(take(1)).subscribe(response => {
      this.handleCommitResponse(response);
    });
  }

  private unCommitChange(parameter: string): void {
    this.saveInProgress = true;
    this.cpqFacade.postCqpUnCommit(this.cartId, this.selectedSystem.sku, this.configId, parameter)
      .pipe(take(1)).subscribe(response => {
      this.handleCommitResponse(response, false);
    });
  }

  private commitContractChange(parameter: string, value: string): void {
    this.saveInProgress = true;
    this.cpqFacade.postCqpCartItemsCommit(this.cartId, this.selectedSystem.sku, this.configId, parameter, value)
      .pipe(take(1)).subscribe(response => {
      this.handleCommitResponse(response);
    });
  }

  private acceptConflictChange(parameter: string, value: string): void {
    this.saveInProgress = true;
    this.cpqFacade.postCqpAccept(this.cartId, this.selectedSystem.sku, this.configId, parameter, value)
      .pipe(take(1)).subscribe(response => {
      this.handleCommitResponse(response);
    });
  }

  private handleCommitResponse(response: any, showNotification = true): void {
    const {cpqResponse} = response.data.attributes;
    this.setConfiguration(cpqResponse);
    this.detectBomChangesAndUpdateLog(cpqResponse.bom, showNotification);
    this.saveInProgress = false;
    this.showSaveDone = true;
  }

  private commitBothContractChanges(changes: Array<{parameter: string; value: any}>): void {
    this.saveInProgress = true;
    this.cpqFacade.postCqpCartItemsCommit(this.cartId, this.selectedSystem.sku, this.configId, changes[0].parameter, changes[0].value)
      .pipe(take(1)).subscribe(() => {
      this.cpqFacade.postCqpCartItemsCommit(this.cartId, this.selectedSystem.sku, this.configId, changes[1].parameter, changes[1].value)
        .pipe(take(1)).subscribe(response => {
        this.handleCommitResponse(response);
      });
    });
  }

  private updateLoader(time: number): void {
    setTimeout(() => {
      this.showAlmostThere = true;
    }, time);
  }
}
