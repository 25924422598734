import { IAbstractProductPrices, IProductImage } from '../models/abstract-product.models';
import { IPrice } from '../models/common.models';
import { IWishlistProductDetails } from '../models/wishlist.models';

export class PayloadUtils {
  static getIncludedData(target: any, included: any, includeName: string): any {
    if (!target?.relationships?.[includeName] || !included) {
      return undefined;
    }
    const id = target.relationships[includeName].data[0].id;
    return included.filter(include => include.type === includeName && include.id === id);
  }

  static getItemDataFromIncludes(included: any, sku: string, includedName: string): any {
    if (!included) {
      return undefined;
    }
    return included.find(value => value.type === includedName && value.id === sku);
  }

  static getPriceFromAbstractProductPrices(prices: IAbstractProductPrices, priceType: string): IPrice | undefined {
    return prices?.attributes.prices?.find(itm => itm.priceTypeName === priceType);
  }

  static getConcreteProductDetails(included: any, sku: string): IWishlistProductDetails {
    const data = PayloadUtils.getItemDataFromIncludes(included, sku, 'concrete-products');
    const image = PayloadUtils.getItemDataFromIncludes(included, sku, 'concrete-product-image-sets') as IProductImage;
    const price = PayloadUtils.getItemDataFromIncludes(included, sku, 'concrete-product-prices') as IAbstractProductPrices;
    const defaultPrice = PayloadUtils.getPriceFromAbstractProductPrices(price, 'DEFAULT') || undefined;
    const secondPrice: IPrice = PayloadUtils.getPriceFromAbstractProductPrices(price, 'GUEST') || undefined;

    return {
      qty: 1,
      imgUrl: image?.attributes?.imageSets[0].images[0].externalUrlSmall,
      name: data.attributes.name,
      sku: data.attributes.sku,
      materialNumber: data.attributes.attributes.material_number || data.attributes.attributes.sap_material_number,
      abstractSku: data.attributes.productAbstractSku,
      pricingGroup: data.attributes?.attributes?.sap_p40_material_pricing_group_3 ?? '',
      defaultPrice,
      secondPrice,
    };
  }

  static getItemDetailsForWishlist(included: any, item: string): IWishlistProductDetails {
    const skuFromWishlistItem = PayloadUtils.getItemDataFromIncludes(
      included, item, 'shopping-list-items');
    const concreteProduct = PayloadUtils.getItemDataFromIncludes(
      included, skuFromWishlistItem.attributes.sku, 'concrete-products');

    return {
      qty: 1,
      name: concreteProduct?.name,
      shoppingListItemId: item,
      sku: concreteProduct?.attributes.sku || skuFromWishlistItem.attributes.sku || skuFromWishlistItem.id,
      abstractSku: concreteProduct?.attributes.productAbstractSku,
    };
  }

  static getItemsWithDetailsFromInclude(
    payloadItems: any,
    reversResult    = false,
    payloadTypes    = ['concrete-products', 'concrete-product-image-sets', 'items'],
    payloadItemType = 'items',
  ): any[] {
    const includes = payloadItems.filter(item => payloadTypes.includes(item.type));
    const items = payloadItems.filter(item => item.type === payloadItemType);
    const resultItems = items.reduce((acc, next) => {
      const includesToMerge = includes.filter(target => target.id.includes(next.id) || next.id.includes(target.id));
      const mergedIncludes = includesToMerge.reduce((includesAcc, include) => {
        if (!includesAcc?.id) {
          includesAcc = include;
        } else {
          const mergedAttributes = {...includesAcc.attributes, ...include.attributes};
          const mergedLvl1Keys = {...includesAcc, ...include};
          includesAcc = Object.assign(mergedLvl1Keys, {attributes: mergedAttributes});
        }
        return includesAcc;
      }, {});
      acc.push(Object.assign({...mergedIncludes, ...next}, {attributes: {...next.attributes, ...mergedIncludes.attributes}}));
      return acc;
    }, []);
    return reversResult ? resultItems.reverse() : resultItems;
  }
}
