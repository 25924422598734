import { Injectable } from '@angular/core';
import { InstallBaseService } from '../services';
import { Observable } from 'rxjs';
import {
  IInstallBaseProducts,
  IInstalledBaseSystemsFilter,
  IShsEquipments,
  IShsEquipmentData,
} from '../models/installedbase.models';
import { Store } from '@ngrx/store';
import * as fromState from '../reducers';
import {
  selectCpqEquipments,
  selectCpqEquipmentsLoading, selectEquipmentsAddresses,
  selectInstalledBaseData,
  selectInstalledBaseDataError,
  selectInstalledBaseProductData,
  selectInstalledBaseProductDataError,
  selectInstalledBaseProductDataLoading,
  selectInstalledBaseSystemDataLoading,
  selectSparePartsEquipments,
  selectSparePartsEquipmentsLoading,
} from '../reducers/install-base.reducer';
import { EGlueResource } from '../configurations/common';
import { IAddress } from '../models/common.models';

@Injectable({
  providedIn: 'root',
})
export class InstallBaseFacade {

  constructor(private installBaseService: InstallBaseService,
              private store: Store<fromState.State>) {
  }

  shsEquipmentInstalledBaseSystemsStartLoadingData(
    installedBaseSystemFilter: IInstalledBaseSystemsFilter = null,
    includes: EGlueResource[] = [],
  ): Observable<IShsEquipments> {
    return this.installBaseService.getShsEquipmentInstalledBaseSystems(installedBaseSystemFilter, includes);
  }

  installedBaseProductsStartLoadingData(): Observable<IInstallBaseProducts> {
    return this.installBaseService.getInstalledBaseProducts();
  }

  selectInstalledBaseSystemData(): Observable<IShsEquipmentData[]> {
    return this.store.select(selectInstalledBaseData);
  }

  selectInstalledBaseAddresses(): Observable<IAddress[]> {
    return this.store.select(selectEquipmentsAddresses);
  }

  selectCpqEquipments(): Observable<IShsEquipmentData[]> {
    return this.store.select(selectCpqEquipments);
  }

  selectCpqEquipmentsLoading(): Observable<boolean> {
    return this.store.select(selectCpqEquipmentsLoading);
  }

  selectSparePartsEquipments(): Observable<IShsEquipmentData[]> {
    return this.store.select(selectSparePartsEquipments);
  }

  selectSparePartsEquipmentsLoading(): Observable<boolean> {
    return this.store.select(selectSparePartsEquipmentsLoading);
  }

  selectInstalledBaseSystemDataError(): Observable<any> {
    return this.store.select(selectInstalledBaseDataError);
  }

  selectInstalledBaseSystemDataLoading(): Observable<boolean> {
    return this.store.select(selectInstalledBaseSystemDataLoading);
  }

  selectInstalledBaseProductData(): Observable<IInstallBaseProducts | undefined> {
    return this.store.select(selectInstalledBaseProductData);
  }

  selectInstalledBaseProductDataError(): Observable<any> {
    return this.store.select(selectInstalledBaseProductDataError);
  }

  selectInstalledBaseProductDataLoading(): Observable<boolean> {
    return this.store.select(selectInstalledBaseProductDataLoading);
  }
}
