import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlueConfigurationService } from './glue-configuration.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleError(error: any): Observable<any> {
    return throwError(() => new Error(error.message || error));
  }

  postContactUsData(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const url = this.glueUrl + '/contact-us';

    return this.http.post<any>(url, newBody).pipe(
      catchError(ContactUsService.handleError),
    );
  }

  postContactUsGeneralData(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const url = this.glueUrl + '/contact-us-general';

    return this.http.post<any>(url, newBody).pipe(
      catchError(ContactUsService.handleError),
    );
  }
}
