import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as ValidationPatterns from '../../configurations/validations';
import { ContactUsFacade } from '../../facades/contact-us.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { ISelectEvent } from '../../models/common.models';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { I18nService } from '../../services';
import { AppUtils } from '../../utils/app.utils';
import { EStoreTypes } from '../../configurations/common';

@Component({
  selector: 'app-page-contact-us-general',
  templateUrl: './page-contact-us-general.component.html',
  styleUrls: ['./page-contact-us-general.component.scss'],
})
export class PageContactUsGeneralComponent implements OnInit {
  contactUsForm: UntypedFormGroup;
  showModalSubmit: boolean = false;
  maxLengthName: number = 35;
  maxLengthPhone: number = 20;
  maxLengthMessage: number = 500;
  currentRoute: string;
  previousRoute: any = {
    path: '',
    params: {},
  };

  selectedTopic: string;
  topicOptions: string[] = [
    'general-request',
    'wrong-address',
    'missing-equipment',
    'different-role',
  ];

  isJpStore: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private customerFacade: CustomerFacade,
    private contactUsFacade: ContactUsFacade,
    private router: Router,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
    this.currentRoute = `/${this.activatedRoute.snapshot.url.join('/')}`;
    this.setPreviousRoute();

    if (!this.previousRoute.path || this.previousRoute.path === this.currentRoute) {
      this.setDefaultPreviousRoute();
    }

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.selectedTopic = params['selected-topic'] ?? '';
    });

    this.initializeForm();
  }

  closeModal(): void {
    this.resetForm();
    this.showModalSubmit = !this.showModalSubmit;
  }

  submitModalAndRedirectBack(): void {
    this.closeModal();
    this.router.navigate(
      [this.previousRoute.path],
      {queryParams: this.previousRoute.params},
    );
  }

  topicOptionLabel(option: string): string {
    return this.topicOptions.includes(option) ? `contact-us.topic-option.${option}` : 'contact-us.select-topic';
  }

  isSelectedTopic(option: string): boolean {
    return option === this.selectedTopic;
  }

  selectTopicOption(option: string): void {
    this.selectedTopic = option;
  }

  setContactUsFormValue(event: ISelectEvent): void {
    this.contactUsForm.patchValue({
      [event.key]: event.value,
    });
  }

  formIsInvalid(): boolean {
    return !this.selectedTopic || this.contactUsForm.invalid;
  }

  resetForm(): void {
    this.selectedTopic = '';
    this.initializeForm();
  }

  resetFormAndRedirectBack(): void {
    this.resetForm();
    this.router.navigate(
      [this.previousRoute.path],
      {queryParams: this.previousRoute.params},
    );
  }

  submitForm(): void {
    this.showModalSubmit = true;

    const formData = {
      data: {
        type: 'contact-us-general',
        attributes: {
          selectedTopic: this.selectedTopic,
          firstName: this.contactUsForm.value.firstName,
          lastName: this.contactUsForm.value.lastName,
          email: this.contactUsForm.value.email,
          phoneNumber: this.contactUsForm.value.phone,
          message: this.contactUsForm.value.message,
          receiveEmails: this.contactUsForm.value.receiveMails,
          hasConsented: this.contactUsForm.value.personalInformationConsent,
        },
      },
    };

    this.contactUsFacade.postContactUsGeneralData(formData)
      .pipe(take(1))
      .subscribe({
        next: () => {
        },
        error: () => {
        },
      });
  }

  private initializeForm(): void {
    this.contactUsForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      phone: ['', [Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(this.maxLengthMessage), ValidationPatterns.noEmptySpaceOnTheBeginning]],
      receiveMails: [false, [Validators.requiredTrue]],
      personalInformationConsent: [false, [Validators.requiredTrue]],
    });

    this.selectCustomerInfo();
  }

  private selectCustomerInfo(): void {
    this.customerFacade.selectMyProfileInformation().pipe(take(1)).subscribe(state => {
      this.contactUsForm.patchValue({
        firstName: state.firstName || '',
        lastName: state.lastName || '',
        email: state.email || '',
      });
    });
  }

  private setPreviousRoute() {
    this.previousRoute.path = LocalStorageUtils.getKeyValue('previousPage');
    this.previousRoute.params = this.router.parseUrl(this.previousRoute.path).queryParams;

    this.previousRoute.path = this.previousRoute.path.indexOf('?') !== -1
      ? this.previousRoute.path.substring(0, this.previousRoute.path.indexOf('?'))
      : this.previousRoute.path;
  }

  private setDefaultPreviousRoute() {
    this.previousRoute.path = `/${this.i18nService.getCurrentLocale()}`;
    this.previousRoute.params = {};
  }
}
