import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ERobotsContent, ETitleSources } from './configurations/seo';
import { HOMEPAGE_FULL_PAGE_LOADER_LIST } from './configurations/common';
import { InterceptorService } from './services';
import { ServerErrorInterceptor } from './error/ServerError.interceptor';

import { AuthGuard } from './auth/auth.guard';
import { BusinessPartnersAccessGuard } from './guards/business-partners-access.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { LanguageGuard } from './i18n/language.guard';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { PreventAccessWithEmptyCartGuard } from './guards/prevent-access-with-empty-cart.guard';
import { PreventDirectGuard } from './auth/prevent-direct.guard';
import { RegularUserAccessGuard } from './guards/regular-user-access.guard';
import { SapP40Guard } from './guards/sapP40.guard';
import { StoreAccessibilityGuard } from './guards/store.accessibility.guard';
import { TermsGuard } from './guards/terms.guard';
import { harmonizedFeatureGuard } from './guards/harmonized-feature.guard';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageMaintenanceComponent } from './components/page-maintenance/page-maintenance.component';
import { HomeComponent } from './components/home/home.component';
import { ProductPageComponent } from './components/product-page/product-page.component';
import { PageMyOrdersComponent } from './components/page-my-orders/page-my-orders.component';
import { SparePartDeliveryDetailsComponent } from './components/spare-part-delivery-details/spare-part-delivery-details.component';
import { DeliveryDetailsComponent } from './components/delivery-details/delivery-details.component';
import { OrderApproveComponent } from './components/order-approve/order-approve.component';
import { SparePartOrderReviewComponent } from './components/spare-part-order-review/spare-part-order-review.component';
import { OrderSummarySuccessComponent } from './components/order-summary-success/order-summary-success.component';
import { OrderTrackingHistoryComponent } from './components/order-tracking-history/order-tracking-history.component';
import { CatalogComponent } from './components/page-catalog/catalog.component';
import { RequestQuoteComponent } from './components/page-request-quote/request-quote.component';
import { PageRequestTrackingHistoryComponent } from './components/page-request-tracking-history/page-request-tracking-history.component';
import { PageCmsComponent } from './components/page-cms/page-cms.component';
import { TermsComponent } from './components/page-terms/terms.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { StoreSelectionComponent } from './components/page-store-selection/store-selection.component';
import { WishlistsComponent } from './components/page-wishlists/wishlists.component';
import { WishlistComponent } from './components/page-wishlists/wishlist/wishlist.component';
import { MyContractsComponent } from './components/page-my-installed-base/my-contracts/my-contracts.component';
import { GuidedQuestionsComponent } from './components/page-guided-questions/guided-questions.component';
import { ServiceConfigurationComponent } from './components/page-service-configuration/service-configuration.component';
import { ConfigurationSummaryComponent } from './components/page-configuration-summary/configuration-summary.component';
import { PagePurchaseActivityComponent } from './components/page-purchase-activity/page-purchase-activity.component';
import { PageShopCartComponent } from './components/page-shop-cart/page-shop-cart.component';
import { PageQuoteReviewFinalizeComponent } from './components/page-quote-review-finalize/page-quote-review-finalize.component';
import { PageContactUsComponent } from './components/page-contact-us/page-contact-us.component';
import { PageContactUsGeneralComponent } from './components/page-contact-us-general/page-contact-us-general.component';
import { CpqDocumentDownloadComponent } from './components/cpq-document-download/cpq-document-download.component';
import { ThankYouForQuoteComponent } from './components/page-thank-you-for-quote/thank-you-for-quote.component';
import { AlmostDoneComponent } from './components/page-quote-review-finalize/page-almost-done/almost-done.component';
import { MyInstalledBaseComponent } from './components/page-my-installed-base/my-installed-base.component';
import { PageMyProfileComponent } from './components/page-my-profile/page-my-profile.component';
import { OrderThankYouPageComponent } from './components/order-thank-you-page/order-thank-you-page.component';
import { SparePartOrderDetailsComponent } from './components/spare-part-order-details/spare-part-order-details.component';
import { SparePartHybrisOrderDetailsComponent } from './components/spare-part-hybris-order-details/spare-part-hybris-order-details.component';
import { MyEquipmentSelectionComponent } from './components/my-equipment-selection/my-equipment-selection.component';
import { HarmonizedPageDeliveryDetailsComponent } from './components/harmonized-page-delivery-details/harmonized-page-delivery-details.component';
import { HarmonizedPageRequestQuoteComponent } from './components/harmonized-page-request-quote/harmonized-page-request-quote.component';
import { HarmonizedPageOrderThankYouComponent } from './components/harmonized-page-order-thank-you/harmonized-page-order-thank-you.component';
import { HarmonizedPageOrderReviewComponent } from './components/harmonized-page-order-review/harmonized-page-order-review.component';

const routes: Routes = [
  {
    path: '',
    component: StoreSelectionComponent,
    pathMatch: 'full',
    canActivate: [],
  },
  {
    path: 'blackfriday2020',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    data: {
      showBreadcrumb: false,
      seoNoCanonical: true,
      seoNonIndexable: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
      seoTitle: ':translateKey=seo-titles.page-not-found',
      seoTitleSource: ETitleSources.CONFIG,
    },
    canActivate: [TermsGuard],
  },
  {
    path: ':lang/page-maintenance',
    component: PageMaintenanceComponent,
    data: {
      hideNavigation: true,
      hideSearchAndActions: true,
      hideSocialMedia: true,
      showBreadcrumb: false,
      seoNoCanonical: true,
      seoNonIndexable: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
      seoTitle: ':translateKey=seo-titles.page-maintenance',
      seoTitleSource: ETitleSources.CONFIG,
    },
    canActivate: [StoreAccessibilityGuard],
  },
  {
    path: ':lang/error',
    component: PageErrorComponent,
    data: {
      showBreadcrumb: false,
      seoNoCanonical: true,
      seoNonIndexable: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
      seoTitle: ':translateKey=seo-titles.page-not-found',
      seoTitleSource: ETitleSources.CONFIG,
    },
    canActivate: [TermsGuard],
  },
  {
    path: ':lang/c/:uuid',
    component: PageCmsComponent,
    data: {showBreadcrumb: false, seoTitleSource: ETitleSources.ENDPOINT},
    canActivate: [TermsGuard],
  },
  {
    path: ':lang',
    pathMatch: 'full',
    redirectTo: ':lang/.',
    data: {showBreadcrumb: false},
  },
  {
    path: ':lang/.',
    component: HomeComponent,
    pathMatch: 'full',
    data: {
      showBreadcrumb: false,
      breadcrumb: ':translateKey=app-breadcrumb.home',
      dynamicBreadCrumb: true,
      fullPageLoader: true,
      fullPageLoaderList: HOMEPAGE_FULL_PAGE_LOADER_LIST,
    },
    canActivate: [TermsGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/shop-cart',
    component: PageShopCartComponent,
    data: {
      showBreadcrumb: false,
      breadcrumb: 'Shop-cart',
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, SapP40Guard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/product/:sku',
    component: ProductPageComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':sku',
      dynamicBreadCrumb: true,
      seoTitleSource: ETitleSources.ENDPOINT,
    },
    canActivate: [TermsGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/catalog',
    component: CatalogComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.product-list',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
      seoTitleSource: ETitleSources.BREADCRUMBS,
    },
    canActivate: [TermsGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
    children: [{
      path: ':category',
      component: CatalogComponent,
      data: {
        showBreadcrumb: true,
        breadcrumb: ':category',
        dynamicBreadCrumb: true,
        seoTitle: ':category',
        seoTitleSource: ETitleSources.BREADCRUMBS,
      },
    }],
  },
  {
    path: ':lang/search-results',
    component: CatalogComponent,
    data: {
      showBreadcrumb: false,
      seoNonIndexable: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
    },
    canActivate: [TermsGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/installed-base',
    component: CatalogComponent,
    data: {showBreadcrumb: false},
    canActivate: [TermsGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/my-orders',
    component: PageMyOrdersComponent,
    data: {
      showBreadcrumb: false,
      seoTitleSource: ETitleSources.HEADER,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/my-orders/:orderId',
    component: OrderTrackingHistoryComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-details/:orderId',
    component: SparePartOrderDetailsComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/hybris-order-details/:orderId',
    component: SparePartHybrisOrderDetailsComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/delivery-status',
    component: OrderTrackingHistoryComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/sap-delivery-details',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageDeliveryDetailsComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, PreventAccessWithEmptyCartGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/sap-delivery-details',
    component: SparePartDeliveryDetailsComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, SapP40Guard, MaintenanceGuard, PreventAccessWithEmptyCartGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/delivery-details',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageDeliveryDetailsComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, PreventAccessWithEmptyCartGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/delivery-details',
    component: DeliveryDetailsComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, PreventAccessWithEmptyCartGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-review/:orderId',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageOrderReviewComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, SapP40Guard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-review/:orderId',
    component: SparePartOrderReviewComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, SapP40Guard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-approval/:orderId',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageOrderReviewComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-approval/:orderId',
    component: OrderApproveComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-thank-you-page/:orderId',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageOrderThankYouComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/order-thank-you-page/:orderId',
    component: OrderThankYouPageComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/summary-success/:orderId',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageOrderThankYouComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/summary-success/:orderId',
    component: OrderSummarySuccessComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/terms/:urlKey',
    component: TermsComponent,
    data: {showBreadcrumb: false},
    canActivate: [MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/request-quote',
    canMatch: [harmonizedFeatureGuard()],
    component: HarmonizedPageRequestQuoteComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, PreventAccessWithEmptyCartGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/request-quote',
    component: RequestQuoteComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, PreventAccessWithEmptyCartGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/my-request/:requestId',
    component: PageRequestTrackingHistoryComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/wishlists',
    component: WishlistsComponent,
    data: {
      showBreadcrumb: false,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/wishlists/:id',
    component: WishlistComponent,
    data: {showBreadcrumb: false, seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW]},
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/my-contracts',
    component: MyContractsComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.my-contracts',
      dynamicBreadCrumb: true,

      breadcrumbDisabled: true,
      seoTitleSource: ETitleSources.BREADCRUMBS,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/my-installed-base',
    component: MyInstalledBaseComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.my-installed-base',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
      seoTitleSource: ETitleSources.BREADCRUMBS,
    },
    canActivate: [RegularUserAccessGuard, TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/equipment-selection',
    component: MyEquipmentSelectionComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.my-equipment-selection',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
      seoTitleSource: ETitleSources.BREADCRUMBS,
    },
    canActivate: [BusinessPartnersAccessGuard, TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard], //[TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/guided-questions',
    component: GuidedQuestionsComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.guided-questions',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
      seoTitleSource: ETitleSources.BREADCRUMBS,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/configuration-summary',
    component: ConfigurationSummaryComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.configuration-summary',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/service-configuration',
    component: ServiceConfigurationComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.service-configuration',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/purchase-activity',
    component: PagePurchaseActivityComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.purchase-activity',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/quote-summary',
    component: PageQuoteReviewFinalizeComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.quote-summary',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/quote-summary/almost-done',
    component: AlmostDoneComponent,
    data: {
      showBreadcrumb: false,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, PreventDirectGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/thank-you-for-quote',
    component: ThankYouForQuoteComponent,
    data: {
      showBreadcrumb: false,
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/contact-us',
    component: PageContactUsComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.contact-us',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/contact',
    component: PageContactUsGeneralComponent,
    data: {
      showBreadcrumb: true,
      breadcrumb: ':translateKey=app-breadcrumb.contact-us',
      dynamicBreadCrumb: true,
      breadcrumbDisabled: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
    },
    canActivate: [TermsGuard, AuthGuard, LanguageGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/cpq-documentation-download',
    component: CpqDocumentDownloadComponent,
    canActivate: [AuthGuard, LanguageGuard, TermsGuard, MaintenanceGuard, StoreAccessibilityGuard],
  },
  {
    path: ':lang/my-profile',
    component: PageMyProfileComponent,
    canActivate: [AuthGuard, LanguageGuard, TermsGuard, MaintenanceGuard, StoreAccessibilityGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      showBreadcrumb: false,
      seoNoCanonical: true,
      seoNonIndexable: true,
      seoRobots: [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW],
      seoTitle: ':translateKey=seo-titles.page-not-found',
      seoTitleSource: ETitleSources.CONFIG,
    },
    canActivate: [TermsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      enableTracing: false,
      onSameUrlNavigation: 'reload',
    },
  )],
  exports: [RouterModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorInterceptor,
    multi: true,
  }, AuthGuard],
})
export class AppRoutingModule {
}
