import { Iiso2Codes } from '../models/enviroment-delivery-details.model';
import { AppUtils } from './app.utils';
import {
  IAddressReduced,
  IAddressSelectBox,
  ICompanyBusinessUnitAddresses,
  ICompanyBusinessUnits, ICustomerCheckoutData,
} from '../models/customer.models';
import { IAddress } from '../models/common.models';
import { EAddressType, EThankYouPageFieldNames } from '../configurations/common';

export class AddressUtils {

  /**
   * Format address object to string.
   *
   * @param {IAddress | IAddressReduced} address
   * @returns {string}
   */
  static formatAddressToString(address: IAddress | IAddressReduced): string {
    if (!address) {
      return '';
    }
    const addressDetails = [
      address.address1,
      address.city,
      address.state,
      address.zipCode,
    ].filter(Boolean).join(', ');
    let addressName = address.company;
    if (!addressName) {
      addressName = address.name ?? '';
    }
    if (addressName === 'empty') {
      addressName = address.firstName ?? '';
    }
    const addressLine = addressDetails.length > 0 ? ` (${addressDetails})` : '';
    return addressName + addressLine;
  }

  static createAddressString(address: any): string {
    if (!address) return '';

    const street: string = address.address1 || '';
    const zipCode: string = address.zipCode || '';
    const city: string = address.city || '';
    const state: string = address.address2 || '';
    const country: string = address.country || '';

    const newAddressFormat: string = [street, `${zipCode},`, city, state, country]
      .filter(item => (item && item !== 'empty' && item !== 'empty,'))
      .join(', ')
      .replace(',,', '');

    if (newAddressFormat.endsWith(',') || newAddressFormat.endsWith(' ')) {
      return newAddressFormat.substring(0, newAddressFormat.length - 1);
    } else {
      return newAddressFormat;
    }
  }

  static createAddressStringForCpq(address: any): string {
    if (!address) return '';

    const company: string = address.name || '';
    const street: string = address.address1 || '';
    const city: string = address.city || '';
    const state: string = address.address2 || '';
    const country: string = address.state || '';
    const zipCode: string = address.zipCode || '';

    const newAddressFormat: string = [company, street, city, state, country, zipCode]
      .filter(item => (item && item !== 'empty' && item !== 'empty,')).join(', ');

    if (newAddressFormat.endsWith(',') || newAddressFormat.endsWith(' ')) {
      return newAddressFormat.substring(0, newAddressFormat.length - 1);
    } else {
      return newAddressFormat;
    }
  }

  static createAddressStringForCpqSelectedBillOrPayer(address: any): string {
    return address.name ? `${address.name}` : '';
  }

  static getBodyRequiredAddress(address: any): any {
    return {
      sapId: address.sapId,
      name: address.company ?? address.name,
      address1: address.address1,
      address2: address.address2,
      address3: address.address3,
      city: address.city,
      phone: address.phone,
      state: address.state,
      zipCode: address.zipCode,
    };
  }

  static changeAddressName(address: IAddress): IAddress {
    return {
      ...address,
      company: address.name,
      name: `${address.name} (${address.address1}, ${address.city}, ${address.state}, ${address.zipCode})`,
    } as IAddress;
  }

  static getCountriesForDeliveryAddress(): Iiso2Codes[] {
    return AppUtils.getCurrentStore().deliveryAddressCountries;
  }

  static getDeliveryCountryByIsoCode(isoCode: string): Iiso2Codes {
    return AppUtils.getCurrentStore().deliveryAddressCountries.find(country => country.value === isoCode);
  }

  static appendAddresses(target: Array<any>, newAddresses: Array<any>, type: string, addressFromSystem = false): void {
    newAddresses.forEach(data => {
      const newAddress = {
        name: AddressUtils.createAddressString(data),
        value: {
          ...data,
          idCustomerAddress: type === 'customer' ? data.id : null,
          idCompanyUnitAddress: type === 'business' ? data.id : null,
        },
        addressFromSystem,
      };

      const addressFound = target.find(address => address.name === newAddress.name);
      if (!addressFound) {
        target.push(newAddress);
      }
    });
  }

  static appendAddressesForCpq(target: Array<any>, newAddresses: Array<any>, billOrPayer: boolean): void {
    newAddresses.forEach(data => {
      const newAddress = {
        name: billOrPayer ? AddressUtils.createAddressStringForCpqSelectedBillOrPayer(data) : AddressUtils.createAddressStringForCpq(data),
        value: {
          ...data,
        },
      };

      const addressFound = target.find(address => address.name === newAddress.name);
      if (!addressFound) {
        target.push(newAddress);
      }
    });
  }

  static isAddressValid(address: IAddress): boolean {
    if (!address) {
      return false;
    }

    const storesWithRequiredStateField = ['US', 'CA'];
    const isValid = !!(address.address1 && address.city && address.zipCode);

    return storesWithRequiredStateField.includes(AppUtils.getCurrentStore().storeId) ? isValid && !!address.state : isValid;
  }

  static unifyAddresses(
    address: any[],
    userData: ICustomerCheckoutData,
  ): IAddress[] {
    return address.map(address => {
      return AddressUtils.unifyAddress(address, userData);
    });
  }

  /**
   * Map not unified address to IAddress type.
   *
   * @static
   *
   * @param {any} address
   * @param {ICustomerCheckoutData} userData
   * @return {IAddress}
   */
  static unifyAddress(
    address: any,
    userData: ICustomerCheckoutData,
  ): IAddress {
    return {
      sapId: address?.sapId,
      salutation: 'Ms',
      firstName: address?.name,
      lastName: '',
      address1: address?.address1,
      address2: '',
      zipCode: address?.zipCode,
      city: address?.city,
      state: address?.state,
      iso2Code: address?.iso2Code,
      company: address?.name,
      phone: userData?.customerData?.phone,
      country: address?.country,
      isDefaultBilling: false,
      isDefaultShipping: false,
    } as IAddress;
  }

  static updateEmptyAddressFields(address: IAddress): IAddress {
    return {
      sapId: address?.sapId,
      salutation: address.salutation || 'Ms',
      firstName: address?.firstName || 'empty',
      lastName: address?.lastName || 'empty',
      address1: address?.address1 || 'empty',
      address2: address?.address2 || 'empty',
      address3: '',
      zipCode: address?.zipCode || 'empty',
      city: address?.city || 'empty',
      country: address?.country || 'empty',
      iso2Code: address?.iso2Code || 'empty',
      phone: address?.phone || 'empty',
      company: address?.company || 'empty',
      state: address?.state || 'empty',
      idCustomerAddress: address?.idCustomerAddress,
      idCompanyUnitAddress: address?.idCompanyUnitAddress,
      isDefaultBilling: false,
      isDefaultShipping: false,
      isCustom: address?.isCustom ?? false,
    };
  }

  static mapAddressesToAddressSelectBox(addresses: IAddress[], addressType: EAddressType, alternativeSapId?: string): IAddressSelectBox[] {
    const selectBoxAddresses: IAddressSelectBox[] = [];
    addresses.forEach(address => {
      const selectBoxAddress = AddressUtils.mapAddressToAddressSelectBox(address, addressType, alternativeSapId);
      selectBoxAddresses.push({
        name: selectBoxAddress.name,
        value: selectBoxAddress.value,
      });
    });
    return selectBoxAddresses;
  }

  static mapAddressToAddressSelectBox(
    address: IAddress,
    addressType: EAddressType,
    alternativeSapId?: string,
  ): IAddressSelectBox {
    let addressTitle: string;
    if (address.isCustom) {
      addressTitle = `${AddressUtils.getFormattedAddress(address, addressType)}`;
    } else {
      addressTitle = `${address?.sapId ? address.sapId + ' - ' : ''}${AddressUtils.getFormattedAddress(address)}`;
    }
    const addressBody: IAddress = {...address, sapId: address.sapId ?? alternativeSapId};

    return {
      name: addressTitle,
      value: addressBody,
    };
  }

  static getFormattedAddress(
    address: IAddress,
    addressType: EAddressType = EAddressType.SHIP_TO,
  ): string {
    if (!AddressUtils.isAddressValid(address)) {
      if (addressType === EAddressType.SHIP_TO) {
        return 'spare-parts-order-details-page.missing-default-fl-ship-to-address';
      } else {
        return 'spare-parts-order-details-page.missing-default-fl-bill-to-address';
      }
    }
    return `${this.formatAddressToString(address)}`;
  }

  static createShipToAddressFromBusinessUnitAddress(
    businessUnit: ICompanyBusinessUnits,
    businessUnitAddress: ICompanyBusinessUnitAddresses,
    userData: ICustomerCheckoutData,
    sapIdFromCart: string,
  ): IAddress {
    return {
      company: businessUnit?.data?.[0]?.attributes?.name,
      isDefaultBilling: false,
      isDefaultShipping: false,
      phone: userData?.customerData?.phone,
      salutation: userData?.customerData?.salutation,
      firstName: userData?.customerData?.firstName,
      lastName: userData?.customerData?.lastName,
      address1: businessUnitAddress?.attributes?.address1,
      address2: businessUnitAddress?.attributes?.address2,
      address3: businessUnitAddress?.attributes?.address3,
      city: businessUnitAddress?.attributes?.city,
      country: businessUnitAddress?.attributes?.country,
      iso2Code: businessUnitAddress?.attributes?.iso2Code,
      state: businessUnitAddress?.attributes?.state,
      zipCode: businessUnitAddress?.attributes?.zipCode,
      sapId: sapIdFromCart,
    };
  }

  /**
   * Checks if the given address field is empty (falsy value or 'empty').
   *
   * @static
   *
   * @param {string} address
   * @returns {boolean}
   */
  static isAddressEmpty(address: string): boolean {
    return !address || address === 'empty';
  }

  /**
   * Mapping table method which returns the address field name of BE response for given address type.
   *
   * @static
   *
   * @param {string} address
   * @returns {boolean}
   */
  static getAddressFieldName(address: string): string {
    switch (address) {
      case EThankYouPageFieldNames.SOLD_TO_ADDRESS:
        return 'soldToAddress';
      case EThankYouPageFieldNames.SHIP_TO_ADDRESS:
        return 'shippingAddress';
      case EThankYouPageFieldNames.BILL_TO_ADDRESS:
        return 'billingAddress';
      case EThankYouPageFieldNames.PAYER_ADDRESS:
        return 'payerAddress';
      default:
        return '';
    }
  }
}
