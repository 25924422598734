import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { CpqService } from '../services/cpq.service';
import * as CpqSelectors from '../reducers/cpq.reducer';
import { CpqActions } from '../actions';
import * as fromState from '../reducers';
import * as AppActions from '../actions/app.actions';
import {
  INotificationActions,
  INotificationMessage,
  ICPQReconfigureResponse, ICPQResponseData,
} from '../models/cpq.models';

@Injectable({
  providedIn: 'root',
})
export class CpqFacade {
  private pCpqService: CpqService;

  public get getCpqService(): CpqService {
    if (!this.pCpqService) {
      this.pCpqService = this.injector.get(CpqService);
    }
    return this.pCpqService;
  }

  constructor(
    private injector: Injector,
    private store: Store<fromState.State>,
  ) {
  }

  postCqpInitialize(cartId: string, sku: string, functionalLocationNumber: string): Observable<any> {
    return this.getCpqService.postCqpInitialize(cartId, sku, functionalLocationNumber);
  }

  postCpqWrongAddress(data: any): Observable<any> {
    return this.getCpqService.postCpqWrongAddress(data);
  }

  getCpqWrongAddress(addressType: string): Observable<any> {
    return this.getCpqService.getCpqWrongAddress(addressType);
  }

  postCpqContractDetailsDefaults(cartId: string, sku: string, configId: string): Observable<any> {
    return this.getCpqService.postCpqContractDetailsDefaults(cartId, sku, configId);
  }

  postCqpReconfigure(cartId: string, sku: string, configId: string): Observable<any> {
    return this.getCpqService.postCqpReconfigure(cartId, sku, configId);
  }

  postCqpCommit(cartId: string, sku: string, configId: string, parameter: string, value: string): Observable<any> {
    return this.getCpqService.postCqpCommit(cartId, sku, configId, parameter, value);
  }

  postCqpUnCommit(cartId: string, sku: string, configId: string, parameter: string): Observable<any> {
    return this.getCpqService.postCqpUnCommit(cartId, sku, configId, parameter);
  }

  postCqpCartItemsCommit(cartId: string, sku: string, configId: string, parameter: string, value: string): Observable<any> {
    return this.getCpqService.postCqpCartItemsCommit(cartId, sku, configId, parameter, value);
  }

  postCqpAccept(cartId: string, sku: string, configId: string, parameter: string, value: string): Observable<any> {
    return this.getCpqService.postCqpAccept(cartId, sku, configId, parameter, value);
  }

  postCpqGuidedInitialize(cartId: string, functionalLocationNumber: string): Observable<any> {
    return this.getCpqService.postCpqGuidedInitialize(cartId, functionalLocationNumber);
  }

  postCpqSetStep(cartId: string, configId: string, step: string): Observable<any> {
    return this.getCpqService.postCpqSetStep(cartId, configId, step);
  }

  postCpqRecalculate(cartId: string): Observable<any> {
    return this.getCpqService.postCpqRecalculate(cartId);
  }

  getSelectedInstalledBaseSystems(materialNumber: string, flNumber: string): void {
    this.store.dispatch(CpqActions.loadSelectedInstalledBase({flNumber, materialNumber}));
  }

  loadSelectedSystem(sku: string): void {
    this.store.dispatch(CpqActions.loadSelectedSystem({sku}));
  }

  selectInstalledBaseSystemsFromCpqStore(): Observable<any> {
    return this.store.select(CpqSelectors.getSelectedInstalledBase);
  }

  selectSelectedSystem(): Observable<any> {
    return this.store.select(CpqSelectors.getSelectedSystem);
  }

  selectChangeLog(): Observable<Array<any>> {
    return this.store.select(CpqSelectors.selectChangeLog);
  }

  updateChangeLog(changeLog: Array<any>): void {
    this.store.dispatch(CpqActions.updateChangeLog({changeLog}));
  }

  showAdditionalDeliverableNotification(
    type: string, title: string, messages: Array<INotificationMessage>, actions: Array<INotificationActions>): void {
    this.store.dispatch(AppActions.appendNotification({
      notification: {type, title, messages, actions},
    }));
  }

  selectShowChangeLogTab(): Observable<boolean> {
    return this.store.select(CpqSelectors.selectShowChangeLogTab);
  }

  updateShowChangeLogTab(): void {
    this.store.dispatch(CpqActions.showChangeLogTab());
  }

  undoLastOptionCommit(): void {
    this.store.dispatch(CpqActions.undoLastOptionCommit());
  }

  selectUndoLastOptionCommit(): Observable<boolean> {
    return this.store.select(CpqSelectors.selectUndoLastOptionCommit);
  }

  getCpqQuote(cartId: string): Observable<ICPQResponseData> {
    return this.getCpqService.getCpqQuote(cartId);
  }

  deleteCpqQuote(cartId: string): Observable<any> {
    return this.getCpqService.deleteCpqQuote(cartId);
  }

  getCpqQuoteItems(cartId: string): Observable<any> {
    return this.getCpqService.getCpqQuoteItems(cartId);
  }

  deleteCpqQuoteItem(cartId: string, sku: string, configId: string): Observable<any> {
    return this.getCpqService.deleteCpqQuoteItem(cartId, sku, configId);
  }

  submitGenerateQuoteDocument(body: any): Promise<any> {
    return this.getCpqService.generateQuoteDocument(body);
  }

  reconfigureService(cartId: string, sku: string, configId: string): Observable<ICPQReconfigureResponse> {
    return this.getCpqService.reconfigureService(cartId, sku, configId);
  }

  getConfigurationSummaryPdfFile(cartId: string, sku: string, configId: string): Observable<Blob> {
    return this.getCpqService.getConfigurationSummaryPdfFile(cartId, sku, configId);
  }

  getCpqQuotePdfFile(cartId: string): Observable<any> {
    return this.getCpqService.getCpqQuotePdfFile(cartId);
  }

  quoteSummarySubmittedOrder(order: any): void {
    this.store.dispatch(CpqActions.quoteSummaryPageActionAfterSubmittedOrder({order}));
  }

  selectLastSubmittedOrder(): Observable<any> {
    return this.store.select(CpqSelectors.selectLastSubmittedOrder);
  }

  thankYouPageStartNewQuote(): void {
    this.store.dispatch(CpqActions.thankYouPageStartNewQuote());
  }

  thankYouPageViewPurchaseActivity(): void {
    this.store.dispatch(CpqActions.thankYouPageViewPurchaseActivity());
  }

  thankYouPageRedirectToLastPage(): void {
    this.store.dispatch(CpqActions.thankYouPageRedirectToLastPage());
  }

  serviceConfigPageShowFLAddedNotification(cartId: string, quantity: number): void {
    this.store.dispatch(CpqActions.serviceConfigPageShowFLAddedNotification({cartId, quantity}));
  }

  serviceConfigPageRedirectWhenCartEmpty(): void {
    this.store.dispatch(CpqActions.serviceConfigPageRedirectWhenCartEmpty());
  }
}
