import { AdvancedFilter } from '../models/advanced-filter';

export enum EEquipmentProductGroup {
  SPARE_PARTS = 'spare-parts',
  CONSUMABLES = 'consumables',
}

export enum EEquipmentFilterAttributes{
  // MODALITY = 'modality',
  // EQUIPMENT_NAME = 'equipment-name',
  // CITY_AND_STATE = 'city-and-state',
  // CONTRACT_NAME = 'contract-name',
  PRODUCT_CATEGORY = 'product-category',
}

export const EquipmentFilters: AdvancedFilter[] = [
  {
    name: 'modality',
    attribute: 'attributes',
    firstAdditionalAttribute: 'category',
    options: [],
    selected: [],
  }, {
    name: 'equipment-name',
    attribute: 'attributes',
    firstAdditionalAttribute: 'materialName',
    options: [],
    selected: [],
  }, {
    name: 'city-and-state',
    attribute: 'attributes',
    firstAdditionalAttribute: 'siemensEquipmentAddress',
    secondAdditionalAttribute: 'city',
    options: [],
    selected: [],
  },
  {
    name: 'contract-name',
    attribute: 'attributes',
    firstAdditionalAttribute: 'contractName',
    options: [],
    selected: [],
  }, {
    name: 'product-category',
    attribute: 'attributes',
    firstAdditionalAttribute: 'isSparePartsAvailable',
    options: [],
    selected: [],
  },
];
