import { Component } from '@angular/core';

@Component({
  selector: 'app-request-quote-2-section',
  templateUrl: './request-quote-2-section.component.html',
  styleUrl: './request-quote-2-section.component.scss'
})
export class RequestQuote2SectionComponent {

}
