import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CategoriesService, InstallBaseService, ProductListService, ProductsService, SearchService } from '../services';
import { CatalogActions } from '../actions';
import * as fromState from '../reducers';
import * as CatalogSelectors from '../reducers/catalog.reducer';
import {
  IShsEquipmentInstalledBaseSystemsSoldTo,
  IShsEquipments,
  ISimpleInstallBaseProduct,
} from '../models/installedbase.models';
import {
  ICatalogSearchIncluded, ICatalogSearchSuggestion, ICatalogSearchSuggestionDTO,
  ICategoryNode,
  ICategoryTreeNode,
  ICurrentParams, ISdmIbComponents,
} from '../models/catalog.models';
import { EGlueResource } from '../configurations/common';
import { TranslateService } from '@ngx-translate/core';
import {
  IAbstractProduct,
  IAbstractProductPricesAndAvailability,
  IAbstractProductWithIncluded,
  ICachedProductSkuAndRequiredReload,
} from '../models/abstract-product.models';
import { SdmIbComponentsService } from '../services/sdm-ib-components.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogFacade {
  private pCategoryService: CategoriesService;
  private pInstallBaseService: InstallBaseService;
  private pProductListService: ProductListService;
  private pProductsService: ProductsService;
  private pSearchService: SearchService;
  private pSdmIbComponentsService: SdmIbComponentsService;

  /**
   * @param {Injector} injector
   * @param {Store<State>} store
   * @param translateService
   */
  constructor(
    private injector: Injector,
    private store: Store<fromState.State>,
    private translateService: TranslateService,
  ) {
  }

  /**
   * Returns value of how many products should be cached during the one session
   * @returns {Observable<number>}
   */
  public getHowManyProductsShouldBeCached(): Observable<number> {
    return this.translateService.get('config.maxCachedProducts');
  }

  /**
   * Returns value of product pricing group for consumables in string format
   * @returns {Observable<string>}
   */
  public getPricingGroupForConsumables(): Observable<string> {
    return this.translateService.get('config.pricingGroupForConsumables');
  }

  /**
   *
   * @returns {CategoriesService}
   */
  public get getCategoriesService(): CategoriesService {
    if (!this.pCategoryService) {
      this.pCategoryService = this.injector.get(CategoriesService);
    }
    return this.pCategoryService;
  }

  /**
   *
   * @returns {SdmIbComponentsService}
   */
  public get getSdmIbComponentsService(): SdmIbComponentsService {
    if (!this.pSdmIbComponentsService) {
      this.pSdmIbComponentsService = this.injector.get(SdmIbComponentsService);
    }
    return this.pSdmIbComponentsService;
  }

  /**
   *
   * @returns {ProductListService}
   */
  public get productListService(): ProductListService {
    if (!this.pProductListService) {
      this.pProductListService = this.injector.get(ProductListService);
    }
    return this.pProductListService;
  }

  /**
   *
   * @returns {ProductsService}
   */
  public getProductsService(): ProductsService {
    if (!this.pProductsService) {
      this.pProductsService = this.injector.get(ProductsService);
    }
    return this.pProductsService;
  }

  /**
   *
   * @returns {InstallBaseService}
   */
  public getInstallBaseService(): InstallBaseService {
    if (!this.pInstallBaseService) {
      this.pInstallBaseService = this.injector.get(InstallBaseService);
    }
    return this.pInstallBaseService;
  }

  /**
   *
   * @returns {SearchService}
   */
  public getSearchService(): SearchService {
    if (!this.pSearchService) {
      this.pSearchService = this.injector.get(SearchService);
    }
    return this.pSearchService;
  }

  /**
   *
   * @returns {Observable<ICategoryTreeNode>}
   */
  getCategoryTree(): Observable<ICategoryTreeNode[]> {
    return this.getCategoriesService.getCategoryTree();
  }

  /**
   *
   * @param {string} functionalLocation
   * @returns {Observable<ISdmIbComponents>}
   */
  getSdmIbComponents(functionalLocation: string): Observable<ISdmIbComponents> {
    return this.getSdmIbComponentsService.getSdmIbComponents(functionalLocation);
  }

  /**
   *
   */
  loadCategories(): void {
    this.store.dispatch(CatalogActions.loadCategoriesStart());
  }

  /**
   *
   * @returns {Observable<ICategoryNode[]>}
   */
  selectCategories(): Observable<ICategoryNode[]> {
    return this.store.select(CatalogSelectors.selectCategories);
  }

  /**
   *
   * @returns {Observable<boolean>}
   */
  selectIsCategoriesLoaded(): Observable<boolean> {
    return this.store.select(CatalogSelectors.selectIsCategoriesLoaded);
  }

  /**
   *
   * @returns {Observable<boolean>}
   */
  selectIsCategoriesLoading(): Observable<boolean> {
    return this.store.select(CatalogSelectors.selectIsCategoriesLoading);
  }

  /**
   *
   * @param params
   * @returns {Observable<ICatalogSearchIncluded>}
   */
  getSearchResults(params: ICurrentParams): Observable<ICatalogSearchIncluded> {
    return this.productListService.getProductByCategorySpecificPage(params);
  }

  /**
   *
   * @param {string} functionalLocationNumber
   * @returns {Observable<IShsEquipments>}
   */
  getShsEquipmentInstallBaseSystem(functionalLocationNumber: string): Observable<IShsEquipments> {
    return this.getInstallBaseService().getShsEquipmentInstalledBaseSystems({functionalLocationNumber});
  }

  /**
   *
   * @param {string} flNumber
   * @returns {Observable<IShsEquipmentInstalledBaseSystemsSoldTo>}
   */
  getShsEquipmentInstalledBaseWithSoldTo(flNumber: string): Observable<IShsEquipmentInstalledBaseSystemsSoldTo> {
    return this.getInstallBaseService().getShsEquipmentInstalledBaseSystems(
      {functionalLocationNumber: flNumber},
      [EGlueResource.CUSTOMER_SOLD_TO_ID],
    ) as Observable<IShsEquipmentInstalledBaseSystemsSoldTo>;
  }

  /**
   *
   * @param {string} sku
   */
  loadAbstractProductData(sku: string): void {
    this.store.dispatch(CatalogActions.loadAbstractProductData({sku}));
  }

  /**
   *
   * @param {string} sku
   * @returns {Observable<IAbstractProductWithIncluded>}
   */
  getAbstractProductDataFromApi(sku: string): Observable<any> {
    return this.getProductsService().getAbstractProductDataFromApi(sku);
  }

  /**
   *
   * @returns {Observable<any>}
   */
  selectAbstractProductError(): Observable<any> {
    return this.store.select(CatalogSelectors.selectAbstractProductError);
  }

  /**
   *
   * @returns {Observable<ICachedProductSkuAndRequiredReload[]>}
   */
  selectRecentlyOpenedProductsSkus(): Observable<ICachedProductSkuAndRequiredReload[]> {
    return this.store.select(CatalogSelectors.selectRecentlyOpenedProductsSkus);
  }

  /**
   *
   * @returns {Observable<IAbstractProduct>}
   */
  selectRecentlyOpenedProductBySku(sku: string): Observable<IAbstractProduct> {
    return this.store.select(CatalogSelectors.selectRecentlyOpenedProductBySku(sku));
  }

  /**
   *
   * @returns {Observable<IAbstractProductPricesAndAvailability>}
   */
  selectAbstractProductPricesAndAvailabilitiesBySku(sku: string): Observable<any> {
    return this.store.select(CatalogSelectors.selectAbstractProductPricesAndAvailabilitiesBySku(sku));
  }

  /**
   *
   * @returns {Observable<boolean>}
   */
  selectArePricesAndAvailabilitiesLoading(): Observable<boolean> {
    return this.store.select(CatalogSelectors.selectSapInfoLoading);
  }

  /**
   *
   * @returns {Observable<boolean>}
   */
  selectIsAbstractProductLoading(): Observable<boolean> {
    return this.store.select(CatalogSelectors.selectIsAbstractProductLoading);
  }

  /**
   *
   */
  clearAbstractProduct(): void {
    this.store.dispatch(CatalogActions.clearAbstractProductData());
  }

  /**
   *
   * @param {string} materialNumber
   * @param {string} functionalLocationNumber
   * @returns {Observable<IShsEquipments>}
   */
  getShsEquipmentInstalledBaseSystemByFunctionalLocation(
    materialNumber: string,
    functionalLocationNumber: string,
  ): Observable<IShsEquipments> {
    return this.getInstallBaseService().getShsEquipmentInstalledBaseSystems({
      materialNumber,
      functionalLocationNumber,
    });
  }

  /**
   *
   * @param {string} query
   */
  setSearchQuery(query: string): void {
    this.store.dispatch(CatalogActions.setSearchQuery({query}));
  }

  /**
   *
   * @returns {Observable<string>}
   */
  selectSearchQuery(): Observable<string> {
    return this.store.select(CatalogSelectors.selectSearchQuery);
  }

  /**
   *
   * @returns {Observable<any>}
   */
  selectInstalledBase(): Observable<any> {
    return this.store.select(CatalogSelectors.selectInstalledBase);
  }

  /**
   *
   * @param {ICurrentParams} params
   */
  setQueryParams(params: ICurrentParams): void {
    this.store.dispatch(CatalogActions.setQueryParams({params}));
  }

  /**
   *
   */
  clearSearchQuery(): void {
    this.store.dispatch(CatalogActions.clearSearchQuery());
  }

  /**
   *
   * @returns {Observable<ICurrentParams>}
   */
  selectQueryParams(): Observable<ICurrentParams> {
    return this.store.select(CatalogSelectors.selectQueryParams);
  }

  /**
   *
   * @param {ISimpleInstallBaseProduct} installedBase
   */
  setInstalledBase(installedBase: ISimpleInstallBaseProduct): void {
    this.store.dispatch(CatalogActions.setInstalledBase({installedBase}));
  }

  /**
   *
   * @param {string} value
   * @returns {Promise<ICatalogSearchSuggestion>}
   */
  getSuggestionsFromService(value: string): Observable<ICatalogSearchSuggestionDTO> {
    return this.getSearchService().getSuggestions(value);
  }

  /**
   *
   * @returns {Observable<string[]>}
   */
  selectBlockedSortByOptions(): Observable<string[]> {
    return this.store.select(CatalogSelectors.selectBlockedSortByOptions);
  }

  /**
   *
   * @param {string} sku
   * @param {string} quoteUuid
   * @returns {Observable<IAbstractProductWithIncluded>}
   */
  getAbstractProductPricesAndAvailabilitiesFromSap(sku: string, quoteUuid: string): Observable<IAbstractProductWithIncluded> {
    return this.getProductsService().getAbstractProductPricesAndAvailabilitiesFromSap(sku, quoteUuid);
  }
}
