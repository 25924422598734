<form [formGroup]="reportWrongAddressForm" (ngSubmit)="reportAddress()">
  <app-modal
      [attr.data-test]="'button-reportWrongAddress'"
      [open]="showModal"
      [id]="'wrongAddressModal'"
      title="{{ 'checkout-delivery-details.report-wrong-address' | translate }}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      @if (loading) {
        <div class="loader__overlay">
          <div class="loader__content">
            <div class="loading-animation"></div>
          </div>
        </div>
      }
      <div class="row">
        <app-select-box
            class="col-12"
            [form]="reportWrongAddressForm"
            [modelValue]="reportWrongAddressForm.value.address"
            [options]="addresses"
            [inputName]="'reportedAddress'"
            label="{{ (label ?? 'checkout-delivery-details.delivery-address') | translate }}"
            [isRequired]="true"
            [readOnly]="readOnly"
            (selected)="selectWrongAddress($event)">
        </app-select-box>
      </div>
      <div class="row">
        <app-textarea
            class="col-12"
            [form]="reportWrongAddressForm"
            [modelValue]="reportWrongAddressForm.value.comment"
            [rows]="3"
            [cssStyles]="{wrapper: 'text-area-comment'}"
            [inputName]="'comment'"
            [maxLength]="maxLengthComment"
            label="{{ 'checkout-delivery-details.comment' | translate }}"
            [isRequired]="true"
            trim
            trimWhitespaces
            (areaFilled)="setCommentWrongAddress($event.value)">
        </app-textarea>
        <div class="form-alerts">
          @if (reportWrongAddressForm.value.comment) {
            <small class="textfield__hint">
              {{ reportWrongAddressForm.value.comment.length }}/{{ maxLengthComment }}
            </small>
          }
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button
          type="reset"
          class="button button"
          data-dismiss="modal-dialog"
          (click)="resetForm()">
        {{ 'checkout-delivery-details.cancel' | translate }}
      </button>
      <button
          type="submit"
          class="button button--primary"
          data-dismiss="modal-dialog"
          [disabled]="reportWrongAddressForm.status !== 'VALID'"
          (click)="showLoader()">
        {{ 'checkout-delivery-details.send-wrong-address' | translate }}
      </button>
    </ng-container>
  </app-modal>
</form>
