import { Component, OnInit } from '@angular/core';
import { Data, Params, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, pairwise } from 'rxjs/operators';
import { AuthActions } from './auth/actions';
import * as fromAuth from './auth/reducers';
import { CustomerFacade } from './facades/customer.facade';
import { SeoFacade } from './facades/seo.facade';
import { StoreConfigurationFacade } from './facades/store-configuration.facade';
import { StoreSelectionFacade } from './facades/store-selection.facade';
import { I18nService } from './services';
import { AppUtils } from './utils/app.utils';
import { LocalStorageUtils } from './utils/localStorage.utils';
import { UrlUtils } from './utils/url.utils';
import { environment } from '../environments/environment';
import { CmsFacade } from './facades/cms.facade';
import { CatalogActions } from './actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Siemens Healthineers Shop';
  routeData: Data;
  routeParams: Params;
  showFullPageLoader = false;
  fullPageLoaderList = [];

  constructor(
    private store: Store<fromAuth.State>,
    private router: Router,
    private seoFacade: SeoFacade,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService,
    private storeSelectionFacade: StoreSelectionFacade,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private cmsFacade: CmsFacade,
  ) {
    seoFacade.setSeoForPage();

    router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
      pairwise(),
    ).subscribe((routes: any[]) => {
      if (UrlUtils.isTheSameRoute(routes)) {
        return;
      }
      LocalStorageUtils.setKeyValue('previousPage', routes[0].url);
    });
  }

  ngOnInit(): void {
    this.store.dispatch(AuthActions.handleAuth());
    this.store.dispatch(CatalogActions.loadHowManyProductShouldBeCached());
    this.handleRouteParamsAndData();
    this.storeConfigurationFacade.dispatchFetchStoreConfiguration(
      AppUtils.getCurrentStore().storeId,
    );
    this.cmsFacade.getHomepageContentBannerData(environment.homePageBannerKey);
    this.cmsFacade.getStoreSelectionContentBannerData(environment.storeSelectionBannerKey);
  }

  handleRouteParamsAndData(): void {
    this.router.events.subscribe(
      (data) => {
        if (data instanceof RoutesRecognized) {
          this.routeData = data.state.root.firstChild.data;
          this.routeParams = data.state.root.firstChild.params;

          const defaultLocale = this.i18nService.getCurrentLocale();
          const store = this.routeParams.lang || defaultLocale;
          this.customerFacade.setStore(store);

          this.showFullPageLoader = this.routeData['fullPageLoader'] ?? false;
          this.fullPageLoaderList = this.routeData['fullPageLoaderList'] ?? [];
        }
      },
    );
  }

  isOnStoreSelection(): boolean {
    return this.storeSelectionFacade.isOnStoreSelectionPage();
  }
}
