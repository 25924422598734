import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Iiso2Codes } from '../../models/enviroment-delivery-details.model';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EStoreTypes, EUserRoles } from '../../configurations/common';
import { AddressUtils } from '../../utils/address.utils';
import { AppUtils } from '../../utils/app.utils';
import { environment } from '../../../environments/environment';
import { TaxUtils } from '../../utils/tax.utils';
import { ActivatedRoute } from '@angular/router';
import { OrdersFacade } from '../../facades/orders.facade';
import { IAddress } from '../../models/common.models';
import { IStore } from '../../models/settings.model';

@Component({
  selector: 'app-order-summary-success',
  templateUrl: './order-summary-success.component.html',
  styleUrls: ['./order-summary-success.component.scss'],
})
export class OrderSummarySuccessComponent implements OnInit, OnDestroy {
  orderApproveSubscription$: Subscription;
  orderId: string;
  currency: string;
  billingAddress: IAddress;
  shippingAddress: IAddress;
  items: any;
  successPageDataLoaded: boolean;
  successPageRefreshed: boolean;
  checkoutItems: any;
  companyRoles: EUserRoles[];
  userSubscription$: Subscription;
  currentUserCardId: string;
  iso2Codes: Iiso2Codes[] = AddressUtils.getCountriesForDeliveryAddress();
  customerName: string;
  itemsQuantity: any;
  customerCompanyId: string;
  customerCompanyIdSubscription$: Subscription;
  taxNumber: string;
  isInStore: boolean = false;
  isJpStore: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
    private ordersFacade: OrdersFacade,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.checkSuccessParamOrderIdAndReloadOrdersHistory();
    this.customerCompanyIdSubscription$ = this.companyUserId();
    this.customerName = '';
    this.currentUserCardId = '';
    this.successPageRefreshed = false;
    this.successPageDataLoaded = false;
    this.orderApproveSubscription$ = this.getOrderApproveData();
    this.userSubscription$ = this.companyRolesSubscription();
    this.isInStore = AppUtils.isStoreActive(EStoreTypes.IN);
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
  }

  ngOnDestroy(): void {
    this.orderApproveSubscription$.unsubscribe();
    this.userSubscription$.unsubscribe();
    this.checkoutFacade.successSummaryPageClearData();
    this.customerCompanyIdSubscription$.unsubscribe();
  }

  companyRolesSubscription(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles().subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  companyUserId(): Subscription {
    return this.customerFacade.selectUserId().subscribe(companyId => {
      this.customerCompanyId = companyId;
    });
  }

  getOrderApproveData(): Subscription {
    return this.checkoutFacade.successSummaryPageData().subscribe(state => {
      // TODO: in future only change value for one that actually changed
      this.checkoutItems = state.items;
      if (!state.items) {
        this.successPageRefreshed = true;
        return;
      }
      this.items = state.items.included.filter(item => item.type === 'concrete-products');
      this.itemsQuantity = state.items.included.filter(item => item.type === 'items');
      // eslint-disable-next-line prefer-const
      let {billingAddress, shippingAddress} = state.data.data.attributes;
      this.billingAddress = this.updateAddress(billingAddress);
      this.shippingAddress = this.updateAddress(shippingAddress);
      this.currency = state.items.attributes.currency;
      this.orderId = state.data.data.id;
      this.customerName = `${state.items.attributes.customer.firstName} ${state.items.attributes.customer.lastName}`;
      this.currentUserCardId = state.cartId;
      this.successPageDataLoaded = true;
      this.taxNumber = state.data.data.attributes.taxNumber;
      if (state.data.data.attributes.customer.uuidCompanyUser === this.customerCompanyId) {
        this.analyticsService.setCartId(state.data.id);
        this.analyticsService.setProducts(state.items);
        this.analyticsService.trackOrderPlaced('Order Summary', PageTypes.ORDER_SUMMARY);
      }
    });
  }

  updateAddress(address): IAddress {
    Object.keys(address).forEach((key) => {
      if (key === 'country') {
        const addressIso: Iiso2Codes =
                this.iso2Codes.find((code: Iiso2Codes): boolean => code.value === address.iso2Code);
        address = Object.assign({...address}, {country: addressIso?.name || ''});
      } else {
        address = Object.assign({...address}, {[key]: address[key] === 'empty' ? '' : address[key]});
      }
    });
    return address;
  }

  getItemPicture(id: string): string {
    // eslint-disable-next-line max-len
    const concreteProductImageSets = this.checkoutItems.included.find((tmp) => tmp.type === 'concrete-product-image-sets' && tmp.id === id);
    return concreteProductImageSets.attributes.imageSets[0].images[0].externalUrlSmall;
  }

  getItemPrice(id: string): number {
    return this.checkoutItems.included.filter((tmp) => tmp.type === 'items' && tmp.id === id)[0].attributes.calculations.unitPrice;
  }


  getTotalPrice(): number {
    const {taxTotal, grandTotal}: {taxTotal: number, grandTotal: number} = this.checkoutItems.attributes.totals;
    if (this.isJpStore) {
      return grandTotal;
    }
    return taxTotal + grandTotal;
  }

  showApproverMessage(): boolean {
    if (this.companyRoles.length === 0 || this.currentUserCardId === '') {
      return false;
    }

    return this.companyRoles.includes(EUserRoles.Approver) ||
      this.companyRoles.includes(EUserRoles.Admin);
  }

  showApproverPersonalMessage(): boolean {
    return this.showApproverMessage() &&
      this.checkoutItems.id === this.currentUserCardId;
  }

  showApproverApprovalMessage(): boolean {
    return this.showApproverMessage() &&
      this.checkoutItems.id !== this.currentUserCardId;
  }

  isExcludeTaxActive(): boolean {
    const currentStore: IStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }

  getSubTotal(): number {
    return this.checkoutItems.attributes.totals.subtotal ?? 0;
  }

  getGrandTotal(): number {
    return this.checkoutItems.attributes.totals.grandTotal ?? 0;
  }

  getShipmentTotal(): number {
    return this.checkoutItems.attributes.totals.expenseTotal ?? 0;
  }

  getTaxTotal(): number {
    return this.checkoutItems.attributes.totals.taxTotal ?? 0;
  }

  getTaxPercent(): number {
    return TaxUtils.getTaxPercentage(this.itemsQuantity);
  }

  private checkSuccessParamOrderIdAndReloadOrdersHistory(): void {
    this.route.params.subscribe(params => {
      if (params.orderId) {
        this.ordersFacade.loadOrdersHistory(undefined, undefined, true);
      }
    });
  }
}
