<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-sm-12 col-lg-1 hidden-sm"></div>
      <div class="col-12 col-lg-10">
        <h3 class="checkout-label" [attr.data-test]="'title-1stDetailsPage'">
          {{ getPageTitle() | translate }}
        </h3>
        <h6 [attr.data-test]="'subtitle-1stDetailsPage'">{{ 'request-details.select-details' | translate }}</h6>
        <br>
        <app-ivk-selection-form
            [installBaseLoading]="installBaseLoading"
            [systems]="systems"
            [businessUnitLoading]="businessUnitLoading"
            [businessUnits]="businessUnits"
            (formChanged)="ivkFormChanged($event)"
            (systemIdChanged)="systemIdValueChanged($event)">
        </app-ivk-selection-form>
        <div class="row">
          <app-select-box
              class="col-12"
              [form]="deliveryForm"
              [modelValue]="deliveryForm.value.deliveryAddress"
              [cssStyles]="{wrapper: 'selectbox-delivery-address'}"
              [options]="addresses"
              [inputName]="'deliveryAddress'"
              label="{{ 'checkout-delivery-details.delivery-address' | translate }}"
              [isRequired]="true"
              [disabled]="!allAddressesLoaded"
              [readOnly]="addresses?.length <= 1"
              [loading]="!allAddressesLoaded"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              (selected)="setDeliveryAddress($event)">
          </app-select-box>
        </div>
        <div *ngIf="visibilityOfInputs?.includes(inputsEnum?.DEPARTMENT)">
          <app-select-box
              class="col-12 field-department"
              [form]="deliveryForm"
              [modelValue]="deliveryForm.value.department"
              [options]="departments"
              [inputName]="'department'"
              label="{{ 'checkout-delivery-details.select-department-title' | translate }}"
              [isRequired]="requiredInputs?.includes(inputsEnum.DEPARTMENT)"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              (selected)="setFormValue($event)">
          </app-select-box>
        </div>
        <div *ngIf="visibilityOfInputs?.includes(inputsEnum?.FLOOR_OR_ROOM)">
          <app-textfield
              class="col-12 field-department"
              [form]="deliveryForm"
              [modelValue]="deliveryForm.value.floorOrRoom"
              [cssStyles]="{wrapper: 'textfield-with-prefix'}"
              [inputName]="'floorOrRoom'"
              label="{{ 'checkout-delivery-details.select-floor-or-room' | translate }}"
              [isRequired]="requiredInputs?.includes(inputsEnum?.FLOOR_OR_ROOM)"
              (fieldFilled)="setFormValue($event)">
          </app-textfield>
        </div>
        <div class="address-buttons"
            [ngClass]="{'m-t-neg-30': visibilityOfInputs?.includes(inputsEnum?.FLOOR_OR_ROOM)}"
        >
          <button
              type="button"
              class="button add-new-address"
              (click)="showModalNewAddress()"
              [attr.data-test]="'button-addNewAddress'">
            {{ 'checkout-delivery-details.add-new-address-btn' | translate }}
          </button>
          <button
              type="button"
              class="button add-new-address"
              [disabled]="businessAddressesLoading || deliveryForm?.value?.deliveryAddress?.value?.isCustom === true ||
              deliveryForm?.value?.deliveryAddress?.addressFromSystem === undefined"
              (click)="showModalWrongAddress()"
              [attr.data-test]="'button-reportWrongAddress'">
            {{ 'checkout-delivery-details.report-wrong-address' | translate }}
          </button>
        </div>
        <hr>
        <ng-container *ngFor="let item of cartItems">
          <app-quote-item-with-ivk-form [item]="item"
                                        [installBaseLoading]="installBaseLoading"
                                        [systems]="systems"
                                        [businessUnitLoading]="businessAddressesLoading"
                                        [businessUnits]="businessUnits"
                                        [additionalFields]="additionalItemLevelFields"
                                        (formChanged)="itemFormChanged($event)"
                                        (formHidden)="itemFormHidden($event)">
          </app-quote-item-with-ivk-form>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="container order-summary col-12 col-lg-4">
    <div class="row">
      <div class="col-12">
        <button
            class="button button--primary button-request-quote"
            type="button"
            [disabled]="!formsAreValid"
            (click)="proceedToNextStep()"
            [attr.data-test]="'button-proceedToNextStep'">
          {{ 'request-details.next' | translate }}
        </button>
        <ng-container *ngIf="!!getTotalPrice() && isRfqOnly && isSetPricesForAllItems()">
          <div class="row">
            <div class="col-12 min-order-value" *ngIf="!isMinimumOrderValue()">
              <strong>
                {{ 'shop-cart.minimum-order-value' | translate }}
              </strong>
            </div>
            <div class="col-12 col-md-8">
              <h6 class="order-summary-total-price">{{ 'checkout-delivery-details.total-price' | translate }}</h6>
            </div>
            <div class="col-12 col-md-4">
              <p class="order-summary-price" [attr.data-test]="'desc-cartTotalPrice'">
                {{ currentCart.totals.subtotal | currency: currency }}
              </p>
            </div>
          </div>
          <div class="order-summary-additional-information">
            <ng-container *ngIf="showPriceDisclaimer">
              {{ 'shop-cart.excludes-vat' | translate }}<br/>
              {{ 'shop-cart.pricing-validation' | translate }}
            </ng-container>
          </div>
        </ng-container>
        <hr class="bottom-line">
        <div class="back-to-cart" (click)="backToCart()" [attr.data-test]="'link-backToCart'">
          <span class="icon-arrow-left"></span>
          {{ 'checkout-delivery-details.back-to-card' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<app-new-delivery-address-modal
    [showModal]="showModalAddress"
    (closeModal)="showModalAddress = false"
    (newAddress)="setDeliveryAddress($event)">
</app-new-delivery-address-modal>
<app-report-wrong-address-modal
    [resetAddressList]="true"
    [userLoggedInRoles]="loggedUserRoles"
    [showModal]="showModalAddressWrong"
    (closeModal)="showModalAddressWrong = false"
    [quoteId]="cartId"
    [addressesList]="getAddressesListReportWrongAddress()"
    [roleSpecificMessage]="true">
</app-report-wrong-address-modal>
