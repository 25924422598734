import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Iiso2Codes } from '../../../../../models/enviroment-delivery-details.model';
import { AddressUtils } from '../../../../../utils/address.utils';
import { Subject } from 'rxjs';
import { AppUtils } from '../../../../../utils/app.utils';
import { EFeatureToggles, EStoreTypes } from '../../../../../configurations/common';
import * as ValidationPatterns from '../../../../../configurations/validations';
import { ICustomerAddressPayloadData } from '../../../../../models/customer.models';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationFacade } from '../../../../../facades/configuration.facade';

@Component({
  selector: 'app-add-edit-delivery-address-modal',
  templateUrl: './add-edit-delivery-address-modal.component.html',
  styleUrls: ['./add-edit-delivery-address-modal.component.scss'],
})
export class AddEditDeliveryAddressModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() address: any;
  @Input() showModal: boolean;

  @Output() newAddress: EventEmitter<ICustomerAddressPayloadData> = new EventEmitter<ICustomerAddressPayloadData>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  isSapStore: boolean = AppUtils.isSapStore();
  isCaStore: boolean = false;
  isUsStore: boolean = false;

  addressForm: UntypedFormGroup;
  maxLengthName: number = 50;
  maxLengthZipcode: number = 15;
  maxLengthCity: number = 35;
  maxLengthState: number = 35;
  maxLengthStreetAndNumber: number = 50;
  minLengthState: number = 2;
  minLengthCity: number = 3;
  iso2Codes: Iiso2Codes[] = AddressUtils.getCountriesForDeliveryAddress();
  isAttentionToEnabled: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.selectIsAttentionToEnabled();
    this.initializeForm();
    this.setFormValidatorsLimits();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal.currentValue) {
      this.initializeForm();
    }
  }

  /**
   * Initialize form and set validators.
   *
   * for add new address workflow -> set empty values for all form fields
   * for edit address workflow, fill form fields with address data
   */
  initializeForm(): void {
    this.addressForm = this.formBuilder.group({
      name: [this.address?.name ?? '', [
        Validators.required,
        Validators.maxLength(this.maxLengthName),
        ValidationPatterns.noEmptySpaceOnTheBeginning,
      ]],
      attentionTo: this.address?.attentionTo ?? '',
      streetAndNumber: [this.address?.address1 ?? '', [
        Validators.required,
        Validators.maxLength(this.maxLengthName),
        ValidationPatterns.streetAndNumberAndZipCodePattern,
      ]],
      zipCode: [this.address?.zipCode ?? '', [
        Validators.required,
        Validators.maxLength(this.maxLengthZipcode),
        ValidationPatterns.zipCodeValidation(
          new RegExp(
            this.isCaStore
              ? ValidationPatterns.canadaZipCodeRegex
              : ValidationPatterns.streetAndNumberAndZipCodeRegex),
          'generalZipCode',
        ),
      ]],
      city: [this.address?.city ?? '', [
        Validators.required,
        Validators.minLength(this.minLengthCity),
        Validators.maxLength(this.maxLengthCity),
        ValidationPatterns.cityStatePattern,
      ]],
      state: [this.address?.state ?? '', [
        Validators.required,
        Validators.minLength(this.minLengthState),
        Validators.maxLength(this.maxLengthState),
        ValidationPatterns.cityStatePattern,
        ...(this.isSapStore ?
          [ValidationPatterns.uppercase] : []),
      ]],
      country: this.iso2Codes.find(i => i.name === this.address?.country?.toUpperCase())
        ?? this.iso2Codes.find(i => i.isDefault)
        ?? '',
    });
  }

  /**
   * Set value for given form field.
   */
  setFormValue(event: any): void {
    this.addressForm.patchValue({
      [event.key]: event,
    });
  }

  /**
   * Reset form and close modal.
   */
  resetForm(): void {
    this.closeModal.emit();
    this.addressForm.reset();
  }

  /**
   * Determine if form is valid.
   */
  isFormValid(): boolean {
    return this.addressForm.status === 'VALID';
  }

  /**
   * Emit ICustomerPayloadData object of added/edited address to parent component.
   */
  saveAddress(): void {
    this.newAddress.emit({
      data: {
        type: 'addresses',
        attributes: {
          attentionTo: this.addressForm.value.attentionTo,
          salutation: 'Ms',
          firstName: this.addressForm.value.name,
          lastName: 'empty',
          address1: this.addressForm.value.streetAndNumber,
          address2: this.addressForm.value.state,
          address3: '',
          phone: '',
          company: this.addressForm.value.name,
          country: this.addressForm.value.country.name,
          zipCode: this.addressForm.value.zipCode,
          city: this.addressForm.value.city,
          iso2Code: this.addressForm.value.country.value,
          isDefaultShipping: false,
          isDefaultBilling: false,
        },
      },
    });
  }

  /**
   * Apply custom validator for "zipCode" form field.
   */
  applyZipCodeValidator(): void {
    const generalZipCodeValidator: ValidatorFn = ValidationPatterns.zipCodeValidation(
      new RegExp(
        this.isCaStore
          ? ValidationPatterns.canadaZipCodeRegex
          : ValidationPatterns.streetAndNumberAndZipCodeRegex),
      'generalZipCode',
    );

    const puertoRicoZipCodeValidator = ValidationPatterns.zipCodeValidation(
      new RegExp(ValidationPatterns.puertoRicoZipCodeRegex), 'puertoRicoZipCode',
    );

    if (this.isUsStore && this.addressForm?.value?.state === ValidationPatterns.puertoRicoState) {
      this.addressForm.controls['zipCode'].setValidators([puertoRicoZipCodeValidator]);
    } else {
      this.addressForm.controls['zipCode'].setValidators([generalZipCodeValidator]);
    }

    this.addressForm.controls['zipCode'].setValue(this.addressForm?.value?.zipCode);
  }

  /**
   * Set form validator limits (max/min length of different form fields).
   *
   * @private
   */
  private setFormValidatorsLimits(): void {
    if (this.isSapStore) {
      this.maxLengthName = 35;
      this.maxLengthZipcode = 10;
      this.maxLengthCity = 30;
      this.maxLengthState = 2;
      this.minLengthCity = 2;
      this.maxLengthStreetAndNumber = 35;
    }

    if (this.isCaStore) {
      this.maxLengthZipcode = 7;
    }
  }


  /**
   * Method for determining if attentionTo functionality is enabled (based on the ARAKH feature toggle).
   *
   * @private
   */
  private selectIsAttentionToEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.ATTENTION_TO).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: boolean) => {
      this.isAttentionToEnabled = value;
    });
  }
}
