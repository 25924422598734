import { formatDate } from '@angular/common';

const N_MILLIS_PER_DAY = 1000 * 3600 * 24;

export class DateUtils {
  /**
   * @param {string} dateToCompare
   * @returns {number}
   */
  static countDaysFromToday(dateToCompare: string): number {
    const today = new Date();
    const time = new Date(dateToCompare).getTime() - new Date(today).getTime();
    return Math.ceil(time / N_MILLIS_PER_DAY);
  }

  /**
   * @param {number} daysFromToday
   * @param {string} format
   * @returns {string | Date}
   */
  static getCurrentDate(daysFromToday: number = 0, format: string = 'yyyy-MM-dd'): string | Date {
    const date = new Date();
    date.setDate(date.getDate() + daysFromToday);
    return formatDate(date, format, 'en');
  }

  /**
   * @param {string | Date} startDate
   * @param {string | Date} endOfSupportDate
   * @returns {number}
   */
  static calculateContractTerm(startDate: string | Date, endOfSupportDate: string | Date): number {
    const endOfSupportYear = new Date(endOfSupportDate).getFullYear();
    const yearOfStart = startDate ? new Date(startDate).getFullYear() : new Date().getFullYear();
    const diff = endOfSupportYear - yearOfStart;
    return diff !== 0 ? diff : 1;
  }

  /**
   * @param {string} date - date in format: YYYY-MM-DD
   * @returns {boolean}
   */
  static isValidDate(date: string): boolean {
    const splitDate = date.split('-');
    const year = splitDate[0],
          month = splitDate[1],
          day = splitDate[2];
    const dayRegex = /^(0?[1-9]|[1-2]\d|3[01])$/, // Check if day is from 01 to 31
          monthRegex = /^(0?[1-9]|1[0-2])$/, // Check if month is from 01 to 12
          yearRegex = /^(19[7-9]\d|20\d{2}|2[1-9]\d{2}|29\d{2})$/; // Check if year is from 1970 to 2999
    return dayRegex.test(day) && monthRegex.test(month) && yearRegex.test(year);
  }

  /**
   * @param {string} endOfSupport - date in format: YYYY-MM-DD
   * @returns {boolean}
   */
  static isAfterEndOfSupport(endOfSupport: string): boolean {
    if (!endOfSupport) {
      return false;
    } else {
      return new Date(endOfSupport).setHours(0, 0) < new Date().setHours(0, 0);
    }
  }
}
