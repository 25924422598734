import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EStoreTypes } from '../../../configurations/common';
import { AppUtils } from '../../../utils/app.utils';
import { combineLatest, Observable } from 'rxjs';
import { ITotals } from '../../../models/common.models';
import { IDiscount } from '../../../models/cart.models';
import { OrderReviewService } from '../services/order-review.service';
import { map, take } from 'rxjs/operators';
import { TaxUtils } from '../../../utils/tax.utils';
import { MarketingFacade } from '../../../facades/marketing.facade';

@Component({
  selector: 'app-totals-and-discounts',
  templateUrl: './totals-and-discounts.component.html',
  styleUrl: './totals-and-discounts.component.scss',
})
export class TotalsAndDiscountsComponent {
  protected readonly EStoreTypes = EStoreTypes;
  protected readonly AppUtils = AppUtils;
  protected readonly TaxUtils = TaxUtils;

  @Input() currency: string;
  @Input() taxPercentage: number;
  @Input() cartId: string;
  @Output() setIsSubmitButtonDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadData: EventEmitter<void> = new EventEmitter<void>();

  totalsAndDiscounts$: Observable<{totals: ITotals, discounts: IDiscount[]}> =
    new Observable<{totals: ITotals, discounts: IDiscount[]}>();

  constructor(protected orderReviewService: OrderReviewService, protected marketingFacade: MarketingFacade) {
    this.totalsAndDiscounts$ = combineLatest([
      this.orderReviewService.cartTotals$,
      this.orderReviewService.cartDiscounts$,
    ]).pipe(
      map(([totals, discounts]) => ({totals, discounts})),
    );
  }

  /**
   * Remove discount from the cart.
   * @param {IDiscount} discount
   */
  removeDiscount(discount: IDiscount): void {
    this.setIsSubmitButtonDisabled.emit(true);
    this.marketingFacade.removeVoucher(this.cartId, discount.code).pipe(
      take(1),
    ).subscribe({
      next: response => {
        this.setIsSubmitButtonDisabled.emit(false);
        this.reloadData.emit();
        this.orderReviewService.updateTotalsAndDiscounts(response.data.attributes.totals, response.data.attributes.discounts);
      },
      error: _ => {
        this.setIsSubmitButtonDisabled.emit(false);
        this.reloadData.emit();
      },
    });
  }
}
