<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-lg-1 hidden-md-down"></div>
      <div class="col-12 col-lg-10">
        <h3 class="page-title" [attr.data-test]="'title-1stDetailsPage'">
          {{ 'checkout-delivery-details.title' | translate }}
        </h3>
        <h6 class="page-subtitle" [attr.data-test]="'subtitle-1stDetailsPage'">
          {{ 'checkout-delivery-details.select-details' | translate }}
        </h6>
        <section class="order-details-form">
          <app-textfield
              label="{{ 'checkout-delivery-details.selected-equipment' | translate }}"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.selectedEquipment"
              [inputName]="'selectedEquipment'"
              [loading]="addressesLoading"
              [readOnly]="true">
          </app-textfield>

          <app-textfield
              *ngIf="isBusinessPartner"
              label="{{ 'checkout-delivery-details.customer' | translate }}"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.customer"
              [inputName]="'customer'"
              [loading]="addressesLoading"
              [readOnly]="true">
          </app-textfield>

          <app-textfield
              label="{{ 'checkout-delivery-details.sold-to-account' | translate }}"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.soldToAccount"
              [inputName]="'soldToAccount'"
              [loading]="addressesLoading"
              [readOnly]="true">
          </app-textfield>

          <div *ngIf="isBusinessPartner" class="display-flex justify-content-end">
            <a (click)="showModalReportWrongAddress = true;
                   reportWrongAddressType = EAddressType.SOLD_TO;"
               [attr.data-test]="'button-reportWrongSoldToAddress'"
               [title]="'checkout-delivery-details.report-wrong-address-btn' | translate"
               class="report-wrong-address">
              <span class="icon-arrow-right color-orange"></span>
              <span> {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}</span>
            </a>
          </div>
          <app-select-box
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.shipToAddress"
              [options]="availableShipToAddresses"
              [inputName]="'shipToAddress'"
              [loading]="addressesLoading"
              [disabled]="availableShipToAddresses.length < 1"
              [readOnly]="availableShipToAddresses.length <= 1"
              label="{{ 'checkout-delivery-details.ship-to-address' | translate }}"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              [attr.data-test]="'checkout-delivery-details-ship-to-addresses-selectbox'"
              (selected)="selectShipToAddressInForm($event)">
          </app-select-box>
          <div *ngIf="isBusinessPartner" class="display-flex justify-content-end">
            <a (click)="showAddNewAddressModal()"
               [attr.data-test]="'button-changeAddress'"
               [title]="'checkout-delivery-details.change-address-btn' | translate"
               class="report-wrong-address padding-right-05"
               [ngClass]="{'disabled': isSaturdayShipment}">
              <span class="icon-arrow-right color-orange"></span>
              <span> {{ 'checkout-delivery-details.change-address-btn' | translate }}</span>
            </a>
            <a (click)="showModalReportWrongAddress = true;
                   reportWrongAddressType = EAddressType.SHIP_TO;"
               [attr.data-test]="'button-reportWrongShipToAddress'"
               [title]="'checkout-delivery-details.report-wrong-address-btn' | translate"
               class="report-wrong-address">
              <span class="icon-arrow-right color-orange"></span>
              <span> {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}</span>
            </a>
          </div>
          <app-select-box
              *ngIf="isBusinessPartner"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.businessPartnerBillToAddress"
              [options]="availableBPBillToAddresses"
              [inputName]="'businessPartnerBillToAddress'"
              [loading]="availableBpBillToAddressesLoading"
              [disabled]="availableBpBillToAddressesLoading"
              [readOnly]="availableBPBillToAddresses.length <= 1"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              label="{{ 'checkout-delivery-details.bill-to-address' | translate }}"
              (selected)="selectBpBillToAddress($event)">
          </app-select-box>
          <a *ngIf="isBusinessPartner" (click)="showModalReportWrongAddress = true;
                   reportWrongAddressType = EAddressType.BILL_TO;"
             [attr.data-test]="'button-reportWrongBillToAddress'"
             [title]="'checkout-delivery-details.report-wrong-address-btn' | translate"
             class="report-wrong-address">
            <span class="icon-arrow-right color-orange icon"></span>
            <span> {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}</span>
          </a>
          <div class="display-flex"
               *ngIf="availableBPBillToAddresses?.length<1 && isBusinessPartner && !availableBpBillToAddressesLoading">
            <div class="padding-right-05">
              <span class="icon-close icon-close__circle"></span>
            </div>
            <div class="text">
              <span [attr.data-test]="'bill-to-address-error-message'">
                {{ 'quote-summary.bill-to-error'| translate }}
              </span>
            </div>
          </div>
        </section>
        <div *ngIf="!isBusinessPartner" class="action-buttons-addresses">
          <button type="button"
                  class="button button--secondary"
                  (click)="showAddNewAddressModal()"
                  [attr.data-test]="'button-addNewAddress'"
                  [disabled]="isSaturdayShipment">
            {{ 'checkout-delivery-details.add-custom-address-btn' | translate }}
          </button>
          <button type="button"
                  class="button button--secondary"
                  [disabled]="addressesLoading || orderDetailsForm?.value?.shipToAddress?.value?.isCustom"
                  (click)="showModalReportWrongAddress = true"
                  [attr.data-test]="'button-reportWrongAddress'">
            {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}
          </button>
        </div>
        <p class="disclaimer-saturday-shipment-order-details" *ngIf="isSaturdayShipment">
          {{'checkout-delivery-details.saturday-delivery-method-disclaimer' | translate }}
        </p>
        <section class="cart-items">
          <strong class="section-title">
            {{ 'checkout-delivery-details.your-items' | translate }}
          </strong>
          <ng-container *ngFor="let item of cartItemsWithDetails">
            <div class="cart-item-outer-container">
              <div class="cart-item" [attr.data-test]="'cart-item-' + item.attributes.name">
                <div class="cart-item--image">
                  <img [src]="getProductImageUrl(item)" [alt]="item.attributes.name" [attr.data-test]="'img-itemImage'">
                </div>
                <div class="cart-item--body">
                  <div class="cart-item--info">
                    <div class="info-header">
                      <h5 class="item-name" [attr.data-test]="'desc-itemName'">
                        {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                      </h5>
                      <span *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && item.attributes.isDiscontinued"
                            class="label discontinued-item"
                            [attr.data-test]="'label-discontinuedItem'">
                        {{ 'spare-parts.discontinued-item' | translate }}
                      </span>
                      <span *ngIf="item.attributes.availability?.isExchangeable"
                            class="label item-exchange-material"
                            [attr.data-test]="'label-exchangeableItem'">
                          {{ 'spare-parts.exchangeable-item' | translate }}
                      </span>
                      <span *ngIf="item.attributes.heavyweight"
                            class="label heavyweight-item"
                            [attr.data-test]="'label-heavyWeightItem'">
                          {{ 'spare-parts.heavyweight-item' | translate }}
                      </span>
                    </div>
                    <div class="item-material-number" [attr.data-test]="'desc-materialNumber'">
                      {{ item.attributes.materialNumber }}
                    </div>
                  </div>
                  <div class="cart-item--additional-fields">
                    <ng-container *ngFor="let additionalField of itemDetailsAdditionalFields">
                      <ng-container *ngIf="additionalField.shouldDisplayFunc(item)">
                        <div class="additional-field-row">
                          <div [attr.data-test]="'label-' + additionalField.name">
                            {{ additionalField.name | translate }}:
                          </div>
                          <div [attr.data-test]="'value-' + additionalField.name">
                            {{ additionalField.getValFunc(item) }}
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              <app-message *ngIf="isPriceDisputingActive && isPriceDisputingSetForItem(item.id)"
                           [message]="itemMessage">
              </app-message>
            </div>
          </ng-container>
        </section>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4 order-summary">
    <div class="row">
      <div class="col-12">
        <button type="button"
                class="button button--primary button-next-step"
                [disabled]="!isFormValid()"
                (click)="proceedToContactDetails()"
                [attr.data-test]="'button-proceedToNextStep'">
          {{ 'checkout-delivery-details.next-btn' | translate }}
        </button>
        <section class="cart-pricelist">
          <div class="disclaimer--price">
            <strong [attr.data-test]="'label-totalPrice'">
              {{ 'checkout-delivery-details.total-price' | translate }}
            </strong>
            <br>
            <span [attr.data-test]="'label-exclTax'">
              {{ 'checkout-delivery-details.exclude-tax' | translate }}
            </span>
          </div>
          <div class="cart--total-price">
            <strong [attr.data-test]="'desc-totalPrice'">
              {{ cartAttributes.totals.subtotal | currency: cartAttributes.currency }}
            </strong>
          </div>
        </section>
        <app-cart-rules
            *ngIf="cartRules?.length > 0"
            [cartRules]="cartRules"
            [priceToPay]="cartAttributes.totals.priceToPay"
            [currency]="cartAttributes.currency">
        </app-cart-rules>
        <hr class="bottom-line">
        <a class="button button--link button-back-to-cart"
           [routerLink]="['/shop-cart']"
           [attr.data-test]="'link-backToCart'">
          <span class="icon-arrow-left color-orange"></span>
          {{ 'checkout-delivery-details.back-to-cart' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<app-new-delivery-address-modal
    [showModal]="showModalAddNewAddress"
    (closeModal)="showModalAddNewAddress = false"
    (newAddress)="setCustomShipToAddress($event)">
</app-new-delivery-address-modal>
<ng-container *ngIf="!isBusinessPartner; else businessPartnerReportWrongAddress">
  <app-report-wrong-address-modal
      [userLoggedInRoles]="loggedUserRoles"
      [roleSpecificMessage]="true"
      [addressesList]="this.shipToAddresses"
      [cpqOnly]="true"
      [quoteId]="cartId"
      [resetAddressList]="true"
      [cpqLabelName]="'checkout-delivery-details.ship-to-address' | translate"
      [cpqAddressType]="addressType"
      [functionalLocations]="[systemDetails?.siemensEquipmentId]"
      [showModal]="showModalReportWrongAddress"
      (closeModal)="showModalReportWrongAddress = false">
  </app-report-wrong-address-modal>
</ng-container>
<ng-template #businessPartnerReportWrongAddress>
  <app-report-wrong-bp-address-modal
      [quoteId]="cartId"
      [addressType]="reportWrongAddressType"
      [functionalLocations]="[systemDetails?.siemensEquipmentId]"
      [showModal]="showModalReportWrongAddress"
      (closeModal)="showModalReportWrongAddress = false; reportWrongAddressType = null"
      [addressValue]="addressValueForReport()"
      [title]="titleForReport()">
  </app-report-wrong-bp-address-modal>
</ng-template>
