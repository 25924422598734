import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreConfigurationActions } from '../actions';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { StoreConfigurationFacade } from '../facades/store-configuration.facade';
import { of } from 'rxjs';

@Injectable()
export class StoreConfigurationEffects {
  constructor(
    private actions$: Actions,
    private storeConfigurationFacade: StoreConfigurationFacade,
  ) {
  }

  retrieveStoreConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StoreConfigurationActions.retrieveStoreConfiguration),
      mergeMap(action =>
        this.storeConfigurationFacade.getStoreConfiguration(action.store).pipe(
          take(1),
          map(configuration => StoreConfigurationActions.retrieveStoreConfigurationSuccess(
            {storeConfiguration: configuration}
          )),
          catchError(err => of(StoreConfigurationActions.retrieveStoreConfigurationFail({error: err})))
        )
      ),
    );
  });
}
