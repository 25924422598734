import { Component } from '@angular/core';
import { RequestQuoteService } from './services/request-quote.service';

@Component({
  selector: 'app-harmonized-page-request-quote',
  templateUrl: './harmonized-page-request-quote.component.html',
  styleUrl: './harmonized-page-request-quote.component.scss'
})
export class HarmonizedPageRequestQuoteComponent {

  //TODO: Please move all possible logic to functional service 'RequestQuoteService', Do NOT use component for logic

  constructor(private requestQuoteFunctionalService: RequestQuoteService) {}

  //Please use '_' when u declare private methods like private _isPrivateMethod
}
