import { createAction, props } from '@ngrx/store';
import { INotification } from '../models/cpq.models';
import { Alert } from '../models/alert.models';

export const redirectToUrl = createAction(
  '[App] Redirect to url',
  props<{url: string, replaceUrl: boolean}>(),
);

export const redirectToUrlExecuted = createAction(
  '[App] Redirect to url executed',
  props<{url: string, replaceUrl: boolean}>(),
);

export const redirectToUrlSkipped = createAction(
  '[App] Redirect to url skipped',
);

export const appendNotification = createAction(
  '[App] Append notification',
  props<{notification: INotification}>(),
);

export const closeNotification = createAction(
  '[App] Close notification',
  props<{notificationId: number}>(),
);

export const clearNotifications = createAction(
  '[App] Clear notifications',
);

export const setAlert = createAction(
  '[Alert] Add new alert',
  props<{alert: Alert}>(),
);

export const clearAlert = createAction(
  '[Alert] Remove alert',
);

export const redirectToLastPage = createAction(
  '[App] Redirect to last page',
);

export const redirectToHomepage = createAction(
  '[App] Redirect to Homepage',
);

export const redirectToShopCart = createAction(
  '[App] Redirect to Shop cart',
);

export const navBarLoaded = createAction(
  '[Navigation Bar] Navigation bar loaded',
  props<{name: string}>(),
);

export const homePageBannerLoaded = createAction(
  '[Homepage - Banner] Banner loaded',
  props<{name: string}>(),
);
