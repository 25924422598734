import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageUtils } from '../../../../utils/image.utils';
import { CartUtils } from '../../../../utils/cart.utils';
import { UiUtils } from '../../../../utils/ui.utils';

@Component({
  selector: 'app-other-quote',
  templateUrl: './other-quote.component.html',
  styleUrls: ['./other-quote.component.scss'],
})
export class OtherQuoteComponent {
  @Input() loadingQuote: boolean;
  @Input() currency: string;
  @Input() quote: any;
  @Input() quoteImages: any[];

  @Output() switchCartEmit = new EventEmitter<{cartId: string, redirect: boolean}>();
  @Output() deleteQuoteEmit = new EventEmitter<{cartId: string, totalItemsQuantity: number}>();

  quoteName = CartUtils.getCartName;
  singularOrPlural = UiUtils.singularOrPlural;

  calculateEquType(details: any): number {
    const materialNumbers = [...new Set(details.map(item => item.attributes.systemDetails?.materialNumber))];
    return materialNumbers ? materialNumbers.length : 0;
  }

  getImageForQuote(itemId: string): string {
    return ImageUtils.getProductImageUrl(
      this.quoteImages.find(imageSet => imageSet.id === itemId)
    );
  }

  switchQuote(cartId: string, redirect?: boolean): void {
    this.switchCartEmit.emit({cartId, redirect});
  }

  deleteQuote(cartId: string, totalItemsQuantity: number): void {
    this.deleteQuoteEmit.emit({cartId, totalItemsQuantity});
  }
}
