import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CatalogFacade } from 'src/app/facades/catalog.facade';
import { IShsEquipmentData } from 'src/app/models/installedbase.models';
import { AdvancedFilter } from '../../../models/advanced-filter';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { CpqFacade } from '../../../facades/cpq.facade';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { SearchFieldPipe } from '../../../shared/pipes/search-field.pipe';
import { IModalitiesAndMaterialNumbers } from '../../../models/cpq.models';

@Component({
  selector: 'app-page-my-contracts',
  templateUrl: './my-contracts.component.html',
  styleUrls: ['./my-contracts.component.scss'],
  providers: [SearchFieldPipe],
})

export class MyContractsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() installedBaseSystemData: IShsEquipmentData[];
  @Input() installBaseLoading: boolean = true;
  @Input() isCpqEnabled: boolean;
  @Input() productSku: string;
  @Input() modalitiesAndMaterialNumbers: IModalitiesAndMaterialNumbers[];
  @Input() soldTo: number;

  searchValue: string;
  searchLoading: boolean = false;
  agreements: IShsEquipmentData[];
  agreementsNeedAttention: IShsEquipmentData[] = [];
  agreementsUnderContract: IShsEquipmentData[] = [];
  contractFilters: AdvancedFilter[] = [
    {
      name: 'modality',
      attribute: 'attributes',
      firstAdditionalAttribute: 'category',
      options: [],
      selected: [],
    },
    {
      name: 'equipment-name',
      attribute: 'attributes',
      firstAdditionalAttribute: 'nameEnUs',
      options: [],
      selected: [],
    },
    {
      name: 'city-and-state',
      attribute: 'attributes',
      firstAdditionalAttribute: 'siemensEquipmentAddress',
      secondAdditionalAttribute: 'city',
      options: [],
      selected: [],
    },
  ];
  cartItems = [];
  selectedSystem: any;
  active: boolean = false;
  advancedSearchDropdownIsOpened: boolean = false;
  allOptionsAreSelected: any;
  selectedOptions: any;
  filterSearchValue: any;

  @ViewChildren(DropdownComponent) dropdowns: QueryList<DropdownComponent>;

  private unsubscribe$ = new Subject<void>();
  private initWidth: number = 0;

  constructor(
    private catalogFacade: CatalogFacade,
    private marketingFacade: MarketingFacade,
    private cpqFacade: CpqFacade,
    private searchFieldPipe: SearchFieldPipe,
  ) {
  }

  ngOnInit(): void {
    if (this.isCpqEnabled) {
      if (this.productSku) {
        this.selectSelectedSystem();
      }
      this.selectCartItemsWithDetails();
    }
    this.initializeAllOptionsSelected();
    this.initializeSelectedOptions();
    this.initializeSearchValue();
  }

  ngOnChanges() {
    if (!this.installBaseLoading && !this.agreements) {
      this.agreements = this.installedBaseSystemData;
      this.initializeAgreements();
      this.contractFilters.forEach(cf => {
        cf.options = [...new Set(this.agreements.map(agreement => {
          if (cf.secondAdditionalAttribute) {
            return agreement[cf.attribute][cf.firstAdditionalAttribute][cf.secondAdditionalAttribute];
          } else if (cf.firstAdditionalAttribute) {
            return agreement[cf.attribute][cf.firstAdditionalAttribute];
          } else {
            return agreement[cf.attribute];
          }
        }))].filter(item => item !== undefined);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCartItemsWithDetails(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(items => {
      this.cartItems = items;
    });
  }

  selectSelectedSystem(): void {
    this.cpqFacade.selectSelectedSystem().pipe(take(1)).subscribe(selectedSystem => {
      this.selectedSystem = selectedSystem;
    });
  }

  applySelectedFiltersOptions(): void {
    this.contractFilters = this.contractFilters.map(filter => {
      filter.selected = [...this.selectedOptions[filter.name]];
      return filter;
    });
    this.openOrCloseDropdown();
  }

  clearSelectedFiltersOptions(): void {
    this.contractFilters.forEach(filter => {
      this.selectedOptions[filter.name] = [...filter.selected];
      this.allOptionsAreSelected[filter.name] = this.allOptionsAreSelectedForFilter(filter.name);
    });
    this.openOrCloseDropdown();
  }

  setSelectedOptionsForFilter(selectedOptions: string[], filterName: string): void {
    this.contractFilters = this.contractFilters.map(filter => {
      if (filter.name === filterName) {
        filter.selected = selectedOptions;
      }
      return filter;
    });
  }

  selectOption(filterName: string, option: string): void {
    const index = this.selectedOptions[filterName].indexOf(option);
    if (index > -1) {
      this.selectedOptions[filterName].splice(index, 1);
    } else {
      this.selectedOptions[filterName].push(option);
    }
    this.allOptionsAreSelected[filterName] = this.allOptionsAreSelectedForFilter(filterName);
  }

  selectAllFilteredOptions(filterName: string): void {
    if (!this.allOptionsAreSelected[filterName]) {
      this.allOptionsAreSelected[filterName] = true;
      const contractFilterOptions = this.contractFilters.find(filter => filter.name === filterName).options;
      let filteredOptions: string[] = this.searchFieldPipe.transform(contractFilterOptions, this.filterSearchValue[filterName]);
      filteredOptions = filteredOptions.filter(option => !this.selectedOptions[filterName].includes(option));
      this.selectedOptions[filterName] = [...this.selectedOptions[filterName], ...filteredOptions];
    } else {
      this.allOptionsAreSelected[filterName] = false;
      this.selectedOptions[filterName] = [];
    }
  }

  private allOptionsAreSelectedForFilter(filterName: string): boolean {
    const maxNumOfOptions = this.contractFilters.find(filter => filter.name === filterName).options.length;
    const actualNumOfSelectedOptions = this.selectedOptions[filterName].length;
    return actualNumOfSelectedOptions === maxNumOfOptions;
  }

  private openOrCloseDropdown(): void {
    if (this.advancedSearchDropdownIsOpened) {
      this.initializeSearchValue();
    }
    this.advancedSearchDropdownIsOpened = !this.advancedSearchDropdownIsOpened;
  }

  private initializeSearchValue(): void {
    this.filterSearchValue = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: ''}), ({}));
  }

  private initializeAllOptionsSelected(): void {
    this.allOptionsAreSelected = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: false}), ({}));
  }

  private initializeSelectedOptions(): void {
    this.selectedOptions = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: []}), ({}));
  }

  private initializeAgreements(): void {

    this.replaceCityWithCityAndState();

    this.agreements.forEach(item => {
      if (item.attributes.isCpqAvailable) {
        this.agreementsNeedAttention.push(item);
      } else {
        this.agreementsUnderContract.push(item);
      }
    });

    if (this.selectedSystem) {
      this.agreementsNeedAttention = this.agreementsNeedAttention.reduce((acc, next) => {
        const compatible = this.selectedSystem.attributes.rel_product_sysivk.includes(next.attributes.materialNumber);
        acc.push({
          ...next,
          compatible,
        });
        return acc;
      }, []);
    }
  }

  private replaceCityWithCityAndState(): void {
    this.agreements = this.agreements.map(agreement => {
      return {
        ...agreement,
        attributes: {
          ...agreement.attributes,
          siemensEquipmentAddress: {
            ...agreement.attributes.siemensEquipmentAddress,
            city: agreement.attributes.siemensEquipmentAddress.city + ', ' +
              agreement.attributes.siemensEquipmentAddress.state,
          },
        },
      };
    });
  }

  activeDropdown(): void {
    this.active = !this.active;
  }

  unselectOptionForFilter(optionToUnselect: string, filterName: string): void {
    const selectedDropdown = this.dropdowns?.find(dropdown => dropdown.dropDownName === filterName);
    selectedDropdown.selectAndApply(optionToUnselect);
    this.active = false;
  }

  onResize(event): void {
    if (this.active && this.initWidth !== event.target.innerWidth) {
      this.initWidth = event.target.innerWidth;
      this.active = false;
    }
  }
}
