import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IOrderItem } from '../../../../../../models/order.models';
import { OrdersFacade } from '../../../../../../facades/orders.facade';
import { MarketingFacade } from '../../../../../../facades/marketing.facade';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { FileType, FileUtils } from '../../../../../../utils/file.utils';
import { TranslateService } from '@ngx-translate/core';
import { EFeatureToggles } from '../../../../../../configurations/common';
import { CustomerFacade } from '../../../../../../facades/customer.facade';
import { ConfigurationFacade } from '../../../../../../facades/configuration.facade';
import { DatePipe } from '@angular/common';
import { I18nService } from '../../../../../../services';
import { DateUtils } from '../../../../../../utils/date.utils';
import { FormatDatePipe } from '../../../../../../shared/pipes/format-date.pipe';
import { OrdersUtils } from '../../../../../../utils/orders.utils';

@Component({
  selector: 'app-spare-part-item-details',
  templateUrl: './spare-part-item-details.component.html',
  styleUrls: ['./spare-part-item-details.component.scss'],
})
export class SparePartItemDetailsComponent implements OnInit, OnDestroy {
  @Input() orderDetails: any;
  @Input() isOpen: boolean;
  @Input() isModalInProgress: boolean = false;

  @Output() addItemToCartModalActiveEvent = new EventEmitter<boolean>();
  @Output() itemsToAddEvent = new EventEmitter<any>();
  @Output() flNumberOfReorderEvent = new EventEmitter<string>();
  @Output() orderIdEvent = new EventEmitter<string>();

  loadingCartDataInProgress: boolean = true;
  itemsInQueueToCart: any[] = [];
  itemsToAdd: Array<any>;
  returnDocumentUrl: string = '';
  sparePartsReturnDocumentUrlKey: string = environment.sparePartsReturnDocumentUrlKey;
  pdfGenerationInProgress: boolean = false;
  isSapP40Enable: boolean = false;
  isReturnableDocumentEmailLinkEnable: boolean = false;
  isEligibleForReturn: boolean = false;
  isBusinessPartner: boolean = false;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  addresses: any[] = [
    {
      type: 'shippingAddress',
      translationKey: 'ship-to-address',
    },
    {
      type: 'soldToAddress',
      translationKey: 'sold-to-address',
    },
    {
      type: 'billingAddress',
      translationKey: 'bill-to-address',
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private ordersFacade: OrdersFacade,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private router: Router,
    private translateService: TranslateService,
    private configurationFacade: ConfigurationFacade,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.selectLoadingCartDataInProgress();
    this.selectItemsInQueueToCart();
    this.selectIsCustomerBusinessPartner();
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SUBMIT_RETURN_DOCUMENT).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isReturnableDocumentEmailLinkEnable = value;
    });
    this._getReturnDocumentUrl();
    this.isEligibleForReturn = OrdersUtils.isSapOrderEligibleForReturn(this.orderDetails.attributes);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
        this.loadingCartDataInProgress = loadingCartDataInProgress;
      },
    );
  }

  selectItemsInQueueToCart(): void {
    this.marketingFacade.selectItemsInQueueToCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.itemsInQueueToCart = state;
      });
  }

  isAddToCartInProgress(): boolean {
    return this.isModalInProgress || this.itemsInQueueToCart.length > 0;
  }

  isAddToCartDisabled(): boolean {
    return this.isModalInProgress || this.loadingCartDataInProgress || this.itemsInQueueToCart.length > 0 || !this.isSapP40Enable;
  }

  addItemToCart(item: IOrderItem): void {
    if (!this.isAddToCartDisabled()) {
      this.itemsToAdd = [{
        data: item,
        isConcrete: true,
        quantity: item.quantity,
      }];

      this.flNumberOfReorderEvent.emit(this.orderDetails.attributes.systemDetails?.siemensEquipmentId);
      this.itemsToAddEvent.emit(this.itemsToAdd);
      this.addItemToCartModalActiveEvent.emit(true);
      this.orderIdEvent.emit(this.orderDetails.id);
    }
  }

  addItemsToCart(items: IOrderItem[]): void {
    if (!this.isAddToCartDisabled()) {
      this.itemsToAdd = [];

      items.forEach(item => {
        this.itemsToAdd.push({
          data: item,
          isConcrete: true,
          quantity: item.quantity,
        });
      });

      this.flNumberOfReorderEvent.emit(this.orderDetails.attributes.systemDetails?.siemensEquipmentId);
      this.itemsToAddEvent.emit(this.itemsToAdd);
      this.addItemToCartModalActiveEvent.emit(true);
      this.orderIdEvent.emit(this.orderDetails.id);
    }
  }

  redirectToOrderDetails(orderId: string): void {
    this.router.navigate([`/order-details/${orderId}`]);
  }

  formatIfMissingValue(attributeValue: string): string {
    return !attributeValue || attributeValue === 'empty' ? '' : attributeValue;
  }

  downloadReturnDocument(): void {
    window.open(this.returnDocumentUrl, '_blank');
  }

  generateOrderDetailPdf(): void {
    this.pdfGenerationInProgress = true;
    const pipe = new FormatDatePipe(new DatePipe(this.i18nService.getCurrentLocale()));
    const orderDate = pipe.transform(DateUtils.getCurrentDate()).slice(0, 10).replace(/\//g, '-');
    this.ordersFacade.getOrderDetailPdfFile(this.orderDetails.id)
      .pipe(take(1))
      .subscribe(file => {
        FileUtils.saveAndOpenFile(file, FileType.PDF, `OrdersDetails_${this.orderDetails.id}_${orderDate}`);
        this.pdfGenerationInProgress = false;
      });
  }

  private _getReturnDocumentUrl(): void {
    this.translateService.get(this.sparePartsReturnDocumentUrlKey).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.returnDocumentUrl = value;
    });
  }

  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }
}
