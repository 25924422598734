import { ICart, ICartItem, ICartItemsSapAvailabilities, ICartItemWithDetail } from '../models/cart.models';
import { MathUtils } from './math.utils';
import { IBaseModel, IMessage, IPriceDisputingPerItem } from '../models/common.models';
import { EMessageType } from '../configurations/common';

export class CartUtils {

  static priceDisputingMessage: IMessage = {
    type: EMessageType.WARNING,
    title: 'cart.disputed-pricing.title',
    description: 'cart.disputed-pricing.description',
  };

  static isSetPricesForAllItems(cartItems: any): boolean {
    if (!cartItems?.length) {
      return false;
    }

    for (const item of cartItems) {
      if (item.attributes?.calculations?.unitPrice === 0 || item?.calculations?.unitPrice === 0) {
        return false;
      }
    }

    return true;
  }

  static getTotalPrice(isCartEmpty: boolean, currentCart: any): number {
    if (isCartEmpty) {
      return 0;
    }
    return currentCart?.totals?.subtotal || 0;
  }

  /**
   * Retrieve cart name for US store.
   *
   * @param {ICart} cart
   * @returns {string}
   */
  static getCartName(cart: ICart): string {
    return  MathUtils.checkIfNumeric(cart.attributes.name)
        ? cart.id
        : cart.attributes.name;
  }

  static getClearEtag(eTag: string): string{
    return eTag.slice(eTag.indexOf('"')).replace(/"+/g, '');
  }

  static getIsMinimumOrderValue(
    minimumOrderValue: number,
    isCartEmpty: boolean,
    currentCart: any,
    cartItems: any,
    decimalDigit?: number
  ): boolean {
    const storeMinimumOrderValue = minimumOrderValue;
    const totalOrderValue = this.getFormattedTotalPrice(isCartEmpty, currentCart, decimalDigit);

    if (this.isSetPricesForAllItems(cartItems)) {
      return storeMinimumOrderValue ? totalOrderValue >= storeMinimumOrderValue : true;
    }

    return true;
  }

  static mapAvailabilitiesToCartItems(
    cartItems: Array<ICartItem | ICartItemWithDetail>,
    cartIncludedResources: IBaseModel[],
  ): Array<ICartItemWithDetail> {
    const itemAvailabilities = cartIncludedResources?.find(
      include => include.type === 'sap-item-availabilities',
    ) as ICartItemsSapAvailabilities;

    if (itemAvailabilities) {
      const itemAvailabilitiesByMatNo = [];
      for (const availabilityInfo of itemAvailabilities.attributes.itemAvailabilities) {
        itemAvailabilitiesByMatNo[availabilityInfo.materialNumber] = availabilityInfo;
      }

      return cartItems.map(
        cartItem => ({
          ...cartItem,
          attributes: {
            ...cartItem.attributes,
            availability: itemAvailabilitiesByMatNo[cartItem.attributes.materialNumber] ?? {},
          },
        }));
    } else {
      return cartItems;
    }
  }

  private static getFormattedTotalPrice(isCartEmpty: boolean, currentCart: any, decimalDigit: number = -0.1): number {
    if (isCartEmpty) {
      return 0;
    }

    let totalPrice: number;

    if (currentCart) {
      totalPrice = currentCart.attributes ? (currentCart.attributes.totals?.subtotal || 0) : (currentCart.totals?.subtotal || 0);
    } else {
      totalPrice = 0;
    }

    if (totalPrice || totalPrice !== 0) {
      totalPrice = totalPrice / (decimalDigit !== -0.1 ? Math.pow(10, decimalDigit) : 100);
    }

    return totalPrice;
  }

  static getCountOfItems(cartItems: any[]): number {
    return cartItems?.length || 0;
  }

  /**
   * Determine if price disputing is set for given item.
   *
   * @param {IPriceDisputingPerItem} priceDisputingPerItem
   * @param {string} itemId
   * @return {boolean}
   */
  static isPriceDisputingSetForItem(priceDisputingPerItem: IPriceDisputingPerItem[], itemId: string): boolean {
    return priceDisputingPerItem?.find(itemPriceDisputing => {
      return itemPriceDisputing.itemId === itemId;
    })?.priceDisputing?.isSet;
  }
}
