export interface Alert {
  type: string;
  options?: AlertOptions;
  message: string;
  messageData?: string;
  action?: any;
  props?: any;
}

export interface AlertOptions {
  alertWidth: string;
  title: string;
  buttonLabel: string;
  buttonPosition: Position;
}

export enum Position {
  LEFT   = 'left',
  CENTER = 'center',
  RIGHT  = 'right'
}
