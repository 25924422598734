import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GlueConfigurationService } from './glue-configuration.service';
import { ICPQReconfigureResponse, ICPQResponseData } from '../models/cpq.models';
import { EGlueResource } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class CpqService {
  private glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleOrderError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  postCqpInitialize(cartId: string, sku: string, functionalLocationNumber: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-initialize',
        attributes: {
          cartId,
          sku,
          functionalLocationNumber,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-initialize', body)
      .pipe(catchError((error) => Promise.reject(error)));
  }

  getCpqWrongAddress(addressType: string): Observable<any> {
    const url = [
      this.glueUrl,
      EGlueResource.CPQ_ADDRESS_REPORT_WRONG,
      addressType,
      EGlueResource.ADDRESSES
    ].join('/');
    return this.httpClient.get<any>(url).pipe(
      catchError(CpqService.handleOrderError)
    );
  }

  postCpqWrongAddress(dataToSend: any): Observable<any> {
    const newBody = JSON.stringify(dataToSend);
    const url = this.glueUrl + `/${EGlueResource.CPQ_ADDRESS_REPORT_WRONG}`;
    return this.httpClient.post<any>(url, newBody).pipe(
      catchError(CpqService.handleOrderError)
    );
  }

  postCpqContractDetailsDefaults(cartId: string, sku: string, configId: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-contract-details-defaults',
        attributes: {
          cartId,
          sku,
          configId,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-contract-details-defaults', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCqpReconfigure(cartId: string, sku: string, configId: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-reconfigure',
        attributes: {
          cartId,
          sku,
          configId,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-reconfigure', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCqpCommit(cartId: string, sku: string, configId: string, parameter: string, value: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-commit',
        attributes: {
          cartId,
          sku,
          configId,
          parameter,
          value,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-commit', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCqpUnCommit(cartId: string, sku: string, configId: string, parameter: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-uncommit',
        attributes: {
          cartId,
          sku,
          configId,
          parameter,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-uncommit', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  getCpqQuote(cartId: string): Observable<ICPQResponseData> {
    return this.httpClient.get<any>(`${this.glueUrl}/cpq-quote/${cartId}`)
      .pipe(catchError(CpqService.handleOrderError));
  }

  deleteCpqQuote(cartId: string): Observable<any> {
    const deleteUrl = `${this.glueUrl}/cpq-quote/${cartId}`;
    return this.httpClient.delete<any>(deleteUrl)
      .pipe(catchError((error) => Promise.reject(error)));
  }

  getCpqQuoteItems(cartId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.glueUrl}/cpq-quote-items/${cartId}`)
      .pipe(catchError(CpqService.handleOrderError));
  }

  deleteCpqQuoteItem(cartId: string, sku: string, configId: string): Observable<any> {
    const deleteUrl = `${this.glueUrl}/cpq-quote-items/${cartId}?sku=${sku}&configId=${configId}`;

    return this.httpClient.delete<any>(deleteUrl)
      .pipe(
        catchError((error) =>
          CpqService.handleOrderError(error.error.errors[0]),
        ),
      );
  }

  generateQuoteDocument(body: any): Promise<any> {
    const newBody = JSON.stringify(body);
    const customerAddressesUrl = this.glueUrl + `/cpq-quote`;
    return this.httpClient.post<any>(customerAddressesUrl, newBody).toPromise()
      .then((response) => {
        return response;
      })
      .catch(CpqService.handleOrderError);
  }

  postCqpCartItemsCommit(cartId: string, sku: string, configId: string, parameter: string, value: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-cart-items-commit',
        attributes: {
          cartId,
          sku,
          configId,
          parameter,
          value,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-cart-items-commit', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCqpAccept(cartId: string, sku: string, configId: string, parameter: string, value: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-accept',
        attributes: {
          cartId,
          sku,
          configId,
          parameter,
          value,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-accept', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  reconfigureService(cartId: string, sku: string, configId: string): Observable<ICPQReconfigureResponse> {
    const body = {
      data: {
        type: 'cpq-reconfigure',
        attributes: {
          cartId,
          sku,
          configId,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-reconfigure', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  getConfigurationSummaryPdfFile(cartId: string, sku: string, configId: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    const cpqConfigurationUrl = this.glueUrl + `/cpq-configuration-download?cartId=${cartId}&configId=${configId}&sku=${sku}`;
    return this.httpClient.get(cpqConfigurationUrl, {headers, responseType: 'blob'})
      .pipe(catchError(CpqService.handleOrderError));
  }

  getCpqQuotePdfFile(cartId: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    const cpqQuoteUrl = this.glueUrl + '/cpq-quote-download/' + cartId;
    return this.httpClient.get(cpqQuoteUrl, {headers, responseType: 'blob'})
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCpqGuidedInitialize(cartId: string, functionalLocationNumber: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-guided-initialize',
        attributes: {
          cartId,
          functionalLocationNumber,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-guided-initialize', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCpqSetStep(cartId: string, configId: string, step: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-set-step',
        attributes: {
          cartId,
          configId,
          step,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-set-step', body)
      .pipe(catchError(CpqService.handleOrderError));
  }

  postCpqRecalculate(cartId: string): Observable<any> {
    const body = {
      data: {
        type: 'cpq-recalculate',
        attributes: {
          cartId,
        },
      },
    };
    return this.httpClient.post<any>(this.glueUrl + '/cpq-recalculate', body)
      .pipe(catchError(CpqService.handleOrderError));
  }
}
