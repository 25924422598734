import { IAddress, IBaseModel } from './common.models';

export interface IShsEquipments {
  data: IShsEquipmentData[],
  links: {
    self: string;
  };
}

export interface IShsEquipment {
  data: IShsEquipmentData
}

export interface IShsEquipmentData extends IBaseModel {
  attributes: IShsEquipmentDataAttributes,
  id: string,
  links: {
    self: string
  },
  type: string,
  compatible?: boolean
}

export interface IShsEquipmentDataAttributes {
  category?: string
  company?: string,
  companyBusinessUnit?: string,
  companyBusinessUnitNumber?: string,
  components?: IShsEquipmentDataAttributesEquipmentComponents[],
  contractExpirationDate?: string,
  contractName?: string,
  contractNumber?: string,
  contractStartDate?: string,
  contractTypeId?: string,
  countryCode?: string,
  customerDesc?: string,
  department?: string,
  endOfSupport?: string,
  externalUrlSmall?: string,
  functionalLocation: string,
  imageUrlLarge?: string,
  imageUrlSmall?: string,
  isCpqAvailable?: boolean,
  isSparePartsAvailable?: boolean,
  marketShare?: null | string,
  materialName?: string,
  materialNumber?: string,
  modality?: string,
  modalityCode?: string,
  nameEnUs?: string,
  namePtBr?: string,
  myEquipmentName?: string,
  serialNumber?: string,
  shipToAddress: IAddress,
  siemensEquipmentAddress?: IShsEquipmentAttributesEquipmentAddress | null,
  siemensEquipmentId: string,
  startupDate?: string,
}

export interface IShsEquipmentDataAttributesEquipmentComponents {
  materialText: string,
  serial: string,
  swVersion: string,
  eqInstallDt: string,
  matEosDt: string,
}

export interface IShsEquipmentAttributesEquipmentAddress {
  city: string,
  state: string,
  street: string,
  zipCode: string
}

export interface ISimpleInstallBaseProduct extends IBaseModel {
  attributes: {
    materialNumber: string;
    materialName: string;
    category: string;
    externalUrlLarge: string;
    externalUrlSmall: string;
  };
  links: {
    self: string;
  };
}


export interface IShsEquipmentInstalledBaseSystemsSoldTo extends IShsEquipments {
  included: {
    attributes: {
      customerSoldToId: string;
      customerSoldToName: string;
    };
    id: string;
    links: {
      self: string;
    };
    type: string;
  }[];
}

export interface IInstallBaseProducts {
  data: ISimpleInstallBaseProduct[];
  links: {
    self: string;
  };
}

export enum EInstalledBaseWorkflow {
  SPARE_PARTS = 'spare-parts',
  CPQ = 'cpq',
}

export interface IInstalledBaseSystemsFilter {
  functionalLocationNumber?: string;
  materialNumber?: string;
  workflow?: EInstalledBaseWorkflow;
}
