import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { TermsFacade } from '../../facades/terms.facade';
import { TermConstants } from '../../shared/terms/TermConstants';
import { CmsFacade } from '../../facades/cms.facade';
import { Subject } from 'rxjs';
import { ICmsPage, ICmsPageAttributes } from '../../models/cms';
import { CustomerFacade } from '../../facades/customer.facade';
import { ISeoPageData } from '../../configurations/seo';
import { SeoFacade } from '../../facades/seo.facade';
import { takeUntil } from 'rxjs/operators';
import { shopName } from '../../utils/localStorage.utils';
import { I18nService } from '../../services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})

export class TermsComponent implements OnInit, OnDestroy {
  cmsPageData: ICmsPage;
  isOnToU: boolean;
  userLoggedIn: boolean;
  userAccepted: boolean;
  isAccepting: boolean;
  isLoaded: boolean;
  pageContent: SafeHtml;

  private urlKey: string;
  private termKey: string;
  private unsubscribe$ = new Subject<void>();
  private consentedTerms: string[];

  constructor(
    private route: ActivatedRoute,
    private authorizationFacade: AuthorizationFacade,
    private termsFacade: TermsFacade,
    private router: Router,
    private cmsFacade: CmsFacade,
    private customerFacade: CustomerFacade,
    private seoFacade: SeoFacade,
    private i18nService: I18nService,
    public sanitizer: DomSanitizer,
  ) {
  }

  /**
   * The reason why NOSONAR is used here is because it is safe (sanitization).
   *
   */
  ngOnInit(): void {
    this.userAccepted = false;
    this.isOnToU = false;
    this.userLoggedIn = false;
    this.isAccepting = false;
    this.isLoaded = false;

    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.urlKey = params.urlKey;
        this.isOnToU = this.urlKey === TermConstants.termKeys.TERMS_OF_USE.urlKey;

        const termsKeys = new Array(Object.entries(TermConstants.termKeys))[0];
        const termKeysMap = termsKeys.find(el => el[1].urlKey === this.urlKey);

        if (!termKeysMap) {
          return this.navigateTo404();
        }

        const termKeys = termKeysMap[1];
        const cmsKey = termKeys.cmsKey;
        this.termKey = termKeys.termsKey;

        if (!cmsKey) {
          return this.navigateTo404();
        }

        this.cmsFacade.getCmsPageData(cmsKey)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
              next: response => {
                this.isLoaded = true;
                this.cmsPageData = response;
                this.setMetaData(response.attributes);
                this.pageContent = this.sanitizer.bypassSecurityTrustHtml(response.attributes.content); // NOSONAR
              },
              error: () => {
                this.navigateTo404();
              },
            },
          );
      });

    this.authorizationFacade.selectIsUserLoggedIn().subscribe(isLoggedIn => {
      this.userLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        this.loadConsentedTerms();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): boolean {
    const pos = window.pageYOffset + window.innerHeight;
    const bottomOfContent = document.getElementsByClassName('main-content').item(0).getBoundingClientRect().height;
    return pos >= bottomOfContent;
  }

  public onClickAccept(): void {
    if (this.userLoggedIn) {
      this.isAccepting = true;

      const acceptBody = {
        data: {
          type: 'terms',
          attributes: {
            termKey: this.termKey,
          },
        },
      };

      this.termsFacade.postAcceptTermsOfUse(acceptBody).subscribe(() => {
        const consentedTerms = this.consentedTerms.includes(this.termKey) ?
          this.consentedTerms : [this.termKey, ...this.consentedTerms];

        this.customerFacade.updateConsentedTerms(consentedTerms);
        this.isAccepting = false;

        this.router.navigate([this.i18nService.getCurrentLocale()]).then();
      });
    }
  }

  public onClickReject(): void {
    this.authorizationFacade.logout();
    localStorage.removeItem(shopName);
  }

  private setMetaData(data: ICmsPageAttributes): void {
    this.seoFacade.setMetaFromComponent({
      description: data.metaDescription,
      keywords: data.metaKeywords,
      titles: [data.metaTitle],
      canonicalUrl: {
        rel: 'canonical',
        href: data.canonicalUrl,
      },
      robots: this.seoFacade.parseRobots(data),
    } as ISeoPageData);
  }

  private loadConsentedTerms(): void {
    this.customerFacade.getCustomerConsentedTerms().subscribe(consentedTerms => {
      this.consentedTerms = consentedTerms;
      if (consentedTerms?.includes(TermConstants.termKeys.TERMS_OF_USE.termsKey)) {
        this.userAccepted = true;
      }
    });
  }

  private navigateTo404(): void {
    this.router.navigate(['/page-not-found']).then();
  }
}
