import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, first, mergeMap, Observable, skipWhile, Subject, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { EUserRoles } from '../configurations/common';
import { AuthorizationFacade } from '../facades/authorization.facade';
import { CustomerFacade } from '../facades/customer.facade';
import { IEndpointConfig, IStore } from '../models/settings.model';
import { AppUtils } from '../utils/app.utils';
import { I18nService } from './i18n.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor, OnDestroy {
  private static env = environment.protectedEndpoints;
  endpointConfig: IEndpointConfig;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private companyUsersMineRequestCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isLoggedSilently: boolean = false;
  private accessToken: string = '';
  private isCustomerLoaded: boolean = false;

  constructor(
    private authFacade: AuthorizationFacade,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private static endpointFromRequest(requestUrl: string): string {
    try {
      const url: URL = new URL(requestUrl);
      const pathName: string = url.pathname;
      const reg: RegExp = /\//;
      const endpointPath: string[] = pathName.replace(reg, '').split(reg);
      return endpointPath[0];
    } catch (e) {
      return requestUrl;
    }
  }

  private static isSecurityRequired(endpoint: string, method: string): IEndpointConfig {
    return this.env.find(f => f.name.toLowerCase() === endpoint.toLowerCase() &&
      f.methods.includes(method.toLowerCase()));
  }

  /**
   * If user is authenticated and has valid token, then call processCustomerLoading method.
   * If user is authenticated but has no token, then wait until the token is received and call processCustomerLoading
   * method.
   * If user is not authenticated, then make the request without Authorization header.
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @param headers
   * @returns {Observable<HttpEvent<any>>}
   *
   * Created with assistance of Copilot.
   */
  processAccessToken(req: HttpRequest<any>, next: HttpHandler, headers: any): Observable<HttpEvent<any>> {
    const isAuthenticated: boolean = this.authFacade.getAuthenticatedCookie();
    if (isAuthenticated) {
      if (this.accessToken.length > 0) {
        return this.processCustomerLoading(req, next, headers, this.accessToken);
      }
      return this.authFacade.selectAccessToken$.pipe(
        skipWhile(accessToken => accessToken.length === 0),
        takeUntil(this.unsubscribe$),
        mergeMap(accessToken => {
          this.accessToken = accessToken;
          return this.processCustomerLoading(req, next, headers, accessToken);
        })
      );
    } else {
      return next.handle(req.clone({
        setHeaders: headers,
      }));
    }
  }

  /**
   * If the request is for company-users/mine endpoint, then call the processRequest method.
   * If the request is not for company-users/mine endpoint, then wait until the customer is loaded and call the
   * processRequest method.
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @param headers
   * @param {string} accessToken
   * @returns {Observable<HttpEvent<any>>}
   *
   * Created with assistance of Copilot.
   */
  processCustomerLoading(req: HttpRequest<any>, next: HttpHandler, headers: any, accessToken: string): Observable<HttpEvent<any>> {
    if (req.url.includes('company-users/mine')) {
      if (AppUtils.getStoreIdFromLocalStorage() !== '') {
        headers = Object.assign({...headers}, {Authorization: `Bearer ${accessToken}`});
        this.companyUsersMineRequestCompleted.next(false);
        return next.handle(req.clone({
          setHeaders: headers
        })).pipe(
          tap(() => this.companyUsersMineRequestCompleted.next(true)),
        );
      }
    } else {
      if (AppUtils.getStoreIdFromLocalStorage() === '') {
        return;
      }
      if (this.isCustomerLoaded) {
        return this.processRequest(req, next, headers, accessToken);
      }
      return this.customerFacade.selectIsCustomerLoaded().pipe(
        skipWhile(loaded => loaded === false),
        takeUntil(this.unsubscribe$),
        mergeMap(_ => {
          this.isCustomerLoaded = true;
          return this.processRequest(req, next, headers, accessToken);
        })
      );
    }
  }

  /**
   * If the user is not Guest, then add Authorization header to the headers.
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @param headers
   * @param {string} accessToken
   * @returns {Observable<HttpEvent<any>>}
   *
   * Created with assistance of Copilot.
   */
  processRequest(req: HttpRequest<any>, next: HttpHandler, headers: any, accessToken: string): Observable<HttpEvent<any>> {
    return this.customerFacade.selectCustomerCompanyRoles().pipe(
      first(),
      takeUntil(this.unsubscribe$),
      mergeMap((roles: EUserRoles[]) => {
        if (!roles.includes(EUserRoles.Guest)) {
          headers = Object.assign({...headers}, {Authorization: `Bearer ${accessToken}`});
        }
        return this.companyUsersMineRequestCompleted.pipe(
          first(completed => completed === true),
          takeUntil(this.unsubscribe$),
          mergeMap(() => {
              return next.handle(req.clone({
                setHeaders: headers,
              }));
            }
          )
        );
      })
    );
  }

  /**
   * Allow basic endpoints (like arakh related) to be called without authentication.
   * Wait while the login process is not completed before making any other request.
   * Then call the processAccessToken method.
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   *
   * Created with assistance of Copilot.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqEndpoint: string = InterceptorService.endpointFromRequest(req.url);
    const languageCode: string = this.i18nService.getCurrentLanguage();
    const store: IStore = AppUtils.getCurrentStore();

    const headers = {
      'Accept-Language': this.i18nService.buildLangParam(store.marketCode, languageCode),
      store: store.storeId,
    };
    this.endpointConfig = InterceptorService.isSecurityRequired(reqEndpoint, req.method);
    if (environment.allowedEndpoints.some(endpoint => reqEndpoint.includes(endpoint))) {
      return next.handle(req.clone({
        setHeaders: headers,
      }));
    }

    if (!this.isLoggedSilently) {
      return this.authFacade.selectIsLoginInProcess$.pipe(
        skipWhile(isLoggingSilently => isLoggingSilently === true),
        takeUntil(this.unsubscribe$),
        mergeMap(_ => {
          this.isLoggedSilently = true;
          return this.processAccessToken(req, next, headers);
        }));
    }
    return this.processAccessToken(req, next, headers);
  }
}
