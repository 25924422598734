import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CpqFacade } from '../../../../facades/cpq.facade';
import { AddressUtils } from '../../../../utils/address.utils';
import { EAddressType } from '../../../../configurations/common';
import { IAddress } from '../../../../models/common.models';

@Component({
  selector: 'app-cpq-bill-and-payer-address',
  templateUrl: './cpq-bill-and-payer-address.component.html',
  styleUrls: ['./cpq-bill-and-payer-address.component.scss'],
})
export class CpqBillAndPayerAddressComponent implements OnInit {
  reportWrongAddressModalFlag = false;
  addressesToReport: IAddress[];
  billToAndPayerAddressForm: UntypedFormGroup;
  open = false;
  labelName = '';
  typePayer = EAddressType.PAYER;
  typeBillTo = EAddressType.BILL_TO;
  addressType = '';
  quoteDocumentGenerationInProgress: boolean = false;

  @Input() billToAddresses: IAddress[];
  @Input() payerAddresses: IAddress[];
  @Input() cart: any;
  @Input() cartItemsWithDetails: any;
  @Input() ecommerceTeamEmailAddress: string;
  @Input() customerBusinessUnitName: string;
  @Input() functionalLocations: string[];
  @Output() showAlmostDone = new EventEmitter<any>();
  @Output() closeReportWrongAddressModal = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cpqFacade: CpqFacade,
  ) {
  }

  ngOnInit(): void {
    this.billToAndPayerAddressForm = this.formBuilder.group({
      billToAddress: ['', Validators.required],
      payerAddress: ['', Validators.required],
    });
  }

  closeModals(): void {
    this.closeReportWrongAddressModal.emit(false);
    this.reportWrongAddressModalFlag = false;
  }

  setFormValue(event: any): void {
    this.billToAndPayerAddressForm.patchValue({
      [event.key]: event,
    });
  }

  isFormValid(): boolean {
    return this.billToAndPayerAddressForm?.status === 'VALID';
  }

  submitBillToAndPayerAddress(): void {
    this.quoteDocumentGenerationInProgress = true;
    const body = {
      data: {
        type: 'cpq-quote',
        attributes: {
          cartId: this.cart.id,
          customerBillToAddress: AddressUtils.getBodyRequiredAddress(this.billToAndPayerAddressForm.value.billToAddress),
          customerPayerAddress: AddressUtils.getBodyRequiredAddress(this.billToAndPayerAddressForm.value.payerAddress),
        },
      },
    };
    this.cpqFacade.submitGenerateQuoteDocument(body).then(
      () => {
        this.open = true;
        this.showAlmostDone.emit(this.open);
        this.quoteDocumentGenerationInProgress = false;
      },
    );
  }

  showReportWrongAddressModal(addresses, labelName, addressType): void {
    this.reportWrongAddressModalFlag = true;
    this.addressesToReport = addresses;
    this.labelName = labelName;
    this.addressType = addressType;
  }
}
