import { IContractParams, ICurrentParams, IShowMessage } from '../../models/catalog.models';
import { ICachedProductSkuAndRequiredReload } from '../../models/abstract-product.models';

export let httpParams: ICurrentParams = {
  'page[offset]': 0,
  'page[limit]': 12,
  sort: '',
};

export let httpContractParams: IContractParams = {
  'fl-number': '',
  'rel-product-sysivk': '',
};

export let notFilterableAttributesData: string[] = ['category', 'label', 'sap_ivk_designation'];

export let warningMessageData: IShowMessage = {
  type: 'warning',
  title: 'issue-with-data.title',
  messages: [{
    key: 'issue-with-data.message',
  }],
};

export let requireProductsData: string[] = [
  'A_package_xEntial',
  'A_package_Centricare_Balance_Service',
  'A_package_Centricare_Benchmark_Service',
  'A_package_Centricare_Signature_Service',
];

export const recentlyOpenedProductsSkus: ICachedProductSkuAndRequiredReload[] = [
  {sku: 'A_03036183', isReloadRequired: false},
  {sku: 'A_03034386', isReloadRequired: false},
  {sku: 'A_10185346', isReloadRequired: true},
];
