import { createAction, props } from '@ngrx/store';
import { User } from '@auth0/auth0-spa-js';

export const initializeAuth = createAction('[Auth] Initialize auth');

export const logIn = createAction(
  '[Auth] Log in',
  props<{ url: string, queryParameters: any }>());

export const logOut = createAction(
  '[Auth] Log out',
  props<{ skipRedirectToStore: boolean }>()
);

export const handleRedirect = createAction('[Auth] Handle redirct');
export const handleRedirectSuccess = createAction(
  '[Auth] Handle redirect success',
  props<{ targetRoute: string, queryParameters: any }>()
);

export const loadUser = createAction('[Auth] Load user');
export const loadUserSuccess = createAction(
  '[Auth] Load user success',
  props<{ user: User }>()
);

export const loadAccessToken = createAction( '[Auth] Load access token');
export const loadAccessTokenSuccess = createAction(
  '[Auth] Load access token success',
  props<{ token: string}>()
);

export const handleAuth = createAction('[Auth] Handle auth');
export const checkAuth = createAction('[Auth] Check auth');
export const checkAuthSuccess = createAction(
  '[Auth] Check auth success',
  props<{ isAuthenticated: boolean }>()
);
export const setNotAuthenticated = createAction(
  '[Auth] Not authenticated',
  props<{ isAuthenticated: boolean }>()
);

export const setIsLoginInProcess = createAction(
  '[Auth] Set isLoginInProcess',
  props<{ isLoginInProcess: boolean }>()
)
