<div class="alert__overlay" *ngIf="isAlert()">
  <div class="alert is-open" [ngClass]="'alert--'+alert.type">
    <div class="alert__container" [ngStyle]="{'width': getAlertWidth()}">
      <button type="button"
              class="alert__close"
              aria-label="Close alert"
              (click)="handleAction()">
        <i aria-hidden="true" class="icon-close"></i>
      </button>
      <div class="alert__header">
        <i aria-hidden="true" class="icon alert__indicator"></i>
        <h5 class="alert__title">{{ getTitle() | translate }}</h5>
      </div>
      <div class="alert__body">
        <p class="alert__text" [innerHTML]="alert.message | translate: {messageData: alert.messageData}"></p>
      </div>
      <div class="alert__footer" [ngStyle]="{'text-align': getButtonPosition()}">
        <button type="button"
                class="button button--primary"
                (click)="handleAction()">
          {{ getButtonLabel() | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
