<div class="container order-details">
  <div [appLoadingAnimation]="!isLoaded" class="app-loading">
    <div class="row">
      <div class="col-lg-1 hidden-md-down"></div>
      <div class="col-12 col-lg-10">
        <ng-container *ngIf="orderDetails">
          <div class="page-header">
            <h3 class="page-title" [attr.data-test]="'title-orderDetails'">
              {{ 'order-tracking-history.order-details' | translate }}
            </h3>
            <div class="page-row">
              <h6 class="order-id" [attr.data-test]="'title-orderStatus'">
                {{ 'order-tracking-history.status-of-order' | translate }} {{ orderDetails.id | removeLeadingZero }}
              </h6>
              <div class="action-link">
                <a class="button button--link button--back-to-purchase-activity"
                   (click)="redirectBackToPurchaseActivity(orderDetails.id)"
                   [attr.data-test]="'btn-backToPurchaseActivity'">
                  <span class="icon-arrow-left color-orange"></span>
                  {{ 'spare-parts-order-details-page.back-to-purchase-activity' | translate }}
                </a>
              </div>
            </div>
            <div class="order-status" [attr.data-test]="'label-orderStatus'">
              <span class="order-icon" [ngClass]="getIcon()"></span>
              <strong>
                {{ 'spare-parts-order-details-step-bar.status-bar-order-'
              + getStatus(orderDetails.attributes?.stateDisplayName) + '-title' | translate }}
              </strong>
            </div>
          </div>
          <div class="order-steps-bar">
            <app-steps-bar
                [scenarioType]="scenarioType"
                [currentStatus]="orderDetails.attributes?.stateDisplayName"
                [comment]="orderDetails.attributes?.cancelReason">
            </app-steps-bar>
          </div>
          <div class="order-accordion">
            <app-accordion [manualOpen]="orderDetailsAccordion">
              <ng-container ngProjectAs="[slot][header]">
                <div class="accordion__header accordion__title icon-arrow-down"
                     (click)="toggleOrderDetailsAccordion()"
                     [attr.data-test]="'desc-toggleOrderedItemsAccordion'">
                  <div class="accordion__header--table">
                    <table class="table table--ordered-items" aria-label="Order-details">
                      <thead>
                        <tr>
                          <th [attr.data-test]="'label-orderId'">{{ 'order-thank-you-page.order' | translate }}</th>
                          <th [attr.data-test]="'label-orderedOn'">{{ 'order-thank-you-page.ordered-on' | translate }}</th>
                          <th [attr.data-test]="'label-totalQuantity'">{{ 'order-thank-you-page.total-quantity' | translate }}</th>
                          <th [attr.data-test]="'label-shippingCost'">{{ 'order-thank-you-page.shipping-cost' | translate }}</th>
                          <th [attr.data-test]="'label-totalSum'">{{ 'order-thank-you-page.total-sum' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="orderDetails">
                          <td [attr.data-test]="'desc-orderId'">{{ orderDetails.id | removeLeadingZero }}</td>
                          <td [attr.data-test]="'desc-orderedOn'">{{ orderDetails.attributes?.createdAt | formatDate }}</td>
                          <td [attr.data-test]="'desc-totalQuantity'">
                            {{ orderDetails.attributes?.itemsTotalNumber }}
                            {{ getTotalItemTranslation(orderDetails.attributes.itemsTotalNumber) | translate }}
                          </td>
                          <td *ngIf="orderDetails.attributes.shipments?.length > 0" [attr.data-test]="'desc-shippingCost'">
                            {{ orderDetails.attributes.shipments[0].defaultGrossPrice | currency:orderDetails.attributes?.currencyIsoCode }}
                          </td>
                          <td [attr.data-test]="'desc-totalSum'">
                            {{ orderDetails.attributes.totals?.grandTotal | currency: orderDetails.attributes?.currencyIsoCode }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
              <ng-container ngProjectAs="[slot][panel]">
                <div class="accordion__content">
                  <div class="row margin-0">
                    <div class="item-section col-12 col-md-7 col-lg-8">
                      <ng-container *ngFor="let item of orderDetails.attributes?.items">
                        <div class="item-details--row">
                          <div class="item-details--content" [attr.data-test]="'order-item-' + item.name">
                            <div class="item--image">
                              <img [src]="item.metadata?.image" [alt]="item.name" [attr.data-test]="'img-productImage'">
                            </div>
                            <div class="item--info">
                              <div class="item--header">
                                <h5 class="item-name" [attr.data-test]="'desc-productName'">{{ item.name }}</h5>
                                <span *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && item?.isDiscontinued"
                                      class="label item-discontinued-material"
                                      [attr.data-test]="'label-discontinuedItem'">
                                  {{ 'order-thank-you-page.discontinued-item' | translate }}
                                </span>
                                <span *ngIf="item.sapDetails?.exchangeableItem"
                                      class="label item-exchange-material"
                                      [attr.data-test]="'label-exchangeableItem'">
                                  {{ 'order-thank-you-page.exchangeable-item' | translate }}
                                </span>
                                <span *ngIf="item?.heavyweight"
                                      class="label item-heavyweight-material"
                                      [attr.data-test]="'label-heavyweightItem'">
                                  {{ 'order-thank-you-page.heavyweight-item' | translate }}
                                </span>
                              </div>
                              <p class="item-material-number" [attr.data-test]="'desc-productMaterialNumber'">
                                {{ item.materialNumber }}
                              </p>
                              <div class="content-row">
                                <div class="column column-1">
                                  <div class="item-quantity">
                                    <span [attr.data-test]="'label-quantity'">
                                      {{ 'order-thank-you-page.quantity' | translate }}
                                    </span>
                                    <span class="quantity" [attr.data-test]="'desc-quantity'">{{ item.quantity }}</span>
                                  </div>
                                  <div class="item-price">
                                    <span [attr.data-test]="'label-totalPrice'">
                                      {{ 'order-thank-you-page.total-price' | translate }}
                                    </span>
                                    <span class="price" [attr.data-test]="'desc-totalPrice'">
                                      {{ item.sumPrice | currency: orderDetails.attributes?.currencyIsoCode }}
                                    </span>
                                  </div>
                                </div>
                                <div class="column column-2">
                                  <div class="item-status">
                                    <span class="wrap-span" [attr.data-test]="'label-itemStatus'">
                                      {{ 'spare-parts-order-details-page.item-shipping-status' | translate }}
                                    </span>
                                    <div class="status" [attr.data-test]="'desc-itemStatus'">
                                      {{ 'spare-parts-order-details-step-bar.' + getStatus(item.state?.name) + '-status' | translate }}
                                    </div>
                                  </div>
                                  <div class="item-tracking-number">
                                    <span class="wrap-span" [attr.data-test]="'label-trackingNumber'">
                                      {{ 'spare-parts-order-details-page.item-tracking-number' | translate }}
                                    </span>
                                    <div class="tracking-number" [attr.data-test]="'desc-trackingNumber'">
                                      <ng-container *ngIf="getTrackingNumbers(item)?.length > 0; else noTrackingNumber">
                                        <ng-container *ngFor="let trackingNumber of getTrackingNumbers(item)">
                                          <a href="{{ ('order-tracking-history.tracking-number-sp-link' | translate) + trackingNumber }}"
                                             class="tracking-number--link"
                                             target="_blank" rel="noopener" [attr.data-test]="'link-trackingNumber'">
                                            <span class="icon-arrow-right color-orange"></span>
                                            <span>{{ trackingNumber }}</span>
                                          </a>
                                        </ng-container>
                                      </ng-container>
                                      <ng-template #noTrackingNumber>
                                        <span>-</span>
                                      </ng-template>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="address-section col-12 col-md-5 col-lg-4">
                      <div *ngIf="orderDetails.attributes?.shippingAddress" class="address-section--delivery-address">
                        <h5 class="title">
                          {{ 'order-thank-you-page.delivery-address' | translate }}
                        </h5>
                        <p>
                          <ng-container *ngIf="orderDetails.attributes.pointOfContact?.attentionTo">
                            {{ orderDetails.attributes.pointOfContact?.attentionTo }}<br>
                          </ng-container>
                          <ng-container
                              *ngIf="!orderDetails.attributes.shippingAddress?.isCustom
                              && orderDetails.attributes.shippingAddress.sapId">
                            {{ orderDetails.attributes.shippingAddress.sapId }} -
                          </ng-container>
                          {{ orderDetails.attributes.shippingAddress.company }}<br>
                          {{ orderDetails.attributes.shippingAddress.address1 }}<br>
                          {{ orderDetails.attributes.shippingAddress.city }}
                          {{ orderDetails.attributes.shippingAddress.zipCode }}
                        </p>
                      </div>
                      <div class="address-section--shipping-method"
                           *ngIf="orderDetails.attributes.shipments?.length > 0">
                        <h5 class="title">
                          {{ 'order-thank-you-page.shipping-method' | translate }}
                        </h5>
                        <p>{{ orderDetails.attributes.shipments[0].shipmentMethodName }}</p>
                      </div>
                      <div class="address-section--purchase-order">
                        <strong class="text-uppercase">
                          {{ 'order-thank-you-page.purchase-order' | translate }}
                        </strong>
                        <span>{{ orderDetails.attributes?.sapPoNumber }}</span>
                      </div>
                      <div class="address-section--return-authorization-number"
                           *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber">
                        <strong class="text-uppercase">
                          {{ 'order-thank-you-page.return-order-no' | translate }}
                        </strong>
                        <span>{{ orderDetails.attributes.sapDetails?.returnAuthorizationNumber }}</span>
                      </div>
                      <div class="address-section--download-documentation"
                           *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber">
                        <a class="download-return-doc"
                           (click)="downloadDocumentation()"
                           [attr.data-test]="'button-downloadDocumentation'">
                          <span class="icon-arrow-right color-orange"></span>
                          <span>{{ 'order-thank-you-page.download-document' | translate }}</span>
                        </a>
                        <span class="submit-return-doc">
                          <a class="submit-return-doc__link"
                             *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable"
                             [attr.data-test]="'link-submitReturnDocument'"
                             href="mailto: {{ 'service-purchase-activity.return-document-recipient-email' | translate }}
                           ?Subject=Return Document {{ orderDetails.attributes.sapDetails.returnAuthorizationNumber }}">
                            <span class="icon-arrow-right color-orange"></span>
                            <span>{{ 'service-purchase-activity.submit-email-document' | translate }}</span>
                          </a>
                          <a class="submit-return-doc__msg"
                             *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable">
                            <div>
                              {{ 'service-purchase-activity.submit-return-document-text-line1' | translate }}<br>
                              {{ 'service-purchase-activity.submit-return-document-text-line2' | translate }}
                            </div>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </app-accordion>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
