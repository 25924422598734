<form [formGroup]="addressForm" (ngSubmit)="saveAddress()" class="new-address__form">
  <app-modal
      [open]="showModal"
      [id]="'ddAddressModal'"
      title="{{'checkout-delivery-details-modal.title' | translate}}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="row">
        <div class="checkout__field {{getColumn(0)}} {{getOrder(0)}}">
          <app-textfield
              class="checkout__textfield {{getColumn(0)}}"
              [form]="addressForm"
              [modelValue]="addressForm.value.name"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-name'}"
              [inputName]="'name'"
              label="{{ 'checkout-delivery-details-modal.name' | translate: {maxNumberOfChars: maxLengthName} }}"
              [ngClass]="{'is-invalid': addressForm.get('name').touched && addressForm.get('name').errors}"
              [maxLength]="maxLengthName"
              [isRequired]="true">
          </app-textfield>
          <div class="alert-controls textarea-alert" *ngIf="addressForm.value.name">
            <small class="alert-text">
              {{ addressForm.value.name.length }}/{{ maxLengthName }}
            </small>
          </div>
        </div>
        <div class="checkout__field {{getColumn(1)}} {{getOrder(1)}}">
          <app-textfield
              class="checkout__textfield col-12 {{getColumn(1)}}"
              [form]="addressForm"
              [modelValue]="addressForm.value.streetAndNumber"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-street'}"
              [inputName]="'streetAndNumber'"
              label="{{ 'checkout-delivery-details-modal.street-and-number' | translate: {maxNumberOfChars: maxLengthStreetAndNumber} }}"
              [ngClass]="{'is-invalid': addressForm.get('streetAndNumber').touched && addressForm.get('streetAndNumber').errors}"
              [maxLength]="maxLengthStreetAndNumber"
              [isRequired]="true">
          </app-textfield>
          <div class="alert-controls textarea-alert" *ngIf="addressForm.value.streetAndNumber">
            <small class="alert-text">
              {{ addressForm.value.streetAndNumber.length }}/{{ maxLengthStreetAndNumber }}
            </small>
          </div>
          <div class="checkout__alerts">
            <ng-container
                *ngIf="addressForm.get('streetAndNumber').hasError('pattern')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalLettersNumbersSpecials'">
                {{ 'checkout-delivery-details-modal.letters-numbers-specials' | translate }}
              </small>
            </ng-container>
          </div>
        </div>
        <div class="checkout__field {{getColumn(2)}} {{getOrder(2)}}">
          <app-textfield
              class="checkout__textfield col-12 {{getColumn(2)}}"
              [form]="addressForm"
              [modelValue]="isCaStore && addressForm.value.zipCode
                ? addressForm.value.zipCode.toUpperCase()
                : addressForm.value.zipCode"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-zip'}"
              [inputName]="'zipCode'"
              label="{{ 'checkout-delivery-details-modal.zip-code' | translate: {maxNumberOfChars: maxLengthZipcode} }}"
              [ngClass]="{'is-invalid': addressForm.get('zipCode').touched && addressForm.get('zipCode').errors}"
              [maxLength]="maxLengthZipcode"
              [isRequired]="true">
          </app-textfield>
          <div class="alert-controls textarea-alert" *ngIf="addressForm.value.zipCode">
            <small class="alert-text">
              {{ addressForm.value.zipCode.length }}/{{ maxLengthZipcode }}
            </small>
          </div>
          <div class="checkout__alerts">
            <ng-container *ngIf="addressForm.get('zipCode').hasError('generalZipCode')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalLettersNumbersSpecial'">
                {{ 'checkout-delivery-details-modal.zip-code-err-msg' | translate }}
              </small>
            </ng-container>
            <ng-container *ngIf="addressForm.get('zipCode').hasError('puertoRicoZipCode')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalPuertoRicoZipCode'">
                {{ 'checkout-delivery-details-modal.zip-code-puerto-rico-err-msg' | translate }}
              </small>
            </ng-container>
          </div>
        </div>
        <div class="checkout__field {{getColumn(3)}} {{getOrder(3)}}">
          <app-textfield
              class="checkout__textfield col-12 {{getColumn(3)}}"
              [form]="addressForm"
              [modelValue]="addressForm.value.city"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-city'}"
              [inputName]="'city'"
              label="{{ 'checkout-delivery-details-modal.city' | translate: {maxNumberOfChars: maxLengthCity} }}"
              [ngClass]="{'is-invalid': addressForm.get('city').touched && addressForm.get('city').errors}"
              [maxLength]="maxLengthCity"
              [isRequired]="true">
          </app-textfield>
          <div class="alert-controls textarea-alert" *ngIf="addressForm.value.city">
            <small class="alert-text">
              {{ addressForm.value.city.length }}/{{ maxLengthCity }}
            </small>
          </div>
          <div class="checkout__alerts">
            <ng-container *ngIf="addressForm.get('city').touched &&
                                 addressForm.get('city').hasError('minlength')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalMinCharacters'">
                {{ 'checkout-delivery-details-modal.city-min-characters' | translate: {charNumber: minLengthCity} }}
              </small>
            </ng-container>
            <ng-container
                *ngIf="addressForm.get('city').hasError('pattern')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalLettersSpecial'">
                {{ 'checkout-delivery-details-modal.letters-special' | translate }}</small>
            </ng-container>
          </div>
        </div>
        <div class="checkout__field {{getColumn(4)}} {{getOrder(4)}}">
          <app-textfield
              class="checkout__textfield col-12 {{getColumn(4)}}"
              [form]="addressForm"
              [modelValue]="addressForm.value.state"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-state'}"
              [inputName]="'state'"
              label="{{ 'checkout-delivery-details-modal.state' | translate: {maxNumberOfChars: maxLengthState} }}"
              [ngClass]="{'is-invalid': addressForm.get('state').touched && addressForm.get('state').errors}"
              [maxLength]="maxLengthState"
              [isRequired]="true"
              (keyup)="getCustomZipCodeValidator()">
          </app-textfield>
          <div class="alert-controls textarea-alert" *ngIf="addressForm.value.state">
            <small class="alert-text">
              {{ addressForm.value.state.length }}/{{ maxLengthState }}
            </small>
          </div>
          <div class="checkout__alerts">
            <ng-container *ngIf="addressForm.get('state').touched &&
                                 addressForm.get('state').hasError('minlength')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalMinCharacters'">
                {{ 'checkout-delivery-details-modal.state-min-characters' | translate: {charNumber: minLengthState} }}
              </small>
            </ng-container>
            <ng-container
                *ngIf="addressForm.get('state').hasError('pattern')">
              <small class="textfield__hint"
                     [attr.data-test]="'hint-modalLettersSpecial'">
                {{ (isSparepartsStore ? 'checkout-delivery-details-modal.state-only-uppercase' : 'checkout-delivery-details-modal.letters-special') | translate }}</small>
            </ng-container>
          </div>
        </div>
        <app-select-box
            class="{{getColumn(5)}} {{getOrder(5)}}"
            [form]="addressForm"
            [modelValue]="addressForm.value.country"
            [options]="iso2Codes"
            [cssStyles]="{wrapper: 'new-address__country'}"
            [inputName]="'country'"
            label="{{ 'checkout-delivery-details-modal.country' | translate }}"
            [isRequired]="true"
            [readOnly]="iso2Codes?.length === 1"
            (selected)="setCountry($event)">
        </app-select-box>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <div class="save-address-for-later-checkbox">
        <input
            type="checkbox"
            id="save-address-for-later"
            class="checkbox"
            [checked]="saveAddressForLater"
            (change)="saveAddressForLater = !saveAddressForLater"
            [attr.data-test]="'checkbox-saveAddressForLater'"
        >
        <label for="save-address-for-later">{{ 'checkout-delivery-details-modal.save-address-for-later' | translate }}</label>
      </div>
      <div>
        <button
            *ngIf="!addNewAddressIsInProgress; else loadingSpinner"
            type="submit"
            class="button button--primary"
            data-dismiss="modal-dialog"
            [disabled]="!formIsValid()">
          {{ 'checkout-delivery-details-modal.new-address' | translate }}
        </button>
      </div>
      <ng-template #loadingSpinner>
        <div class="spinner">
          <div class="loading-animation"></div>
        </div>
      </ng-template>
    </ng-container>
  </app-modal>
</form>
