import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketingFacade } from '../../facades/marketing.facade';
import { EFeatureToggles, EInstalledBaseTabs, EUserRoles } from '../../configurations/common';
import { ArrayUtils } from '../../utils/array.utils';
import { IShsEquipmentData } from '../../models/installedbase.models';
import { IModalitiesAndMaterialNumbers } from '../../models/cpq.models';
import { InstallBaseFacade } from '../../facades/install-base.facade';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { CustomerFacade } from '../../facades/customer.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-my-installed-base',
  templateUrl: './my-installed-base.component.html',
  styleUrls: ['./my-installed-base.component.scss'],
})
export class MyInstalledBaseComponent implements OnInit, OnDestroy {
  isCartOperationInProgress: boolean;
  loadingCartDataInProgress: boolean;

  EInstalledBaseTabs = EInstalledBaseTabs;

  defaultTab: string;
  currentTab: string;
  tabs: string[] = [
    EInstalledBaseTabs.EQUIPMENT,
    EInstalledBaseTabs.CONTRACTS,
  ];

  isSparePartsEnabled: boolean = false;
  myInstallBaseFlowEnabled: boolean = false;
  isCpqEnabled: boolean = false;

  cpqEquipments: IShsEquipmentData[] = [];
  cpqEquipmentsLoading: boolean = true;
  cpqModalitiesAndMaterialNumbers: IModalitiesAndMaterialNumbers[] = [];
  sparePartsEquipments: IShsEquipmentData[] = [];
  sparePartsEquipmentsLoading: boolean = true;

  productSku: string;
  soldTo: number;
  isReorderPending: boolean = false;

  companyRoles: EUserRoles[];

  private unsubscribe$ = new Subject<void>();
  private pageReadyEventFired: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private marketingFacade: MarketingFacade,
    private installBaseFacade: InstallBaseFacade,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCartOperationInProgress();
    this.selectLoadingCartDataInProgress();

    this.selectFeatures();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.productSku = params.selectedService;
        this.soldTo = +params.soldTo;
        this.defaultTab = (!this.isSparePartsEnabled || !this.myInstallBaseFlowEnabled) && this.isCpqEnabled
          ? EInstalledBaseTabs.CONTRACTS
          : EInstalledBaseTabs.EQUIPMENT;
        this.currentTab = params.tab
          ? params.tab
          : this.defaultTab;
      });
    this.selectReorderPending();
    this.getCustomerCompanyRoles();
  }

  ngOnDestroy(): void {
    if (this.isReorderPending) {
      this.marketingFacade.cancelReorder();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
      if (this.isCpqEnabled) {
        this.selectCpqEquipments();
      } else {
        this.cpqEquipmentsLoading = false;
        this.trackPageReady();
      }
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.myInstallBaseFlowEnabled = value;
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSparePartsEnabled = value;
      if (this.isSparePartsEnabled && this.myInstallBaseFlowEnabled) {
        this.selectSparePartsEquipments();
      } else {
        this.sparePartsEquipmentsLoading = false;
        this.trackPageReady();
      }
    });
  }

  changeTab(tab: string): void {
    this.currentTab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {tab: tab},
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  selectReorderPending(): void {
    this.marketingFacade.isReorderPending()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isReorderPending => {
        this.isReorderPending = isReorderPending;
      });
  }

  selectCpqEquipments(): void {
    combineLatest([
      this.installBaseFacade.selectCpqEquipmentsLoading(),
      this.installBaseFacade.selectCpqEquipments(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: ([equipmentsLoading, equipments]) => {
        this.cpqEquipmentsLoading = equipmentsLoading;
        if (equipments?.length) {
          this.cpqEquipments = ArrayUtils.sortByAttribute([...equipments], 'attributes.nameEnUs');
          this.cpqModalitiesAndMaterialNumbers = this.extractEquipmentModalitiesAndMaterialNumbers(equipments);
        }
        this.trackPageReady();
      },
      error: () => {
        this.cpqEquipmentsLoading = false;
        this.trackPageReady();
      },
    });
  }

  selectSparePartsEquipments(): void {
    combineLatest([
      this.installBaseFacade.selectSparePartsEquipmentsLoading(),
      this.installBaseFacade.selectSparePartsEquipments(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: ([equipmentsLoading, equipments]) => {
        this.sparePartsEquipmentsLoading = equipmentsLoading;
        if (equipments?.length) {
          this.sparePartsEquipments = ArrayUtils.sortByAttribute([...equipments], 'attributes.nameEnUs');
        }
        this.trackPageReady();
      },
      error: () => {
        this.sparePartsEquipmentsLoading = false;
        this.trackPageReady();
      },
    });
  }

  getCustomerCompanyRoles(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(companyRoles => {
        this.companyRoles = companyRoles;
      });
  }

  private extractEquipmentModalitiesAndMaterialNumbers(equipments: IShsEquipmentData[]): IModalitiesAndMaterialNumbers[] {
    const modalitiesAndMaterialNumbers = equipments.map(equipment => ({
      modality: equipment.attributes.category,
      materialNumber: equipment.attributes.materialNumber,
    }));
    return ArrayUtils.uniqueObjects(modalitiesAndMaterialNumbers, 'materialNumber');
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inProgress => {
      this.isCartOperationInProgress = inProgress;
    });
  }

  private selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
      this.loadingCartDataInProgress = loadingCartDataInProgress;
      this.trackPageReady();
    });
  }

  private trackPageReady(): void {
    if (
      !this.pageReadyEventFired &&
      !this.cpqEquipmentsLoading &&
      !this.sparePartsEquipmentsLoading &&
      !this.loadingCartDataInProgress
    ) {
      this.analyticsService.trackPageReady('My Installed Base', PageTypes.INSTALL_BASE_PAGE);
      // Not strictly necessary currently, but it's more future-proof as it ensures
      // that the event is only fired once per component lifetime
      this.pageReadyEventFired = true;
    }
  }
}
