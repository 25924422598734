import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISelectEvent, ISystemDetails } from '../../models/common.models';
import * as ValidationPatterns from '../../configurations/validations';
import { EGlueResource, EAddressType } from '../../configurations/common';
import { take } from 'rxjs/operators';
import { CpqFacade } from '../../facades/cpq.facade';
import { IAddressData } from '../../models/checkout.models';

@Component({
  selector: 'app-harmonized-report-wrong-address-modal',
  templateUrl: './harmonized-report-wrong-address-modal.component.html',
})
export class HarmonizedReportWrongAddressModalComponent implements OnInit {
  reportWrongAddressForm: UntypedFormGroup;
  loading: boolean = false;
  maxLengthComment: number = 500;

  @Input() showModal: boolean;
  @Input() cartId: string;
  @Input() addresses: IAddressData[];
  @Input() addressType: EAddressType;
  @Input() systemDetails: ISystemDetails;
  @Input() label: string;
  @Input() readOnly: boolean = true;
  @Output() closeModal = new EventEmitter<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cpqFacade: CpqFacade,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Initialize form with default values.
   * Set first address from the list as default.
   */
  initializeForm(): void {
    this.reportWrongAddressForm = this.formBuilder.group({
      address: ['', Validators.required],
      comment: ['', [Validators.required, Validators.maxLength(this.maxLengthComment),
        ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });

    this.reportWrongAddressForm.patchValue({
      address: !this.addresses[0]?.name
        ? {isAddressValid: false, name: '-', value: '-', type: this.addressType}
        : this.addresses[0],
    });
  }

  /**
   * Show loader over modal content.
   */
  showLoader(): void {
    this.loading = true;
  }

  /**
   * Reset form and close modal when user clicks on submit button, cancel button or outside the modal window.
   */
  resetForm(): void {
    this.closeModal.emit();
    this.reportWrongAddressForm.reset();
  }

  /**
   * Select address from SelectBox component.
   * @param {ISelectEvent} event
   */
  selectWrongAddress(event: ISelectEvent): void {
    this.reportWrongAddressForm.patchValue({
      address: event,
    });
  }

  /**
   * Set comment value from textarea.
   * @param {string} value
   */
  setCommentWrongAddress(value: string): void {
    this.reportWrongAddressForm.patchValue({
      comment: value,
    });
  }

  /**
   * If form of reporting wrong address is valid, then use postCpqWrongAddress method.
   */
  reportAddress(): void {
    if (this.reportWrongAddressForm.status === 'VALID') {
      const dataToSend = {
        type: EGlueResource.CPQ_ADDRESS_REPORT_WRONG,
        attributes: {
          addressType: this.addressType,
          functionalLocations: this.systemDetails?.siemensEquipmentId ? [this.systemDetails.siemensEquipmentId] : ['-'],
          comment: this.reportWrongAddressForm.value.comment,
          quoteUuid: this.cartId ?? '-',
          address: this.reportWrongAddressForm.value.address.name,
        },
      };
      this.cpqFacade.postCpqWrongAddress({data: dataToSend}).pipe(
        take(1),
      ).subscribe(
        () => this.reportAddressSuccess(),
      );
    }
  }

  /**
   * Show notification when address is reported successfully.
   * @private
   */
  private reportAddressSuccess(): void {
    this.loading = false;
    this.resetForm();
    this.cpqFacade.showAdditionalDeliverableNotification(
      'success',
      'report-wrong-address.reported',
      [{
        key: 'report-wrong-address.reported-desc',
        params: {},
        css: [],
      }],
      [],
    );
  }
}
