import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { MathUtils } from '../../../utils/math.utils';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { EProductDetails } from '../../../configurations/product-details';
import { ProductUtils } from '../../../utils/product.utils';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';
import { IMaterialMasterNumbersToggles, IPrice, ISystemDetails } from '../../../models/common.models';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { EFeatureToggles, EUserRoles } from '../../../configurations/common';
import { IShsEquipmentData, ISimpleInstallBaseProduct } from '../../../models/installedbase.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import {
  IAbstractProductAvailability,
  IAbstractProductAvailabilityResource,
  IAbstractProductPrices,
  ICachedProductSkuAndRequiredReload,
} from '../../../models/abstract-product.models';
import { PriceUtils } from '../../../utils/price.utils';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';
import { ICart } from '../../../models/cart.models';
import { CustomerFacade } from '../../../facades/customer.facade';
import { SparePartsUtils } from '../../../utils/spare-parts.utils';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { CatalogActions } from '../../../actions';
import { MarketingFacade } from '../../../facades/marketing.facade';

@Component({
  selector: 'app-spare-part-product-pdp',
  templateUrl: './spare-part-product-pdp.component.html',
  styleUrls: ['./spare-part-product-pdp.component.scss'],
})
export class SparePartProductPdpComponent implements OnInit, OnDestroy, OnChanges {
  openZoomedPicture: boolean = false;
  isQuantityValid: boolean = true;
  currentlyInStock: boolean = false;
  closeModal: boolean = false;
  isEligible: boolean = false;
  isExchangeable: boolean = false;
  isAvailable: boolean = false;
  pricesLoaded: boolean = false;
  emptyAttribute: string = '-';
  attributes: IBaseConfig[] = [];
  guestConst: EUserRoles = EUserRoles.Guest;
  isBusinessPartner: boolean = false;
  priceUtils = PriceUtils;
  pricingGroupForConsumables: string;
  materialMasterToggles: IMaterialMasterNumbersToggles;
  canCartItemsBeChanged: (currentCart: ICart, isBusinessPartner: boolean) => boolean = SparePartsUtils.canCartItemsBeChanged;

  @Input() systemDetails: ISystemDetails;
  @Input() equipmentFlNumber: string;
  @Input() stepNumber;
  @Input() concreteProduct;
  @Input() wishlists;
  @Input() sku;
  @Input() yourPrice: IPrice;
  @Input() listPrice: IPrice;
  @Input() productInfoLoaded;
  @Input() concreteSku;
  @Input() abstractProduct;
  @Input() labels;
  @Input() defaultImg;
  @Input() largeUrls;
  @Input() addNewModalActive;
  @Input() productQuantity;
  @Input() isAddToCartInProgress$;
  @Input() smallUrls;
  @Input() mainPicture;
  @Input() productDetailsEnum;
  @Input() installBaseProducts;
  @Input() displayConfig;
  @Input() loadingCartDataInProgress: boolean;
  @Input() wasRedirectedFromEquipmentPage: boolean;
  @Input() companyRoles: EUserRoles[];
  @Input() agreement: IShsEquipmentData;
  @Input() recentlyOpenedProducts: ICachedProductSkuAndRequiredReload[];

  @Output() addNewModalWishlist = new EventEmitter<any>();
  @Output() addProductToCartSubmit = new EventEmitter<any>();
  @Output() productQuantityChange = new EventEmitter<number>();

  @ViewChild(SlickCarouselComponent) slickCarouselComponent: SlickCarouselComponent;

  currentCart: ICart;
  isSapP40Enabled: boolean = false;
  loadedSuccessfully: boolean = true;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();
  isMyInstalledBaseFlowEnabled$: Observable<boolean> = new Observable<boolean>();
  private unsubscribe$ = new Subject<void>();

  sparePartsDetails = [
    'sap_country_of_origin',
    'sap_p40_modality_description',
    'material_number',
    'sap_p40_eos',
    'sap_p40_material_pricing_group_3_mapped',
    'sap_gtin',
    'mcm_webshop_anatomic_area_mapped',
    'sap_p40_proratio_material',
    'sap_p40_alternate_material',
    'sap_p40_subsequent_material',
  ];

  constructor(
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private translate: TranslateService,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
    private store: Store<State>,
    private marketingFacade: MarketingFacade,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.isMyInstalledBaseFlowEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW);

    this.getAttributes();
    this.selectIsCustomerBusinessPartner();
    this.shouldShowWarning();
    this.getMaterialMasterNumbersToggles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.abstractProduct?.previousValue?.sku !== changes?.abstractProduct?.currentValue?.sku) {
      this.getPricesAndAvailabilitiesWhenCartIsAvailable();
    }
  }

  /**
   * Method for showing warning message
   */
  shouldShowWarning(): void {
    if (!this.currentCart?.attributes?.soldToAddress && this.isBusinessPartner && (!!this.systemDetails?.siemensEquipmentId && !!this.systemDetails?.materialNumber && !!this.systemDetails?.nameEnUs)) {
      this.showWarning();
    }
  }

  isAddToCartButtonDisabled(): boolean {
    return !this.isSapP40Enabled
      || !this.isQuantityValid
      || this.loadingCartDataInProgress
      || (!this.pricesLoaded && !!this.equipmentFlNumber)
      || !this.loadedSuccessfully
      || (this.isProductDiscontinued() && !this.isAvailable);
  }

  addToWishlist(): void {
    this.addNewModalWishlist.emit();
  }

  setLargeUrl(index: number): void {
    this.mainPicture = this.largeUrls[index];
    this.slickCarouselComponent.slickGoTo(index);
  }

  isProductWithMaterialMasterNumber(): boolean {
    return (
      (this.materialMasterToggles?.peakNumber &&
        this.abstractProduct?.attributes?.sap_p40_peak_flag?.toUpperCase() === 'X') ||
      (this.materialMasterToggles?.notificationNumber &&
        this.abstractProduct?.attributes?.sap_p40_notif_flag?.toUpperCase() === 'X')
      );
  }

  isConfigurable(): boolean {
    return this.concreteProduct ? this.concreteProduct.attributes.productConfigurationInstance !== null : false;
  }

  changeProductQuantity(productQuantity: number): void {
    this.checkIfPositiveInteger(productQuantity);
    if (this.isQuantityValid) {
      this.productQuantityChange.emit(productQuantity);
    }
  }

  private checkIfPositiveInteger(productQuantity: number): void {
    this.isQuantityValid = MathUtils.checkIfNumeric(productQuantity) && productQuantity > 0;
  }

  addProductToCart(): void {
    this.addProductToCartSubmit.emit();
  }

  openZoomPicture(): void {
    if (this.mainPicture !== this.defaultImg) {
      this.openZoomedPicture = !this.openZoomedPicture;
      if (this.openZoomedPicture) {
        this.analyticsService.setProducts(this.abstractProduct);
        this.analyticsService.trackProduct('image.enlarge', this.abstractProduct);
      }
    }
  }

  getDetailAttribute(attributeValue: string | null): string {
    return ProductUtils.getDetailAttribute(attributeValue);
  }

  tracking(name: string): void {
    this.analyticsService.setProducts(this.abstractProduct);
    this.analyticsService.trackAccordion(name);
  }

  trackInstalledBase(installedBase: ISimpleInstallBaseProduct): void {
    this.catalogFacade.setInstalledBase(installedBase);
    this.analyticsService.trackInstalledBase(installedBase.attributes.materialName, installedBase.id);
  }

  displayProductDetail(section: EProductDetails): boolean {
    return this.displayConfig.includes(section);
  }

  hasTranslation(key: string): boolean {
    const translation = this.translate.instant(key);
    return !!translation && translation !== key;
  }

  getWeightDetails(): string {
    const weightInLbs = this.abstractProduct.attributes['sap_p40_gross_weight']
      ? `${this.abstractProduct.attributes['sap_p40_gross_weight']} LBS`
      : '';
    const weightInKg = this.abstractProduct.attributes['sap_gross_weight']
      ? `${this.abstractProduct.attributes['sap_gross_weight']} KG`
      : '';

    if (!weightInLbs && !weightInKg) {
      return '-';
    } else {
      return weightInLbs && weightInKg
        ? `${weightInLbs} / ${weightInKg}`
        : weightInLbs || weightInKg;
    }
  }

  openSparePartsViewer(): void {
    const productMaterialNumber = this.abstractProduct.attributes.material_number;
    const sysIvk = this.systemDetails?.materialNumber;
    const sparePartsLink = sysIvk
      ? `${environment.sparePartsViewerUrl}&sysIVK=${sysIvk}&matnum=${productMaterialNumber}`
      : `${environment.sparePartsViewerUrl}&matnum=${productMaterialNumber}`;
    window.open(sparePartsLink, '_blank');
  }

  toggleAttribute(attribute: string): boolean {
    return this.attributes.find((attr) => attr.name === attribute).value === 'true';
  }

  private getAttributes(): void {
    this.configurationFacade.getTranslationByKey(['spare-parts.attributes'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        Object.keys(data['spare-parts.attributes']).forEach((key) => {
          const attribute = data['spare-parts.attributes'][key];
          this.attributes.push({
            name: key,
            value: attribute,
          });
        });
      });
  }

  /**
   * This method selects loading flag form catalog state and wait until flag is not false.
   * This is required because we have to wait until product abstract data (prices and availabilities) are loaded.
   * This logic is required cause is not necessary to call API every time.
   */
  selectPricesLoadingAndBasedOnItLoadProductAbstractData(): void {
    this.catalogFacade.selectArePricesAndAvailabilitiesLoading()
      .pipe(
        skipWhile(loading => loading === true),
        takeUntil(this.unsubscribe$),
      ).subscribe(loading => {
      this.pricesLoaded = !loading;
      this.selectPricesAndAvailabilitiesAndProcessData();
    });
  }

  /**
   * This method waits until the cart is loaded,
   * checks whether SAP P40 is enabled and gets a pricing group for consumables.
   * When the cart is loaded and all observables emitted a value, the getPricesAndAvailabilities method is called.
   */
  getPricesAndAvailabilitiesWhenCartIsAvailable(): void {
    combineLatest([
      this.marketingFacade.selectCart(),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40),
      this.catalogFacade.getPricingGroupForConsumables()
    ]).pipe(
      skipWhile(([cart]) => cart === null),
      takeUntil(this.unsubscribe$),
    ).subscribe(([cart, isFeatureEnabled, pricingGroup]) => {
      if (cart.relationships) {
        this.currentCart = cart;
        this.isSapP40Enabled = isFeatureEnabled;
        this.pricingGroupForConsumables = pricingGroup;
        this.getPricesAndAvailabilities();
      }
    });
  }

  /**
   * In case loading prices and availabilities from API is required then
   * this.getPricesAndAvailabilitiesFrom SAP method is called
   */
  getPricesAndAvailabilities(): void {
    if (this.isSapP40Enabled && this.checkIfProductIsAvailableForOnlinePurchase()) {
      if (this.currentCart?.id && this.currentCart.attributes?.systemDetails?.siemensEquipmentId) {
        if (this.isGettingProductDataFromApiRequired()) {
          this.getPriceAndAvailabilityFromSAP();
        }
        this.selectPricesLoadingAndBasedOnItLoadProductAbstractData();
      }
    }
  }

  /**
   * Method for dispatching of getting product abstract prices and availabilities from API action
   */
  getPriceAndAvailabilityFromSAP(): void {
    this.store.dispatch(CatalogActions.loadAbstractProductInfoFromSap({sku: this.sku, uuid: this.currentCart.id}));
  }

  /**
   * Method for selecting and processing prices and availability from storage
   */
  selectPricesAndAvailabilitiesAndProcessData(): void {
    let productPrices: IAbstractProductPrices;
    let productAvailabilities: IAbstractProductAvailability;
    this.catalogFacade.selectAbstractProductPricesAndAvailabilitiesBySku(this.sku)
      .pipe(
        skipWhile(productPrices => productPrices[0].id !== this.sku),
        takeUntil(this.unsubscribe$),
      ).subscribe({
      next: (pricesAndAvailabilities) => {
        productPrices = pricesAndAvailabilities[0];
        productAvailabilities = pricesAndAvailabilities[1];
        if (
          productPrices?.attributes.loadedSuccessfully
          && productAvailabilities?.attributes.loadedSuccessfully
        ) {
          this.loadedSuccessfully = true;
          this.isAvailable = productAvailabilities?.attributes?.availability;
          this.setListPrice(productPrices);
          this.setYourPrice(productPrices);
          this.setProductAvailabilityAttributes(productAvailabilities);
        }
        // if cart is without FL (system details.siemensEquipmentId), error should not be displayed
        else if (this.currentCart?.attributes?.systemDetails?.siemensEquipmentId) {
          this.handlePriceOrAvailabilityLoadingFailure(productPrices, productAvailabilities);
        }
      },
      error: () => {
        this.handlePriceOrAvailabilityLoadingFailure(productPrices, productAvailabilities);
      },
    });
  }

  /**
   * This method try to find current product's sku in cached products skus list and depends on flag "isReloadRequired"
   * decide whether to load product abstract data from sap or from cached state
   * @returns {boolean}
   */
  isGettingProductDataFromApiRequired(): boolean {
    const currentProduct: ICachedProductSkuAndRequiredReload = this.recentlyOpenedProducts.find(item => item.sku === this.sku);
    return currentProduct.isReloadRequired;
  }

  isUserSparePartsViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.SPCViewer);
  }

  private handlePriceOrAvailabilityLoadingFailure(
    productPrices: IAbstractProductPrices,
    productAvailabilities: IAbstractProductAvailabilityResource,
  ): void {
    this.loadedSuccessfully = false;

    if (productPrices) {
      this.setListPrice(productPrices);
      this.setYourPrice(productPrices);
    }
    if (productAvailabilities) {
      this.setProductAvailabilityAttributes(productAvailabilities);
    }
    this.translate.get([
      'spare-parts.pdp-pricing-availability-failed-loading-msg',
      'spare-parts.pdp-pricing-availability-failed-loading-msg-type',
    ]).pipe(take(1))
      .subscribe((translations) => {
        const message = translations['spare-parts.pdp-pricing-availability-failed-loading-msg'];
        const type = translations['spare-parts.pdp-pricing-availability-failed-loading-msg-type'];
        this.configurationFacade.setAlert({message, type});
      });
  }

  private setListPrice(productPrices: IAbstractProductPrices): void {
    const prices = productPrices.attributes.prices;
    const guestPrice = this.priceUtils.getGuestPrice(prices);
    const defaultPrice = this.priceUtils.getDefaultPrice(prices);
    // assume the default price === list price if it's the only price returned in the response
    this.listPrice = guestPrice ?? defaultPrice;
  }

  private setYourPrice(productPrices: IAbstractProductPrices): void {
    const prices = productPrices.attributes.prices;
    const defaultPrice = this.priceUtils.getDefaultPrice(prices);
    this.yourPrice = defaultPrice ?? {} as IPrice;
  }

  private setProductAvailabilityAttributes(productAvailabilities: IAbstractProductAvailabilityResource): void {
    this.isExchangeable = productAvailabilities.attributes.exchangeableItem;
    this.isEligible = productAvailabilities.attributes.eligible;
    this.currentlyInStock = productAvailabilities.attributes.availability;
  }

  public updatedPricesAfterChangeSoldTo(value: boolean) {
    if (value) {
      this.getPricesAndAvailabilities();
    }
  }

  /**
   *
   * @private
   * Method for creating warning window
   */
  private showWarning(): void {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'issue-with-data.title',
      messages: [{
        key: 'issue-with-data.message',
      }],
    });
  }

  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  /**
   * Check if product is discontinued (sap_p40_sales_status is '01' or '02')
   *
   * @return {boolean}
   */
  isProductDiscontinued(): boolean {
    switch (this.abstractProduct?.attributes?.sap_p40_sales_status) {
      case '01':
        return true;
      case '02':
        return true;
      default:
        return false;
    }
  }

  /**
   * Check if product has subsequent material
   *
   * @return {boolean}
   */
  isSubsequentMaterialAvailable(): boolean {
    return !!(this.abstractProduct?.attributes?.sap_p40_sales_status === '02'
      && this.abstractProduct?.attributes?.sap_p40_subsequent_material);
  }

  /**
   * Get subsequent material for product
   * Handle multiple cases: no subsequent material/one subsequent material
   * Add leading zeros to subsequent material number if needed
   *
   * @return {string[]}
   */
  getSubsequentMaterial(): string[] {
    if (this.isSubsequentMaterialAvailable()) {
      return [this.abstractProduct.attributes.sap_p40_subsequent_material.padStart(8, '0')];
    }

    return [];
  }

  /**
   * Returns true if product is available for online purchase
   *  user has to be non-business partner
   *  FL has to be selected
   *  product is available for online purchase
   *    if product is consumable and system details is not isSparePartsAvailable
   *    or system details is isSparePartsAvailable
   *
   * @return {boolean}
   */
  checkIfProductIsAvailableForOnlinePurchase(): boolean {
    if (
      !( // check if user is non-BP and FL is selected
        this.companyRoles.includes(EUserRoles.BusinessPartnerBuyer)
        || this.companyRoles.includes(EUserRoles.BusinessPartnerApprover)
      ) && this.currentCart?.attributes?.systemDetails?.siemensEquipmentId
    ) {
      return ( // check if product is available for online purchase
        this.abstractProduct.attributes.sap_p40_material_pricing_group_3 === this.pricingGroupForConsumables
        && !this.currentCart?.attributes.systemDetails.isSparePartsAvailable
      ) || this.currentCart?.attributes.systemDetails.isSparePartsAvailable
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getMaterialMasterNumbersToggles(): void {
    this.marketingFacade.getMaterialMasterNumbersToggles()
      ?.pipe(take(1))
      .subscribe((materialMasterToggles) => {
        this.materialMasterToggles = materialMasterToggles;
        for (const [key, value] of Object.entries(this.materialMasterToggles)) {
          this.materialMasterToggles[key] = value === true || value === 'true';
        }
      });
  }

}
