import { Component, Input, OnInit } from '@angular/core';
import {
  IShsEquipmentData,
  IShsEquipmentDataAttributesEquipmentComponents,
} from '../../../../models/installedbase.models';
import { Observable, skipWhile, Subject } from 'rxjs';
import { EFeatureToggles, EUserRoles } from '../../../../configurations/common';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { DateUtils } from '../../../../utils/date.utils';
import { FormatDatePipe } from '../../../../shared/pipes/format-date.pipe';

@Component({
  selector: 'app-my-equipment-components',
  templateUrl: './my-equipment-components.component.html',
  styleUrls: ['./my-equipment-components.component.scss'],
})

export class MyEquipmentComponentsComponent implements OnInit {
  @Input() agreement: IShsEquipmentData;
  equipmentData: IShsEquipmentDataAttributesEquipmentComponents[];

  attributes = [
    {
      title: 'materialText',
      dataTestTitle: 'Name',
      key: 'my-contracts.equipment-components-name',
    },
    {
      title: 'serial',
      dataTestTitle: 'Serial',
      key: 'my-contracts.equipment-components-serial',
    },
    {
      title: 'swVersion',
      dataTestTitle: 'SoftwareVersion',
      key: 'my-contracts.equipment-components-software-version',
    },
    {
      title: 'eqInstallDt',
      dataTestTitle: 'InstallationDate',
      key: 'my-contracts.equipment-components-installation-date',
    },
    {
      title: 'matEosDt',
      dataTestTitle: 'EosDate',
      key: 'my-contracts.equipment-components-EOS-date-of-component',
    },
  ];

  private unsubscribe$: Subject<void> = new Subject<void>();
  userRoles$: Observable<EUserRoles[]>;

  private userRoleToToggleMap = {
    [EUserRoles.Admin]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_CUSTOMER_ADMIN,
    [EUserRoles.Buyer]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_ORDER_USER,
    [EUserRoles.Approver]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_APPROVER_USER,
    [EUserRoles.Viewer]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_BROWSER_USER,
    [EUserRoles.SPCViewer]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_SPAREPARTS_VIEW,
    [EUserRoles.BusinessPartnerBuyer]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_BUSINESSPARTNER_BUYER,
    [EUserRoles.BusinessPartnerApprover]: EFeatureToggles.EQUIPMENT_COMPONENT_FILTER_ACTIVE_FOR_BUSINESSPARTNER_APPROVER,
  };
  isEquipmentComponentsVisible: boolean = true;
  featureToggleEquipmentComponent: boolean;


  constructor(
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
    private formatDatePipe: FormatDatePipe,
  ) {
  }

  ngOnInit(): void {
    this.equipmentData = this.getEquipmentData();
    this.getFeatureTogglesForEquipmentComponent();
    this.userRoles$ = this.customerFacade.getCustomerCompanyRoles();
    this.filterComponentData();
    this.setShowEquipmentComponents();
  }

  getEquipmentData(): IShsEquipmentDataAttributesEquipmentComponents[] {
    return this.agreement.attributes.components;
  }

  getAttribute(component: IShsEquipmentDataAttributesEquipmentComponents, title: string): string {
    if (title === 'eqInstallDt' || title === 'matEosDt') {
      return DateUtils.isValidDate(component[title]) ? this.formatDatePipe.transform(component[title]) : '-';
    }
    return component[title];
  }

  getFeatureTogglesForEquipmentComponent(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_EQUIPMENT_COMPONENTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.featureToggleEquipmentComponent = value;
    });
  }

  setShowEquipmentComponents():  void {
    if (!this.featureToggleEquipmentComponent || this.equipmentData.length === 0 || !this.isRoleToggleEnabled()) {
      this.isEquipmentComponentsVisible = false;
    }
  }

  filterComponentData(): void {
    this.equipmentData = this.equipmentData
      .filter(item => Object.values(item).some(value => value !== null))
      .map(item => {
        Object.keys(item).forEach(key => {
          item[key] = item[key] ?? '-';
        });
        return item;
      });
  }

  isRoleToggleEnabled(): boolean {
    let isRoleToggleEnabled = true;
    this.userRoles$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((userRoles: EUserRoles[]) => {
      userRoles.forEach((role: EUserRoles) => {
        if (this.userRoleToToggleMap[role]) {
          this.configurationFacade.isFeatureEnabled(this.userRoleToToggleMap[role]).pipe(
            skipWhile(res => res === true),
            takeUntil(this.unsubscribe$),
          ).subscribe(() => {
            isRoleToggleEnabled = false;
          });
        }
      });
    });
    return isRoleToggleEnabled;
  }
}
