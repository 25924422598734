import { Component, Input, OnInit } from '@angular/core';
import { EOrderStatus, EScenarioType, IOrderStatusScenario, OrderStatus } from '../../configurations/order-status';
import { EStoreTypes, ETranslationTypes } from '../../configurations/common';
import { environment } from '../../../environments/environment';
import { AppUtils } from '../../utils/app.utils';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'app-steps-bar',
  templateUrl: './steps-bar.component.html',
  styleUrls: ['./steps-bar.component.scss'],
})
export class StepsBarComponent implements OnInit {
  activeScenario: IOrderStatusScenario;
  completedSteps = [] as string[];
  eTranslationTypes = ETranslationTypes;
  declinedStatues = environment.statuesTreatedAsDeclined;
  cpqOrderDeclinedStatus = EOrderStatus.ISSUE_WITH_ORDER;
  onlinePaymentCcavenue = 'onlinePaymentCcavenue';

  @Input() comment: string;
  @Input() scenarioType = EScenarioType.ORDERS as string;
  @Input() currentStatus: string;
  @Input() currentDesc: string;
  @Input() paymentMethod: string;
  @Input() isPaymentSuccess: boolean = true;

  ngOnInit(): void {
    //TODO: For testing purposes for PBI #114579. Will be deleted in PBI #114581
    if (!this.isPaymentSuccess) {
      this.currentStatus = EOrderStatus.CANCELED;
    }
    switch (this.scenarioType) {
      case EScenarioType.REQUEST:
        this.requestScenarios();
        break;
      case EScenarioType.GENERATED_QUOTE:
        this.generatedQuoteScenarios();
        break;
      case EScenarioType.CPQ:
        this.cpqScenarios();
        this.selectCpqCompletedSteps();
        return;
      case EScenarioType.SPARE_PART_ORDER_DETAILS:
        this.sparePartOrderScenarios();
        return;
      default:
        this.defaultScenarios();
    }

    this.selectCompletedSteps();
  }

  sparePartOrderScenarios(): void {
    if (this.currentStatus === EOrderStatus.IN_PROCESS) {
      this.currentStatus = EOrderStatus.CREATED;
    }

    switch (this.currentStatus) {
      case EOrderStatus.NEW:
      case EOrderStatus.CREATED:
      case EOrderStatus.SHIPPED:
        this.activeScenario = OrderStatus.ShippedSparePartOrderScenario;
        break;
      case EOrderStatus.PARTIALLY_SHIPPED:
        this.activeScenario = OrderStatus.PartiallyShippedSparePartOrderScenario;
        break;
      case EOrderStatus.CANCELED:
        this.activeScenario = OrderStatus.CanceledSparePartOrderScenario;
        break;
      case EOrderStatus.ISSUE:
        this.activeScenario = OrderStatus.IssueSparePartOrderScenario;
        break;
      case EOrderStatus.SEE_DETAILS:
        this.activeScenario = OrderStatus.SeeDetailsSparePartOrderScenario;
        break;
      default:
        break;
    }
  }

  selectCompletedSteps(): void {
    this.activeScenario.scenarioSteps.every((element) => {
      this.completedSteps.push(element);
      return element !== this.currentStatus;
    });
  }

  selectCpqCompletedSteps(): void {
    if (this.currentStatus === EOrderStatus.CPQ_ORDER_DECLINED) {
      this.completedSteps = this.activeScenario.scenarioSteps;
    } else {
      let currentStatusIndex = 0;
      this.activeScenario.scenarioSteps.forEach((element, index) => {
        this.completedSteps.push(element);
        if (element === this.currentStatus) {
          currentStatusIndex = index;
        }
      });
      this.completedSteps = this.completedSteps.slice(0, currentStatusIndex);
    }
  }

  requestScenarios(): void {
    if (this.currentStatus === EOrderStatus.CANCELED) {
      this.activeScenario = OrderStatus.RequestCanceledScenario;
    } else {
      this.activeScenario = OrderStatus.RequestSuccessScenario;
    }
  }

  generatedQuoteScenarios(): void {
    if (this.currentStatus === EOrderStatus.CANCELED) {
      this.activeScenario = OrderStatus.GeneratedQuoteCanceledScenario;
    } else {
      this.activeScenario = OrderStatus.GeneratedQuoteSuccessScenario;
    }
  }

  cpqScenarios(): void {
    switch (this.currentStatus) {
      case EOrderStatus.ORDER_SUBMITTED:
        this.activeScenario = OrderStatus.CpqPendingScenario;
        break;
      case EOrderStatus.PENDING_DOCUMENTATION:
        this.activeScenario = OrderStatus.CpqPendingScenario;
        break;
      case EOrderStatus.DOCUMENTATION_SUBMITTED:
        this.activeScenario = OrderStatus.CpqReviewScenario;
        break;
      case EOrderStatus.ISSUE_WITH_ORDER:
        this.activeScenario = OrderStatus.CpqIssueWithOrderScenario;
        break;
      case EOrderStatus.UNDER_REVIEW:
        this.activeScenario = OrderStatus.CpqUnderReviewScenario;
        break;
      case EOrderStatus.DOCUMENT_ACCEPTED:
      case EOrderStatus.ORDER_COMPLETE:
        this.activeScenario = OrderStatus.CpqOrderCompleteScenario;
        break;
      case EOrderStatus.CPQ_ORDER_DECLINED:
        this.activeScenario = OrderStatus.CpqOrderDeclinedScenario;
        break;
      default:
        break;
    }
  }

  defaultScenarios(): void {
    switch (this.currentStatus) {
      case EOrderStatus.DECLINED:
        this.activeScenario = OrderStatus.DeclineScenario;
        break;
      case EOrderStatus.CANCELED:
        this.activeScenario = OrderStatus.CanceledOrderScenario;
        break;
      default:
        this.setDefaultScenarios();
    }
  }

  private setDefaultScenarios() {
    if (AppUtils.isStoreActive(EStoreTypes.IN)) {
      if (this.paymentMethod === this.onlinePaymentCcavenue) {
        this.activeScenario = OrderStatus.IndiaCCAvenueSuccessScenario;
      } else {
        this.activeScenario = OrderStatus.IndiaSuccessScenario;
      }
    } else if (AppUtils.isStoreActive(EStoreTypes.JP)) {
      this.activeScenario = OrderStatus.JapanSuccessScenario;
    } else {
      this.activeScenario = OrderStatus.SuccessScenario;
    }

    return this.activeScenario;
  }

  getTitlePosition(): number {
    const hSteps = this.activeScenario.hospitalSteps || this.activeScenario.organizationSteps;
    const middle = hSteps[Math.floor(hSteps.length / 2)];
    return hSteps.length % 2 !== 0 ? hSteps.indexOf(middle) : hSteps.indexOf(middle) - 1;
  }

  getStepName(step: string): string {
    return StringUtils.replaceSpaces(step, '-');
  }

  getTranslation(step: string, type: ETranslationTypes): string {
    const stepName = this.getStepName(step);
    switch (type) {
      case ETranslationTypes.LABEL:
        return `${this.scenarioType}-step-bar.${stepName}-label`;
      case ETranslationTypes.DESCRIPTION:
        return `${this.scenarioType}-step-bar.${stepName}-desc`;
    }
  }

  getComment(step: string, type: ETranslationTypes): string {
    if (!this.currentDesc || (this.currentDesc && this.currentStatus !== step)) {
      return this.getTranslation(step, type);
    }
    return '';
  }
}
