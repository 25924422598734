<div class="multi-cart">
  <h3 class="page-title" [attr.data-test]="'title-pageTitle'">{{ 'multi-cart.title' | translate }}</h3>
  <app-access-control [notForViewer]="true" [description]="true">
    <div class="multi-cart__content" ngProjectAs="[slot][nonViewerContent]">
      <ng-container *ngIf="(currentCartLoaded$ | async) === false && currentCartLoaded; else currentCartLoader">
        <app-current-cart
            #currentCartRef
            *ngIf="!isContractInCart()"
            [isUsStore]="isUsStore"
            [isCaStore]="isCaStore"
            [rfqActive]="rfqActive"
            [isRfqOnly]="isRfqOnly"
            [minimumOrderValue]="minimumOrderValue"
            [isExportCartActive]="isExportCartActive"
            [loadingCart]="switchingCartInProgress
              || (isCartOperationInProgress && cartIdOfCartWhichIsPrepareToDelete === currentCart.id)"
            [currency]="currency"
            [currentCart]="currentCart"
            [currentCartItems]="currentCartItems ?? []"
            [hasNickname]="hasNickname"
            [wishlists]="wishlists"
            [addWishlistModalActive]="addWishlistModalActive"
            (showAddWishlistModal)="addWishlistModalActive = $event"
            (showAddNicknameModal)="addNicknameModalActive = $event"
            (showDeleteCartModal)="deleteCurrentCart($event)"
            (generatePdfFile)="generatePdfFile()"
            (removeCartItem)="removeCartItem($event)"
            (updateCartItemQuantity)="updateCartItemQuantity($event)">
        </app-current-cart>
        <app-current-quote
            *ngIf="isContractInCart()"
            [loadingQuote]="switchingCartInProgress
              || (isCartOperationInProgress && quoteIdOfQuoteWhichIsPrepareToDelete === currentCart.id)"
            [currency]="currency"
            [totals]="totals"
            [currentCart]="currentCart"
            [currentCartItems]="currentCartItems"
            [hasNickname]="hasNickname"
            [validUntilDate]="validUntilDate"
            (showAddNicknameModal)="addNicknameModalActive = $event"
            (showDeleteQuoteModal)="deleteCurrentQuote($event)"
            (removeQuoteItem)="quoteItemToDelete = $event"
            (showRemoveConfigurationModal)="removeConfigurationModalActive = $event">
        </app-current-quote>
        <div class="multi-cart__other-carts">
          <h6 *ngIf="currentTab === multiCartTabs.PARTS">{{ 'multi-cart.other-carts' | translate }}</h6>
          <h6 *ngIf="currentTab === multiCartTabs.SERVICES">{{ 'multi-cart.other-quotes' | translate }}</h6>
          <div class="tabs">
            <div class="tabs__list margin-left-0" *ngIf="otherCarts?.length > 0">
            <span *ngIf="otherPartProductCarts?.length > 0"
                  class="tabs__item" [ngClass]="{'is-active': currentTab === multiCartTabs.PARTS}"
                  (click)="currentTab = multiCartTabs.PARTS">
              {{ 'multi-cart.tab-parts' | translate }}
              <span>({{ otherPartProductCarts?.length }})</span>
            </span>
              <span *ngIf="otherServiceProductCarts?.length > 0"
                    class="tabs__item" [ngClass]="{'is-active': currentTab === multiCartTabs.SERVICES}"
                    (click)="currentTab = multiCartTabs.SERVICES">
              {{ 'multi-cart.tab-services' | translate }}
                <span>({{ otherServiceProductCarts?.length }})</span>
            </span>
            </div>
            <div class="tabs__panel" [ngClass]="{'is-active': currentTab === multiCartTabs.PARTS}">
              <div class="other-carts" (window:resize)="onResize($event)">
                <div class="create-new-cart" (click)="createNewEmptyCartAndRedirectTo()">
                  <h6>+ {{ 'multi-cart.create-new-cart' | translate }} </h6>
                </div>
                <ng-container *ngFor="let otherCart of otherPartProductCarts |
                  slice:0:(viewAllPartsCarts ? otherPartProductCarts.length : viewItemsForParts)">
                  <app-other-cart
                      [loadingCart]="(switchingCartInProgress && cartIdOfSwitchingCart === otherCart.id)
                      || (isCartOperationInProgress && cartIdOfCartWhichIsPrepareToDelete === otherCart.id)"
                      [cart]="otherCart"
                      [cartImages]="otherCartsImages"
                      (switchCartEmit)="switchDefaultCart($event)"
                      (deleteCartEmit)="deleteOtherCart($event)">
                  </app-other-cart>
                </ng-container>
              </div>
              <div class="action-buttons">
                <button *ngIf="otherPartProductCarts?.length > viewItemsForParts && !viewAllPartsCarts"
                        type="button" class="button button--link icon-arrow-long-right"
                        (click)="viewAllPartsCarts = true">
                  {{ 'multi-cart.button-view-all' | translate }}
                </button>
                <button *ngIf="otherPartProductCarts?.length > viewItemsForParts && viewAllPartsCarts"
                        type="button" class="button button--link icon-arrow-long-left"
                        (click)="viewAllPartsCarts = false">
                  {{ 'multi-cart.button-show-less' | translate }}
                </button>
              </div>
            </div>
            <div class="tabs__panel" [ngClass]="{'is-active': currentTab === multiCartTabs.SERVICES}">
              <div class="other-quotes" (window:resize)="onResize($event)">
                <ng-container *ngFor="let otherQuote of otherServiceProductCarts |
                  slice:0:(viewAllServicesQuotes ? otherServiceProductCarts.length : viewItemsForServices)">
                  <app-other-quote
                      [loadingQuote]="(switchingCartInProgress && cartIdOfSwitchingCart === otherQuote.id)
                      || (isCartOperationInProgress && quoteIdOfQuoteWhichIsPrepareToDelete === otherQuote.id)"
                      [currency]="currency"
                      [quote]="otherQuote"
                      [quoteImages]="otherCartsImages"
                      (switchCartEmit)="switchDefaultCart($event)"
                      (deleteQuoteEmit)="deleteOtherQuote($event)">
                  </app-other-quote>
                </ng-container>
              </div>
              <div class="action-buttons">
                <button *ngIf="otherServiceProductCarts?.length > viewItemsForServices && !viewAllServicesQuotes"
                        type="button" class="button button--link icon-arrow-long-right"
                        (click)="viewAllServicesQuotes = true">
                  {{ 'multi-cart.button-view-all' | translate }}
                </button>
                <button *ngIf="otherServiceProductCarts?.length > viewItemsForServices && viewAllServicesQuotes"
                        type="button" class="button button--link icon-arrow-long-left"
                        (click)="viewAllServicesQuotes = false">
                  {{ 'multi-cart.button-show-less' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #currentCartLoader>
        <div class="multi-cart__current-cart-loader">
          <div class="loading-animation"></div>
        </div>
      </ng-template>
    </div>
  </app-access-control>
  <app-modal-add-cart-name
      *ngIf="currentCart"
      [cartId]="currentCart.id"
      [hasNickname]="hasNickname"
      [newNameChange]="currentCartName$"
      [showAddNicknameModal]="addNicknameModalActive"
      [isContractInCart]="isContractInCart()"
      (closeModal)="addNicknameModalActive = false">
  </app-modal-add-cart-name>
  <app-add-new-wishlist
      [showModal]="addWishlistModalActive"
      (closeModal)="addWishlistModalActive = false">
  </app-add-new-wishlist>
  <app-modal-confirm
      [type]="iconType.WARNING"
      [modalTitle]="'multi-cart.delete-cart-title' | translate"
      [notifyText]="'multi-cart.delete-cart-text' | translate"
      [cancelBtnText]="'multi-cart.cancel-btn' | translate"
      [primaryBtnText]="'multi-cart.delete-btn' | translate"
      [showModalConfirm]="deleteCartModalActive"
      (closeModalConfirm)="deleteCartModalActive = false"
      (primaryActionConfirm)="deleteCart()">
  </app-modal-confirm>
  <app-modal-confirm
      [type]="iconType.WARNING"
      [modalTitle]="'multi-cart.delete-quote-title' | translate"
      [notifyText]="'multi-cart.delete-quote-text' | translate"
      [cancelBtnText]="'multi-cart.cancel-btn' | translate"
      [primaryBtnText]="'multi-cart.delete-btn' | translate"
      [showModalConfirm]="deleteQuoteModalActive"
      (closeModalConfirm)="deleteQuoteModalActive = false"
      (primaryActionConfirm)="deleteQuote()">
  </app-modal-confirm>
  <app-modal-confirm
      [type]="iconType.WARNING"
      [modalTitle]="'multi-cart.remove-configuration-title' | translate"
      [notifyText]="'multi-cart.remove-configuration-text' | translate"
      [cancelBtnText]="'multi-cart.cancel-btn' | translate"
      [primaryBtnText]="'multi-cart.remove-btn' | translate"
      [showModalConfirm]="removeConfigurationModalActive"
      (closeModalConfirm)="removeConfigurationModalActive = false"
      (primaryActionConfirm)="removeQuoteItem()">
  </app-modal-confirm>
</div>
