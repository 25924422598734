<section class="order-details-form">
  <div *ngIf="orderDetailsForm" class="order-details-form-inputs-wrapper">
    @for (input of visibleInputs; track input) {
      @switch (input) {
        @case (EDeliveryInputNames.SELECTED_EQUIPMENT) {
          <app-textfield
              label="{{ 'checkout-delivery-details.selected-equipment' | translate }}"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.selectedEquipment"
              [inputName]="'selectedEquipment'"
              [loading]="loadingCheckoutData"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.SELECTED_EQUIPMENT)"
              [readOnly]="readOnlyInputs.includes(EDeliveryInputNames.SELECTED_EQUIPMENT)">
          </app-textfield>
        }
        @case (EDeliveryInputNames.CUSTOMER) {
          <app-textfield
              *ngIf="isBusinessPartner"
              label="{{ 'checkout-delivery-details.customer' | translate }}"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.customer"
              [inputName]="'customer'"
              [loading]="addressesLoading"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.CUSTOMER)"
              [readOnly]="readOnlyInputs.includes(EDeliveryInputNames.CUSTOMER)">
          </app-textfield>
        }
        @case (EDeliveryInputNames.SOLD_TO_ACCOUNT) {
          <app-textfield
              label="{{ 'checkout-delivery-details.sold-to-account' | translate }}"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.soldToAccount"
              [inputName]="'soldToAccount'"
              [loading]="addressesLoading"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.SOLD_TO_ACCOUNT)"
              [readOnly]="readOnlyInputs.includes(EDeliveryInputNames.SOLD_TO_ACCOUNT)">
          </app-textfield>
          <div *ngIf="isBusinessPartner" class="display-flex justify-content-end">
            <a (click)="showModalReportWrongAddress = true;
                   reportWrongAddressType = EAddressType.SOLD_TO;"
               [attr.data-test]="'button-reportWrongSoldToAddress'"
               [title]="'checkout-delivery-details.report-wrong-address-btn' | translate"
               class="report-wrong-address">
              <span class="icon-arrow-right color-orange"></span>
              <span> {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}</span>
            </a>
          </div>
        }
        @case (EDeliveryInputNames.SHIP_TO_ADDRESS) {
          <app-select-box
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.shipToAddress"
              [options]="availableShipToAddresses"
              [inputName]="'shipToAddress'"
              [loading]="addressesLoading"
              [disabled]="availableShipToAddresses.length < 1"
              [readOnly]="availableShipToAddresses.length <= 1"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.SHIP_TO_ADDRESS)"
              label="{{ 'checkout-delivery-details.ship-to-address' | translate }}"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              [attr.data-test]="'checkout-delivery-details-ship-to-addresses-selectbox'"
              (selected)="selectShipToAddressInForm($event)">
          </app-select-box>
          <div *ngIf="!isBusinessPartner" class="action-buttons-addresses">
            <button type="button"
                    class="button button--secondary"
                    (click)="showAddNewAddressModal()"
                    [attr.data-test]="'button-addNewAddress'"
                    [disabled]="isSaturdayShipment">
              {{ 'checkout-delivery-details.add-custom-address-btn' | translate }}
            </button>
            <button type="button"
                    class="button button--secondary"
                    [disabled]="addressesLoading || orderDetailsForm?.value?.shipToAddress?.value?.isCustom"
                    (click)="showModalReportWrongAddress = true"
                    [attr.data-test]="'button-reportWrongAddress'">
              {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}
            </button>
          </div>
          <div *ngIf="isBusinessPartner" class="display-flex justify-content-end">
            <a (click)="showAddNewAddressModal()"
               [attr.data-test]="'button-changeAddress'"
               [title]="'checkout-delivery-details.change-address-btn' | translate"
               class="report-wrong-address padding-right-05"
               [ngClass]="{'disabled': isSaturdayShipment}">
              <span class="icon-arrow-right color-orange"></span>
              <span> {{ 'checkout-delivery-details.change-address-btn' | translate }}</span>
            </a>
            <a (click)="showModalReportWrongAddress = true;
                   reportWrongAddressType = EAddressType.SHIP_TO;"
               [attr.data-test]="'button-reportWrongShipToAddress'"
               [title]="'checkout-delivery-details.report-wrong-address-btn' | translate"
               class="report-wrong-address">
              <span class="icon-arrow-right color-orange"></span>
              <span> {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}</span>
            </a>
          </div>
        }
        @case (EDeliveryInputNames.BUSINESS_PARTNER_BILL_TO_ADDRESS) {
          <app-select-box
              *ngIf="isBusinessPartner"
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.businessPartnerBillToAddress"
              [options]="availableBillToAddresses"
              [inputName]="'businessPartnerBillToAddress'"
              [loading]="addressesLoading"
              [disabled]="addressesLoading"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.BUSINESS_PARTNER_BILL_TO_ADDRESS)"
              [readOnly]="availableBillToAddresses.length <= 1"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              label="{{ 'checkout-delivery-details.bill-to-address' | translate }}"
              (selected)="selectBillToAddressInForm($event)">
          </app-select-box>
          <a *ngIf="isBusinessPartner" (click)="showModalReportWrongAddress = true;
                   reportWrongAddressType = EAddressType.BILL_TO;"
             [attr.data-test]="'button-reportWrongBillToAddress'"
             [title]="'checkout-delivery-details.report-wrong-address-btn' | translate"
             class="report-wrong-address">
            <span class="icon-arrow-right color-orange icon"></span>
            <span> {{ 'checkout-delivery-details.report-wrong-address-btn' | translate }}</span>
          </a>
          <div class="display-flex"
               *ngIf="availableBillToAddresses?.length<1 && isBusinessPartner && !addressesLoading">
            <div class="padding-right-05">
              <span class="icon-close icon-close__circle"></span>
            </div>
            <div class="text">
              <span [attr.data-test]="'bill-to-address-error-message'">
                {{ 'quote-summary.bill-to-error'| translate }}
              </span>
            </div>
          </div>
        }
        @case (EDeliveryInputNames.ATTENTION_TO) {
          <div class="width-100">
            <app-textfield
                label="{{ 'checkout-delivery-details.attention-to' | translate: {maxNumberOfChars: maxLengthAttentionTo} }}"
                [form]="orderDetailsForm"
                [modelValue]="orderDetailsForm.value.attentionTo"
                [inputName]="'attentionTo'"
                [maxLength]="maxLengthAttentionTo"
                [ngClass]="{'is-invalid': orderDetailsForm.get('attentionTo').touched && orderDetailsForm.get('attentionTo').errors}"
                (fieldFilled)="setContactFormValue($event)"
                [readOnly]="!isUsStore && isSaturdayShipment"
                [isRequired]="requiredInputs.includes(EDeliveryInputNames.ATTENTION_TO)">
            </app-textfield>
            <div class="alert-controls textarea-alert" *ngIf="orderDetailsForm.value.attentionTo">
              <small class="alert-text">
                {{ orderDetailsForm.value.attentionTo.length }}/{{ maxLengthAttentionTo }}
              </small>
            </div>
          </div>
        }
        @case (EDeliveryInputNames.NAME_ROW) {
          <div class="form-row">
            <div class="textfield-with-controls width-50">
              <app-textfield
                  label="{{ 'checkout-delivery-details.select-first-name' | translate }}"
                  [form]="orderDetailsForm"
                  [modelValue]="orderDetailsForm.value.firstName"
                  [inputName]="'firstName'"
                  [isRequired]="requiredInputs.includes(EDeliveryInputNames.FIRST_NAME)"
                  [readOnly]="readOnlyInputs.includes(EDeliveryInputNames.FIRST_NAME)"
                  [ngClass]="{'is-invalid': orderDetailsForm.get('firstName').touched && orderDetailsForm.get('firstName').errors}"
                  (fieldFilled)="setContactFormValue($event)">
              </app-textfield>
              <div class="alert-controls" *ngIf="orderDetailsForm.get('firstName').touched">
                <ng-container *ngIf="orderDetailsForm.get('firstName').hasError('maxlength')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
                  </small>
                </ng-container>
                <ng-container *ngIf="orderDetailsForm.get('firstName').hasError('pattern')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.only-letters' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="textfield-with-controls width-50">
              <app-textfield
                  label="{{ 'checkout-delivery-details.select-last-name' | translate }}"
                  [form]="orderDetailsForm"
                  [modelValue]="orderDetailsForm.value.lastName"
                  [inputName]="'lastName'"
                  [isRequired]="requiredInputs.includes(EDeliveryInputNames.LAST_NAME)"
                  [readOnly]="readOnlyInputs.includes(EDeliveryInputNames.LAST_NAME)"
                  [ngClass]="{'is-invalid': orderDetailsForm.get('lastName').touched && orderDetailsForm.get('lastName').errors}"
                  (fieldFilled)="setContactFormValue($event)">
              </app-textfield>
              <div class="alert-controls" *ngIf="orderDetailsForm.get('lastName').touched">
                <ng-container *ngIf="orderDetailsForm.get('lastName').hasError('maxlength')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
                  </small>
                </ng-container>
                <ng-container *ngIf="orderDetailsForm.get('lastName').hasError('pattern')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.only-letters' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
          </div>
        }
        @case (EDeliveryInputNames.EMAIL) {
          <div class="textfield-with-controls width-100">
            <app-textfield
                label="{{ 'request-details.input-email' | translate }}"
                [inputType]="'email'"
                [form]="orderDetailsForm"
                [modelValue]="orderDetailsForm.value.email"
                [inputName]="'email'"
                [isRequired]="requiredInputs.includes(EDeliveryInputNames.EMAIL)"
                [readOnly]="readOnlyInputs.includes(EDeliveryInputNames.EMAIL)"
                [ngClass]="{'is-invalid': orderDetailsForm.get('email').touched && orderDetailsForm.get('email').errors}"
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="alert-controls" *ngIf="orderDetailsForm.get('email').touched">
              <ng-container *ngIf="orderDetailsForm.get('email').hasError('email')">
                <small class="alert-text">
                  {{ 'request-details.email-pattern' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        }
        @case (EDeliveryInputNames.PHONE) {
          <div class="textfield-with-controls width-100">
            <app-textfield
                label="{{ 'checkout-delivery-details.select-phone-number' | translate }}"
                [form]="orderDetailsForm"
                [modelValue]="orderDetailsForm.value.phone"
                [inputName]="'phone'"
                [isRequired]="requiredInputs.includes(EDeliveryInputNames.PHONE)"
                trim
                [multiCharToTrim]="['-']"
                trimWhitespaces
                [ngClass]="{'is-invalid': orderDetailsForm.get('phone').touched && orderDetailsForm.get('phone').errors}"
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="alert-controls" *ngIf="orderDetailsForm.get('phone').touched">
              <ng-container *ngIf="orderDetailsForm.get('phone').hasError('maxlength')">
                <small class="alert-text">
                  {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthPhone} }}
                </small>
              </ng-container>
              <ng-container *ngIf="orderDetailsForm.get('phone').hasError('pattern')">
                <small class="alert-text">
                  {{ 'checkout-delivery-details.only-numbers' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        }
        @case (EDeliveryInputNames.SHIPMENT_METHOD) {
          <div class="width-100">
            <app-select-box
                label="{{ 'checkout-delivery-details.shipping-method' | translate }}"
                [form]="orderDetailsForm"
                [modelValue]="orderDetailsForm.value.shipmentMethod"
                [options]="editedShipmentMethodOptions"
                [inputName]="'shipmentMethod'"
                [isRequired]="requiredInputs.includes(EDeliveryInputNames.SHIPMENT_METHOD)"
                [ngClass]="{'is-invalid': orderDetailsForm.get('shipmentMethod').errors}"
                [disabled]="shipmentMethodsLoading"
                [loading]="shipmentMethodsLoading"
                (selected)="setShipmentMethod($event)"
                (click)="checkIsShipmentMethodChanged()">
            </app-select-box>
          </div>

          <p class="disclaimer--shipment-method">
            {{ 'checkout-delivery-details.shipment-method-disclaimer' | translate }}
            <a class="button button--link" target="_blank" [routerLink]="['/terms/terms-and-conditions']"
               [attr.data-test]="'link-viewShippingPolicy'">
              <span class="icon-arrow-right color-orange"></span>
              <span class="underline">{{ 'checkout-delivery-details.view-shipping-policy-btn' | translate }}</span>
            </a>
          </p>
        }
        @case (EDeliveryInputNames.DEPARTMENT) {
          <app-select-box
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.department"
              [options]="departments$ | async"
              [inputName]="'department'"
              label="{{ 'checkout-delivery-details.select-department-title' | translate }}"
              [isRequired]="requiredInputs?.includes(EDeliveryInputNames.DEPARTMENT)"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              (selected)="setContactFormValue($event)">
          </app-select-box>
        }
        @case (EDeliveryInputNames.FLOOR_OR_ROOM) {
          <app-textfield
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.floorOrRoom"
              [cssStyles]="{wrapper: 'textfield-with-prefix'}"
              [inputName]="'floorOrRoom'"
              label="{{ 'checkout-delivery-details.select-floor-or-room' | translate }}"
              [isRequired]="requiredInputs?.includes(EDeliveryInputNames.FLOOR_OR_ROOM)"
              (fieldFilled)="setContactFormValue($event)">
          </app-textfield>
        }
        @case (EDeliveryInputNames.COMMENT) {
          <div class="textarea-with-controls width-100">
            <app-textarea
                label="{{ 'checkout-delivery-details.special-shipping-instructions' | translate }}"
                [form]="orderDetailsForm"
                [modelValue]="orderDetailsForm.value.comment"
                [rows]="5"
                [cssStyles]="{wrapper: 'text-area-comment'}"
                [inputName]="'comment'"
                [maxLength]="maxLengthComment"
                [isRequired]="requiredInputs.includes(EDeliveryInputNames.COMMENT)"
                trim
                trimWhitespaces
                [ngClass]="{'is-invalid': orderDetailsForm.get('comment').touched && orderDetailsForm.get('comment').errors}"
                (areaFilled)="setContactFormValue($event)"
                [readOnly]="isSaturdayShipment">
            </app-textarea>
            <p class="disclaimer--shipment-method" *ngIf="isSaturdayShipment">
              {{ 'checkout-delivery-details.saturday-delivery-method-disclaimer' | translate }}
            </p>
            <div class="alert-controls textarea-alert" *ngIf="orderDetailsForm.value.comment">
              <small class="alert-text">
                {{ orderDetailsForm.value.comment.length }}/{{ maxLengthComment }}
              </small>
            </div>
          </div>
        }
        @case (EDeliveryInputNames.DELIVERY_DATE) {
          <app-datepicker
              class="datepicker"
              [form]="orderDetailsForm"
              [minimumDaysFromToday]="minDaysFromToday"
              [modelValue]="orderDetailsForm.value.deliveryDate"
              [dateFormat]="'d/m/Y'"
              [inputName]="'deliveryDate'"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.DELIVERY_DATE)"
              label="{{ 'checkout-delivery-details.select-delivery-date' | translate }}"
              (selected)="setContactFormValue($event)">
          </app-datepicker>
        }
        @case (EDeliveryInputNames.DELIVERY_TIME) {
          <app-select-box
              [form]="orderDetailsForm"
              [modelValue]="orderDetailsForm.value.deliveryTime"
              [options]="deliveryTimes$ | async"
              [inputName]="'deliveryTime'"
              [isRequired]="requiredInputs.includes(EDeliveryInputNames.DELIVERY_TIME)"
              label="{{ 'checkout-delivery-details.select-delivery-time' | translate }}"
              (selected)="setContactFormValue($event)">
          </app-select-box>
        }
      }
    }
  </div>
</section>
@if (isSectionDisplayed) {
  <app-new-delivery-address-modal
      [showModal]="showModalAddNewAddress"
      (closeModal)="showModalAddNewAddress = false"
      (newAddress)="setCustomShipToAddress($event)">
  </app-new-delivery-address-modal>
  @if (!isBusinessPartner) {
    <app-report-wrong-address-modal
        [userLoggedInRoles]="userRoles"
        [roleSpecificMessage]="true"
        [addressesList]="[currentShipToAddress]"
        [cpqOnly]="true"
        [quoteId]="cartId"
        [resetAddressList]="true"
        [cpqLabelName]="'checkout-delivery-details.ship-to-address' | translate"
        [cpqAddressType]="EAddressType.SHIP_TO"
        [functionalLocations]="[systemDetails?.siemensEquipmentId]"
        [showModal]="showModalReportWrongAddress"
        (closeModal)="showModalReportWrongAddress = false">
    </app-report-wrong-address-modal>
  } @else {
    <app-report-wrong-bp-address-modal
        [quoteId]="cartId"
        [addressType]="reportWrongAddressType"
        [functionalLocations]="[systemDetails?.siemensEquipmentId]"
        [showModal]="showModalReportWrongAddress"
        (closeModal)="showModalReportWrongAddress = false; reportWrongAddressType = null"
        [addressValue]="addressValueForReport()"
        [title]="titleForReport()">
    </app-report-wrong-bp-address-modal>
  }
}
