import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InstallBaseFacade } from '../facades/install-base.facade';
import { mergeMap, of } from 'rxjs';
import * as InstallBaseActions from '../actions/install-base.actions';
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators';
import { CustomerActions } from '../actions';
import { CustomerFacade } from '../facades/customer.facade';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { EFeatureToggles, EStoreFeatures } from '../configurations/common';
import { EInstalledBaseWorkflow } from '../models/installedbase.models';

@Injectable({
  providedIn: 'root',
})
export class InstallBaseEffects {

  constructor(
    private actions$: Actions,
    private installBaseFacade: InstallBaseFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  initInstallBaseDataDataAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionSuccess),
      mergeMap(() => this.customerFacade.isBusinessPartner()
        .pipe(
          take(1),
          concatMap(isBp => {
            if (isBp) {
              // Do not trigger loading of IB equipments or their material numbers overview for BP users
              return [];
            } else {
              return [
                InstallBaseActions.InstallBaseSystemsActionStartDataAction(),
                InstallBaseActions.InstallBaseProductsActionStartDataAction(),
                InstallBaseActions.GetInstallBaseSystemsCpqDataActionStart(),
                InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionStart(),
              ];
            }
          }),
        ),
      ),
    );
  });

  GetInstallBaseSystemsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstallBaseActions.InstallBaseSystemsActionStartDataAction),
      mergeMap(
        () => {
          const isCpqAvailable = this.configurationFacade.isFeatureAvailable(EStoreFeatures.CPQ);
          const isSparePartsAvailable = this.configurationFacade.isFeatureAvailable(EStoreFeatures.SPARE_PARTS);
          if (isCpqAvailable || isSparePartsAvailable) {
            // return an action with empty payload to ensure loading flag is canceled in application state
            return of(InstallBaseActions.SuccessGetInstallBaseSystemsDataAction({
              payload: {
                data: [],
                links: {self: ''},
              },
            }));
          } else {
            // "Regular" equipments are only for stores which do not support any "special" workflows
            // such as CPQ or Spare parts
            return this.installBaseFacade.shsEquipmentInstalledBaseSystemsStartLoadingData()
              .pipe(
                map((payload) =>
                  InstallBaseActions.SuccessGetInstallBaseSystemsDataAction({payload}),
                ),
                catchError(error => of(InstallBaseActions.GetInstallBaseSystemsDataActionFailed({
                  error,
                }))),
              );
          }
        },
      ),
    ));

  GetCpqInstallBaseSystemsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstallBaseActions.GetInstallBaseSystemsCpqDataActionStart),
      switchMap(
        () => this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
          take(1),
          switchMap((isCpqEnabled) => {
            if (isCpqEnabled) {
              return this.installBaseFacade.shsEquipmentInstalledBaseSystemsStartLoadingData({
                workflow: EInstalledBaseWorkflow.CPQ,
              }).pipe(
                map((payload) =>
                  InstallBaseActions.GetInstallBaseSystemsCpqDataActionSuccess({payload}),
                ),
                catchError(error => of(InstallBaseActions.GetInstallBaseSystemsCpqDataActionFailed({
                  error,
                }))),
              );
            } else {
              // return an action with empty payload to ensure loading flag is canceled in application state
              return of(InstallBaseActions.GetInstallBaseSystemsCpqDataActionSuccess({
                payload: {
                  data: [],
                  links: {self: ''},
                },
              }));
            }
          }),
        ),
      ),
    ));

  GetSparePartsInstallBaseSystemsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionStart),
      switchMap(
        () => this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
          take(1),
          switchMap((isSparePartsEnabled) => {
            if (isSparePartsEnabled) {
              return this.installBaseFacade.shsEquipmentInstalledBaseSystemsStartLoadingData({
                workflow: EInstalledBaseWorkflow.SPARE_PARTS,
              }).pipe(
                map((payload) =>
                  InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionSuccess({payload}),
                ),
                catchError(error => of(InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionFailed({
                  error,
                }))),
              );
            } else {
              // return an action with empty payload to ensure loading flag is canceled in application state
              return of(InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionSuccess({
                payload: {
                  data: [],
                  links: {self: ''},
                },
              }));
            }
          }),
        ),
      ),
    ));

  GetInstallBaseProductsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstallBaseActions.InstallBaseProductsActionStartDataAction),
      mergeMap(
        () => this.installBaseFacade.installedBaseProductsStartLoadingData()
          .pipe(
            map((product) =>
              InstallBaseActions.SuccessGetInstallBaseProductsDataAction({product}),
            ),
            catchError(error => of(InstallBaseActions.GetInstallBaseProductsDataActionFailed({error}))),
          ),
      ),
    ));
}
