import { createAction, props } from '@ngrx/store';
import { IRfqHistory } from '../models/rfq.models';
import { IAddress, IPointOfContact, ITotals } from '../models/common.models';
import { IApproverData } from '../models/customer.models';
import { ICartVoucher } from '../models/cart.models';

export const UpdateSelectedDeliveryAddress = createAction(
  '[Checkout] - Update  Delivery Address',
  props<{payload: any}>(),
);

export const BeginApproversListAction = createAction(
  '[Checkout] - Begin Get Approver List',
);

export const UpdateCheckoutData = createAction(
  '[Checkout] - Update Checkout Data',
  props<{deliveryAddress: any, pointOfContact: any, approver: any, approverComment: string}>(),
);

export const UpdatePOCData = createAction(
  '[Checkout] - Update Checkout Data',
  props<{payload: any}>(),
);

export const SuccessGetApproversListAction = createAction(
  '[Checkout] - Success Get Approvers List',
  props<{payload: any}>(),
);

export const ErrorGetApproversListAction = createAction(
  '[Checkout] - Error Get Approvers List Action',
  props<Error>(),
);

export const PostCheckoutData = createAction(
  '[Checkout] - Begin Post Checkout Data)',
  props<{postCheckoutData: any, cartId: string}>(),
);

export const SuccessPostCheckoutDataAction = createAction(
  '[Checkout] - Success Post Checkout Data',
  props<{responseFromPostCheckout: any}>(),
);

export const GetQuoteRfqHistoryStartDataAction = createAction(
  '[Checkout-quote-history] - Start get quote rfq history Action',
);

export const SuccessGetQuoteRfqHistoryDataAction = createAction(
  '[Checkout-quote-history] - Success Get quote rfq history Action',
  props<{rfqBaseInfo: IRfqHistory}>(),
);

export const setSapPoNumber = createAction(
  '[Customer] - Set sap PO number',
  props<{sapPoNumber: string}>(),
);

export const setOrderEmailCopy = createAction(
  '[Customer] - Set order email copy',
  props<{orderEmailCopy: string}>(),
);

export const clearPurchaseOrderNumber = createAction(
  '[Customer] - Clear purchase order number',
);

export const clearOrderEmailCopy = createAction(
  '[Customer] - Clear order email copy',
);

export const setLoadingDeliveryDetailsData = createAction(
  '[Checkout] - Set loading delivery details data',
  props<{loadingDeliveryDetailsData: boolean}>(),
);

export const setAvailableShipToAddresses = createAction(
  '[Checkout] - Set available shipTo addresses',
  props<{availableShipToAddresses: IAddress[]}>(),
);

export const addShipToAddressToAvailableShipToAddresses = createAction(
  '[Checkout] - Add shipTo address to available shipTo addresses',
  props<{shipToAddress: IAddress}>(),
);

export const setPreselectedShipToAddress = createAction(
  '[Checkout] - Set preselected shipTo address',
  props<{preselectedShipToAddress: IAddress}>(),
);

export const setAvailableBillToAddresses = createAction(
  '[Checkout] - Set available billTo addresses',
  props<{availableBillToAddresses: IAddress[]}>(),
);

export const setPreselectedBillToAddress = createAction(
  '[Checkout] - Set preselected billTo address',
  props<{preselectedBillToAddress: IAddress}>(),
);

export const setDeliveryDetailsFormData = createAction(
  '[Checkout] - Set delivery details form data',
  props<{deliveryDetailsFormData: IPointOfContact}>(),
);

export const setDeliveryDetailsApproverFormData = createAction(
  '[Checkout] - Set delivery details approver form data',
  props<{deliveryDetailsApproverFormData: IApproverData}>(),
);

export const resetDeliveryDetailsFormData = createAction(
  '[Checkout] - Reset delivery details form data',
);

export const resetDeliveryDetailsApproverFormData = createAction(
  '[Checkout] - Reset delivery details approver form data',
);

export const setTotalPrices = createAction(
  '[Checkout] - Set delivery details total prices',
  props<{totalPrices: ITotals}>(),
);

export const setTotalPricesLoading = createAction(
  '[Checkout] - Set delivery details total prices loading',
  props<{totalPricesLoading: boolean}>(),
);

export const setCartVouchers = createAction(
  '[Checkout] - Set checkout cart vouchers list',
  props<{cartVouchers: ICartVoucher[]}>(),
);
