import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-information-notification',
  templateUrl: './product-information-notification.component.html',
  styleUrls: ['./product-information-notification.component.scss'],
})
export class ProductInformationNotificationComponent {

  @Input() margin: boolean = true;
}
