<ng-container *ngIf="(customerState$ | async) as customerStateData">
  <h4 *ngIf="customerStateData.isGuest || customerStateData.isViewer"
      class="page-title" [attr.data-test]="'title-wishlists'">
    {{ 'wishlists.title' | translate }}
  </h4>
</ng-container>
<app-access-control [notForViewer]="true" [description]="true">
  <ng-container ngProjectAs="[slot][nonViewerContent]">
    <div class="wishlists">
      <div class="wishlists__top">
        <h3 [attr.data-test]="'desc-title-wishlists'">{{ 'wishlists.title' | translate }}</h3>
        <button class="button" (click)="addNewModalActive = true" [attr.data-test]="'button-wishlistsAddNew'">
          <span class="btn-add__icon icon-plus"></span>
          {{ 'wishlists.add-new' | translate }}
        </button>
      </div>
      <div [appLoadingAnimation]="!loaded">
        <ng-container *ngIf="wishlists?.length > 0; else emptyList">
          <div class="wishlists__sort">
            <app-sort-by [sort]="sortOptions" [current]="currentSort" (selected)="sort($event)"></app-sort-by>
          </div>
          <ul class="wishlists__list">
            <li class="wishlist" *ngFor="let wishlist of wishlists" [attr.data-test]="'wishlist-' + wishlist.attributes.name">
              <a class="wishlist__container" [routerLink]="['/wishlists/', wishlist.id ]">
                <span class="wishlist__title">
                <span class="wishlist__name" [attr.data-test]="'desc-wishlistsName'">{{ wishlist.attributes.name }}</span>
                <span class="wishlist__time" [attr.data-test]="'desc-wishlistsLastUpdate'">{{ wishlist.attributes.updatedAt | formatDate }}
                  ({{ 'wishlists.last-time' | translate }})
                </span>
              </span>
                <span class="wishlist__qty" [attr.data-test]="'desc-wishlistsQty'">
                <ng-container *ngIf="wishlist.attributes.numberOfItems === 1; else multiProduct">
                  {{ 'wishlists.single-product' | translate }}
                </ng-container>
                <ng-template #multiProduct>
                  {{ 'wishlists.multi-product' | translate: {number: wishlist.attributes.numberOfItems || 0 } }}
                </ng-template>
              </span>
              </a>
              <div class="wishlist__menu">
                <span class="icon-close wishlist__remove" (click)="remove(wishlist)" [attr.data-test]="'icon-wishlistsRemove'"></span>
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-template #emptyList>
          <div class="wishlists__empty">
            {{ 'wishlists.create-new' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
    <app-add-new-wishlist
        [showModal]="addNewModalActive"
        (closeModal)="addNewModalActive = false"
        (created)="wishlistCreated($event)">
    </app-add-new-wishlist>
    <app-wishlist-remove
        [wishlist]="wishlistToRemove"
        [showModal]="removeModalActive"
        (closeModal)="removeModalActive = false"
        (removed)="wishlistRemoved()">
    </app-wishlist-remove>
  </ng-container>
</app-access-control>
