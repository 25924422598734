import { Injectable } from '@angular/core';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import { AppActions, CheckoutActions, CpqActions, CustomerActions } from '../actions';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { MarketingFacade } from '../facades/marketing.facade';
import { CheckoutFacade } from '../facades/checkout.facade';
import { CpqFacade } from '../facades/cpq.facade';
import * as OrderApproveActions from '../actions/order-approve.actions';
import { CustomerFacade } from '../facades/customer.facade';
import { LocalStorageUtils } from '../utils/localStorage.utils';
import { I18nService } from '../services';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private configurationFacade: ConfigurationFacade,
    private cpqFacade: CpqFacade,
    private marketingFacade: MarketingFacade,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
    private router: Router,
    private i18nService: I18nService,
  ) {
  }

  GetCompanyRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionStart),
      mergeMap(
        () => this.customerFacade.getCompanyUsers()
          .pipe(
            map(data => {
              return CustomerActions.CompanyUsersActionSuccess({payload: data});
            }),
            catchError(error => of(CustomerActions.CompanyUsersActionError(error)),
            ),
          ),
      ),
    ),
  );

  GetApprovers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.BeginApproversListAction),
      mergeMap(
        () => this.checkoutFacade.getCompanyUsers()
          .pipe(
            map(data => {
              return CheckoutActions.SuccessGetApproversListAction({payload: data});
            }),
            catchError(error => of(CheckoutActions.ErrorGetApproversListAction(error))),
          ),
      ),
    ),
  );
  // TODO: In #111904 consider if this needs to be removed, as this is basically dead code
  // addVoucher$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(OrderApproveActions.addVoucherToCart),
  //     exhaustMap(action => this.marketingFacade.postVoucher(action.cartId, action.voucherData)),
  //     catchError((error) => {
  //       return of({
  //         type: 'Add voucher to cart error',
  //         payload: {error},
  //       });
  //     }),
  //     mergeMap((latestAddedVoucherItem) => [
  //       OrderApproveActions.addVoucherToCartSuccess({latestAddedVoucher: latestAddedVoucherItem}),
  //     ]));
  // });
  //
  // removeVoucher$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(OrderApproveActions.removeVoucherFromCart),
  //     exhaustMap(action => this.marketingFacade.removeVoucher(action.cartId, action.voucherCode)),
  //     catchError((error) => {
  //       return of({
  //         type: 'Remove voucher to cart error',
  //         payload: {error},
  //       });
  //     }),
  //     mergeMap((latestRemovedVoucherItem) => [
  //       OrderApproveActions.removeVoucherFromCartSuccess({latestRemovedVoucher: latestRemovedVoucherItem}),
  //     ]));
  // });

  loadCartItemsForOrderApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderApproveActions.loadCartItems),
      mergeMap(action => {
        if (action.cartId === '') {
          return this.configurationFacade.getTranslationByKey('error.valid-cart-id').pipe(
            map(message =>
              OrderApproveActions.loadCartItemsFail({
                category: 'error',
                message,
                action: OrderApproveActions.loadCartItemsReset.type,
              }),
            ),
          );
        } else {
          return this.marketingFacade.getCartItems(action.cartId)
            .pipe(
              map(cartItems => OrderApproveActions.loadCartItemsSuccess({cartItems})),
              catchError(error => of(OrderApproveActions.loadCartItemsFail({
                category: 'error',
                message: error,
                action: OrderApproveActions.loadCartItemsReset.type,
              }))),
            );
        }
      }));
  });

  loadCartItemsForOrderApproveFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderApproveActions.loadCartItemsFail),
      map(error =>
        AppActions.setAlert({
          alert: {
            type: error.category,
            message: error.message,
            action: error.action,
          },
        }),
      ));
  });

  loadCustomerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.loadCustomerData),
      mergeMap(
        () => this.customerFacade.getCompanyUsers()
          .pipe(
            map(data => CustomerActions.CompanyUsersActionSuccess({payload: data})),
            catchError(error => of(CustomerActions.loadCustomerDataError({error}))),
          ),
      ),
    );
  });

  redirectToUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.redirectToUrl),
      exhaustMap((action) => {
        if (!action?.url?.length) {
          return of(AppActions.redirectToUrlSkipped());
        }
        return of(AppActions.redirectToUrlExecuted({url: action.url, replaceUrl: action.replaceUrl}));
      }));
  });

  redirectToUrlExecuted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.redirectToUrlExecuted),
        tap(({url, replaceUrl}) => this.router.navigateByUrl(url, {replaceUrl})),
      ),
    {dispatch: false},
  );

  redirectToLastPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.redirectToLastPage),
      exhaustMap(() => {
        const homepageUrl = `/${this.i18nService.getCurrentLocale()}`;
        const previousPage = LocalStorageUtils.getKeyValue('previousPage') ?? homepageUrl;
        const currentRoute = this.router.url;
        const url = previousPage !== currentRoute ? previousPage : homepageUrl;
        return of(AppActions.redirectToUrl({url, replaceUrl: true}));
      }));
  });

  redirectToHomepage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.redirectToHomepage, CpqActions.serviceConfigPageRedirectWhenCartEmpty),
      exhaustMap(() => {
        const url = `/${this.i18nService.getCurrentLocale()}`;
        return of(AppActions.redirectToUrl({url, replaceUrl: true}));
      }));
  });

  redirectToShopCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.redirectToShopCart),
      exhaustMap(() => {
        const url = `/${this.i18nService.getCurrentLocale()}/shop-cart`;
        return of(AppActions.redirectToUrl({url, replaceUrl: true}));
      }));
  });
}
