import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CatalogFacade } from '../facades/catalog.facade';
import { CpqActions } from '../actions';
import { PayloadUtils } from '../utils/payload.utils';
import * as ShopCartActions from '../actions/shop-cart.actions';
import * as AppActions from '../actions/app.actions';

@Injectable()
export class CpqEffects {
  constructor(
    private actions$: Actions,
    private catalogFacade: CatalogFacade,
  ) {
  }

  loadSelectedInstalledBase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.loadSelectedInstalledBase),
      mergeMap(action =>
        this.catalogFacade.getShsEquipmentInstalledBaseSystemByFunctionalLocation(
          action.materialNumber, action.flNumber,
        ).pipe(
          map(
            result => {
              return CpqActions.setSelectedInstalledBase({selectedInstalledBase: result.data[0]?.attributes});
            }),
          catchError(error => of(CpqActions.loadDataFailed({error}))),
        ),
      ),
    );
  });

  loadSelectedSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.loadSelectedSystem),
      mergeMap(action =>
        this.catalogFacade.getAbstractProductDataFromApi(action.sku).pipe(
          map(
            result => {
              const systemToStore = PayloadUtils.getItemsWithDetailsFromInclude(result.included, false, [
                'install-base',
                'abstract-product-image-sets',
                'concrete-products',
              ], 'concrete-products');
              return CpqActions.setSelectedSystem({selectedSystem: systemToStore.length === 0 ? null : systemToStore[0]});
            }),
          catchError(error => of(CpqActions.loadDataFailed({error}))),
        ),
      ),
    );
  });

  quoteSummarySubmittedOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.quoteSummaryPageActionAfterSubmittedOrder),
      map(() =>
        ShopCartActions.createEmptyCart({}),
      ),
    );
  });

  redirectToThankYouPageAfterOrderSubmit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.quoteSummaryPageActionAfterSubmittedOrder),
      map(() =>
        AppActions.redirectToUrl({url: '/thank-you-for-quote', replaceUrl: true}),
      ),
    );
  });

  thankYouPageStartNewQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.thankYouPageStartNewQuote),
      map(() =>
        AppActions.redirectToUrl({url: '/my-installed-base', replaceUrl: true}),
      ),
    );
  });

  thankYouPageViewPurchaseActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.thankYouPageViewPurchaseActivity),
      map(() =>
        AppActions.redirectToUrl({url: '/purchase-activity', replaceUrl: true}),
      ),
    );
  });

  clearLastSubmittedOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.thankYouPageStartNewQuote, CpqActions.thankYouPageViewPurchaseActivity),
      map(() =>
        CpqActions.thankYouPageClearLastSubmittedOrder(),
      ),
    );
  });

  thankYouPageRedirectToLastPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.thankYouPageRedirectToLastPage),
      map(() =>
        AppActions.redirectToLastPage(),
      ),
    );
  });

  serviceConfigPageShowFLAddedNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CpqActions.serviceConfigPageShowFLAddedNotification),
      mergeMap((action) => {
        if (action.quantity > 1) {
          return of(AppActions.appendNotification({
              notification: {
                type: 'success',
                title: 'service-configuration.another-configuration-added-notification',
                messages: [{
                  key: '',
                  params: '',
                  css: [],
                }],
              },
            },
          ));
        } else {
          return of(AppActions.appendNotification({
              notification: {
                type: 'success',
                title: 'service-configuration.configuration-added',
                messages: [{
                  key: 'service-configuration.quote-added-notification',
                  params: {name: action.cartId},
                  css: ['message'],
                }],
              },
            },
          ));
        }
      }),
    );
  });
}
