import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { CustomerFacade } from '../../../facades/customer.facade';
import { IAddress, ISelectEvent } from '../../../models/common.models';
import {
  IAddressReduced,
  ICustomerPreferences,
  ICustomerPreferencesExtended,
  IPreferenceSettings,
} from '../../../models/customer.models';
import { IconType } from '../../../models/settings.model';
import { EFeatureToggles, EUserRoles } from '../../../configurations/common';
import { AppUtils } from '../../../utils/app.utils';
import { InstallBaseFacade } from '../../../facades/install-base.facade';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() customerId: string;
  @Input() userRoles: EUserRoles[];
  @Output() isFormPristineEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resetAddressesList: boolean = false;

  iconType = IconType;
  isFormPristine: boolean = true;
  formSubmitted: boolean = false;
  formSubmittedSuccessfully: boolean;
  updatingDataInProgress: boolean = false;
  customerPreferences: ICustomerPreferences;
  customerPreferencesExtended: ICustomerPreferencesExtended;
  customerPreferencesForm: UntypedFormGroup;
  showDeleteModal: boolean = false;
  showReportWrongAddressModal: boolean = false;
  addressesLoading: boolean = false;
  isCpqEnabled: boolean;
  isBusinessPartner$: Observable<boolean> = new Observable<boolean>();
  isSapStore: boolean = false;
  businessAddresses: IAddress[] | IAddressReduced[] = [];
  defaultBusinessAddresses: IAddress[] | IAddressReduced[] = [];
  preferredPaymentMethodToggle: boolean = false;
  selectedPreferredAddress: IAddress | IAddressReduced;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    private installBaseFacade: InstallBaseFacade,
  ) {
  }

  ngOnInit(): void {
    this.isSapStore = AppUtils.isSapStore();
    this.isBusinessPartner$ = this.customerFacade.isBusinessPartner();

    if (!this.isSapStore) {
      this.customerFacade.beginGetBusinessUnitsAction();
      this.selectBusinessAddresses();
    }

    this.selectCPQFeatures();
    this.showPreferredPayment();
    this.getPreferredShipToAddress();
  }

  selectCPQFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectCustomerPreferences();
    this.updateCustomerPreferencesExtendedObject();
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  formChange(): void {
    this.formSubmitted = false;
    this.isFormPristine = this.customerPreferencesForm.pristine;
    this.isFormPristineEmit.emit(this.isFormPristine);
  }

  setFormValue(event: ISelectEvent): void {
    this.customerPreferencesForm.patchValue({
      [event.key]: event.value,
    });

    this.formSubmitted = false;
    this.isFormPristine = false;
    this.isFormPristineEmit.emit(false);
  }

  submitForm(): void {
    this.updatingDataInProgress = true;
    this.isFormPristine = true;
    this.isFormPristineEmit.emit(true);
    this.customerPreferencesForm.markAsPristine();

    const formData = {
      data: {
        type: 'customer-preferences',
        attributes: {
          preferredPayment: {
            'preferred_payment.method': {
              selected: this.customerPreferencesForm.value['preferred_payment.method'],
            },
          },
          notifications: {
            'notification.order_confirmation': {
              selected: this.customerPreferencesForm.value['notification.order_confirmation'],
            },
            'notification.shipment_notification': {
              selected: this.customerPreferencesForm.value['notification.shipment_notification'],
            },
            'notification.status_update': {
              selected: this.customerPreferencesForm.value['notification.status_update'],
            },
            'notification.reminder': {
              selected: this.customerPreferencesForm.value['notification.reminder'],
            },
          },
          preferredShipToId: this.customerPreferences.preferredShipToId,
        },
      },
    };

    this.customerFacade.saveCustomerSettingPreferences(this.customerId, formData).subscribe({
      next: response => {
        const responseData = response.data.attributes;

        this.formSubmittedSuccessfully = !!(responseData.preferredPayment && responseData.notifications);
        if (this.formSubmittedSuccessfully) {
          this.customerPreferences = responseData;
          this.customerFacade.updateCustomerPreferences(this.customerPreferences);
        }

        this.updatingDataInProgress = false;
      },
      error: () => {
        this.formSubmittedSuccessfully = false;
        this.updatingDataInProgress = false;
      },
      complete: () => this.formSubmitted = true,
    });
  }

  deleteAccount(): void {
    this.showDeleteModal = false;

    this.customerFacade.deleteCustomerAccount(this.customerId).subscribe({
      complete: () => {
        this.configurationFacade.appendNotification({
          type: 'success',
          title: 'my-profile.notification.delete-account-title',
          messages: [{
            key: 'my-profile.notification.delete-account-text',
          }],
        });
      },
    });
  }

  private updateCustomerPreferencesExtendedObject(): void {
    Object.keys(this.customerPreferences).forEach(preferenceKey => {
      if (preferenceKey !== 'preferredShipToId') {
        let customerPreference = this.customerPreferences[preferenceKey];
        let newCustomerPreference = Object.keys(customerPreference).map(key => ({
          id: key,
          label: customerPreference[key].label,
          preferences:
            Object.keys(customerPreference[key].options).map(optionKey => ({
              id: this.createUniquePreferenceId(optionKey, key),
              key: optionKey,
              value: customerPreference[key].options[optionKey],
            })),
          default: customerPreference[key].default,
          selected: customerPreference[key].selected,
        }));
        if (!this.isCpqEnabled) {
          newCustomerPreference = newCustomerPreference.filter(
            preference => preference.id !== 'notification.status_update'
              && preference.id !== 'notification.reminder');
        }

        this.customerPreferencesExtended = {
          [preferenceKey]: [...newCustomerPreference],
          ...this.customerPreferencesExtended,
        };
      }
    });
  }

  private initializeForm(): void {
    this.customerPreferencesForm = this.formBuilder.group({
      userRoles: [''],
    });

    this.customerPreferencesForm.patchValue({
      userRoles: this.userRoles,
    });

    this.addCustomerPreferencesFormControls();
  }

  private addCustomerPreferencesFormControls(): void {
    this.customerPreferencesExtended.preferredPayment.forEach((preferredPayment: IPreferenceSettings) => {
      this.customerPreferencesForm.addControl(
        preferredPayment.id,
        this.formBuilder.control(''),
      );

      this.customerPreferencesForm.controls[preferredPayment.id].patchValue(
        this.getPreferenceSelected(preferredPayment),
      );
    });

    this.customerPreferencesExtended.notifications.forEach((notification: IPreferenceSettings) => {
      this.customerPreferencesForm.addControl(
        notification.id,
        this.formBuilder.control(''),
      );

      this.customerPreferencesForm.controls[notification.id].patchValue(
        this.getPreferenceSelected(notification),
      );
    });
  }

  private createUniquePreferenceId(optionKey: string, preferenceKey: string): string {
    const optionKeyChunks: string[] = optionKey.split('.');

    return optionKey.replace(optionKeyChunks[0], preferenceKey);
  }

  private getPreferenceSelected(node: IPreferenceSettings): string {
    return node.selected || node.default;
  }

  private selectCustomerPreferences(): void {
    this.customerFacade.selectCustomerPreferences().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(customerPreferences => {
      if (customerPreferences) {
        this.customerPreferences = customerPreferences;
      }
    });
  }

  /**
   * Method for displaying report wrong address modal (only if addresses are loaded)
   */
  showModalReportWrongAddress(): void {
    if (!this.addressesLoading && !this.isSelectedCustomAddress()) {
      this.showReportWrongAddressModal = true;
    }
  }

  /**
   * Get all addresses from equipments.
   *
   * @private
   */
  private selectBusinessAddresses(): void {
    this.addressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: addresses => {
        if (addresses) {
          this.defaultBusinessAddresses = addresses;
          this.businessAddresses = addresses;
          this.addressesLoading = false;
        }
      },
      error: () => {
        this.addressesLoading = false;
      },
    });
  }

  /**
   * Show preferred payment input based on value of EFeatureToggles.PREFERRED_PAYMENT.
   *
   * @returns {void}
   */
  showPreferredPayment(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PREFERRED_PAYMENT).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: boolean) => {
      this.preferredPaymentMethodToggle = value;
    });
  }

  /**
   * Get preferred ship to address only when preferredShipToId from customer preferences is business or install
   * base address.
   */
  getPreferredShipToAddress(): void {
    combineLatest([
      this.customerFacade.selectBusinessAddress(),
      this.installBaseFacade.selectInstalledBaseAddresses(),
    ]).pipe(
      filter(([businessAddresses, installBaseAddresses]) =>
        Array.isArray(businessAddresses) || Array.isArray(installBaseAddresses)),
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: ([businessAddresses, installBaseAddresses]) => {
        this.selectedPreferredAddress = businessAddresses.find(
          (address: IAddress) => address.id === this.customerPreferences.preferredShipToId,
        ) ?? installBaseAddresses.find(
          (address: IAddress) => address.sapId === this.customerPreferences.preferredShipToId,
        );
      },
    });
  }

  /**
   * Custom address is selected, when "selectedPreferredAddress" variable is empty (it is not possible to find this
   * address among business and install base addresses).
   * If SAP store, method automatically returns false for html component functionality
   *
   * @returns {boolean}
   */
  isSelectedCustomAddress(): boolean {
    return this.isSapStore ? false : Boolean(!this.selectedPreferredAddress &&
      this.customerPreferences.preferredShipToId);
  }

  /**
   * Return preferred business address if exists, otherwise return the default
   * business address.
   * Set resetAddressesList as true for Report Wrong Address modal.
   *
   * @returns {IAddress[]|IAddressReduced[]}
   */
  getAddressesListReportWrongAddress(): IAddress[] | IAddressReduced[] {
    if (this.isSapStore) {
      return this.businessAddresses;
    } else {
      this.resetAddressesList = true;
      return this.selectedPreferredAddress ? [this.selectedPreferredAddress] : this.defaultBusinessAddresses;
    }
  }
}
