<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-sm-12 col-lg-1 hidden-sm"></div>
      <div class="col-12 col-lg-10">
        <h3 class="checkout-label" [attr.data-test]="'title-2ndDetailsPage'">
          {{ 'checkout-delivery-details.title' | translate }}
        </h3>
        <h6 [attr.data-test]="'subtitle-2ndDetailsPage'">
          {{ getSecondPageTitle() | translate }}
        </h6>
        <br>
        <div class="row">
          <app-select-box
              class="col"
              [form]="contactForm"
              [modelValue]="contactForm.value.department"
              [options]="departments"
              [inputName]="'department'"
              label="{{ 'checkout-delivery-details.select-department-title' | translate }}"
              [isRequired]="true"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              (selected)="setContactFormValue($event)">
          </app-select-box>
        </div>
        <div class="row">
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.firstName"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-firstname'}"
                [inputName]="'firstName'"
                label="{{ 'checkout-delivery-details.select-first-name' | translate }}"
                [isRequired]="true"
                [ngClass]="{'is-invalid': contactForm.get('firstName').errors && contactForm.get('firstName').touched}"
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('firstName').touched &&
                                    contactForm.get('firstName').hasError('maxlength')">
                <small class="textfield__hint">
                  {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
                </small>
              </ng-container>
              <ng-container
                  *ngIf="contactForm.get('firstName').hasError('pattern') &&
                                    contactForm.get('firstName').touched">
                <small class="textfield__hint">
                  {{ 'checkout-delivery-details.only-letters' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.lastName"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-lastname'}"
                [inputName]="'lastName'"
                label="{{ 'checkout-delivery-details.select-last-name' | translate }}"
                [isRequired]="true"
                [ngClass]="{'is-invalid': contactForm.get('lastName').errors && contactForm.get('lastName').touched}"
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('lastName').touched &&
                                    contactForm.get('lastName').hasError('maxlength')">
                <small class="textfield__hint">
                  {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
                </small>
              </ng-container>
              <ng-container
                  *ngIf="contactForm.get('lastName').hasError('pattern') && contactForm.get('lastName').touched">
                <small class="textfield__hint">
                  {{ 'checkout-delivery-details.only-letters' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="checkout__field col">
            <app-textfield
                class="checkout__textfield col"
                [inputType]="'email'"
                [form]="contactForm"
                [modelValue]="contactForm.value.email"
                [cssStyles]="{wrapper: 'email-with-prefix email-telephone'}"
                [inputName]="'email'"
                label="{{ 'request-details.input-email' | translate }}"
                [isRequired]="true"
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('email')">
                <small class="textfield__hint">
                  {{ 'request-details.email-pattern' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.phone"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-telephone'}"
                [inputName]="'phone'"
                label="{{ 'checkout-delivery-details.select-phone-number' | translate }}"
                [isRequired]="true"
                trim
                [multiCharToTrim]="['-']"
                trimWhitespaces
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('phone').touched &&
                                    contactForm.get('phone').hasError('maxlength')">
                <small class="textfield__hint">
                  {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthPhone} }}
                </small>
              </ng-container>
              <ng-container
                  *ngIf="contactForm.get('phone').hasError('pattern')">
                <small class="textfield__hint">
                  {{ 'checkout-delivery-details.only-numbers' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="!isAuStore; else auStoreShipmentMethod">
            <ng-container *ngIf="isJpStore; else otherStores">
              <app-datepicker
                  class="datepicker col-12 col-md-6"
                  [form]="contactForm"
                  [minimumDaysFromToday]="minDaysFromToday"
                  [modelValue]="contactForm.value.deliveryDate"
                  [dateFormat]="'d/m/Y'"
                  [inputName]="'deliveryDate'"
                  label="{{ 'checkout-delivery-details.select-delivery-date' | translate }}"
                  (selected)="setContactFormValue($event)">
              </app-datepicker>
            </ng-container>
            <ng-template #otherStores>
              <app-select-box
                  class="col-12 col-md-6"
                  [form]="contactForm"
                  [modelValue]="contactForm.value.deliveryTime"
                  [options]="deliveryTimes"
                  [inputName]="'deliveryTime'"
                  label="{{ 'checkout-delivery-details.select-delivery-time' | translate }}"
                  (selected)="setContactFormValue($event)">
              </app-select-box>
            </ng-template>
          </ng-container>
          <ng-template #auStoreShipmentMethod>
            <app-select-box
                class="col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.shipmentMethod"
                [options]="shipmentMethods"
                [inputName]="'shipmentMethod'"
                [isRequired]="true"
                [disabled]="shipmentMethodsLoading"
                [loading]="shipmentMethodsLoading"
                label="{{ 'checkout-delivery-details.shipping-method' | translate }}"
                (selected)="setShipmentMethod($event)">
            </app-select-box>
          </ng-template>
        </div>
        <div class="row shipping-disclaimer col" *ngIf="isAuStore">
          {{ 'checkout-delivery-details.shipping-disclaimer' | translate }}
        </div>
        <div class="row">
          <app-textarea
              class="col-12"
              [form]="contactForm"
              [modelValue]="contactForm.value.comment"
              [rows]="3"
              [cssStyles]="{wrapper: 'text-area-comment'}"
              [inputName]="'comment'"
              [maxLength]="maxLengthComment"
              [isRequired]="isJapanStore()"
              label="{{ 'checkout-delivery-details.comment' | translate }}"
              trim
              trimWhitespaces
              (areaFilled)="setContactFormValue($event)">
          </app-textarea>
          <div class="checkout__alerts">
            <ng-container *ngIf="contactForm.value.comment">
              <small class="textfield__hint">
                {{ contactForm.value.comment.length }}/{{ maxLengthComment }}
              </small>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="!hasApproverAccess">
          <h6>{{ 'checkout-delivery-details.approver-data-title' | translate }}</h6>
          <br>
          <div class="row">
            <app-select-box
                class="col-12 col-md-6"
                [form]="approverForm"
                [modelValue]="approverForm.value.approver"
                [options]="approversNameList"
                [loading]="approversNameListLoading"
                [inputName]="'approver'"
                label="{{ 'checkout-delivery-details.select-approver' | translate }}"
                [isRequired]="true"
                (selected)="selectApprover($event)">
            </app-select-box>
          </div>
          <div class="row">
            <app-textarea
                class="col-12"
                [form]="approverForm"
                [modelValue]="approverForm.value.commentForApprover"
                [rows]="3"
                [cssStyles]="{wrapper: 'text-area-comment'}"
                [inputName]="'commentForApprover'"
                [maxLength]="maxLengthComment"
                label="{{ 'checkout-delivery-details.comment' | translate }}"
                trim
                trimWhitespaces
                (areaFilled)="setApproverFormValue($event)">
            </app-textarea>
            <div class="checkout__alerts">
              <ng-container *ngIf="approverForm.value.commentForApprover">
                <small class="textfield__hint">
                  {{ approverForm.value.commentForApprover.length }}/{{ maxLengthComment }}
                </small>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="row voucher-form" *ngIf="isJpStore">
          <ng-container *ngIf="!voucherButtonIsClicked">
            <div class="col-12">
              <button class="button"
                      (click)="showVoucher()"
                      [attr.data-test]="'button-addCode'">
                {{ 'order-approve.add-promotional-code' | translate }}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="voucherButtonIsClicked">
            <div class="col-12">
              <label [attr.data-test]="'label-addVoucher'">
                <strong>{{ 'order-approve.insert-voucher' | translate }}</strong>
              </label>
            </div>
            <div class="checkout__field col-12 col-md-6">
              <app-textfield
                  class="checkout__textfield"
                  [form]="contactForm"
                  [modelValue]="contactForm.value.promotionCode"
                  [markInvalid]="voucherHasError && !voucherListLoaded"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-promotionCode'}"
                  [inputName]="'promotionCode'"
                  label="{{ 'order-approve.code' | translate }}"
                  [readOnly]="addVoucherInProgress"
                  (fieldFilled)="resetVoucherField()"
                  (keydown.enter)="!isVoucherBtnDisabled() && addVoucher()">
              </app-textfield>
              <div class="checkout__alerts">
                <ng-container
                    *ngIf="contactForm.get('promotionCode').touched &&
                                  (this.voucherHasError && !this.voucherListLoaded)">
                  <small class="textfield__hint" [attr.data-test]="'hint-invalidCode'">
                    {{ 'order-approve.invalid-code' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="col-3">
              <button
                  type="button"
                  class="button button add-voucher__btn"
                  [ngClass]="{'in-progress': addVoucherInProgress}"
                  (click)="addVoucher()"
                  [disabled]="isVoucherBtnDisabled()"
                  [attr.data-test]="'button-applyVoucher'">
                <ng-container *ngIf="addVoucherInProgress; else applyMsg">
                  <span class="loading-animation"></span>
                </ng-container>
                <ng-template #applyMsg>
                  <span>{{ 'order-approve.apply-voucher' | translate }}</span>
                </ng-template>
              </button>
            </div>
          </ng-container>
        </div>
        <span class="label">{{ 'checkout-delivery-details.mandatory-fields-required' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="container order-summary col-12 col-lg-4">
    <div class="row">
      <div class="col-12">
        <p class="approval-notice" [attr.data-test]="'desc-approvalNotice'">
          <span *ngIf="hasApproverAccess">
            {{ 'checkout-delivery-details.approver-checkout-title' | translate }}
          </span>
          <span *ngIf="!hasApproverAccess">
            {{ 'checkout-delivery-details.approver-notice'
              | translate:{name: selectedApprover ? selectedApprover.name : ''} }}
          </span>
        </p>
        <button
            *ngIf="!creatingOrderInProgress; else loadingSpinner"
            appDebounceClick
            class="button button--primary button-create-order"
            type="button"
            [disabled]="isProceedToCreateOrderBtnDisabled()"
            [debounceTime]="300"
            (debounceClick)="proceedToCreateOrder()"
            [attr.data-test]="'button-proceedToCreateOrder'">
          <span *ngIf="hasApproverAccess">
            {{ 'checkout-delivery-details.approver-checkout-button' | translate }}
          </span>
          <span *ngIf="!hasApproverAccess">
              {{ 'checkout-delivery-details.send-to-approval' | translate }}
          </span>
        </button>
        <ng-container *ngIf="isJpStore">
          <button
              *ngIf="!creatingPreviewInProgress; else loadingSpinner"
              appDebounceClick
              class="button button-create-order button-previous-page"
              type="button"
              [disabled]="isProceedToCreateOrderBtnDisabled()"
              [debounceTime]="300"
              (debounceClick)="previewQuote()"
              [attr.data-test]="'button-previewQuote'">
          <span>
            {{ 'checkout-delivery-details.preview-quote' | translate }}
          </span>
          </button>
        </ng-container>
        <ng-template #loadingSpinner>
          <div class="btn-spinner">
            <div class="loading-animation"></div>
          </div>
        </ng-template>
        <button
            class="button button-previous-page"
            type="button"
            (click)="backToRequestDetails()"
            [attr.data-test]="'button-previousPage'">
          {{ 'request-details.previous-page' | translate }}
        </button>
        <div class="row">
          <div *ngIf="!isCartEmpty && !isAuStore" class="col-12">
            <h6 [attr.data-test]="'title-totalPrice'">
              {{ 'checkout-delivery-details.total-price' | translate }}
            </h6>
            <p>
              <span [attr.data-test]="'desc-priceInfo'">
                {{ 'checkout-delivery-details.price-info' | translate }}
              </span>
              <br/>
              <span [attr.data-test]="'desc-shipping'">
                {{ 'checkout-delivery-details.free-shipping' | translate }}
              </span>
            </p>
            <p class="price" [attr.data-test]="'desc-cartTotalPrice'">
              {{ cartItems.totals.subtotal | currency: cartItems.currency }}
            </p>
          </div>
        </div>
        <div class="row voucher" *ngIf="isJpStore && showAdditionalInfoForVoucher">
          <div class="col-12" *ngFor="let voucher of voucherList">
            <label>
              <span class="icon-close remove-voucher__btn"
                    title="{{ 'order-approve.remove-voucher' | translate }}"
                    (click)="removeVoucher(voucher.id)">
              </span>
              {{ getVoucherTitle(voucher.id)}}{{ 'order-approve.promotional-code' | translate }}
            </label>
            <label class="voucher-amount">
              -{{ getVoucherAmount(voucher.id) | currency: cartItems.currency }}
            </label>
          </div>
          <div class="col-12">
            <hr class="voucher-line">
            <label class="price">
              <strong>{{ getPriceAfterVoucher() | currency: cartItems.currency }}</strong>
            </label>
          </div>
        </div>
        <div *ngIf="isAuStore" class="row justify-content-center shopping-cart">
          <div class="col-8 col-xl-8" [attr.data-test]="'subTotal-title'">
            {{ 'checkout-delivery-details.sub-total' | translate }}
          </div>
          <div class="col-4 col-xl-4 text-align-right" [attr.data-test]="'subTotal-price'">
            {{ cartItems.totals.subtotal | currency: cartItems.currency }}
          </div>
          <div class="col-8 col-xl-8" [attr.data-test]="'shipping-title'">
            {{ 'checkout-delivery-details.shipping' | translate }}
          </div>
          <div class="col-4 col-xl-4 text-align-right" [attr.data-test]="'shipping-shipmentTotal-price'">
            <span *ngIf="pricesLoading; else expenseTotal" class="stripe"></span>
            <ng-template #expenseTotal>
              {{ cartItems.totals.expenseTotal | currency: cartItems.currency }}
            </ng-template>
          </div>
          <div class="col-8 col-xl-8" [attr.data-test]="'gst-taxTotal-title'">
            {{ 'order-approve.gst' | translate: {tax: getTaxPercent()} }}
          </div>
          <div class="col-4 col-xl-4 text-align-right" [attr.data-test]="'gst-taxTotal-price'">
            <span *ngIf="pricesLoading; else taxTotal" class="stripe"></span>
            <ng-template #taxTotal>
              {{ cartItems.totals.taxTotal | currency: cartItems.currency }}
            </ng-template>
          </div>
          <div class="col-12 col-xl-12">
            <hr class="order-total-border">
          </div>
          <div class="col-8 col-xl-8 shopping-cart__total-title" [attr.data-test]="'total-orderTotal-title'">
            {{ 'checkout-delivery-details.order-total' | translate }}
              <span class="shopping-cart__total-title--disclaimer" [attr.data-test]="'total-orderTotal-title-disclaimer'">
                {{ 'checkout-delivery-details.gst-disclaimer' | translate }}
              </span>
          </div>
          <div class="col-4 col-xl-4 shopping-cart__total-price text-align-right" [attr.data-test]="'total-orderTotal-price'">
            <span *ngIf="pricesLoading; else priceToPay" class="stripe"></span>
            <ng-template #priceToPay>
              {{ cartItems.totals.priceToPay | currency: cartItems.currency }}
            </ng-template>
          </div>
        </div>
        <div class="clear-both" *ngIf="cartRules && cartRules.length > 0">
          <app-cart-rules
              [cartRules]="cartRules"
              [priceToPay]="cartItems.totals.priceToPay"
              [currency]="cartItems.currency">
          </app-cart-rules>
        </div>
        <hr class="bottom-line">
        <div class="back-to-cart" (click)="backToCart()" [attr.data-test]="'link-backToCart'">
          <span class="icon-arrow-left"></span>
          {{ 'checkout-delivery-details.back-to-card' | translate }}
        </div>
        <div class="cart-items-header">
          <div class="cart-items-header-title" [attr.data-test]="'title-cardTitle'">
            {{ 'request-details.your-cart-title' | translate }}
          </div>
        </div>
        <div *ngIf="cartItemsWithDetails && cartItemsWithDetails.length > 0" class="cart-items">
          <div class="item-row" *ngFor="let item of cartItemsWithDetails" [attr.data-test]="'dp2-cart-item-' + item.attributes.name">
            <div class="item-col-img">
              <img
                  [src]="getProductImageUrl(item)"
                  [alt]="item.attributes.name"
                  [attr.data-test]="'img-productImage-' + item.attributes.name">
            </div>
            <div class="item-row-attributes">
              <div class="item-row-attributes-flex">
                <div class="item-col item-col-desc"
                     [attr.data-test]="'desc-itemName-' + item.attributes.name">
                  {{ item.attributes.name }}
                </div>
              </div>
              <div class="item-row-attributes-space">
                <div class="item-row-attributes-flex">
                  <div class="item-col">
                      <span [attr.data-test]="'label-itemQuantity-' + item.attributes.name">
                        {{ 'request-details.items-quantity-title' | translate }}
                      </span>
                  </div>
                  <span class="item-col-line"
                        [attr.data-test]="'desc-itemQuantity-' + item.attributes.name">
                    {{ item.attributes.quantity }}
                  </span>
                </div>
                <div class="item-row-attributes-flex">
                  <div class="item-col" [attr.data-test]="'label-itemPrice-' + item.attributes.name">
                    {{ 'shop-cart.item-price' | translate }}
                  </div>
                  <span class="item-col-line"
                        [attr.data-test]="'desc-itemPrice-' + item.attributes.name">
                    {{ item.attributes.calculations.unitPrice | currency: cartItems.currency }}
                    <span *ngIf="isAuStore" class="item-col-line--disclaimer" [attr.data-test]="'label-itemPrice-disclaimer-' + item.attributes.name">
                      {{ 'checkout-delivery-details.itemPrice-disclaimer' | translate }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
