import { Component, OnDestroy, OnInit } from '@angular/core';
import { ILanguage } from '../../models/settings.model';
import { ActivatedRoute, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';
import { takeUntil } from 'rxjs/operators';
import { IContractParams } from '../../models/catalog.models';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
})
export class SelectLanguageComponent implements OnInit, OnDestroy {
  title: string;
  languages: ILanguage[];
  store: any;
  currentLang: string;
  contractParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': ''
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configFacade: ConfigurationFacade,
    private router: Router,
    private translate: TranslateService,
    private customerFacade: CustomerFacade,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(params => {
      this.contractParams['fl-number'] = params['fl-number'];
      this.contractParams['rel-product-sysivk'] = params['rel-product-sysivk'];
    });
    this.currentLang = this.configFacade.getCurrentLanguage();
    this.selectStoreSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Triggered after click on "Store selection" button in dropdown. Redirecting preserves validity of authentication
   * token, so user is automatically logged in after redirecting to specific shop from Store selection page.
   */
  goToStoreSelection(): void {
    this.router.navigate(['/']).then();
  }

  /**
   * Assign selected language code to the end of the url. Merge base url with country-language and other parameters.
   *
   * @param {ILanguage} language
   */
  useLanguage(language: ILanguage): void {
    // parse the current URL minus the :lang key at the beginning
    const reg: RegExp = this.configFacade.getLanguageRegex(false);
    const url: string = this.router.url;
    const parsedUrl: UrlTree = this.router.parseUrl(url);
    const primary: UrlSegmentGroup = parsedUrl.root.children.primary;
    if (primary?.segments[0].path.match(reg)) {
      primary.segments.splice(0, 1);
    }
    const serializedUrl: string = this.router.serializeUrl(parsedUrl);

    // set the current location URL with the newly selected locale code at the start
    this.translate.use(language.id).subscribe(() => {
      const langParam: string = this.configFacade.buildLangParam(this.store.marketCode, language.code);
      const catalogParam: string = 'catalog';
      if(serializedUrl.includes(catalogParam)) {
        let url: string = `${window.location.origin}/${langParam}/${catalogParam}`;
        this.setWindowHref(
          this.contractParams['fl-number'] && this.contractParams['rel-product-sysivk']
            ? `${url}?fl-number=${this.contractParams['fl-number']}&rel-product-sysivk=${this.contractParams['rel-product-sysivk']}`
            : url
        );
      } else {
        this.setWindowHref(`${window.location.origin}/${langParam + serializedUrl}`);
      }
    });
  }

  /**
   * Process redirect in "useLanguage()" method.
   *
   * @param {string} newHref
   */
  setWindowHref(newHref: string): void {
    window.location.href = newHref;
  }

  /**
   * Get store related data - store code and available languages.
   *
   * @private
   */
  private selectStoreSubscription(): void {
    this.customerFacade.selectStore().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(store => {
      if (store) {
        this.store = store;
        this.languages = store.languages;
      }
    });
  }
}
