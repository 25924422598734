import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlueConfigurationService } from './glue-configuration.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ISoldToAddressesResponse } from '../models/soldTo-selection.models';
import { EGlueResource } from '../configurations/common';
import { GlueUtils } from '../utils/glue.utils';

@Injectable({
  providedIn: 'root',
})
export class SoldToAccountsService {
  glueUrl = this.glueConfiguration.getEndpointUrl();
  localStorage = window.localStorage;

  constructor(private http: HttpClient,
              private glueConfiguration: GlueConfigurationService,
              private httpClient: HttpClient,
  ) {
  }

  /**
   * @param error
   * @returns {Promise<any>}
   * @private
   */
  private static handleSoldToAccountsError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  /**
   * @param {EGlueResource[]} includes
   * @returns {Observable<ISoldToAddressesResponse>}
   */
  getSoldToAccounts(includes: EGlueResource[]): Observable<ISoldToAddressesResponse> {
    const apiUrl = this.glueUrl + '/' + EGlueResource.SOLD_TO_ADDRESSES;
    const params = GlueUtils.addIncludeParam(new HttpParams(), includes);

    return this.httpClient
      .get<ISoldToAddressesResponse>(apiUrl, {params})
      .pipe(catchError(SoldToAccountsService.handleSoldToAccountsError));
  }

  /**
   * Returns SoldToAddress by specified id with includes specified as 'includes' parameter.
   *
   * @param {string} soldToId
   * @param {EGlueResource[]} includes
   * @returns {Observable<ISoldToAddressesResponse>}
   */
  getSoldToAccountsById(soldToId: string, includes: EGlueResource[]): Observable<ISoldToAddressesResponse> {
    const apiUrl = `${this.glueUrl}/${ EGlueResource.SOLD_TO_ADDRESSES}/${soldToId}`;
    const params = GlueUtils.addIncludeParam(new HttpParams(), includes);

    return this.httpClient
      .get<ISoldToAddressesResponse>(apiUrl, {params})
      .pipe(catchError(SoldToAccountsService.handleSoldToAccountsError));
  }
}
