<div class="card spare-part-product"
     [ngClass]="{'in-progress': isItemInProgress(product), 'card-col': layoutType !== 'grid'}">
  <div class="card__content" [attr.data-test]="'catalog-item-' + product.abstractName">
    <div class="card__image">
      <a [attr.data-test]="'link-product-' + product.abstractSku" (click)="redirectToPDP(product.abstractSku)"
         [title]="product.abstractName || product.abstractSku">
        <div class="aspect-ratio-16x9 aspect-ratio--fill"
             [attr.data-test]="'link-refToProductPdpFromImage-' + product.abstractName">
          <ng-container *ngIf="hasImages(product); else defaultImage">
            <img alt="{{ product.abstractName }}" [src]="product.images[0].externalUrlSmall"
                 [attr.data-test]="'img-productImage-' + product.abstractName"/>
          </ng-container>
          <ng-template #defaultImage>
            <img alt="{{ product.abstractName }}" [src]="defaultImg"/>
          </ng-template>
        </div>
      </a>
    </div>
    <div class="card__body">
      <h2 class="card__title" [attr.data-test]="'link-refToProductPdpFromTitle ' + product.abstractName">
        <a class="title-link"
           (click)="redirectToPDP(product.abstractSku)"
           [title]="product.abstractName || product.abstractSku"
           [attr.data-test]="'link-productName'">
          {{ product.abstractName || product.abstractSku }}
        </a>
      </h2>
      <div class="card__product-info">
        <p class="additional-info">
          <span [attr.data-test]="'desc-materialNumber'" class="material-number">
            {{ product.attributes?.materialNumber || product.attributes?.sap_material_number }}
          </span>
          <br>
          <span [attr.data-test]="'desc-partClassification'" class="part-classification">
            {{ product.attributes?.sap_p40_material_pricing_group_3_mapped || '&nbsp;' }}
          </span>
        </p>
        <p class="desc" [innerHTML]="product.description" [attr.data-test]="'desc-description'"></p>
      </div>
    </div>
    <div class="card__price">
      <app-access-control>
        <ng-container ngProjectAs="[slot][content]">
          <app-access-control [notForViewer]="true">
            <ng-container
                *ngIf="checkIfProductIsAvailableForOnlinePurchase();
                else productIsNotAvailableForOnlinePurchase"
                ngProjectAs="[slot][nonViewerContent]">
              <p class="price-info" *ngIf="product.price | shouldDisplayPrice">
            <span class="actual-price" [attr.data-test]="'desc-actualPrice'">
              {{ product.price | currency: priceUtils.getStoreCurrencyCode() }}*
            </span>
                <small *ngIf="hasGuestPrice(product)" class="second-price" [attr.data-test]="'desc-secondPrice'">
                  {{ getGuestPrice(product) | currency: priceUtils.getStoreCurrencyCode() }}
                </small>
              </p>
              <button class="button button--link add-to-cart"
                      [attr.data-test]="'button-addToCart'"
                      appDebounceClick
                      [debounceTime]="300"
                      [disabled]="canCartItemsBeChanged(currentCart, isBusinessPartner) || loadingCartDataInProgress || !isSapP40Enable"
                      (debounceClick)="addToCart(product)">
                <span class="icons-group">
                  <span class="icon-basket"></span>
                </span>
                <span class="add-to-cart-label" [attr.data-test]="'button-addToCart ' + product.abstractName">
                  {{ 'app-product-page.add-to-cart' | translate }}
                </span>
              </button>
            </ng-container>
            <ng-template #productIsNotAvailableForOnlinePurchase>
              <div class="notification__header" [attr.data-test]="'desc-productIsNotAvailable'">
                <p class="font-weight-bold product-not-available">
                  <span class="icon-info icon-info__circle icon-orange-white"></span>
                  {{ 'app-product-page.product-is-not-available-for-online-purchase' | translate }}
                </p>
              </div>
            </ng-template>
          </app-access-control>
        </ng-container>
      </app-access-control>
    </div>
  </div>
  <div class="loader__overlay">
    <span class="loading-animation"></span>
  </div>
  <app-access-control [notForViewer]="true">
    <ng-container ngProjectAs="[slot][nonViewerContent]">
      <app-sticker [product]="product" [isCatalog]="true" [labels]="labels" [prices]="product.prices">
      </app-sticker>
      <app-add-to-wishlist
          [wishlists]="wishlists"
          [sku]="getItemConcreteSku(product)"
          [productName]="product.abstractName"
          [productKeywords]="product.attributes?.mcm_keywords"
          [addNewModalActive]="addNewModalActive"
          (createNew)="addNewModalActive = true"
          (click)="openAddNewWishlistModal()">
      </app-add-to-wishlist>
    </ng-container>
  </app-access-control>
</div>
