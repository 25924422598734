import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';

import { ConfigurationFacade } from '../facades/configuration.facade';
import { MarketingFacade } from '../facades/marketing.facade';
import { AppActions, CpqActions, CustomerActions, OrderApprove, ShopCartActions } from '../actions';
import { PayloadUtils } from '../utils/payload.utils';
import { CpqFacade } from '../facades/cpq.facade';
import { CARTS_API_ERROR_CODES_TO_SHOW_ALERT, EGlueResource } from '../configurations/common';
import { ICartPayload, ICartUpdateRequest } from '../models/cart.models';
import { I18nService } from '../services';
import { Position } from '../models/alert.models';

@Injectable()
export class ShopCartEffects {
  constructor(
    private actions$: Actions,
    private cpqFacade: CpqFacade,
    private marketingFacade: MarketingFacade,
    private configurationFacade: ConfigurationFacade,
    private i18nService: I18nService,
  ) {
  }

  initCurrentCartAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionSuccess),
      map(() => ShopCartActions.loadCurrentCartAndCartItems()),
    );
  });

  postNewCartWithItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.postNewCartWithItem),
      mergeMap(action =>
        this.marketingFacade.postCart()
          .pipe(
            exhaustMap((newCart) => [
              ShopCartActions.postCartSuccess({newCartData: newCart}),
              ShopCartActions.addItemToCart({
                cartId: newCart.data.id,
                requestBody: action.requestBody,
                product: action.product,
                redirectUrl: action.redirectUrl,
                retryWithNewCart: true,
              }),
            ]),
            catchError(error => of(ShopCartActions.postCartFail({error}))),
          ),
      ),
    );
  });

  checkIsCartDefault$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.checkIsCartDefault),
      concatMap((action) =>
        this.marketingFacade.selectCartById(action.cartId)
          .pipe(concatMap((cart) => {
              if (cart?.attributes?.isDefault) {
                return of(ShopCartActions.addItemToCart({
                  cartId: action.cartId,
                  requestBody: action.requestBody,
                  product: action.product,
                  redirectUrl: action.redirectUrl,
                  retryWithNewCart: true,
                }));
              } else {
                return of(ShopCartActions.addItemToCartLoadCarts({
                  cartId: action.cartId,
                  requestBody: action.requestBody,
                  product: action.product,
                  redirectUrl: action.redirectUrl,
                }));
              }
            }),
            catchError(_ => of(ShopCartActions.addItemToCartLoadCarts({
              cartId: action.cartId,
              requestBody: action.requestBody,
              product: action.product,
              redirectUrl: action.redirectUrl,
            }))),
          )),
    );
  });

  addItemToCartLoadCarts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.addItemToCartLoadCarts),
      exhaustMap((action) =>
        this.marketingFacade.getCarts()
          .pipe(
            mergeMap((carts) => [
              ShopCartActions.loadCartsSuccess({carts}),
              ShopCartActions.addItemToCartLoadCartsSuccess({
                cart: carts.data.find(cart => cart.attributes.isDefault),
                product: action.product,
                requestBody: action.requestBody,
                redirectUrl: action.redirectUrl,
              }),
            ]),
            catchError(error => of(ShopCartActions.loadCartsFail({error}))),
          )),
    );
  });

  addItemToCartLoadCartsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.addItemToCartLoadCartsSuccess),
      exhaustMap(action =>
        this.marketingFacade.getCartItems(action.cart.id)
          .pipe(
            mergeMap((cartItems) => [
              ShopCartActions.loadCartSuccess({cart: action.cart}),
              ShopCartActions.loadCartItemsSuccess({cartItems}),
              ShopCartActions.loadCurrentCartItemsSuccess(),
              ShopCartActions.addItemToCart({
                cartId: action.cart.id,
                requestBody: action.requestBody,
                product: action.product,
                redirectUrl: action.redirectUrl,
                retryWithNewCart: true,
              }),
            ]),
            catchError(error => of(ShopCartActions.addItemFailAfterCartLoad({error}))),
          )),
    );
  });

  addItemToCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.addItemToCart),
      concatMap(action =>
        this.marketingFacade.addItemToCart(action.cartId, action.requestBody)
          .pipe(
            concatMap((cartResponse) => {
              const dispatchList = [];
              dispatchList.push(ShopCartActions.addItemToCartSuccess(
                {cartResponse, itemData: action.requestBody, product: action.product}),
              );
              dispatchList.push(ShopCartActions.loadCartItemsSuccess({cartItems: cartResponse}));
              if (action.product?.type === 'contract') {
                const itemsWithDetails = PayloadUtils.getItemsWithDetailsFromInclude(cartResponse.included);
                dispatchList.push(CpqActions.setSelectedSystem({
                  selectedSystem: itemsWithDetails.find(item => item.attributes.abstractSku === action.product.sku),
                }));
                dispatchList.push(CpqActions.updateChangeLog({changeLog: []}));
                dispatchList.push(AppActions.redirectToUrl({url: action.redirectUrl, replaceUrl: false}));
              } else {
                dispatchList.push(ShopCartActions.openMiniCartAndShowNotificationAfterAddToCart());
              }
              return dispatchList;
            }),
            catchError(error => {
              if (!action.retryWithNewCart) {
                return of(ShopCartActions.addItemToCartError({error}));
              }
              return of(ShopCartActions.handleAddToCartFail({
                error,
                cartId: action.cartId,
                requestBody: action.requestBody,
                product: action.product,
                redirectUrl: action.redirectUrl,
              }));
            }),
          ),
      ));
  });

  deleteItemsFromCart = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.deleteItemsFromCart),
      mergeMap(action =>
        this.marketingFacade.deleteItemsFromCart(action.cartId)
          .pipe(
            mergeMap((cartResponse) => {
              const payload: ICartUpdateRequest = {
                data: {
                  type: EGlueResource.CARTS,
                  attributes: {
                    name: cartResponse.data.attributes.name,
                    systemDetails: action.systemDetails,
                  },
                },
              };

              return [
                ShopCartActions.deleteItemsFromCartSuccess({cartResponse: cartResponse}),
                ShopCartActions.startCartOperation(),
                ShopCartActions.updateCartData({cartId: action.cartId, cartData: payload, eTag: cartResponse.eTag}),
              ];
            }),
            catchError(error => {
              return of(ShopCartActions.deleteItemsFromCartError({error}));
            }),
          ),
      ));
  });

  addItemToCartError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.addItemToCartError),
      mergeMap(() =>
        this.configurationFacade.getTranslationByKey('error.add-item-fail').pipe(
          map(message =>
            AppActions.setAlert({
              alert: {
                type: 'error',
                message,
              },
            }),
          ),
        ),
      ),
    );
  });

  loadCartsAfterPostCartSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.postCartSuccess),
      map((action) =>
        ShopCartActions.loadCurrentCartAndCartItems()
      )
    );
  });

  addItemsToCartFromCsvFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.addToCartFromCsvFile),
      mergeMap((action) =>
        this.marketingFacade.addItemsToCartFromCsvFile(action.cartId, action.requestBody).pipe(
          mergeMap(cart => [
            ShopCartActions.addToCartFromCsvFileSuccess({cart}),
            ShopCartActions.updateShopCartAfterItemUpdate({payload: cart}),
          ]),
          catchError(error => of(ShopCartActions.addToCartFromCsvFileFail({error}))),
        ),
      ),
    );
  });

  handleAddToCartFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.handleAddToCartFail),
      switchMap((action) => {
        const errorCodes = action?.error?.map(error => error.code);
        const invalidCodes = CARTS_API_ERROR_CODES_TO_SHOW_ALERT;
        if (errorCodes.some(code => invalidCodes.includes(code))) {
          const errorObj = action.error.find(error => invalidCodes.includes(error.code));
          return of(ShopCartActions.handleAddToCartResetState({
            error: errorObj,
            messageKey: errorObj.code !== '102' || errorObj.detail.includes('cart-cannot-be-mixed')
              ? errorObj.detail : 'error.product-unavailable',
            params: {name: action.product.name, subsequentMaterial: action.product.subsequentMaterial},
          }));
        }
        return of(ShopCartActions.createCartThanAddItem({
          error: action.error,
          cartId: action.cartId,
          requestBody: action.requestBody,
          product: action.product,
          redirectUrl: action.redirectUrl,
        }));
      }),
    );
  });

  handleAddToCartResetState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.handleAddToCartResetState),
      mergeMap((action) => {
          if (action?.error?.detail === 'product-cart.validation.error.item-not-available') {
            return showDiscontinuedProductWarning(this.i18nService.getCurrentLocale(), action.params);
          } else {
            return this.configurationFacade.getTranslationByKey(action.messageKey, action.params).pipe(
              map(message =>
                AppActions.setAlert({
                  alert: {
                    type: 'error',
                    message,
                  },
                }),
              ),
            );
          }
        },
      ),
    );
  });

  createCartThanAddItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.createCartThanAddItem),
      switchMap(action => {
        return this.marketingFacade.postCart()
          .pipe(
            exhaustMap((newCart) => [
              ShopCartActions.postCartSuccess({newCartData: newCart}),
              ShopCartActions.addItemToCart({
                cartId: newCart.data.id,
                requestBody: action.requestBody,
                product: action.product,
                redirectUrl: action.redirectUrl,
                retryWithNewCart: false,
              }),
            ]),
            catchError(error => of(ShopCartActions.postCartFail({error}))),
          );
      }),
    );
  });

  loadCartItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCartItems),
      exhaustMap(action =>
        this.marketingFacade.getCartItems(action.cartId)
          .pipe(
            map(cartItems => ShopCartActions.loadCartItemsSuccess({cartItems})),
            catchError(error => of(ShopCartActions.loadCartItemsFail({error}))),
          )),
    );
  });

  loadCarts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCarts),
      mergeMap(() =>
        this.marketingFacade.getCarts()
          .pipe(
            map(carts => ShopCartActions.loadCartsSuccess({carts})),
            catchError(error => of(ShopCartActions.loadCartsFail({error}))),
          )),
    );
  });

  setDefaultCartItemsInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCartItemsSuccess),
      map((action) =>
        ShopCartActions.setDefaultCartItems({cartItems: action.cartItems}),
      ),
    );
  });

  updateCartDataEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.updateCartData),
      mergeMap(action =>
        this.marketingFacade.updateCartData(action.cartId, action.cartData, action.eTag).pipe(
          mergeMap((cart: ICartPayload) => [
            ShopCartActions.updateCartSystemDetailsSuccess({cart: cart}),
          ]),
          catchError(error => of(ShopCartActions.updateCartDataFailed({error}))),
        ),
      ),
    );
  });

  updateCartDataSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.updateCartDataSuccess),
      concatMap(() => [
        ShopCartActions.loadCurrentCartAndCartItems(),
      ]),
    );
  });

  updateCartDataFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.updateCartDataFailed),
      concatMap(error => [
        ShopCartActions.loadCartItemsFail({error: error.error}),
        ShopCartActions.stopCartOperation(),
      ]),
    );
  });

  deleteCartSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.deleteCartSuccess),
      concatMap((action) => {
        if (action.isDefault) {
          // if the default cart was deleted, a new default is selected on the BE,
          // meaning that a refresh is necessary
          return [
            ShopCartActions.loadCurrentCartAndCartItems(),
          ];
        } else {
          return [];
        }
      }),
    );
  });

  loadCurrentCartItemsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCurrentCartItemsSuccess),
      map(() => ShopCartActions.stopCartOperation()),
    );
  });

  deleteCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.deleteCart, ShopCartActions.deleteCpqCartSuccess),
      exhaustMap(action => this.marketingFacade.selectCartById(action.cartId).pipe(
        exhaustMap(cart => this.marketingFacade.deleteCartById(action.cartId).pipe(
          concatMap(_ => {
            // in case the cart isn't found among the users other carts, it's best to re-trigger loading of all carts
            // to refresh the data in the app state (which always happens for the default cart)
            const isDefault = !cart ? true : cart.attributes.isDefault;
            const dispatchList = [];
            dispatchList.push(ShopCartActions.deleteCartSuccess({cartId: action.cartId, isDefault}));
            if (action.redirectUrl !== '') {
              dispatchList.push(AppActions.redirectToUrl({url: action.redirectUrl, replaceUrl: true}));
            }
            return dispatchList;
          }),
        )),
        catchError(error => of(ShopCartActions.deleteCartFail({error}))),
      )),
    );
  });

  loadCurrentCartAndCartItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCurrentCartAndCartItems),
      concatMap(() =>
        this.marketingFacade.getCarts()
          .pipe(
            mergeMap((carts) => [
              ShopCartActions.startCartOperation(),
              ShopCartActions.loadCartsSuccess({carts}),
              ShopCartActions.loadCurrentCartItems({cart: carts.data.find(cart => cart.attributes.isDefault)}),
            ]),
            catchError(error => of(ShopCartActions.loadCartsFail({error}))),
          )),
    );
  });

  loadCurrentCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCurrentCart),
      concatMap((id) =>
        this.marketingFacade.getCartItems(id.id)
          .pipe(
            mergeMap((cart) => [
              ShopCartActions.updateItemInCartLoadCartItemsSuccess({updatedItemInCartAndLoadedCartItems: cart}),
              ShopCartActions.updateShopCartAfterItemUpdate({payload: cart}),
            ]),
            catchError(error => of(ShopCartActions.loadCartsFail({error}))),
          )),
    );
  });

  loadCurrentCartItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.loadCurrentCartItems),
      exhaustMap(action => {
        if (action.cart?.id) {
          return this.marketingFacade.getCartItems(action.cart.id)
            .pipe(
              mergeMap((cartItems) => [
                ShopCartActions.loadCartSuccess({cart: action.cart}),
                ShopCartActions.loadCartItemsSuccess({cartItems: {...cartItems}}),
                ShopCartActions.loadCurrentCartItemsSuccess(),
              ]),
              catchError(error => of(ShopCartActions.loadCartItemsFail({error}))),
            );
        } else {
          return this.marketingFacade.postCart()
            .pipe(
              mergeMap((newCartData) => [
                ShopCartActions.postCartSuccess({newCartData}),
                ShopCartActions.loadCurrentCartItemsSuccess(),
              ]),
              catchError(error => of(ShopCartActions.postCartFail({error}))),
            );
        }
      }));
  });

  createEmptyCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.createEmptyCart),
      exhaustMap(action =>
        this.marketingFacade.postCart(action.attributes)
          .pipe(
            map((newCartData) => ShopCartActions.postCartSuccess({newCartData})),
            catchError(error => of(ShopCartActions.postCartFail({error}))),
          )),
    );
  });

  clearCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.clearCart),
      exhaustMap(_ =>
        this.marketingFacade.postCart()
          .pipe(
            map((newCartData) => ShopCartActions.postCartSuccess({newCartData})),
            catchError(error => of(ShopCartActions.postCartFail({error}))),
          )),
    );
  });

  deleteItemFromCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.deleteItemFromCart),
      exhaustMap(action =>
        this.marketingFacade.deleteItemFromCart(action.cartId, action.itemId)
          .pipe(
            mergeMap(() => [
              ShopCartActions.addItemToCartInProgress({status: true}),
              ShopCartActions.deleteItemFromCartSuccess(),
              ShopCartActions.loadCurrentCart({id: action.cartId}),
            ]),
            catchError(error => of(ShopCartActions.deleteItemFromCartFail({error}))),
          )),
    );
  });

  deleteItemFromCartOrderApprove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderApprove.deleteItemFromCart),
      mergeMap(action =>
        this.marketingFacade.deleteItemFromCart(action.cartId, action.itemId)
          .pipe(
            map(latestDeletedItemFromCart => OrderApprove.deleteItemFromCartSuccess({latestDeletedItemFromCart})),
            map(() => OrderApprove.loadCartItems({cartId: action.cartId})),
            catchError(error => of(OrderApprove.deleteItemFromCartFail({error}))),
          )),
    );
  });

  updateItemInCartLoadCartItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.updateItemInCartLoadCartItems),
      switchMap(action =>
        this.marketingFacade.sequentialRequestPatchCartItemGetCartItems(action.cartId, action.itemId, action.updateItemInCartData)
          .pipe(
            mergeMap((updatedItemInCart: ICartPayload) => [
              ShopCartActions.updateShopCartAfterItemUpdate({payload: updatedItemInCart}),
            ]),
            catchError(error => of(ShopCartActions.updateItemInCartLoadCartItemsFail({error}))),
          )),
    );
  });

  switchDefaultCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.switchDefaultCart),
      concatMap(action => this.marketingFacade.getCartItems(action.cartId).pipe(
        map(cartItems => ({cartItems, action})),
      )),
      concatMap(({cartItems, action}) => this.marketingFacade.updateCartData(
        cartItems.data.id,
        {data: {type: EGlueResource.CARTS, attributes: {...action.attributes, isDefault: true}}},
        cartItems.eTag,
      ).pipe(
        concatMap(cart => [
          ShopCartActions.loadCartItemsSuccess({cartItems: {...cart, included: cartItems.included}}),
          ShopCartActions.switchDefaultCartSuccess({cart}),
        ]),
      )),
      catchError(error => of(ShopCartActions.switchDefaultCartFail({error}))),
    );
  });


  deleteCpqQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShopCartActions.deleteCpqCart, ShopCartActions.quoteSummaryPageDeleteQuote),
      exhaustMap(action => this.cpqFacade.deleteCpqQuote(action.cartId).pipe(
        mergeMap(() => [
          ShopCartActions.deleteCpqCartSuccess({cartId: action.cartId, redirectUrl: action.redirectUrl}),
        ]),
        catchError(error => {
          if (error.error.errors.find(err => err.code === '404')) {
            return of(ShopCartActions.deleteCpqCartSuccess({cartId: action.cartId, redirectUrl: action.redirectUrl}));
          } else {
            return of(ShopCartActions.deleteCpqCartFail({error}));
          }
        }),
      )),
    );
  });
}

function showDiscontinuedProductWarning(currentLocale: string, params: any): Observable<any> {
  return of(AppActions.setAlert({
    alert: {
      type: 'warning',
      options: {
        alertWidth: '28rem',
        title: 'alert.discontinued-part-title',
        buttonLabel: 'alert.close',
        buttonPosition: Position.RIGHT,
      },
      message: params?.subsequentMaterial && params.subsequentMaterial.length != 0
        ? `alert.discontinued-part-message-with-subsequent-material`
        : 'alert.discontinued-part-message',
      messageData: params?.subsequentMaterial && params.subsequentMaterial.length != 0
        ? getSubsequentMaterial(currentLocale, params.subsequentMaterial)
        : null,
    },
  }));
}

function getSubsequentMaterial(currentLocale: string, subsequentMaterial: string): string {
  return `<a href=\"/${currentLocale}/product/A_${subsequentMaterial}\">${subsequentMaterial}</a>`;
}
