import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EStoreTypes, EUserRoles } from '../../configurations/common';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { StoreConfigurationFacade } from '../../facades/store-configuration.facade';
import { IAddress } from '../../models/common.models';
import { ICreateRfQ } from '../../models/rfq.models';
import { I18nService } from '../../services';
import { TermConstants } from '../../shared/terms/TermConstants';
import { AddressUtils } from '../../utils/address.utils';
import { AppUtils } from '../../utils/app.utils';
import { CartUtils } from '../../utils/cart.utils';
import { FileType, FileUtils } from '../../utils/file.utils';
import { PriceUtils } from '../../utils/price.utils';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
})
export class RequestQuoteComponent implements OnInit, OnDestroy {
  requestQuoteData: ICreateRfQ;
  loggedUserRoles = [EUserRoles.Buyer];
  cartItems: any;
  cartId: string;
  quoteRequestReference: string;
  quoteCreatedAt: string;
  requestDetailsData: any;
  contactDetailsData: any;
  creatingOrderInProgress = false;
  showApprovalModal = false;
  isOnDetailsPage = true;
  isCartEmpty = true;
  isRfqOnly = true;
  minimumOrderValue = 0;
  currentCart: any;
  totalPrice: number;
  currency: string;
  termsAccessTime: Date;
  isAuStore: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private checkoutFacade: CheckoutFacade,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectLoggedUserRoles();
    this.selectCartIdSubscription();
    this.selectCartItemsSubscription();
    this.selectIsRfqOnly();
    this.selectMinimumOrderValue();
    this.selectIsCartEmpty();
    this.shopCartPageDataSubscription();

    this.isAuStore = AppUtils.isStoreActive(EStoreTypes.AU);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  backToHomepage(): void {
    this.router.navigate([this.i18nService.getCurrentLocale()]).then();
  }

  proceedToContactDetails(requestDetailsData: any): void {
    this.requestDetailsData = requestDetailsData;
    this.isOnDetailsPage = false;
  }

  proceedToRequestDetails(): void {
    this.isOnDetailsPage = true;
  }

  createRequestQuote(contactDetailsData: any): void {
    this.contactDetailsData = contactDetailsData;

    this.termsAccessTime = new Date();

    this.createRequest();
  }

  selectIsRfqOnly(): void {
    this.storeConfigurationFacade.selectIsRfqOnly().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isRfqOnly = state;
    });
  }

  selectMinimumOrderValue(): void {
    this.storeConfigurationFacade.selectMinimumOrderValue().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.minimumOrderValue = state;
    });
  }

  selectIsCartEmpty(): void {
    this.marketingFacade.selectIsCartEmpty().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCartEmpty = state;
    });
  }

  shopCartPageDataSubscription(): void {
    this.marketingFacade.shopCartPageDataSelector().pipe(
      take(1),
    ).subscribe(state => {
      if (!state) {
        this.currency = PriceUtils.getStoreCurrencyCode();
        return;
      }
      this.currentCart = state.cartItems?.data?.attributes;
      this.totalPrice = this.getTotalPrice();
      this.currency = this.currentCart.currency;
    });
  }

  getModalTitle(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems() ? 'request-quote.success-modal-title-rfq-only' : 'request-quote.success-modal-title';
  }

  getModalBody(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems() ? 'request-quote.success-modal-body-rfq-only' : 'request-quote.success-modal-body';
  }

  isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.cartItems);
  }

  getTotalPrice(): number {
    return CartUtils.getTotalPrice(this.isCartEmpty, this.currentCart?.attributes);
  }

  private selectLoggedUserRoles(): void {
    this.customerFacade.selectCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(roles => {
      this.loggedUserRoles = roles;

      this.checkRolesForAccess();
    });
  }

  private selectCartItemsSubscription(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      take(1),
    ).subscribe(cartItems => {
      if (cartItems && cartItems.length > 0 && !this.cartItems?.length) {
        this.cartItems = cartItems;
        this.analyticsService.setProducts(cartItems);
        this.analyticsService.trackPageReady('request quote', PageTypes.REQUEST_QUOTE_PAGE, 'items');
      }
    });
  }

  private selectCartIdSubscription(): void {
    this.marketingFacade.selectCartId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      cartId => {
        if (cartId) {
          this.cartId = cartId;
          this.analyticsService.setCartId(cartId);
        }
      },
    );
  }

  private checkRolesForAccess(): void {
    if (this.loggedUserRoles.includes(EUserRoles.Viewer)) {
      this.router.navigate([this.i18nService.getCurrentLocale()]).then();
    }
  }

  private createRequest(): void {
    const deliveryAddress = this.requestDetailsData.deliveryAddress;

    const shippingAddress: IAddress = {
      idCustomerAddress: deliveryAddress?.idCustomerAddress ? deliveryAddress.idCustomerAddress : 'empty',
      idCompanyUnitAddress: deliveryAddress?.idCompanyUnitAddress ? deliveryAddress.idCompanyUnitAddress : 'empty',
      salutation: 'Ms',
      firstName: deliveryAddress?.firstName ? deliveryAddress.firstName : 'empty',
      lastName: deliveryAddress?.lastName ? deliveryAddress.lastName : 'empty',
      address1: deliveryAddress?.address1 ? deliveryAddress.address1 : 'empty',
      address2: deliveryAddress?.address2 ? deliveryAddress.address2 : 'empty',
      address3: '',
      zipCode: deliveryAddress?.zipCode ? deliveryAddress.zipCode : 'empty',
      city: deliveryAddress?.city ? deliveryAddress.city : 'empty',
      iso2Code: deliveryAddress?.iso2Code ? deliveryAddress.iso2Code : 'DE',
      company: deliveryAddress?.company ? deliveryAddress.company : 'empty',
      phone: deliveryAddress?.phone ? deliveryAddress.phone : 'empty',
      country: deliveryAddress?.country ? deliveryAddress.country : 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
    };

    // handle BU address iso2Code
    if (shippingAddress.country === 'empty') {
      shippingAddress.country = AddressUtils.getDeliveryCountryByIsoCode(shippingAddress.iso2Code)?.name || 'empty';
    }

    this.requestQuoteData = {
      data: {
        type: 'quote-requests',
        attributes: {
          cartUuid: this.cartId,
          meta: {
            purchase_order_number: '',
            delivery_date: '',
            note: '',
          },
          systemDetails: {
            ...this.requestDetailsData.systemDetails,
          },
          systemDetailsPerItem: [
            ...this.requestDetailsData.systemDetailsPerItem,
          ],
          pointOfContact: {
            ...this.contactDetailsData.pointOfContact,
            floorOrRoom: this.contactDetailsData.pointOfContact.floorOrRoom ? this.contactDetailsData.pointOfContact.floorOrRoom : this.requestDetailsData.floorOrRoom,
            department: this.contactDetailsData.pointOfContact.department ? this.contactDetailsData.pointOfContact.department : this.requestDetailsData.department,
          },
          shippingAddress,
          taxNumber: this.contactDetailsData.taxNumber,
          termsAccessTime: this.termsAccessTime,
          consentedTerms: [
            TermConstants.termKeys.PRIVACY_POLICY.termsKey,
            TermConstants.termKeys.TERMS_AND_CONDITIONS.termsKey,
          ],
        },
      },
    } as ICreateRfQ;

    this.creatingOrderInProgress = true;

    this.checkoutFacade.createRfq(this.requestQuoteData).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: response => {
          if (response) {
            this.quoteRequestReference = response.data.attributes.quoteRequestReference;
            this.quoteCreatedAt = response.data.attributes.createdAt;
          }

          this.creatingOrderInProgress = false;
          this.showApprovalModal = true;

          this.analyticsService.trackCart('rfq.placed');
          const previousCartId = this.cartId;
          this.marketingFacade.createEmptyCart();
          this.marketingFacade.deleteCart(previousCartId);

          this.getQuotePdfFile();
        },
        error: (error) => {
          this.creatingOrderInProgress = false;
          this.handleCheckoutError(error);
        },
      },
    );
  }

  private getQuotePdfFile(): void {
    if (this.isRfqOnly && this.isSetPricesForAllItems() && !!this.quoteRequestReference) {
      this.checkoutFacade.getQuotePdfFile(this.quoteRequestReference).pipe(take(1))
        .subscribe(file => {
          FileUtils.saveAndOpenFile(file, FileType.PDF, `Quote_details_${this.quoteRequestReference}`, this.quoteCreatedAt);
        });
    }
  }

  private handleCheckoutError(httpError: HttpErrorResponse): void {
    if (httpError.status === 422) {
      this.configurationFacade.getAlert().pipe(
        take(1),
      ).subscribe(alert => {
        if (alert && alert.type === 'error' && alert.message) {
          this.configurationFacade.setAlert({
            ...alert,
            type: 'warning',
          });
        }
      });

      this.termsAccessTime = new Date();
    }
  }
}
