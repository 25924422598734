<div class="my-profile-page">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <div class="col-12 col-lg-10">
      <h4>
        {{ customerData.firstName }}
        {{ customerData.lastName }}
      </h4>
      <h6 class="margin-top-0">
        {{ businessUnitName }}
      </h6>
      <div class="tabs">
        <div class="tabs__list margin-left-0">
          <span class="tabs__item" [ngClass]="{'is-active': currentTab === profileTabs.PERSONAL}"
                (click)="changeTab(profileTabs.PERSONAL)"
                [attr.data-test]="'desc-tab-personalData'">
            {{ 'my-profile.tab.personal-data' | translate }}
          </span>
          <span class="tabs__item" [ngClass]="{'is-active': currentTab === profileTabs.ACCOUNT}"
                (click)="changeTab(profileTabs.ACCOUNT)"
                [attr.data-test]="'desc-tab-accountSettings'">
            {{ 'my-profile.tab.account-settings' | translate }}
          </span>
          <span class="tabs__item" [ngClass]="{'is-active': currentTab === profileTabs.DELIVERY}"
                (click)="changeTab(profileTabs.DELIVERY)"
                [attr.data-test]="'desc-tab-deliveryAddresses'">
            {{ 'my-profile.tab.delivery-addresses' | translate }}
          </span>
        </div>
        <div class="tabs__panel" [ngClass]="{'is-active': currentTab === profileTabs.PERSONAL}">
          <app-personal-data [customerData]="customerData">
          </app-personal-data>
        </div>
        <div class="tabs__panel" [ngClass]="{'is-active': currentTab === profileTabs.ACCOUNT}">
          <app-account-settings
              [userRoles]="companyRoles"
              [customerId]="customerId"
              (isFormPristineEmit)="canClose = $event">
          </app-account-settings>
        </div>
        <div class="tabs__panel" [ngClass]="{'is-active': currentTab === profileTabs.DELIVERY}">
          <app-delivery-addresses
              [customerId]="customerId"
              [businessUnitName]="businessUnitName"
              [businessUnitNumber]="businessUnitNumber">
          </app-delivery-addresses>
        </div>
      </div>
    </div>
    <app-modal-confirm
        [type]="iconType.WARNING"
        modalTitle="{{ 'my-profile.modal.save-changes-title' | translate }}"
        notifyText="{{ 'my-profile.modal.save-changes-text' | translate }}"
        primaryBtnText="{{ 'my-profile.button.delete' | translate }}"
        cancelBtnText="{{ 'my-profile.button.cancel' | translate }}"
        [showModalConfirm]="showModal"
        (closeModalConfirm)="showModal = false"
        (primaryActionConfirm)="discardChangesAndLeavePage()">
    </app-modal-confirm>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="display-flex f-align-items-center flex-column margin-top-2">
      <div class="loading-animation"></div>
    </div>
  </ng-template>
</div>
