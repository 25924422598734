import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlueConfigurationService } from './glue-configuration.service';
import {
  IAddNewItemToWishlistRequest,
  IBaseWishlist,
  ICreateWishlistRequest,
  IWishlist,
  IWishlists,
} from '../models/wishlist.models';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { GlobalErrorHandler } from '../error/GlobalErrorHandler';

@Injectable({
  providedIn: 'root',
})
export class WishlistsService {

  private wishlistUrl = '/shopping-lists';
  private wishlistAddItemUrl = '/shopping-list-items';
  private url = this.glueConfiguration.getEndpointUrl() + this.wishlistUrl;

  private httpWishlistParams = {
    params: new HttpParams()
      .set('include', 'shopping-list-items,concrete-products,concrete-product-image-sets,concrete-product-prices'),
  };

  private httpWishlistItemsParam = {
    params: new HttpParams()
      .set('include', 'shopping-list-items,concrete-products'),
  };

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  createWishlist(wishlist: ICreateWishlistRequest): Observable<IBaseWishlist> {
    return this.httpClient.post<any>(this.url, wishlist).pipe(
      take(1),
      map(result => {
        return result.data as IBaseWishlist;
      }),
    );
  }

  getWishlists(): Observable<IWishlists> {
    return this.httpClient.get<IWishlists>(this.url, this.httpWishlistItemsParam).pipe(
      take(1),
      catchError((error) =>
        GlobalErrorHandler.handleError(error.error.errors[0]),
      ),
    );
  }

  getWishlist(wishlistId: string): Observable<IWishlist> {
    const wishlistUrl = `${this.url}/${wishlistId}`;
    return this.httpClient.get<IWishlist>(wishlistUrl, this.httpWishlistParams).pipe(take(1));
  }

  editWishlist(wishlistId: string, wishlist: ICreateWishlistRequest): Observable<IBaseWishlist> {
    const editUrl = `${this.url}/${wishlistId}`;
    return this.httpClient.patch<any>(editUrl, wishlist).pipe(
      take(1),
      map(result => result.data as IBaseWishlist),
    );
  }

  deleteWishlist(wishlistId: string): Observable<any> {
    const deleteUrl = `${this.url}/${wishlistId}`;
    return this.httpClient.delete(deleteUrl).pipe(take(1));
  }

  addNewItemToWishlist(wishlistId: string, request: IAddNewItemToWishlistRequest):
    Observable<IAddNewItemToWishlistRequest> {
    const addUrl = `${this.url}/${wishlistId}${this.wishlistAddItemUrl}`;
    return this.httpClient.post<IAddNewItemToWishlistRequest>(addUrl, request).pipe(take(1));
  }

  removeItemFromWishList(wishlistId: string, shoppingListItemId: string): Observable<any> {
    const removeUrl = `${this.url}/${wishlistId}${this.wishlistAddItemUrl}/${shoppingListItemId}`;
    return this.httpClient.delete<any>(removeUrl);
  }

  updateItemFromWishList(wishlistId: string, shoppingListItemId: string, sku: string, quantity: number): Observable<any> {
    const body = {
      data: {
        type: 'shopping-list-items',
        attributes: {
          quantity,
          sku,
        },
      },
    };
    const removeUrl = `${this.url}/${wishlistId}${this.wishlistAddItemUrl}/${shoppingListItemId}`;
    return this.httpClient.patch<any>(removeUrl, body).pipe(take(1));
  }
}
