<form *ngIf="pointOfContactForm" [formGroup]="pointOfContactForm" class="point-of-contact__form">
  <app-modal
      [open]="showModal"
      [id]="'ddPOCModal'"
      title="{{ 'checkout-delivery-details.departments-points-of-contact' | translate }}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="row">
        <app-select-box
            class="col-12"
            [form]="pointOfContactForm"
            [modelValue]="pointOfContactForm.value.department"
            [cssStyles]="{wrapper: 'selectbox-delivery-address'}"
            [options]="departments"
            [inputName]="'department'"
            label="{{ 'checkout-delivery-details.select-department-title' | translate }}"
            [isRequired]="true"
            [search]="true"
            [searchPlaceholder]="'checkout-delivery-details-modal.search-box-department-placeholder' | translate"
            (selected)="setOrderFormValue($event)">
        </app-select-box>
      </div>
      <div class="row">
        <div class="checkout__field col-md-6">
          <app-textfield
              class="checkout__textfield col-12 col-md-6"
              [form]="pointOfContactForm"
              [modelValue]="pointOfContactForm.value.firstName"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-street'}"
              [inputName]="'firstName'"
              label="{{'checkout-delivery-details.select-first-name' | translate}}"
              [isRequired]="true">
          </app-textfield>
          <div class="checkout__alerts">
            <ng-container
                *ngIf="pointOfContactForm.get('firstName').touched &&
                            pointOfContactForm.get('firstName').hasError('maxlength')">
              <small class="textfield__hint">
                {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
              </small>
            </ng-container>
            <ng-container
                *ngIf="pointOfContactForm.get('firstName').hasError('pattern') &&
                            pointOfContactForm.get('firstName').touched">
              <small class="textfield__hint">{{ 'checkout-delivery-details.only-letters' | translate }}</small>
            </ng-container>
          </div>
        </div>

        <div class="checkout__field col-md-6">
          <app-textfield
              class="checkout__textfield col-12 col-md-6"
              [form]="pointOfContactForm"
              [modelValue]="pointOfContactForm.value.lastName"
              [cssStyles]="{wrapper: 'textfield-with-prefix '}"
              [inputName]="'lastName'"
              label="{{'checkout-delivery-details.select-last-name' | translate}}"
              [isRequired]="true">
          </app-textfield>
          <div class="checkout__alerts">
            <ng-container
                *ngIf="pointOfContactForm.get('lastName').touched &&
                          pointOfContactForm.get('lastName').hasError('maxlength')">
              <small class="textfield__hint">
                {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
              </small>
            </ng-container>
            <ng-container
                *ngIf="pointOfContactForm.get('lastName').hasError('pattern')">
              <small class="textfield__hint">{{ 'checkout-delivery-details.only-letters' | translate }}</small>
            </ng-container>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="checkout__field col-md-6">
          <app-textfield
              class="checkout__textfield col-12 col-md-6"
              [form]="pointOfContactForm"
              [modelValue]="pointOfContactForm.value.phone"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-country'}"
              [inputName]="'phone'"
              label="{{'checkout-delivery-details.select-phone-number' | translate}}"
              [isRequired]="true">
          </app-textfield>
          <div class="checkout__alerts">
            <ng-container
                *ngIf="pointOfContactForm.get('phone').touched &&
                                    pointOfContactForm.get('phone').hasError('maxlength')">
              <small class="textfield__hint">
                {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthPhone} }}
              </small>
            </ng-container>
            <ng-container
                *ngIf="pointOfContactForm.get('phone').hasError('pattern')">
              <small class="textfield__hint">{{ 'checkout-delivery-details.only-numbers' | translate }}</small>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="isJpStore; else otherStores">
          <app-datepicker
              class="datepicker col-12 col-md-6"
              [form]="pointOfContactForm"
              [minimumDaysFromToday]="7"
              [modelValue]="pointOfContactForm.value.deliveryDate"
              [dateFormat]="'d/m/Y'"
              [inputName]="'deliveryDate'"
              label="{{ 'checkout-delivery-details.select-delivery-date' | translate }}"
              (selected)="setDateFormValue($event)">
          </app-datepicker>
        </ng-container>
        <ng-template #otherStores>
          <app-select-box
              class="col-12 col-md-6"
              [form]="pointOfContactForm"
              [modelValue]="pointOfContactForm.value.deliveryTime"
              [cssStyles]="{wrapper: 'selectbox-delivery-address'}"
              [options]="deliveryTimes"
              [inputName]="'deliveryTime'"
              label="{{ 'checkout-delivery-details.select-delivery-time' | translate }}"
              [isRequired]="false"
              (selected)="setOrderFormValue($event)">
          </app-select-box>
        </ng-template>
      </div>
      <div class="row">
        <app-textarea
            class="col-12"
            [form]="pointOfContactForm"
            [modelValue]="pointOfContactForm.value.comment"
            [rows]="3"
            [cssStyles]="{wrapper: 'text-area-comment'}"
            [inputName]="'comment'"
            [maxLength]="maxLengthComment"
            [isRequired]="isJpStore"
            label="{{ 'checkout-delivery-details.comment' | translate }}"
            trim
            trimWhitespaces
            (areaFilled)="setOrderFormCommentValue($event)">
        </app-textarea>
        <div class="checkout__alerts">
          <ng-container *ngIf="pointOfContactForm.value.comment">
            <small class="textfield__hint">
              {{ pointOfContactForm.value.comment.length }}/{{ maxLengthComment }}
            </small>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button type="cancel" class="button button" (click)="resetForm()"
              data-dismiss="modal-dialog">{{'order-approve.cancel-modal' | translate}}</button>
      <button type="submit" class="button button--primary" [disabled]="!formIsValid() || compareFormToPocData()"
              (click)="saveAddressPOC()"
              data-dismiss="modal-dialog">{{'order-approve.save-changes-modal' | translate}}</button>
    </ng-container>
  </app-modal>
</form>
