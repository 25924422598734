<div class="advanced-info-wrapper"
     [ngClass]="!isEditingEnabled ?
      'is-not-editable-material-master-numbers' :
       ''"
>
  <div *ngFor="let key of fillMasterNumbers()" class="input-container">
    <label [for]="key"
           [attr.data-test]="'material-master-number-'+ key + '-label'">
      {{ 'material-master-number.' + key | translate }}
    </label>
    <input
        [id]="key"
        type="text"
        class="basic-input"
        spellcheck="false"
        autocomplete="off"
        [disabled]="!isEditingEnabled"
        [attr.data-test]="'material-master-number-'+ key + '-input'"
        [(ngModel)]="materialMasterData[key]"
        [maxlength]="20"
        (input)="updateInputValuesAndStoreIt()"/>
  </div>
</div>

