import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ICatalogSearchSuggestionDTO } from '../models/catalog.models';
import { GlueConfigurationService } from './glue-configuration.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  getSuggestions(query = ''): Observable<ICatalogSearchSuggestionDTO> {
    const catalogSearchSuggestionUrl = this.glueUrl + '/catalog-search-suggestions?q=' + query;
    return this.http.get<ICatalogSearchSuggestionDTO>(catalogSearchSuggestionUrl)
      .pipe(
        catchError(error => throwError(() => error)),
      );
  }
}
