import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { formatDate } from '@angular/common';
import { DateUtils } from '../../utils/date.utils';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
  isActive: boolean = false;
  isInvalid: boolean = false;
  isSelected: boolean = false;
  showCalendarIcon: boolean = false;
  monthSelectorType: 'static' | 'dropdown' = 'static';
  mode: 'single' | 'multiple' | 'range' = 'single';
  minDateFormat: Date | string = new Date();
  maxDateFormat: Date | string = new Date();

  @Input() label: string;
  @Input() isRequired: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() inputName: string;
  @Input() minimumDaysFromToday = 0;
  @Input() maxDate: string | Date;
  @Input() form: UntypedFormGroup;
  @Input() modelValue: string = '';
  @Input() dateFormat: string = 'M-d-Y';
  @Input() disabled: boolean = false;
  @Output() selected: EventEmitter<{value: any, key: string}> = new EventEmitter<{value: any, key: string}>();
  @Output() resetInput: EventEmitter<{value: any, key: string}> = new EventEmitter<{value: any, key: string}>();

  ngOnInit(): void {
    this.isActive = !!this.modelValue;
    this.minDateFormat = this.minimumDaysFromToday ? DateUtils.getCurrentDate(this.minimumDaysFromToday) : null;
    this.maxDateFormat = this.maxDate ? formatDate(this.maxDate, 'yyyy-MM-dd', 'en') : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.revalidate();
    setTimeout(() => {
      if (changes.modelValue?.previousValue && !changes.modelValue?.currentValue) {
        this.resetDatepicker();
      }
    }, 0);
  }

  /**
   * Open or close Datepickr
   */
  setOpenCloseDatepickr(): void {
    this.isActive = !this.isActive;
    this.disabled = !this.disabled;
  }

  /**
   * Show calendar icon
   */
  setShowCalendarIcon(): void {
    this.showCalendarIcon = true;
  }

  /**
   * Emit update of advanced filter
   */
  update(): void {
    if (this.form) {
      this.form.get(this.inputName).markAsTouched();
    }

    this.selected.emit({value: this.modelValue, key: this.inputName});
  }

  /**
   * Reset Datepickr modal value
   */
  resetDatepicker(): void {
    this.modelValue = '';
    this.showCalendarIcon = false;
    this.revalidate();
    this.update();
    this.resetInput.emit({value: '', key: this.inputName});
  }

  /**
   * Get label
   * @returns {string}
   */
  getLabel(): string {
    return `${this.label}${this.isRequired ? '*' : ''}`;
  }

  /**
   * Revalidate form
   */
  revalidate(): void {
    if (this.form) {
      this.isInvalid = !this.modelValue && this.isRequired && this.form.get(this.inputName).touched;
    }
  }
}
