import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlueConfigurationService } from './glue-configuration.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IShsEquipment } from '../models/installedbase.models';
import { EGlueResource } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class ShsEquipmentService {
  glueUrl: string = this.glueConfiguration.getEndpointUrl();
  localStorage: Storage = window.localStorage;

  constructor(private glueConfiguration: GlueConfigurationService,
              private httpClient: HttpClient,
  ) {
  }

  private static handleOpalError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  getShsEquipment(functionalLocation: string): Observable<IShsEquipment> {
    const navigationBarUrl = this.glueUrl + `/${EGlueResource.SHS_EQUIPMENTS}/${functionalLocation}`;
    return this.httpClient.get<IShsEquipment>(navigationBarUrl)
      .pipe(catchError(ShsEquipmentService.handleOpalError));
  }

}
