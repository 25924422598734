import { ICart } from '../models/cart.models';

export class SparePartsUtils {
  /**
   * Checking if current cart has sold-to address for disabling button - Add to cart
   * If user is BP and doesn't have SOLD-TO address, then button for Add to cart will be disabled
   * Method is used for checking if user is able to change cart items (quantity, removing from cart etc.)
   *  @returns {boolean}
   */
  static canCartItemsBeChanged(currentCart: ICart, isBusinessPartner: boolean): boolean {
    return !currentCart?.attributes?.soldToAddress
      && isBusinessPartner
      && (
        !!currentCart?.attributes?.systemDetails?.siemensEquipmentId
        && !!currentCart.attributes?.systemDetails?.materialNumber
        && !!currentCart.attributes?.systemDetails?.nameEnUs
      );
  }
}
