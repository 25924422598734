<div *ngIf="isEquipmentComponentsVisible" class="my-equipment-component">
  <div class="my-equipment-component__title" [attr.data-test]="'desc-tableTitle'">
    {{ 'my-contracts.equipment-components-title' | translate }}
  </div>
  <div class="table">
    <div class="table__header" [attr.data-test]="'desc-tableHeader'">
      <div *ngFor="let attribute of attributes" class="table__column" [attr.data-test]="'desc-header' + attribute.dataTestTitle">{{ attribute.key | translate }}</div>
    </div>
    <div *ngFor="let item of equipmentData" class="table__data" [attr.data-test]="'desc-tableData'">
      <div *ngFor="let attribute of attributes" class="table__column" [attr.data-test]="'desc-data' + attribute.dataTestTitle">{{ getAttribute(item, attribute.title) }}
      </div>
    </div>
  </div>
</div>
