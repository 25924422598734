import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import { User } from '@auth0/auth0-spa-js';

export const authStatusFeatureKey = 'authStatus';

export interface State {
  user: User;
  isAuthenticated: boolean;
  token: string;
  isLoginInProcess: boolean;
}

export const initialState: State = {
  user: null,
  isAuthenticated: false,
  token: '',
  isLoginInProcess: false,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.loadUserSuccess, (state, { user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(
    AuthActions.checkAuthSuccess,
    AuthActions.setNotAuthenticated,
    (state, { isAuthenticated }) => {
      return {
        ...state,
        isAuthenticated,
      };
    }
  ),
  on(
    AuthActions.loadAccessTokenSuccess,
    (state, { token }) => {
      return {
        ...state,
        token,
      };
    }
  ),
  on(
    AuthActions.setIsLoginInProcess,
    (state, { isLoginInProcess }) => {
      return {
        ...state,
        isLoginInProcess,
      };
    }
  )
);

export const selectUser = (state: State) => state.user;
export const selectIsAuthenticated = (state: State) => state.isAuthenticated;
export const selectAccessToken = (state: State) => state.token;
export const selectIsLoginInProcess = (state: State) => state.isLoginInProcess;
