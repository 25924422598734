<div class="content-banner" [appLoadingAnimation]="(isContentBannerDataLoading$ | async)">
  <div
      class="container"
      [ngClass]="bannerData.clickUrl ? 'hero-block hero-block__clickable' : 'hero-block'">
    <a class="banner-link" [href]="bannerData.clickUrl">
      <div
          class="row"
          [ngClass]="bannerData.theme.toLowerCase()==='light' ? 'light-theme' : 'dark-theme'">
        <div class="hero-block__image-wrapper col-12 col-lg-6 order-lg-2">
          <div class="hero-block__image aspect-ratio-21x9 aspect-ratio--contain">
            <img
                [src]="bannerData.imageUrl" [alt]="bannerData.altText" [attr.data-default-src]="defaultImg"
                [ngClass]="bannerData.covered ? 'stage-slide__image' : ''"
                [attr.data-test]="'banner-image'"/>
          </div>
        </div>
        <div class="hero-block__panel col-12 col-lg-6 order-lg-1">
          <div class="hero-block__content">
            <div class="hero-block__dots" *ngIf="bannerData.clickUrl !=='/' && !!bannerData.clickUrl"
                 [attr.data-test]="'banner-dots'">
              <div class="dot">
                <uimc-dot-pulse/>
              </div>
            </div>
            <h1 class="hero-block__title" [attr.data-test]="'banner-title'">
              {{ bannerData.title }}
            </h1>
            <h6 class="hero-block__subtitle"
                *ngIf="bannerData.clickUrl !=='/' && !!bannerData.clickUrl && bannerData.subtitle"
                [attr.data-test]="'banner-subtitle'">
              {{ bannerData.subtitle }}
            </h6>
            <div class="hero-block__button-wrap"
                 *ngIf="bannerData.clickUrl !=='/' && !!bannerData.clickUrl && bannerData.buttonLabel && bannerData.clickUrl">
              <a
                  class="button button--primary hero-block__button"
                  [attr.data-test]="'banner-button'">
                {{bannerData.buttonLabel}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
