<app-access-control [notForViewer]="true" [description]="true">
  <ng-container ngProjectAs="[slot][nonViewerContent]">
    <ng-container *ngIf="wishlist; else loading">
      <div class="wishlist">
        <a class="wishlist__back" routerLink="/wishlists">
          <span class="icon-arrow-left"></span>
          {{ 'wishlists.back' | translate }}
        </a>
        <div class="wishlist__top">
          <div class="wishlist__title">
            <h2 class="title" [attr.data-test]="'desc-wishlistTitle'">{{ wishlist.attributes.name }}</h2>
            <span class="wishlist__time" [attr.data-test]="'desc-wishlistLastUpdate'">
            {{ wishlist.attributes.updatedAt | formatDate }}
              ({{ 'wishlists.last-time' | translate }})
          </span>
          </div>
          <div class="wishlist__actions">
            <div
                class="action__edit"
                [ngClass]="{'disabled': editInProgress}"
                (click)="edit()"
                [attr.data-test]="'button-wishlist-edit'">
              <span class="icon-settings"></span>
              {{ 'wishlists.edit' | translate }}
              <div *ngIf="editInProgress" class="loading-animation"></div>
            </div>
            <div class="action__remove"
                 (click)="removeModalActive = true"
                 [attr.data-test]="'button-wishlist-remove'">
              <span class="icon-close"></span>
              {{ 'wishlists.remove' | translate }}
            </div>
          </div>
        </div>
        <ng-container *ngIf="itemsLoaded; else itemsLoader">
          <ng-container *ngIf="wishlistProducts.length > 0; else empty">
            <div class="select">
              <div class="select__all">
                <input
                    type="checkbox"
                    id="select-all"
                    name="select-all"
                    class="checkbox"
                    [checked]="selectAllValue || isAllProductsSelected()"
                    (change)="selectAll($event)"
                    [attr.data-test]="'input-selectAll'">
                <label for="select-all">
                  {{ 'wishlists.select-all' | translate }}
                </label>
              </div>
              <div class="select__actions">
                <div class="select__add-all"
                     [ngClass]="{'disabled': (!areSelectedProductAvailable())}"
                     appDebounceClick
                     [debounceTime]="300"
                     (click)="addAllSelectedToCart()"
                     [attr.data-test]="'icon-product-addToCart'">
                  <span class="icon-basket"></span>
                  {{ 'wishlists.add-selected' | translate }}
                </div>
                <div class="select__remove-all"
                     [ngClass]="{'disabled': (selectedProducts.length === 0)}"
                     appDebounceClick
                     [debounceTime]="300"
                     (click)="removeAllSelectedItems()"
                     [attr.data-test]="'icon-product-remove-all'">
                  <span class="icon-close"></span>
                  {{ 'wishlists.remove-selected' | translate }}
                </div>
              </div>
            </div>
            <!-- //NOSONAR --><table class="products__table">
            <!-- //Reason why was added here NOSONAR, because <thead> in <table> its not required, in the future we will update rules in SonarQube  -->
              <caption>{{ 'wishlists.products' | translate }}</caption>
              <tbody>
              <tr class="products__row" *ngFor="let product of wishlistProducts"
                  [attr.data-test]="'wishlist-item-' + product.name">
                <td class="col__select">
                  <div class="product__select">
                    <input
                        type="checkbox"
                        [id]="'select-'+product.sku"
                        [name]="'checkbox-'+product.sku"
                        class="checkbox"
                        [checked]="selectedProducts.includes(product.sku)"
                        (change)="selectProduct(product)"
                        [attr.data-test]="'input-select-'+product.sku">
                    <label [for]="'select-'+product.sku"></label>
                  </div>
                </td>
                <td class="col__img">
                  <a [routerLink]="['/product/', product.abstractSku]">
                    <img [src]="product.imgUrl" [alt]="product.name" [attr.data-test]="'img-productImage'">
                  </a>
                </td>
                <td class="col__title">
                  <a [routerLink]="['/product/', product.abstractSku]"
                     [attr.data-test]="'link-item'">{{ product.name }}</a>
                  <p *ngIf="isUsStore || isCaStore" class="col__subtitle"
                     [attr.data-test]="'desc-item-sku'">{{ product.materialNumber }}</p>
                </td>
                <td *ngIf="!isUsStore && !isCaStore" class="col__price">
                  {{ product.defaultPrice ?
                    (priceUtils.getPriceValue(product.defaultPrice) | currency: currency) :
                    '&nbsp;'
                  }}
                </td>
                <td class="width-40"
                    *ngIf="!checkIfProductIsAvailableForOnlinePurchase(product); else productAvailable">
                  <div class="notification__header" [attr.data-test]="'desc-productIsNotAvailable'">
                    <span class="icon-info icon-info__circle icon-orange-white"></span>
                    <p class="font-weight-bold product-not-available margin-0">
                      {{ 'app-product-page.product-is-not-available-for-online-purchase' | translate }}
                    </p>
                  </div>
                </td>
                <ng-template #productAvailable>
                  <td *ngIf="!isAddToCartDisabled(); else productsNotLoaded">
                    <div class="display-flex">
                      <div class="col__qty">
                        <input
                            #quantity
                            type="number"
                            [name]="'quantity-'+product.sku"
                            [value]="product.qty"
                            min="1"
                            step="1"
                            class="product__qty--input"
                            [disabled]="isItemInProgress(product.sku)"
                            (change)="updateProductQty(product.sku, quantity.value)"
                            [attr.data-test]="'icon-product-qty'"
                        />
                      </div>
                      <div class="col__menu">
                        <div class="product__menu" [ngClass]="{'disabled': isItemInProgress(product.sku)}">
                      <span
                          appDebounceClick
                          [ngClass]="{'disabled': isAddToCartDisabled()}"
                          [style.visibility]="isItemInProgress(product.sku) || (!isSapP40Enable && (isUsStore || isCaStore)) ? 'hidden' : 'visible'"
                          class="icon-basket product__add"
                          [attr.data-test]="'icon-product-add'"
                          [debounceTime]="300"
                          (click)="addProductToCart({
                           sku: product.sku,
                           name: product.name || product.sku,
                           pricingGroup: product.pricingGroup
                          })"></span>
                          <span
                              appDebounceClick
                              [ngClass]="{'disabled': !isSapP40Enable && (isUsStore || isCaStore)}"
                              [style.visibility]="(isSapP40Enable && (isUsStore || isCaStore)) || (!isSapP40Enable && !isUsStore && !isCaStore)  ? 'hidden' : 'visible'"
                              class="icon-basket product__add"></span>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-template>
                <ng-template #productsNotLoaded>
                  <td class="loader__overlay product__loader">
                    <span class="loading-animation"></span>
                  </td>
                </ng-template>
                <td class="width-10">
                  <span class="icon-close product__remove"
                        [attr.data-test]="'icon-product-remove'"
                        appDebounceClick
                        [debounceTime]="300"
                        [style.visibility]="isItemInProgress(product.sku) ? 'hidden' : 'visible'"
                        (click)="removeItemFromWishlist(product.sku)"></span>
                  <div *ngIf="isItemInProgress(product.sku)"
                       class="loader__overlay product__loader">
                    <span class="loading-animation"></span>
                  </div>
              </tr>
              </tbody>
            </table>
            <div class="wishlist__summary" [attr.data-test]="'desc-wishlist-summary'">
              <ng-container *ngIf="getProductsQty() === 1; else multiProduct">
                {{ 'wishlists.single-product' | translate }}
              </ng-container>
              <ng-template #multiProduct>
                {{ 'wishlists.multi-product' | translate: {number: getProductsQty()} }}
              </ng-template>
              <div class="wishlist__total" *ngIf="getTotalPrice() > 0 && !isUsStore && !isCaStore">
                {{ 'wishlists.total-price' | translate }}
                {{ getTotalPrice() | currency: currency }}
              </div>
            </div>
          </ng-container>
          <ng-template #empty>
            <div class="empty-wishlist">
              {{ 'wishlists.empty' | translate }}
              <button class="button col-10 empty-cart shopping-cart-continue-shopping-button"
                      (click)="redirectToProductLists()">{{ 'shop-cart.continueShopping' | translate }}</button>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #itemsLoader>
          <div class="loader__content">
            <div class="loading-animation"></div>
          </div>
        </ng-template>
      </div>
      <app-wishlist-edit
          [name]="wishlist.attributes.name"
          [id]="wishlist.id"
          [showModal]="editModalActive"
          (closeModal)="editModalActive = false"
          (inProgress)="editInProgress = true; editModalActive = false"
          (edited)="wishlistEdited($event)">
      </app-wishlist-edit>
      <app-wishlist-remove
          [wishlist]="wishlist"
          [showModal]="removeModalActive"
          (closeModal)="removeModalActive = false"
          (removed)="wishlistRemoved()">
      </app-wishlist-remove>
      <app-add-item-modal
          [showModal]="addItemToCartModalActive"
          (closeModal)="addItemToCartModalActive = false"
          [items]="items"
          [currentCartId]="currentCartId">
      </app-add-item-modal>
      <app-modal-confirm
          [type]="iconType.WARNING"
          modalTitle="{{ 'catalog.select-equipment-modal.title' | translate }}"
          notifyText="{{ 'catalog.select-equipment-modal.text' | translate }}"
          cancelBtnText="{{ 'catalog.select-equipment-modal.cancel-btn' | translate }}"
          primaryBtnText="{{ 'catalog.select-equipment-modal.submit-btn' | translate }}"
          [showModalConfirm]="showModalSelectEquipment"
          (closeModalConfirm)="showModalSelectEquipment = false"
          (primaryActionConfirm)="selectEquipment()">
      </app-modal-confirm>
    </ng-container>
    <ng-template #loading>
      <div class="loader__content">
        <div class="loading-animation"></div>
      </div>
    </ng-template>
  </ng-container>
</app-access-control>
