import {
  Directive,
  EventEmitter,
  Output,
  HostListener,
  HostBinding,
} from '@angular/core';
@Directive({
  selector: '[appDragDropFileUpload]',
})
export class DragDropFileUploadDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') private background = '#f9f9f9';

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#e6e6e6';
  }

  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#f9f9f9';
  }

  // Drop Event
  @HostListener('drop', ['$event']) public drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#f9f9f9';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // Trigger the file input click programmatically
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.click();
    inputElement.addEventListener('change', (fileChangeEvent: Event) => {
      // Handle the file change event here (e.g., upload the file)
      const target = fileChangeEvent.target as HTMLInputElement;
      const files = target.files;
      if (files?.length) {
        this.fileDropped.emit(files);
      }
    });
  }
}
