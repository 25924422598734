<div class="row">
  <div class="col-12 col-lg-8">
    <div class="order-details-container">
      <h3 class="page-title" [attr.data-test]="'title-1stDetailsPage'">
        {{ 'checkout-delivery-details.title' | translate }}
      </h3>
      <h6 class="page-subtitle" [attr.data-test]="'subtitle-1stDetailsPage'">
        {{ 'checkout-delivery-details.select-details' | translate }}
      </h6>
      <section class="order-details-form">
        <app-dynamic-checkout-form
            [isSectionDisplayed]="isSectionDisplayed"
            [visibleInputs]="deliveryDetails1FormInputs$ | async"
            [requiredInputs]="deliveryDetailsFormRequiredInputs"
            [readOnlyInputs]="deliveryDetailsFormReadOnlyInputs$ | async"
            [cartId]="cartId"
            [cartAttributes]="cartAttributes"
            [systemDetails]="systemDetails"
            [userData]="userData"
            [userRoles]="userRoles"
            [isBusinessPartner]="isBusinessPartner"
            [isSaturdayShipment]="isSaturdayShipment"
            [isPreselectedShipToAddressCustom]="isPreselectedShipToAddressCustom"
            [isPriceDisputingSetInCart]="isPriceDisputingSetInCart"
            (formValidityChange)="isDeliveryDetailsPage1FormValid = $event">
        </app-dynamic-checkout-form>
        <p *ngIf="deliveryDetailsFormRequiredInputs.length" class="disclaimer--mandatory-fields">
          <small>
            {{ 'checkout-delivery-details.mandatory-fields-required' | translate }}
          </small>
        </p>
      </section>
      <p *ngIf="isSaturdayShipment" class="disclaimer--saturday-shipment-order-details">
        {{ 'checkout-delivery-details.saturday-delivery-method-disclaimer' | translate }}
      </p>
      <section class="cart-items">
        <strong class="section-title">
          {{ 'checkout-delivery-details.your-items' | translate }}
        </strong>
        <ng-container *ngFor="let item of cartItemsWithDetails">
          <div class="cart-item-outer-container">
            <div class="cart-item" [attr.data-test]="'cart-item-' + item.attributes.name">
              <div class="cart-item--image">
                <img [src]="ImageUtils.getProductImageUrl(item)" [alt]="item.attributes.name"
                     [attr.data-test]="'img-itemImage'">
              </div>
              <div class="cart-item--body">
                <div class="cart-item--info">
                  <div class="info-header">
                    <h5 class="item-name" [attr.data-test]="'desc-itemName'">
                      {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                    </h5>
                    <span *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && item.attributes.isDiscontinued"
                          class="label discontinued-item"
                          [attr.data-test]="'label-discontinuedItem'">
                        {{ 'spare-parts.discontinued-item' | translate }}
                      </span>
                    <span *ngIf="item.attributes.availability?.isExchangeable"
                          class="label item-exchange-material"
                          [attr.data-test]="'label-exchangeableItem'">
                          {{ 'spare-parts.exchangeable-item' | translate }}
                      </span>
                    <span *ngIf="item.attributes.heavyweight"
                          class="label heavyweight-item"
                          [attr.data-test]="'label-heavyWeightItem'">
                          {{ 'spare-parts.heavyweight-item' | translate }}
                      </span>
                  </div>
                  <div class="item-material-number" [attr.data-test]="'desc-materialNumber'">
                    {{ item.attributes.materialNumber }}
                  </div>
                </div>
                <div class="cart-item--additional-fields">
                  <div class="additional-field-row">
                    <div [attr.data-test]="'label-quantity'">
                      {{ 'multi-cart.quantity' | translate }}:
                    </div>
                    <div [attr.data-test]="'value-quantity'">
                      {{ item.attributes.quantity }}
                    </div>
                  </div>
                  <div class="additional-field-row">
                    <div [attr.data-test]="'label-pricePerUnit'">
                      {{ 'multi-cart.price-per-unit' | translate }}:
                    </div>
                    <div [attr.data-test]="'value-pricePerUnit'">
                      {{ item.attributes.calculations.unitPrice | currency: cartAttributes.currency }}
                    </div>
                  </div>
                  <div class="additional-field-row">
                    <div [attr.data-test]="'label-totalPrice'">
                      {{ 'multi-cart.total-price' | translate }}:
                    </div>
                    <div [attr.data-test]="'value-totalPrice'">
                      {{ item.attributes.calculations.sumPrice | currency: cartAttributes.currency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-message
                *ngIf="isPriceDisputingActive && CartUtils.isPriceDisputingSetForItem(priceDisputingPerItem, item.id)"
                [message]="CartUtils.priceDisputingMessage"/>
          </div>
        </ng-container>
      </section>
    </div>
  </div>
  <div class="col-12 col-lg-4">
    <div class="order-summary">
      <div class="row">
        <div class="col-12">
          <button type="button"
                  class="button button--primary button-next-step"
                  [disabled]="!isDeliveryDetailsPage1FormValid"
                  (click)="proceedToDeliveryDetails2SectionEmit()"
                  [attr.data-test]="'button-proceedToNextStep'">
            {{ 'checkout-delivery-details.next-btn' | translate }}
          </button>
          <section class="cart--pricelist">
            <div class="cart--price">
              <strong [attr.data-test]="'label-totalPrice'">
                {{ 'checkout-delivery-details.total-price' | translate }}
              </strong>
              <strong [attr.data-test]="'desc-totalPrice'">
                {{ cartAttributes.totals.subtotal | currency: cartAttributes.currency }}
              </strong>
            </div>
            <div class="cart--additional-price-details">
            <span [attr.data-test]="'desc-priceInfo'">
              {{ 'checkout-delivery-details.price-info' | translate }}
            </span>
              <br>
              <span *ngIf="cartAttributes?.totals?.expenseTotal === 0 && cartAttributes?.totals?.shipmentTotal === 0"
                    [attr.data-test]="'desc-shipping'">
              {{ 'checkout-delivery-details.free-shipping' | translate }}
            </span>
            </div>
          </section>
          <app-cart-rules
              *ngIf="cartRules?.length > 0"
              [cartRules]="cartRules"
              [priceToPay]="cartAttributes.totals.priceToPay"
              [currency]="cartAttributes.currency">
          </app-cart-rules>
          <hr class="bottom-line">
          <a class="button button--link button-back-to-cart"
             [routerLink]="['/shop-cart']"
             [attr.data-test]="'link-backToCart'">
            <span class="icon-arrow-left color-orange"></span>
            {{ 'checkout-delivery-details.back-to-cart' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
