import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { IAddress, IPriceDisputingPerItem, ISystemDetails, ITotals } from '../../../models/common.models';
import { EFeatureToggles, EGlueResource, EUserRoles } from '../../../configurations/common';
import { ICartAttributes, ICartItemWithDetail, ICartRule, ICartVoucher } from '../../../models/cart.models';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { ImageUtils } from '../../../utils/image.utils';
import { ICustomerCheckoutData } from '../../../models/customer.models';
import { CartUtils } from '../../../utils/cart.utils';
import { DynamicCheckoutFormComponent } from '../../../shared/dynamic-checkout-form/dynamic-checkout-form.component';
import { DynamicCheckoutApproverFormComponent } from './dynamic-checkout-approver-form/dynamic-checkout-approver-form.component';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { GlueUtils } from '../../../utils/glue.utils';
import { FileType, FileUtils } from '../../../utils/file.utils';

@Component({
  selector: 'app-delivery-details-2-section',
  templateUrl: './delivery-details-2-section.component.html',
  styleUrl: './delivery-details-2-section.component.scss',
})
export class DeliveryDetails2SectionComponent implements OnInit, OnDestroy {
  protected readonly ImageUtils = ImageUtils;
  protected readonly CartUtils = CartUtils;
  private unsubscribe$: Subject<void> = new Subject<void>();

  isDeliveryDetailsPage2FormValid: boolean = false;
  deliveryDetails2FormInputs$: Observable<string[]> = new Observable<string[]>();
  deliveryDetailsFormRequiredInputs: string[] = [];
  deliveryDetailsFormReadOnlyInputs$: Observable<string[]> = new Observable<string[]>();

  isApproverFormValid: boolean = false;
  approverFormInputs$: Observable<string[]> = new Observable<string[]>();
  approverFormRequiredInputs: string[] = [];
  approverFormReadOnlyInputs$: Observable<string[]> = new Observable<string[]>();
  selectedApprover: string;

  totalPrices$: Observable<ITotals> = new Observable<ITotals>();
  totalPricesLoading: boolean = true;

  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();
  voucherList$: Observable<ICartVoucher[]> = new Observable<ICartVoucher[]>();

  isPdfPreviewQuoteEnabled: boolean = false;
  creatingPdfPreviewQuoteInProgress: boolean = false;

  @Input() isSectionDisplayed: boolean = false;
  @Input() cartId: string;
  @Input() cartAttributes: ICartAttributes;
  @Input() cartItemsWithDetails: ICartItemWithDetail[];
  @Input() cartRules: Array<ICartRule>;
  @Input() systemDetails: ISystemDetails;
  @Input() userRoles: EUserRoles[];
  @Input() userData: ICustomerCheckoutData;
  @Input() shipToAddress: IAddress;
  @Input() isPreselectedShipToAddressCustom: boolean = false;
  @Input() isBusinessPartner: boolean = false;
  @Input() isSaturdayShipment: boolean = false;
  @Input() isPriceDisputingActive: boolean = false;
  @Input() isPriceDisputingSetInCart: boolean = false;
  @Input() priceDisputingPerItem: IPriceDisputingPerItem[] = [];
  @Input() creatingOrderInProgress: boolean = false;
  @Input() isApproverBuyerWorkflowEnabled: boolean = false;
  @Input() hasApproverAccess: boolean = false;

  @Output() backToDeliveryDetails1Section: EventEmitter<void> = new EventEmitter<void>();
  @Output() createOrder: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(DynamicCheckoutFormComponent) dynamicCheckoutForm: DynamicCheckoutFormComponent;
  @ViewChild(DynamicCheckoutApproverFormComponent) dynamicCheckoutApproverForm: DynamicCheckoutApproverFormComponent;

  constructor(
    private checkoutFacade: CheckoutFacade,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
  ) {
  }

  /**
   * Initializes the component by getting necessary Arakh configs/feature toggles
   * for the component and its child components (dynamic-checkout-form, dynamic-checkout-approver-form).
   */
  ngOnInit(): void {
    this.deliveryDetails2FormInputs$ = this.configurationFacade.getDynamicDeliveryDetailsFormConfiguration(2);
    this._selectDeliveryDetailsFormRequiredInputs();
    this.deliveryDetailsFormReadOnlyInputs$ = this.configurationFacade.getDynamicDeliveryDetailsFormReadOnlyInputs();

    if (this.isApproverBuyerWorkflowEnabled && !this.hasApproverAccess) {
      this.approverFormInputs$ = this.configurationFacade.getDynamicDeliveryDetailsApproverFormConfiguration();
      this._selectApproverFormRequiredInputs();
      this.approverFormReadOnlyInputs$ = this.configurationFacade.getDynamicDeliveryDetailsApproverFormReadOnlyInputs();
    }

    this.voucherList$ = this.checkoutFacade.selectCartVouchers();
    this._selectTotalPrices();
    this._selectIsPdfPreviewQuoteEnabled();

    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(
      EFeatureToggles.PRODUCT_DISCONTINUED_STATUS,
    );
  }

  /**
   * Cleans up the component by caching the delivery details 2 form data and completing the unsubscribe$ subject.
   */
  ngOnDestroy(): void {
    this._cacheCheckoutDeliveryDetails2Form();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Caches the delivery details 2 form data and emits the event to proceed back to the delivery details section 1.
   */
  backToDeliveryDetails1SectionEmit(): void {
    this._cacheCheckoutDeliveryDetails2Form();
    this.backToDeliveryDetails1Section.emit();
  }

  /**
   * Checks if the "Proceed to create order" button should be disabled.
   *
   * It should return true if at least one condition is met:
   * - the delivery details 2 form is invalid
   * - creating order process is in progress
   * - creating PDF preview quote process is in progress
   * - total prices are loading
   *
   * Otherwise, it should return false.
   *
   * @returns {boolean}
   */
  isProceedToCreateOrderBtnDisabled(): boolean {
    return !this.isDeliveryDetailsPage2Valid()
      || this.creatingOrderInProgress
      || this.creatingPdfPreviewQuoteInProgress
      || this.totalPricesLoading;
  }

  /**
   * Checks if the delivery details 2 form is valid.
   *
   * If approver/buyer workflow is enabled and user is approver,
   * the approver form validity is checked as well.
   *
   * @private
   *
   * @returns {boolean}
   */
  isDeliveryDetailsPage2Valid(): boolean {
    return this.isApproverBuyerWorkflowEnabled && !this.hasApproverAccess
      ? this.isDeliveryDetailsPage2FormValid && this.isApproverFormValid
      : this.isDeliveryDetailsPage2FormValid;
  }

  /**
   * Caches the delivery details 2 form data and emits the createOrder event.
   */
  proceedToCreateOrderEmit(): void {
    this._cacheCheckoutDeliveryDetails2Form();
    this.createOrder.emit();
  }

  /**
   * Caches the delivery details 2 form data.
   *
   * If approver/buyer workflow is enabled and user is approver,
   * the approver form data is cached as well.
   *
   * @private
   */
  private _cacheCheckoutDeliveryDetails2Form(): void {
    this.dynamicCheckoutForm.cacheCheckoutFormData();
    if (this.isApproverBuyerWorkflowEnabled && !this.hasApproverAccess) {
      this.dynamicCheckoutApproverForm.cacheCheckoutApproverFormData();
    }
  }

  /**
   * Selects the required inputs for the delivery details 2 form.
   *
   * @private
   */
  private _selectDeliveryDetailsFormRequiredInputs(): void {
    this.configurationFacade.getDynamicDeliveryDetailsFormRequiredInputs().pipe(take(1)).subscribe((value) => {
      this.deliveryDetailsFormRequiredInputs = value;
    });
  }

  /**
   * Selects the required inputs for the approver form based on the Arakh config.
   *
   * @private
   */
  private _selectApproverFormRequiredInputs(): void {
    this.configurationFacade.getDynamicDeliveryDetailsApproverFormRequiredInputs().pipe(take(1)).subscribe((value) => {
      this.approverFormRequiredInputs = value;
    });
  }

  /**
   * Selects the total prices for the cart.
   *
   * @private
   */
  private _selectTotalPrices(): void {
    this.totalPrices$ = this.checkoutFacade.selectTotalPrices();
    this.checkoutFacade.selectTotalPricesLoading().pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.totalPricesLoading = value;
    });
  }

  /**
   * Selects whether the PDF preview quote feature is enabled.
   *
   * @private
   */
  private _selectIsPdfPreviewQuoteEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PDF_PREVIEW_QUOTE).pipe(take(1)).subscribe(value => {
      this.isPdfPreviewQuoteEnabled = value;
    });
  }

  /**
   * Creates a PDF preview of quote.
   */
  createPdfPreviewQuote(): void {
    this.creatingPdfPreviewQuoteInProgress = true;
    this._cacheCheckoutDeliveryDetails2Form();
    this.checkoutFacade.selectPreselectedShipToAddress().pipe(take(1)).subscribe(shipToAddress => {
      this.checkoutFacade.postCheckoutUpdate({
        data: {
          type: EGlueResource.CHECKOUT_UPDATE,
          attributes: {
            idCart: this.cartId,
            shippingAddress: shipToAddress,
            isAddressSavingSkipped: true,
          },
        },
      }).pipe(take(1)).subscribe({
        next: () => this._generatePdfPreviewQuote(),
        error: () => this.creatingPdfPreviewQuoteInProgress = false,
      });
    });
  }

  /**
   * Generates a PDF preview file of quote.
   *
   * @private
   */
  private _generatePdfPreviewQuote(): void {
    this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, true).pipe(
      take(1),
    ).subscribe({
      next: file => FileUtils.saveAndOpenFile(file, FileType.PDF, `PREVIEW_details_${this.cartId}`),
      complete: () => this.creatingPdfPreviewQuoteInProgress = false,
      error: () => this.creatingPdfPreviewQuoteInProgress = false,
    });
  }

  /**
   * Gets the voucher title as a percentage of the cart subtotal.
   *
   * @param {ICartVoucher} voucher
   * @param {ITotals} cartTotals
   * @return {number}
   */
  getVoucherTitle(voucher: ICartVoucher, cartTotals: ITotals): number {
    const voucherAmount = voucher.attributes.amount;
    return Math.round((voucherAmount * 100) / cartTotals.subtotal);
  }

  /**
   * Removes a voucher from the cart.
   *
   * @param {ICartVoucher} voucher
   */
  removeVoucher(voucher: ICartVoucher): void {
    this.checkoutFacade.setCheckoutTotalPricesLoading(true);
    this.marketingFacade.removeVoucher(this.cartId, voucher.id).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: response => {
        this.checkoutFacade.setCheckoutTotalPricesLoading(false);
        this.checkoutFacade.setCheckoutTotalPrices(response.data.attributes.totals);
        this.checkoutFacade.setCartVouchers(
          GlueUtils.filterResourcesByType(response.included, EGlueResource.VOUCHERS) as ICartVoucher[],
        );
      },
      error: () => {
        this.checkoutFacade.setCheckoutTotalPricesLoading(false);
      },
    });
  }
}
