<div class="accordion orders__table">
  <div class="orders-table__header">
    <div class="width-25">{{ 'orders-page.request' | translate }}</div>
    <div class="width-25">{{ 'orders-page.date' | translate }}</div>
    <div class="width-25 padding-left-2 text-align-left">{{ 'orders-page.status' | translate }}</div>
    <div class="width-15 padding-left-3">{{ 'orders-page.total' | translate }}</div>
    <div class="width-10">&nbsp;</div>
  </div>
  <div class="order-history__table">
    <div class="table-items">
      <app-accordion
          *ngFor="let request of orders | searchFilter:searchValue" [attr.data-test]="'previousRequest-' + request.id">
        <ng-container ngProjectAs="[slot][header]">
          <div class="accordion__title icon-arrow-down"
               (click)="toggleTab(request.id)">
            <div class="order-info width-25 order-id" *ngIf="request.type !== 'Carts'; else cartId">
              <span class="header__label" [attr.data-test]="'label-requestId'">
                {{ 'orders-page.request' | translate }}:
              </span>
              <span [attr.data-test]="'desc-requestId'">
                {{ request.id }}
              </span>
            </div>
            <ng-template #cartId>
              <div class="order-info width-25 request-id">
                <span class="header__label" [attr.data-test]="'label-requestId'">{{ 'orders-page.request' | translate }}:</span>
                <span [attr.data-test]="'desc-requestId'">
                  #{{ request.id.split('-')[0] }}
                </span>
              </div>
            </ng-template>
            <div class="order-info width-25 order-date">
              <span class="header__label" [attr.data-test]="'label-orderDate'">{{ 'orders-page.date' | translate }}:</span>
              <span [attr.data-test]="'desc-orderDate'">
                {{ ((request.type === 'Carts' && request.attributes.orderedAt) ? request.attributes.orderedAt : request.attributes.createdAt) | formatDate }}
              </span>
              <small class="user-name" [attr.data-test]="'desc-customer'">
                {{ request.attributes.customer.firstName }} {{ request.attributes.customer.lastName }}
              </small>
            </div>
            <div class="order-info width-25 order-status">
              <span class="header__label" [attr.data-test]="'label-orderStatus'">{{ 'orders-page.status' | translate }}:</span>
              <div class="text-capitalize display-flex justify-content-center flex-column">
                <div class="order-status__icon">
                  <span class="status__icon"
                        [ngClass]="getIcon(request.attributes.status)"
                        [attr.data-test]="'icon-orderStatus'">
                  </span>
                  <span class="status__name" [attr.data-test]="'desc-orderStatus'">
                    {{ request.attributes.status }}
                  </span>
                </div>
              </div>
            </div>
            <div class="order-info width-15 total">
              <span class="header__label" [attr.data-test]="'label-totalPrize'">{{ 'orders-page.total' | translate }}:</span>
              <div class="price">
                <div class="order-items-count">
                  <ng-container *ngIf="request.itemsTotalNumber > 1">
                    <span [attr.data-test]="'desc-quantity'">
                      {{ 'orders-page.item-plural' | translate:{number: request.itemsTotalNumber} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="request.itemsTotalNumber === 1">
                    <span [attr.data-test]="'desc-quantity'">
                      {{ 'orders-page.item-singular' | translate:{number: request.itemsTotalNumber} }}
                    </span>
                  </ng-container>
                </div>
              </div>
              <span class="additional-info"></span>
            </div>
            <div class="width-10"></div>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content">
            <app-my-request-details
                [request]="request"
                [isOpen]="tabsOpened.includes(request.id)">
            </app-my-request-details>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
