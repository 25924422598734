import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IInstallBaseProducts, IShsEquipmentData } from '../models/installedbase.models';
import * as InstallBaseActions from '../actions/install-base.actions';
import { IAddressReduced, ICompanyBusinessUnitAddressesAttributes } from '../models/customer.models';
import { IAddress } from '../models/common.models';

export interface IInstallBaseState {
  equipments: IShsEquipmentData[] | [],
  equipmentsLoading: boolean,
  equipmentsLoadingError: string | number,
  equipmentsAddresses: IAddress[],
  product: IInstallBaseProducts | undefined,
  productLoading: boolean,
  getProductError: string | number,
  cpqEquipments: IShsEquipmentData[],
  cpqEquipmentsLoading: boolean,
  cpqEquipmentsLoadingError: string | number,
  sparePartsEquipments: IShsEquipmentData[],
  sparePartsEquipmentsLoading: boolean,
  sparePartsEquipmentsLoadingError: string | number,
}

export const installedBaseState = createFeatureSelector<IInstallBaseState>('installBase');

export const selectInstalledBaseData = createSelector(
  installedBaseState,
  state => state.equipments,
);

export const selectInstalledBaseDataError = createSelector(
  installedBaseState,
  state => state.equipmentsLoadingError,
);

export const selectInstalledBaseSystemDataLoading = createSelector(
  installedBaseState,
  state => state.equipmentsLoading,
);

export const selectCpqEquipments = createSelector(
  installedBaseState,
  state => state.cpqEquipments,
);

export const selectCpqEquipmentsLoading = createSelector(
  installedBaseState,
  state => state.cpqEquipmentsLoading,
);

export const selectSparePartsEquipments = createSelector(
  installedBaseState,
  state => state.sparePartsEquipments,
);

export const selectSparePartsEquipmentsLoading = createSelector(
  installedBaseState,
  state => state.sparePartsEquipmentsLoading,
);

export const selectInstalledBaseProductData = createSelector(
  installedBaseState,
  state => state.product,
);

export const selectInstalledBaseProductDataError = createSelector(
  installedBaseState,
  state => state.getProductError,
);

export const selectInstalledBaseProductDataLoading = createSelector(
  installedBaseState,
  state => state.productLoading,
);

export const selectEquipmentsAddresses = createSelector(
  installedBaseState,
  state => state.equipmentsAddresses,
);

export const initialState: IInstallBaseState = {
  equipments: null,
  equipmentsLoading: false,
  equipmentsLoadingError: null,
  equipmentsAddresses: null,
  product: null,
  productLoading: false,
  getProductError: null,
  cpqEquipments: [],
  cpqEquipmentsLoading: false,
  cpqEquipmentsLoadingError: null,
  sparePartsEquipments: [],
  sparePartsEquipmentsLoading: false,
  sparePartsEquipmentsLoadingError: null,
};

const reducer = createReducer(
  initialState,

  on(InstallBaseActions.InstallBaseSystemsActionStartDataAction, (state: IInstallBaseState) => {
    return {
      ...state,
      equipmentsLoading: true,
    };
  }),
  on(InstallBaseActions.SuccessGetInstallBaseSystemsDataAction, (state: IInstallBaseState, {payload}) => {
    const addresses: IAddress[] = payload?.data
      .filter((equipment: IShsEquipmentData, index: number) => {
        if (equipment.attributes.shipToAddress?.sapId) {
          const indexOfAddress: number = payload?.data.findIndex((equipmentFromArray: IShsEquipmentData) =>
            JSON.stringify(equipmentFromArray.attributes.shipToAddress) === JSON.stringify(equipment.attributes.shipToAddress));
          return indexOfAddress === index;
        }
        return false;
      })
      .map((equipment: IShsEquipmentData) => {
        return {
          ...equipment.attributes.shipToAddress,
          sapId: equipment.attributes.companyBusinessUnitNumber,
        };
      });
    return {
      ...state,
      equipments: payload?.data as IShsEquipmentData[],
      equipmentsAddresses: addresses,
      equipmentsLoading: false,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsDataActionFailed, (state: IInstallBaseState, {error}) => {
    const errorMessage: string | number = error?.error?.errors?.[0].detail ?? error.status;

    return {
      ...state,
      equipmentsLoadingError: errorMessage,
      equipmentsLoading: false,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsCpqDataActionStart, (state: IInstallBaseState) => {
    return {
      ...state,
      cpqEquipmentsLoading: true,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsCpqDataActionSuccess, (state: IInstallBaseState, {payload}) => {
    return {
      ...state,
      cpqEquipmentsLoading: false,
      cpqEquipments: payload.data,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsCpqDataActionFailed, (state: IInstallBaseState, {error}) => {
    const cpqEquipmentsLoadingError = error.error.errors?.[0].detail ?? error.status;
    return {
      ...state,
      cpqEquipmentsLoading: false,
      cpqEquipmentsLoadingError,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionStart, (state: IInstallBaseState) => {
    return {
      ...state,
      sparePartsEquipmentsLoading: true,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionSuccess, (state: IInstallBaseState, {payload}) => {
    return {
      ...state,
      sparePartsEquipmentsLoading: false,
      sparePartsEquipments: payload.data,
    };
  }),
  on(InstallBaseActions.GetInstallBaseSystemsSparePartsDataActionFailed, (state: IInstallBaseState, {error}) => {
    const sparePartsEquipmentsLoadingError = error?.error?.errors?.[0].detail ?? error.status;
    return {
      ...state,
      sparePartsEquipmentsLoading: false,
      sparePartsEquipmentsLoadingError,
    };
  }),
  on(InstallBaseActions.InstallBaseProductsActionStartDataAction, (state: IInstallBaseState) => {
    return {
      ...state,
      productLoading: true,
    };
  }),
  on(InstallBaseActions.SuccessGetInstallBaseProductsDataAction, (state: IInstallBaseState, {product}) => {
    return {
      ...state,
      product: product,
      productLoading: false,
    };
  }),
  on(InstallBaseActions.GetInstallBaseProductsDataActionFailed, (state: IInstallBaseState, {error}) => {
    const errorMessage: string | number = error?.error?.errors?.[0].detail ?? error.status;

    return {
      ...state,
      getProductError: errorMessage,
      productLoading: false,
    };
  }),
);

export function InstallBaseReducer(state: IInstallBaseState | undefined, action: Action): any {
  return reducer(state, action);
}
