import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CmsFacade } from '../../../../facades/cms.facade';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { IContentBannerAttributes } from '../../../../models/cms';
import { AppUtils } from '../../../../utils/app.utils';
import { EStoreTypes } from '../../../../configurations/common';

@Component({
  selector: 'app-content-banner',
  templateUrl: './content-banner.component.html',
  styleUrls: ['./content-banner.component.scss'],
})
export class ContentBannerComponent implements OnInit, OnDestroy {
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  defaultImg: string = environment.defaultBannerImg;
  bannerData: IContentBannerAttributes = {
    title: '',
    subtitle: '',
    imageUrl: this.defaultImg,
    clickUrl: '',
    altText: '',
    buttonLabel: '',
    theme: 'dark',
    covered: '',
  };
  isContentBannerDataLoading$: Observable<boolean> = new Observable<boolean>();
  @Input() bannerKey: string;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private cmsFacade: CmsFacade,
    private translateService: TranslateService,
    private configFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isContentBannerDataLoading$ = this.cmsFacade.selectShopHomepageBannerLoading();
    this.bannerKey ? this.getContentBannerData() : this.getDefaultBanner();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getDefaultBanner(): void {
    this.bannerData = Object.assign(
      {},
      this.bannerData,
      {
        title: this.translateService.instant('home-page.hero-block'),
        subtitle: this.translateService.instant('home-page.subtitle'),
        altText: this.translateService.instant('home-page.banner-image-alt-text'),
      },
    );
    this.configFacade.homePageBannerLoaded();
  }

  getContentBannerData(): void {
    combineLatest([
      this.cmsFacade.selectShopHomepageBannerData(this.bannerKey),
      this.cmsFacade.selectShopHomepageBannerError(),
    ]).pipe(
      filter(([contentBannerData, contentBannerDataError]) => contentBannerData !== undefined),
      takeUntil(this.unsubscribe$),
    ).subscribe(([contentBannerData, contentBannerDataError]) => {
      if (contentBannerDataError === '') {
        this.bannerData = contentBannerData;
        this.bannerData.theme = this.bannerData.theme ? this.bannerData.theme : 'dark';
        this.configFacade.homePageBannerLoaded();
      } else {
        this.getDefaultBanner();
      }
    });
  }
}
