import { UrlHandlingStrategy, UrlSegment, UrlTree } from '@angular/router';
import { I18nService } from '../services';
import { lmExclusions } from './languageMarketExclusions';

export class LanguageUrlStrategy implements UrlHandlingStrategy {
  service = I18nService.prototype;
  currentLanguage: string;

  shouldProcessUrl(url: UrlTree): boolean {
    return true;
  }

  extract(url: UrlTree): UrlTree {
    return url;
  }

  merge(newUrlPart: UrlTree, wholeUrl: UrlTree): UrlTree {
    const reg = /^[a-z]{2}-[a-z]{2}$/;
    if (newUrlPart.root.children.primary) {
      const firstSegmentPath = newUrlPart.root.children.primary.segments[0].path;
      if (!reg.exec(firstSegmentPath)) {
        const is404 = newUrlPart.root.children.primary.segments.findIndex(s => s.path === 'page-not-found');
        if (is404 !== -1) {
          this.currentLanguage = this.service.getCurrentParam();
        }
        if (!lmExclusions.includes(firstSegmentPath)) {
          newUrlPart.root.children.primary.segments.splice(0, 0,
            new UrlSegment(this.currentLanguage || this.service.getCurrentParam(), {}));
        }
      } else {
        if (!this.currentLanguage) {
          this.currentLanguage = firstSegmentPath;
        }
        if (firstSegmentPath !== this.currentLanguage) {
          this.currentLanguage = firstSegmentPath;
        }
      }
    }
    return newUrlPart;
  }
}
