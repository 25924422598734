import { Component, Input } from '@angular/core';
import { AddressUtils } from '../../utils/address.utils';
import { IAddress } from '../../models/common.models';

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrl: './address-block.component.scss',
})
export class AddressBlockComponent {
  protected readonly AddressUtils = AddressUtils;

  @Input() address: IAddress;
  @Input() attentionTo: string;
}
