<div class="address"
     [ngClass]="{'preferred-address': isPreferredAddress()}"
     [attr.data-test]="isPreferredAddress() ? 'tile-preferredAddress' : 'tile-address'">
  <div *ngIf="updateInProgress" class="loader__overlay">
    <span class="loading-animation"></span>
  </div>
  <ng-container *ngIf="!address?.isCustom; else manualAddress">
    <div *ngIf="isPreferredAddress()" class="address__labels">
      <span class="label preferred-address" [attr.data-test]="'label-preferred'">
        {{ 'my-profile.delivery-addresses.preferred-address-label' | translate }}
      </span>
    </div>
    <div class="address__header" [attr.data-test]="'desc-addressHeader'">
      <ng-container *ngIf="isSapStore; else nonSapStore">
        <h6 class="title">{{ address?.sapId }} - {{ address?.name }}</h6>
      </ng-container>
      <ng-template #nonSapStore>
        <h6 class="title">
          <span *ngIf="address?.sapId">{{ address.sapId }} - {{address?.company}}</span>
          <span *ngIf="!address?.sapId">{{ businessUnitNumber }} - {{ businessUnitName }}</span>
        </h6>
      </ng-template>
      <p *ngIf="isAttentionToEnabled" class="attentionTo">
        <strong>{{ address?.attentionTo }}</strong>
      </p>
    </div>
    <div class="address__body" [attr.data-test]="'desc-addressBody'">
      <p [attr.data-test]="'desc-addressStreetName'">{{ address?.address1 }}</p>
      <p [attr.data-test]="'desc-addressCityStateZIP'">{{ getAddressDetails(true) }}</p>
    </div>
    <div class="address__footer" [attr.data-test]="'desc-addressFooter'"
         [ngClass]="{'disabled': updateCustomerAddressesInProgress || updatePreferredAddressInProgress}">
      <a (click)="showReportWrongAddressModal()" [attr.data-test]="'link-reportWrongAddress'">
        {{ 'my-profile.delivery-addresses.report-wrong-address' | translate }}
      </a>
      <div class="vertical-line"></div>
      <ng-container *ngIf="isPreferredAddress(); else notPreferredAddress">
        <a (click)="clearPreferredShipToAddress()" [attr.data-test]="'link-clearPreference'">
          {{ 'my-profile.delivery-addresses.clear-preference' | translate }}
        </a>
      </ng-container>
      <ng-template #notPreferredAddress>
        <a (click)="setPreferredShipToAddress(address?.sapId ?? address?.id)" [attr.data-test]="'link-setAsPreferred'">
          {{ 'my-profile.delivery-addresses.set-as-preferred' | translate }}
        </a>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #manualAddress>
    <div class="address__labels">
      <span *ngIf="isPreferredAddress()" class="label preferred-address" [attr.data-test]="'label-preferred'">
        {{ 'my-profile.delivery-addresses.preferred-address-label' | translate }}
      </span>
      <span class="label" [attr.data-test]="'label-custom'">
        {{ 'my-profile.delivery-addresses.customer-address-label' | translate }}
      </span>
    </div>
    <div class="address__header" [attr.data-test]="'desc-addressHeader'">
      <h6 class="title">{{ address?.name }}</h6>
      <p *ngIf="isAttentionToEnabled" class="attentionTo">
        <strong>{{ address?.attentionTo }}</strong>
      </p>
    </div>
    <div class="address__body" [attr.data-test]="'desc-addressBody'">
      <p [attr.data-test]="'desc-addressStreetName'">{{ address?.address1 }}</p>
      <p [attr.data-test]="'desc-addressCityStateZIP'">{{ address?.city }}, {{ address?.address2 }}, {{ address?.zipCode }}</p>
    </div>
    <div class="address__footer" [attr.data-test]="'desc-addressFooter'"
         [ngClass]="{'disabled': updateCustomerAddressesInProgress || updatePreferredAddressInProgress}">
      <a (click)="showUpdateAddressModal()" [attr.data-test]="'link-Edit'">
        {{ 'my-profile.delivery-addresses.edit-address' | translate }}
      </a>
      <div class="vertical-line"></div>
      <a (click)="showDeleteAddressModal()" [attr.data-test]="'link-Remove'">
        {{ 'my-profile.delivery-addresses.remove-address' | translate }}
      </a>
      <div class="vertical-line"></div>
      <ng-container *ngIf="isPreferredAddress(); else notPreferredAddress">
        <a (click)="clearPreferredShipToAddress()" [attr.data-test]="'link-clearPreference'">
          {{ 'my-profile.delivery-addresses.clear-preference' | translate }}
        </a>
      </ng-container>
      <ng-template #notPreferredAddress>
        <a (click)="setPreferredShipToAddress(address?.id)" [attr.data-test]="'link-setAsPreferred'">
          {{ 'my-profile.delivery-addresses.set-as-preferred' | translate }}
        </a>
      </ng-template>
    </div>
  </ng-template>
</div>
<app-add-edit-delivery-address-modal
    *ngIf="showModalUpdateAddress"
    [address]="address"
    [showModal]="showModalUpdateAddress"
    (closeModal)="showModalUpdateAddress = false"
    (newAddress)="updateAddress($event)">
</app-add-edit-delivery-address-modal>
<app-modal-confirm
    *ngIf="showModalDeleteAddress"
    [type]="iconType.WARNING"
    modalTitle="{{ 'my-profile.delivery-addresses.delete-address-modal.title' | translate }}"
    notifyText="{{ 'my-profile.delivery-addresses.delete-address-modal.msg' | translate }}"
    cancelBtnText="{{ 'my-profile.delivery-addresses.delete-address-modal.cancel-btn' | translate }}"
    primaryBtnText="{{ 'my-profile.delivery-addresses.delete-address-modal.delete-btn' | translate }}"
    [showModalConfirm]="showModalDeleteAddress"
    (closeModalConfirm)="showModalDeleteAddress = false"
    (primaryActionConfirm)="deleteAddress()">
</app-modal-confirm>
<app-report-wrong-bp-address-modal
    *ngIf="showModalReportWrongAddress"
    [quoteId]="'-'"
    [addressType]="shipToAddressType"
    [functionalLocations]="['-']"
    [showModal]="showModalReportWrongAddress"
    (closeModal)="showModalReportWrongAddress = false"
    [addressValue]="getAddressForReportWrongAddressModal()"
    [title]="'my-profile.delivery-addresses.address-type'">
</app-report-wrong-bp-address-modal>
