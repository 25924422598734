<div class="order-details">
  <div class="loader__overlay" *ngIf="!loaded || actionInProgress">
    <div class="loader__content">
      <div class="loading-animation"></div>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="loaded">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="itemsList">
        <ng-container *ngIf="cartDetails.attributes.shippingAddress">
          <div class="delivery-address">
            <h5 class="delivery-address__title"
                [attr.data-test]="'title-address'">{{ 'orders-page.delivery-address' | translate }}</h5>
            <ng-container *ngIf="!isAddressFieldEmpty(cartDetails.attributes.shippingAddress.firstName)">
              <p [attr.data-test]="'desc-firstName'">
                {{ renderEmptyAddressField(cartDetails.attributes.shippingAddress.firstName) }}
              </p>
            </ng-container>
            <p [attr.data-test]="'desc-address1'">
              {{ renderEmptyAddressField(cartDetails.attributes.shippingAddress.address1) }}
            </p>
            <div>
              <p>
                <ng-container *ngIf="!isAddressFieldEmpty(cartDetails.attributes.shippingAddress.zipCode)">
                  <span
                      [attr.data-test]="'desc-zipCode'">{{ renderEmptyAddressField(cartDetails.attributes.shippingAddress.zipCode) }}&nbsp;</span>
                </ng-container>
                <ng-container *ngIf="!isAddressFieldEmpty(cartDetails.attributes.shippingAddress.city)">
                  <span
                      [attr.data-test]="'desc-city'">{{ renderEmptyAddressField(cartDetails.attributes.shippingAddress.city) }}, </span>
                </ng-container>
                <ng-container *ngIf="!isAddressFieldEmpty(cartDetails.attributes.shippingAddress.address2)">
                  <span
                      [attr.data-test]="'desc-address2'"> {{ renderEmptyAddressField(cartDetails.attributes.shippingAddress.address2) }}</span>
                </ng-container>
              </p>
            </div>
            <ng-container *ngIf="!isAddressFieldEmpty(cartDetails.attributes.shippingAddress.country)">
              <p [attr.data-test]="'desc-country'">{{ renderEmptyAddressField(cartDetails.attributes.shippingAddress.country) }}</p>
            </ng-container>
          </div>
        </ng-container>
        <div class="cart-system-details">
          <ng-container *ngIf="cartDetails.attributes.systemDetails">
            <h5 class="cart-system-details__title" [attr.data-test]="'title-systemDetails'">
              {{ 'orders-page.system-details' | translate }}
            </h5>
            <div class="padding-styling">
              <div>
                <p [attr.data-test]="'label-selectedSystem'">
                  {{ "orders-page.selected-system" | translate }}
                </p>
                <div class="cart-system-details--inline">
                  <p *ngIf="cartDetails.attributes.systemDetails" [attr.data-test]="'desc-selectedSystem'">
                    {{ cartDetails.attributes.systemDetails | selectedSystem | async }}
                  </p>
                </div>
              </div>
              <div>
                <p [attr.data-test]="'label-companyDetails'">
                  {{ "orders-page.company" | translate }}
                </p>
                <div class="cart-system-details--inline">
                  <p *ngIf="cartDetails.attributes.systemDetails" [attr.data-test]="'desc-companyDetails'">
                    {{ cartDetails.attributes.systemDetails | companyDetails }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="itemsList.length > 0">
          <div class="product__wrapper" *ngFor="let item of itemsList" [attr.data-test]="'order-item-' + getItemName(item)">
            <div class="product__image">
              <img [src]="getItemPicture(item)" [alt]="getItemName(item)" [attr.data-test]="'img-productImage'">
            </div>
            <div class="product__details">
              <h5 class="product__name" [attr.data-test]="'title-productName'">{{ getItemName(item) }}</h5>
              <div>
                <span class="product__price" [attr.data-test]="'label-productPrice'">
                  {{ 'orders-page.unit-price' | translate }}:
                </span>
                  <span class="product__price" [attr.data-test]="'desc-productPrice'">
                  {{ getItemPrice(item) | currency: cartDetails.attributes.currency }}
                    <span *ngIf="isExcludeTaxActive()" class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span>
                </span>
              </div>
              <div class="item-level-details">
                <ng-container *ngIf="getItemSystemDetails(item)">
                  <div>
                    <span>
                      {{ 'orders-page.selected-system' | translate }}
                    </span>
                    <div class="item-system-details-styling">
                      <span *ngIf="getItemSystemDetails(item)">
                        {{ getItemSystemDetails(item) | selectedSystem | async }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span>
                      {{ 'orders-page.company' | translate }}
                    </span>
                    <div class="item-system-details-styling">
                      <span *ngIf="getItemSystemDetails(item)">
                        {{ getItemSystemDetails(item) | companyDetails }}
                      </span>
                    </div>
                  </div>
                </ng-container>
                <div class="product__qty">
                  <span [attr.data-test]="'desc-productQuantity'">
                    {{ 'shop-cart.quantity' | translate }}: {{ getItemQuantity(item) }}
                  </span>
                </div>
              </div>
              <div class="remove-item__wrapper">
                <button
                    type="button"
                    class="remove-item-label"
                    (click)="removeItemFromCartModal(item, itemsDetails.data.id)"
                    [attr.data-test]="'button-removeItem'">
                  <span class="icon-close"></span>
                  {{ 'order-approve.remove-item' | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-12 col-md-6">
      <div class="user-comment">
        <div class="user-comment__user-identifier">
          <span class="user-identifier__avatar">
            <span class="user-identifier__initials" [attr.data-test]="'desc-userInitials'">
              {{ getUserInitials(cartDetails.attributes.customer.firstName, cartDetails.attributes.customer.lastName) }}
            </span>
            <span class="user-identifier__details">
              <span class="user-identifier__name" [attr.data-test]="'desc-userFullname'">
                {{ getUserFullName(cartDetails.attributes.customer.firstName, cartDetails.attributes.customer.lastName) }}
              </span>
              <span class="user-details__email" [attr.data-test]="'desc-userEmail'">
                {{ cartDetails.attributes.customer.email }}
              </span>
            </span>
          </span>
        </div>
        <div class="user-comment__comment">
          <p [attr.data-test]="'desc-userComment'">{{ cartDetails.attributes.approverDetails.comment }}</p>
        </div>
        <div class="user-comment__button" *ngIf="isApproverCheck()">
          <a href="mailto:{{cartDetails.attributes.customer.email}}"
             class="button button--link button--small icon-email"
             [attr.data-test]="'link-userEmail'">
            {{ 'orders-page.request-more-info' | translate }}
          </a>
        </div>
      </div>
      <ng-container *ngIf="itemsList.length > 0 && cartDetails.attributes.shippingAddress">
        <div class="order-buttons button-group">
          <a *ngIf="isJpStore"
             [attr.data-test]="'button-downloadQuoteDocumentation'"
             (click)="generateQuotePdf()">
            <ng-container *ngIf="!isPdfGenerationInProgress; else loadingSpinner">
              <span class="icon-arrow-right"></span>
              <span>
               {{ 'orders-page.download-quote-documentation' | translate }}
              </span>
            </ng-container>
            <ng-template #loadingSpinner>
              <div class="spinner">
                <div class="loading-animation"></div>
              </div>
            </ng-template>
          </a>
          <div *ngIf="allowApprove; else notApprover">
            <button class="button" (click)="openModalDialog()" [attr.data-test]="'button-declineOrder'">
              {{ 'orders-page.decline-order' | translate }}
            </button>
            <button class="button button--primary" (click)="approveOrder()" [attr.data-test]="'button-approveOrder'">
              {{ 'orders-page.go-to-approve' | translate }}
            </button>
          </div>
          <ng-template #notApprover>
            <button class="button" (click)="openModalDialog()" [attr.data-test]="'button-cancelOrder'">
              {{ 'orders-page.cancel-order' | translate }}
            </button>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="itemsList.length === 0 || !cartDetails.attributes.shippingAddress">
      <div class="col-12" style="text-align: center; padding-top: 1rem;">
        <strong>
          {{ 'orders-page.empty-order' | translate:{number: cartDetails.id.substr(0, cartDetails.id.indexOf('-'))} }}
        </strong>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="openModal">
  <div class="modal-dialog modal-dialog--warning is-open"
       tabindex="-1"
       id="modal-confirm-decline"
       role="dialog"
       aria-modal="true"
       aria-labelledby="modal-confirm-decline_title"
       aria-describedby="modal-confirm-decline_desc"
       aria-hidden="true"
       data-linked="#modal-confirm-decline">
    <div class="modal-dialog__container" role="document">
      <div class="modal-dialog__header">
        <i aria-hidden="true" class="modal-dialog__indicator"></i>
        <h5 class="modal-dialog__title" id="modal-confirm-decline_title">
          {{ getTranslation('orders-page.decline-order') | translate }}
        </h5>
        <button
            type="button"
            class="modal-dialog__close"
            data-dismiss="modal-dialog"
            aria-label="Close"
            (click)="closeModalDialog()">
          <span aria-hidden="true" class="icon-close"></span>
        </button>
      </div>
      <div id="modal-confirm-decline_desc" class="modal-dialog__body">
        <p>
          {{ getTranslation('orders-page.modal-decline-message') | translate }}
        </p>
      </div>
      <div class="modal-dialog__footer">
        <button type="button" class="button button" data-dismiss="modal-dialog" (click)="closeModalDialog()">
          {{ getTranslation('orders-page.cancel-button') | translate }}
        </button>
        <button
            type="button"
            class="button button--primary"
            data-dismiss="modal-dialog"
            appDebounceClick
            [debounceTime]="300"
            [disabled]="actionInProgress"
            (debounceClick)="onDeclineOrderClick(cartDetails)">
          {{ getTranslation('orders-page.decline-button') | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<app-modal
    [open]="showModalConfirmRemove"
    title="{{ 'order-approve.remove-modal-title' | translate }}"
    (crossClick)="restartShowModal()">
  <ng-container ngProjectAs="[slot][body]">
    <ng-container *ngIf="!deleteLastItemAndDeclineOrderFlag">
      <h5 style="text-align: left;font: normal normal normal 16px/30px Siemens Sans, sans-serif;letter-spacing: 0;color: #666666;">
        {{ 'order-approve.remove-modal-content' | translate:{name: clickedItemDataName} }} </h5>
    </ng-container>
    <ng-container *ngIf="deleteLastItemAndDeclineOrderFlag">
      <h5 style="text-align: left;font: normal normal normal 16px/30px Siemens Sans, sans-serif;letter-spacing: 0;color: #666666;">
        {{ 'order-approve.last-item-decline-order-modal-content' | translate:{name: clickedItemDataName} }} </h5>
    </ng-container>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <button
        type="button"
        class="button button"
        [disabled]="actionInProgress"
        data-dismiss="modal-dialog"
        (click)="restartShowModal()">
      {{ 'order-approve.keep-item' | translate }}
    </button>
    <button
        *ngIf="!deleteLastItemAndDeclineOrderFlag"
        type="button"
        class="button button--primary remove-item__btn"
        [ngClass]="{'in-progress': actionInProgress}"
        [disabled]="actionInProgress"
        data-dismiss="modal-dialog"
        (click)="deleteItemFromOrder()">
      <ng-container *ngIf="actionInProgress; else acceptMsg">
        <span class="loading-animation"></span>
      </ng-container>
      <ng-template #acceptMsg>
        <span>{{ 'order-approve.remove-item' | translate }}</span>
      </ng-template>
    </button>
    <button
        *ngIf="deleteLastItemAndDeclineOrderFlag"
        type="button"
        class="button button--primary remove-item__btn"
        [ngClass]="{'in-progress': actionInProgress}"
        [disabled]="actionInProgress"
        data-dismiss="modal-dialog"
        (click)="deleteLastItemAndDeclineOrder()">
      <ng-container *ngIf="actionInProgress; else acceptMsg">
        <span class="loading-animation"></span>
      </ng-container>
      <ng-template #acceptMsg>
        <span>{{ 'order-approve.decline-order-button' | translate }}</span>
      </ng-template>
    </button>
  </ng-container>
</app-modal>
