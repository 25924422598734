import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IOrderData } from '../../../models/order.models';
import { OrdersFacade } from '../../../facades/orders.facade';
import { fulfilledHeaders, underReviewHeaders } from './configuration/configuration';
import { combineLatest, skipWhile, Subject } from 'rxjs';
import { IPaginationCurrent } from '../../../models/settings.model';

@Component({
  selector: 'app-service-purchase-activity',
  templateUrl: './service-purchase-activity.component.html',
  styleUrls: ['./service-purchase-activity.component.scss'],
})
export class ServicePurchaseActivityComponent implements OnInit {

  searchValue: string;
  searchLoading: boolean = false;

  quotesUnderReview: IOrderData[] = [];
  fulfilledOrders: IOrderData[] = [];

  underReviewHeaders = underReviewHeaders;
  fulfilledHeaders = fulfilledHeaders;

  orders: any[] = [];
  isFulfilledLoading: boolean = true;
  isUnderReviewLoading: boolean = true;
  fulfilledOrdersCurrentPage: number = 1;
  underReviewOrdersCurrentPage: number = 1;
  fulfilledOrdersNumPages: number = 1;
  underReviewOrdersNumPages: number = 1;

  private unsubscribe$ = new Subject<void>();

  @Output() loadServiceOrdersEmit = new EventEmitter<boolean>();

  constructor(
    private ordersFacade: OrdersFacade,
  ) {
  }

  ngOnInit(): void {
    this.goToCpqUnderReviewOrdersPage(1);
    this.goToCpqFulfilledOrdersPage(1);
  }

  goToCpqUnderReviewOrdersPage(pageNum: number): void {
    this.isUnderReviewLoading = true;
    this.underReviewOrdersCurrentPage = pageNum;
    combineLatest([
      this.ordersFacade.selectCpqUnderReviewOrdersAtPage(pageNum),
      this.ordersFacade.selectCpqUnderReviewOrdersPagination(),
    ]).pipe(
      skipWhile(([, pagination]) => !pagination.currentPage),
      takeUntil(this.unsubscribe$),
    ).subscribe(([cpqOrders, pagination]) => {
      if (cpqOrders.length) {
        this.quotesUnderReview = cpqOrders;
        this.updateUnderReviewOrders(pagination);
      } else {
        if (this.underReviewOrdersCurrentPage === 1) {
          this.updateUnderReviewOrders(pagination);
        } else {
          const limit = pagination.limitPerPage;
          const offset = limit ? (pageNum - 1) * limit : undefined;
          this.ordersFacade.loadCpqOrdersUnderReviewData(offset, limit);
        }
      }
    });
  }

  goToCpqFulfilledOrdersPage(pageNum: number): void {
    this.isFulfilledLoading = true;
    this.fulfilledOrdersCurrentPage = pageNum;
    combineLatest([
      this.ordersFacade.selectCpqFulFilledOrdersAtPage(pageNum),
      this.ordersFacade.selectCpqFulFilledOrdersPagination(),
    ]).pipe(
      skipWhile(([, pagination]) => !pagination.currentPage),
      takeUntil(this.unsubscribe$),
    ).subscribe(([cpqOrders, pagination]) => {
      if (cpqOrders.length) {
        this.fulfilledOrders = cpqOrders;
        this.updateFulfilledOrders(pagination);
      } else {
        if (this.fulfilledOrdersCurrentPage === 1) {
          this.updateFulfilledOrders(pagination);
        } else {
          const limit = pagination.limitPerPage;
          const offset = limit ? (pageNum - 1) * limit : undefined;
          this.ordersFacade.loadCpqOrdersFulfilledData(offset, limit);
        }
      }
    });
  }

  private updateFulfilledOrders(pagination: IPaginationCurrent): void {
    this.fulfilledOrdersNumPages = pagination.lastPage;
    this.isFulfilledLoading = false;
    this.isCpqLoadingFinished();
  }

  private updateUnderReviewOrders(pagination: IPaginationCurrent): void {
    this.underReviewOrdersNumPages = pagination.lastPage;
    this.isUnderReviewLoading = false;
    this.isCpqLoadingFinished();
  }

  private isCpqLoadingFinished(): void {
    if (
      !this.isFulfilledLoading
      && !this.isUnderReviewLoading
      && this.fulfilledOrdersCurrentPage === 1
      && this.underReviewOrdersCurrentPage === 1
    ) {
      this.loadServiceOrdersEmit.emit(true);
    }
  }
}
