import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ConfigurationFacade } from '../facades/configuration.facade';
import { environment } from '../../environments/environment';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  alerts: IAlert[] = [];

  constructor(private router: Router, private configurationFacade: ConfigurationFacade) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const endpointToSkip = environment.endpointsToSkipErrorHandling.find(endpoint => error.url.includes(endpoint.name));
        const errorCodes = error.error.errors?.map(err => err.code);
        if (endpointToSkip &&
          (endpointToSkip.codes.length === 0 || endpointToSkip.codes.filter(code => errorCodes.includes(code)).length)) {
          return throwError(() => error);
        }
        switch (error.status) {
          case 500:
            this.router.navigate(['error']).then();
            break;
          case 0:
            if (error.error === 'abort') {
              break;
            }
            break;
          default:
            if (!error?.error?.errors) {
              break;
            }
            if (error.error.errors[0].detail.includes('shipment.validation.expired')) {
              break;
            }
            this.alerts.push({
              type: 'error',
              message: this.parseErrorMessageFromResponse(error),
            });
        }
        this.configurationFacade.setAlert({
          type: 'error',
          message: this.alerts.map(a => a.message).join('<br />'),
        });
        this.alerts = [];
        return throwError(() => error);
      }),
    );
  }

  parseErrorMessageFromResponse(error: HttpErrorResponse): string {
    const errorsArray = error.error.errors as IErrors[] || [];
    const errorElement = errorsArray.find((e: IErrors) => e.status === error.status);
    return errorElement ? errorElement.detail : error.message;
  }
}

interface IErrors {
  code: string;
  status: number;
  detail: string;
}

interface IAlert {
  type: string;
  message: string;
  action?: any;
}
