<div class="cart-rules">
    <div class="display-flex justify-content-between" *ngFor="let cartRule of cartRules">
        <span>
            {{cartRule.attributes.displayName}}
        </span>
        <span class="price-2">
            -{{ cartRule.attributes.amount | currency: currency }}
        </span>
    </div>
    <hr class="rules-hr">
    <div class="display-flex justify-content-end">
        <strong>
            {{ priceToPay | currency: currency }}
        </strong>
    </div>
</div>
