import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ICatalogProduct, IProductLabel } from 'src/app/models/abstract-product.models';
import { ISystemDetails } from 'src/app/models/common.models';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { EFeatureToggles } from '../../../../configurations/common';
import { Subject } from 'rxjs';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { ICart } from '../../../../models/cart.models';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { PriceUtils } from '../../../../utils/price.utils';
import { SparePartsUtils } from '../../../../utils/spare-parts.utils';
import { IBaseWishlist } from '../../../../models/wishlist.models';
import { CatalogFacade } from '../../../../facades/catalog.facade';

@Component({
  selector: 'app-spare-part-product',
  templateUrl: './spare-part-product.component.html',
  styleUrls: ['./spare-part-product.component.scss'],
})
export class SparePartProductComponent implements OnInit, OnDestroy {
  defaultImg: string = environment.defaultImg;
  priceUtils = PriceUtils;
  canCartItemsBeChanged: (currentCart: ICart, isBusinessPartner: boolean) => boolean = SparePartsUtils.canCartItemsBeChanged;

  @Input() layoutType: string;
  @Input() product: ICatalogProduct;
  @Input() labels: IProductLabel[];
  @Input() wishlists: IBaseWishlist[];
  @Input() addNewModalActive: boolean;
  @Input() itemsInQueueToCart: Array<string>;
  @Input() loadingCartDataInProgress: boolean;
  @Input() currentCart: ICart;
  @Input() systemDetails: ISystemDetails;

  @Output() addProductToCart: EventEmitter<ICatalogProduct> = new EventEmitter<ICatalogProduct>();
  @Output() addNewWishlistModalActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSapP40Enable: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isBusinessPartner: boolean = false;
  pricingGroupForConsumables: string;
  isBusinessPartnerRole: boolean = false;

  constructor(
    private router: Router,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
    private catalogFacade: CatalogFacade,
  ) {
  }

  ngOnInit(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });
    this.selectIsCustomerBusinessPartner();
    this.catalogFacade.getPricingGroupForConsumables().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(pricingGroup => {
        this.pricingGroupForConsumables = pricingGroup;
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Method to emit add to cart
   * @param {ICatalogProduct} product
   */
  addToCart(product: ICatalogProduct): void {
    this.addProductToCart.emit(product);
  }

  /**
   * Method to open add new wishlist modal
   */
  openAddNewWishlistModal(): void {
    this.addNewWishlistModalActive.emit(this.addNewModalActive);
  }

  /**
   * Method to check if product has guest price
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  hasGuestPrice(product: ICatalogProduct): boolean {
    return this.priceUtils.hasGuestPrice(product.prices);
  }

  /**
   * Method to get guest price from product
   * @param {ICatalogProduct} product
   * @returns {number}
   */
  getGuestPrice(product: ICatalogProduct): number {
    return this.priceUtils.getPriceValue(this.priceUtils.getGuestPrice(product.prices));
  }

  /**
   * Method to get item concrete sku from product
   * @param {ICatalogProduct} product
   * @returns {string | undefined}
   */
  getItemConcreteSku(product: ICatalogProduct): string | undefined {
    if (!product?.attributeMap?.product_concrete_ids) {
      return undefined;
    }

    return product.attributeMap.product_concrete_ids[0];
  }

  /**
   * Method to check if item is in progress
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  isItemInProgress(product: ICatalogProduct): boolean {
    const concreteSku: string = this.getItemConcreteSku(product);

    return this.itemsInQueueToCart.includes(concreteSku) || this.itemsInQueueToCart.includes(product.abstractSku);
  }

  /**
   * Method to check if product contains image
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  hasImages(product: ICatalogProduct): boolean {
    if (!product?.images) {
      return false;
    }

    return !!product.images && product.images.length > 0;
  }

  /**
   * Method to redirect to PDP
   * @param {string} abstractSku
   */
  redirectToPDP(abstractSku: string): void {
    this.router.navigate(['/product/' + abstractSku]).then();
  }

  /**
   * Method to select if customer is business partner
   */
  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  /**
   * Returns true if product is available for online purchase
   *  user has to be non-business partner
   *  FL has to be selected
   *  product is available for online purchase
   *    if product is consumable and system details is not isSparePartsAvailable
   *    or system details is isSparePartsAvailable
   *
   * @return {boolean}
   */
  checkIfProductIsAvailableForOnlinePurchase(): boolean {
    if (!this.isBusinessPartner && this.systemDetails?.siemensEquipmentId) {
      return (
        this.product?.attributes?.sap_p40_material_pricing_group_3 === this.pricingGroupForConsumables
        && !this.systemDetails?.isSparePartsAvailable
      ) || this.systemDetails?.isSparePartsAvailable
    } else {
      return true;
    }
  }
}
