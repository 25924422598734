import { Injectable, OnDestroy } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { LinkService } from './link.service';
import {
  EMetaTag,
  ERobotsContent,
  ESeoData,
  ETitleSources,
  ISeoData,
  ISeoPageData,
  SeoData,
} from '../configurations/seo';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router, UrlTree } from '@angular/router';
import { combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { I18nService } from './i18n.service';

import { StringUtils } from '../utils/string.utils';
import { ArrayUtils } from '../utils/array.utils';
import { BreadCrumb } from '../models/common.models';
import { StructuredDataService } from './structured-data.service';
import { PRODUCT_SCHEME_CLASS } from '../configurations/structured-data';
import { ICmsPageAttributes } from '../models/cms';
import { AppUtils } from '../utils/app.utils';

@Injectable({
  providedIn: 'root',
})
export class SeoService implements OnDestroy {

  private routeListener: Subscription;
  private defaultRobots = [ERobotsContent.INDEX, ERobotsContent.FOLLOW];
  private nonProdRobots: ERobotsContent[] = [ERobotsContent.NO_INDEX, ERobotsContent.NO_FOLLOW];
  private defaultTitle = environment.defaultPageTitle;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private linkService: LinkService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
    private structuredData: StructuredDataService
  ) {
  }

  /**
   * Start listening on NavigationEnd router events
   */
  public startRouteListener(): void {
    this.routeListener = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() =>
      this.structuredData.removeStructuredData(PRODUCT_SCHEME_CLASS)
    );

    this.routeListener = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      map(route => combineLatest(route.data, route.params).pipe(map(([data, params]) => {
        return {data, params};
      }))),
      mergeMap(data => data),
    ).subscribe(
      (r) => {
        const data: ISeoData = r.data;
        const params: Params = r.params;
        const urlTree: UrlTree = this.router.parseUrl(this.router.url);
        const hasNoCanonical = this.handleRobotsMeta(data);
        this.handleCanonicalLink(urlTree, hasNoCanonical);
        this.addMetaTag(EMetaTag.DESCRIPTION, '');
        this.addMetaTag(EMetaTag.KEYWORDS, '');
        switch (data.seoTitleSource) {
          case ETitleSources.CONFIG:
            this.handleTitleFromConfig(data, params).then(val => {
              this.updateTitle(val);
            });
            break;
          case ETitleSources.HEADER:
            this.handleTitleFromHeading();
            break;
          case ETitleSources.BREADCRUMBS:
          case ETitleSources.ENDPOINT:
            break;
          case ETitleSources.DEFAULT:
          default:
            this.updateTitle([]);
        }
        const isNoIndexable = data.hasOwnProperty(ESeoData.seoNonIndexable) && data.seoNonIndexable;
        if (!isNoIndexable) {
          this.setAlternativeUrl(urlTree);
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.routeListener.unsubscribe();
  }

  handleRobotsMeta(data: ISeoData): boolean {
    const hasNoCanonical = data.hasOwnProperty(ESeoData.seoNoCanonical) && data.seoNoCanonical;
    let robots = [];
    if (data.hasOwnProperty(ESeoData.seoRobots)) {
      robots = data.seoRobots;
    }
    this.addRobotsMeta(robots);
    return hasNoCanonical;
  }

  handleCanonicalLink(tree: UrlTree, removeTag: boolean): void {
    if (removeTag) {
      this.linkService.removeTag({
        rel: 'canonical',
      });
    } else {
      this.setCanonicalUrl(tree);
    }
  }

  setAlternativeUrl(tree: UrlTree): void {
    for (const store of environment.stores) {
      for (const language of store.languages) {
        const langParam = this.i18nService.buildLangParam(store.marketCode, language.code);
        this.linkService.setAlternateLink(tree, langParam);
      }
    }
  }

  setCanonicalUrl(tree: UrlTree): void {
    this.linkService.setCanonicalLink(tree);
  }

  addRobotsMeta(robots: ERobotsContent[]): void {
    let robotsContent: string;

    robotsContent = AppUtils.isProductionEnv() && AppUtils.getEnvName() === 'production' ? this.defaultRobots.join(', ') : this.nonProdRobots.join(', ');

    if (robots.length > 1) {
      robotsContent = robots.join(', ');
    }

    this.addMetaTag(EMetaTag.ROBOTS, robotsContent);
  }

  addMetaTagFromDefinition(definition: MetaDefinition): void {
    this.addMetaTag(definition.name as EMetaTag, definition.content);
  }

  addMetaTag(tagName: EMetaTag, content: string): void {
    const metaTag: MetaDefinition = {name: tagName, content};
    const tag = this.metaService.getTag(`name=${tagName}`);
    if (tag !== null) {
      this.metaService.updateTag(metaTag);
    } else {
      this.metaService.addTag(metaTag);
    }
  }

  updateTitle(titles: string[], addDefault = true): void {
    let titlesContent = this.defaultTitle;
    if (titles.length > 0) {
      const upperTitle = titles.map(t => StringUtils.capitalize(t));
      titlesContent = `${upperTitle.join(' | ')}`;
      if (addDefault) {
        titlesContent += ` | ${this.defaultTitle}`;
      }
    }
    this.titleService.setTitle(titlesContent);
  }

  handleTitleFromHeading(): void {
    let heading: HTMLHeadingElement | boolean = true;
    let i = 1;
    do {
      heading = ArrayUtils.searchForHighestPageHeader(document.querySelectorAll(`h${i}`));
      i++;
    } while (!(heading instanceof HTMLHeadingElement) && i < 7);
    this.updateTitle(heading instanceof HTMLHeadingElement ? [heading.innerText] : []);
  }

  async handleTitleFromConfig(data: ISeoData, params: Params): Promise<string[]> {
    let title = '';
    if (data.hasOwnProperty(ESeoData.seoTitle)) {
      title = data[ESeoData.seoTitle];
      if (title.startsWith(':')) {
        const titleParam = title.slice(1);
        switch (true) {
          case (/category/.test(titleParam) && params.hasOwnProperty('category')):
          case (/sku/.test(titleParam) && params.hasOwnProperty('sku')):
            title = params[titleParam];
            break;
          case /translateKey=[A-Za-z0-9_.-]/g.test(titleParam):
            await firstValueFrom(this.i18nService.getTranslationByKey(titleParam.split('=')[1])).then(translation => title = translation);
            break;
          default:
            title = '';
        }
      }
    }
    return title.length > 0 ? [title] : [] as string[];
  }

  handleTitleFromComponent(titles: string[]): void {
    this.updateTitle(titles);
  }

  handleTitleFromBreadcrumb(breadcrumbs: BreadCrumb[]): void {
    const titles = breadcrumbs.slice(1).reverse().map(b => b.label);
    this.updateTitle(titles);
    this.structuredData.handleBreadcrumbSchema(breadcrumbs);
  }

  setMetaFromComponent(seoPageData: ISeoPageData): void {
    const pageData: SeoData = new SeoData(seoPageData);
    switch (seoPageData.titleSource) {
      case ETitleSources.ENDPOINT:
        this.updateTitle(pageData.getTitles(), false);
        break;
      case ETitleSources.BREADCRUMBS:
        break;
      default:
        this.updateTitle(pageData.getTitles());
    }
    try {
      const canonical = seoPageData.canonicalUrl;
      if (!canonical.href.startsWith('http')) {
        canonical.href = `${window.location.origin}${canonical.href}`;
      }
      this.linkService.setCanonicalLinkFromEndpoint(seoPageData.canonicalUrl);
    } catch (e) {}
    this.addMetaTagFromDefinition(pageData.getRobots());
    this.addMetaTagFromDefinition(pageData.getMetaData(EMetaTag.DESCRIPTION));
    this.addMetaTagFromDefinition(pageData.getMetaData(EMetaTag.KEYWORDS));
  }

  parseRobots(data: ICmsPageAttributes): string[] {
    const robots = [];
    Object.entries(data).forEach(([key, value]) => {
      if (key.startsWith('tag') || key === 'unavailableAfter') {
        if (value !== null) {
          robots.push(key === 'unavailableAfter' ? `unavailable_after[${value}]` : value);
        }
      }
    });
    return robots;
  }
}


