import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { EAddressType } from '../../../../configurations/common';
import { IAddress } from '../../../../models/common.models';

@Component({
  selector: 'app-system-detail',
  templateUrl: './system-detail.component.html',
  styleUrls: ['./system-detail.component.scss'],
})
export class SystemDetailComponent implements OnInit {
  properties = [];
  shipToAddresses: IAddress[] = [];
  cpqAddressType = '';
  reportWrongShipToAddressModalFlag = false;

  @Input() form: UntypedFormGroup;
  @Input() item: any;
  @Input() cart: any;
  @Input() ecommerceTeamEmailAddress: string;
  @Input() customerBusinessUnitName: string;
  @Input() functionalLocations: string[];

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.properties = [
      {
        key: 'contractStartDate',
        title: 'Contract start date',
        tooltip: 'quote-summary.contract-start-date-tooltip',
        value: this.form.value['contractStartDate' + this.item.id]
          ? formatDate(this.form.value['contractStartDate' + this.item.id], 'MM/dd/yyyy', 'en')
          : null,
      },
      {
        key: 'contractTerm',
        title: 'Contract term',
        tooltip: 'quote-summary.contract-term-tooltip',
        value: this.form.value['contractTerm' + this.item.id],
        contractEndOfSupport:
          this.item.attributes.systemDetails.endOfSupport
            ? formatDate(this.item.attributes.systemDetails.endOfSupport, 'MM/dd/yyyy', 'en')
            : '-',
      },
      {
        key: 'shipToAddress',
        title: 'Ship to address',
        tooltip: 'quote-summary.ship-to-address-tooltip',
        value: this.form.value['shipToAddress' + this.item.id],
      },
    ];
    this.shipToAddresses.push(this.item.attributes?.customerShipToAddress ?? '');
  }

  isFieldValid(property: string): boolean {
    return this.form.controls[property + this.item.id].status === 'VALID';
  }

  edit(): void {
    this.router.navigate(['/service-configuration'],
      {
        queryParams: {
          selectedService: this.item.attributes.abstractSku,
          'fl-number': this.item.attributes.systemDetails.siemensEquipmentId,
          'rel-product-sysivk': this.item.attributes.systemDetails.materialNumber,
          step: 'contract-details',
          configId: this.item.attributes.cpqConfigId,
        },
      }).then();
  }

  showReportWrongShipToAddressModal(): void {
    this.reportWrongShipToAddressModalFlag = true;
    this.cpqAddressType = EAddressType.SHIP_TO;
  }

}
