import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';

import { CpqFacade } from '../../facades/cpq.facade';
import { IContractParams } from '../../models/catalog.models';
import { MarketingFacade } from '../../facades/marketing.facade';
import { ICart } from '../../models/cart.models';
import { CartUtils } from '../../utils/cart.utils';
import { MathUtils } from '../../utils/math.utils';
import { ImageUtils } from '../../utils/image.utils';
import { CpqUtils } from '../../utils/cpq.utils';
import { IShsEquipmentDataAttributes } from '../../models/installedbase.models';

@Component({
  selector: 'app-contract-steps-bar',
  templateUrl: './contract-steps-bar.component.html',
  styleUrls: ['./contract-steps-bar.component.scss'],
})
export class ContractStepsBarComponent implements OnInit, OnDestroy {
  steps: any[] = [
    {
      index: 1, title: 'contract-steps-bar.step-one-title',
    },
    {
      index: 2, title: 'contract-steps-bar.step-two-title',
    },
    {
      index: 3, title: 'contract-steps-bar.step-three-title',
    },
  ];
  serviceParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': '',
    selectedService: '',
  };
  cart: ICart;
  nickname: string;
  isLoading: boolean = false;
  hasNickname: boolean = false;
  functionalLocation: IShsEquipmentDataAttributes;
  showAddNicknameModal: boolean = false;
  itemsInCart: any[];
  equipmentTypesCount: number = 0;
  functionalLocationCount: number = 0;
  cartItemImage: string;
  cartItemAlt: string;
  currentCartName$: ReplaySubject<string> = new ReplaySubject<string>();

  @Input() stepNo: number;
  @Input() soldTo: number;
  @Input() isQuoteSummary = false;
  @Input() isConfigSummary = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private cpqFacade: CpqFacade,
    private activatedRoute: ActivatedRoute,
    private marketingFacade: MarketingFacade,
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.serviceParams['fl-number'] = params['fl-number'] ?? '';
      this.serviceParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
      this.serviceParams.selectedService = params.selectedService ?? '';
      this.selectLoadingCartDataInProgress();
      this.selectCurrentCart();
      this.selectCartItemsWithDetails();
      this.selectInstalledBaseSystemsFromCpqStore();
      this.selectSelectedSystem();
    });
  }

  getItemPicture(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  getEquipmentCount(itemsInCart: any): number {
    const materialNumbers = [...new Set(itemsInCart.map(item => item.attributes.systemDetails.materialNumber))];
    return materialNumbers ? materialNumbers.length : 0;
  }

  private selectCurrentCart(): void {
    this.marketingFacade.selectCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(cart => {
        if (cart) {
          this.cart = cart;
          this.nickname = CartUtils.getCartName(cart);
          this.hasNickname = !MathUtils.checkIfNumeric(cart.attributes.name);
          this.currentCartName$.next(this.hasNickname ? this.nickname : '');
        }
      });
  }

  private selectCartItemsWithDetails(): void {
    this.marketingFacade.selectCartItemsWithDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(cartItems => {
        if (cartItems) {
          this.itemsInCart = CpqUtils.mergeCpqItems(cartItems);
          const cartItem = this.itemsInCart[0];
          if (cartItem) {
            this.equipmentTypesCount = this.getEquipmentCount(cartItem.items);
            this.functionalLocationCount = cartItem.items.length;
            this.cartItemImage = ImageUtils.getProductImageUrl(cartItem);
            this.cartItemAlt = cartItem.attributes.name;
          }
        }
      });
  }

  private selectInstalledBaseSystemsFromCpqStore(): void {
    this.cpqFacade.selectInstalledBaseSystemsFromCpqStore()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedSystem => {
        if (selectedSystem
          && selectedSystem.siemensEquipmentId === this.serviceParams['fl-number']
          && selectedSystem.materialNumber === this.serviceParams['rel-product-sysivk']) {
          this.functionalLocation = selectedSystem;
        } else {
          this.cpqFacade.getSelectedInstalledBaseSystems(this.serviceParams['rel-product-sysivk'], this.serviceParams['fl-number']);
        }
      });
  }

  private selectSelectedSystem(): void {
    this.cpqFacade.selectSelectedSystem()
      .pipe(take(1))
      .subscribe(selectedSystem => {
        if (this.serviceParams.selectedService !== '' && (!selectedSystem || this.serviceParams.selectedService !== selectedSystem?.productAbstractSku)) {
          this.cpqFacade.loadSelectedSystem(this.serviceParams.selectedService);
        }
      });
  }

  private selectLoadingCartDataInProgress(): void {
    this.isLoading = true;
    this.marketingFacade.selectCartOperationInProgress()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loadingCartDataInProgress => {
          this.isLoading = loadingCartDataInProgress;
        },
      );
  }
}
