import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../analytics/analytics.service';
import * as ValidationPatterns from '../../configurations/validations';
import { CatalogFacade } from '../../facades/catalog.facade';
import { ContactUsFacade } from '../../facades/contact-us.facade';
import { CpqFacade } from '../../facades/cpq.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { IContractParams, ICurrentParams } from '../../models/catalog.models';
import { ISelectEvent } from '../../models/common.models';
import { I18nService } from '../../services';
import { DropdownComponent } from '../../shared/dropdown/dropdown.component';

@Component({
  selector: 'app-page-contact-us',
  templateUrl: './page-contact-us.component.html',
  styleUrls: ['./page-contact-us.component.scss'],
})
export class PageContactUsComponent implements OnInit, OnDestroy {

  params: ICurrentParams = {
    'page[offset]': 0,
    'page[limit]': 12,
    sort: '',
  };

  contractParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': '',
    selectedService: '',
  };

  abstractProductOptions = [];
  contactUsForm: UntypedFormGroup;
  maxLengthPhone: number = 20;
  maxLengthName: number = 35;
  maxLengthMessage: number = 500;
  cartItems: any;
  cartItemsLength: number = 0;

  checkboxReceiveEmails: boolean = false;
  checkboxPersonalData: boolean = false;
  showModalSubmit: boolean = false;

  product: any;
  selectedContracts: string[];

  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild(DropdownComponent) inputDropdown: DropdownComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerFacade: CustomerFacade,
    private activatedRoute: ActivatedRoute,
    private catalogFacade: CatalogFacade,
    private marketingFacade: MarketingFacade,
    private analyticsService: AnalyticsService,
    private cpqFacade: CpqFacade,
    private contactUsFacade: ContactUsFacade,
    private router: Router,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.contractParams['fl-number'] = params['fl-number'] ?? '';
      this.contractParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
      this.contractParams.selectedService = params.selectedService ?? '';

      this.getAdditionalInfo();
    });
    this.initializeForm();
    this.setProducts();
    this.selectCartItemsSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setContactUsFormValue(event: ISelectEvent): void {
    this.contactUsForm.patchValue({
      [event.key]: event.value,
    });
  }

  setProducts(): void {
    this.catalogFacade.getSearchResults(this.params).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      const {abstractProducts} = response.data[0].attributes;

      abstractProducts.forEach(product => {
        this.abstractProductOptions.push(product.abstractName);
      });
    });
  }

  submitForm(): void {
    this.showModalSubmit = true;

    const formData = {
      data: {
        type: 'contact-us',
        attributes: {
          systemDetails: {
            siemensEquipmentId: this.product?.siemensEquipmentId,
            nameEnUs: this.product?.nameEnUs,
          },
          selectedContract: this.contractParams.selectedService,
          contractTypes: this.selectedContracts,
          firstName: this.contactUsForm.value.firstName,
          lastName: this.contactUsForm.value.lastName,
          email: this.contactUsForm.value.email,
          phoneNumber: this.contactUsForm.value.phone,
          message: this.contactUsForm.value.message,
          forMultipleSystems: this.contactUsForm.value.multipleSystems,
          receiveEmails: this.contactUsForm.value.receiveMails,
          hasConsented: this.contactUsForm.value.personalInformationConsent,
        },
      },
    };
    this.initializeForm();
    this.resetOptions();

    this.contactUsFacade.postContactUsData(formData)
      .pipe(take(1))
      .subscribe({
        next: () => {
        },
        error: () => {
        },
      });
  }

  cancelOnClick(): void {
    this.router.navigate([this.i18nService.getCurrentLocale()]).then();
  }

  getAdditionalInfo(): void {
    this.cpqFacade.selectInstalledBaseSystemsFromCpqStore()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedSystem => {
        if (selectedSystem && selectedSystem.siemensEquipmentId === this.contractParams['fl-number']) {
          this.product = selectedSystem;
        }
      });
  }

  contractTypesSelected(contractTypes: string[]): void {
    this.selectedContracts = contractTypes;
  }

  private resetOptions(): void {
    this.inputDropdown.savedOptions = [];
    this.checkboxPersonalData = false;
    this.checkboxReceiveEmails = false;
  }

  private selectCartItemsSubscription(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItems => {
      if (cartItems && cartItems.length > 0 && !this.cartItems?.length) {
        this.cartItems = cartItems;
        this.analyticsService.setProducts(cartItems);
        this.cartItemsLength = cartItems.length;
      }
    });
  }

  private initializeForm(): void {
    this.contactUsForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      phone: ['', [Validators.required, Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.maxLength(this.maxLengthMessage), ValidationPatterns.noEmptySpaceOnTheBeginning]],
      receiveMails: '',
      personalInformationConsent: '',
      multipleSystems: '',
    });

    this.selectCustomerInfo();
  }

  private selectCustomerInfo(): void {
    this.customerFacade.selectMyProfileInformation().pipe(take(1)).subscribe(state => {
      this.contactUsForm.patchValue({
        ['firstName']: state.firstName || '',
        ['lastName']: state.lastName || '',
        ['email']: state.email || '',
      });
    });
  }
}
