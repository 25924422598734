import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistFacade } from '../../../facades/wishlist.facade';
import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { IBaseWishlist, IProductToCart, IWishlistProductDetails } from '../../../models/wishlist.models';
import { PayloadUtils } from '../../../utils/payload.utils';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { MathUtils } from '../../../utils/math.utils';
import { ICart } from '../../../models/cart.models';
import { IconType } from '../../../models/settings.model';
import {
  CONFIG_PRICING_GROUP_FOR_CONSUMABLES,
  EFeatureToggles,
  EInstalledBaseTabs,
  EStoreFeatures,
  EStoreTypes,
} from '../../../configurations/common';
import { AppUtils } from '../../../utils/app.utils';
import { PriceUtils } from '../../../utils/price.utils';
import { ISystemDetails } from '../../../models/common.models';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { IInstallBaseQueryParams } from '../../../models/catalog.models';
import { CustomerFacade } from '../../../facades/customer.facade';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit, OnDestroy {
  iconType = IconType;
  selectedProducts = [];
  selectAllValue: boolean = false;
  editModalActive: boolean = false;
  removeModalActive: boolean = false;
  addItemToCartModalActive: boolean = false;
  showModalSelectEquipment: boolean = false;
  currency: string;
  wishlistId: string;
  wishlist: IBaseWishlist;
  wishlistProducts: IWishlistProductDetails[] = [];
  itemsLoaded: boolean = false;
  itemsInQueueToCart = [];
  itemsInQueueToRemove = [];
  editInProgress: boolean = false;
  wishlistInProgress = [];
  loadingCartDataInProgress: boolean = true;
  isSapP40Enable: boolean = false;
  delayTimer: any;
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  currentCart: ICart;
  currentCartId: string;
  hasCartContract: boolean = false;
  items: any;
  excludeTax: boolean = false;
  priceUtils = PriceUtils;
  pricingGroupForConsumables: string;
  isBusinessPartner: boolean = false;
  pricingGroupToggle: boolean = false;
  productPricingGroup: string;
  isMyInstalledBaseFlowEnabled: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private wishlistFacade: WishlistFacade,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private configurationFacade: ConfigurationFacade,
    private catalogFacade: CatalogFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.currency = PriceUtils.getStoreCurrencyCode();
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.excludeTax = this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXCLUDE_TAX);
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });

    if (this.isUsStore) {
      this.selectHasCartContract();
    }

    this.route.params.subscribe(params => {
      if (params.id) {
        this.wishlistId = params.id;
        this.getWishlist(this.wishlistId);
      }
    });

    this.selectIsMyInstalledBaseFlowEnabled();
    this.selectCurrentCart();
    this.selectLoadingCartDataInProgress();
    this.selectItemsInQueueToCart();
    this.getLastUpdatedProduct();
    this.selectWishlistInProgress();
    this.selectPricingGroupToggle();
    this.selectIsCustomerBusinessPartner();
    this.selectPricingGroupForConsumables();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
  }

  private selectPricingGroupForConsumables(): void {
    this.catalogFacade.getPricingGroupForConsumables().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(pricingGroup => this.pricingGroupForConsumables = pricingGroup);
  }


  /**
   * Get wishlist based on id and get all items with details.
   *
   * @param {string} id
   */
  getWishlist(id: string): void {
    this.wishlistFacade.getWishlist(id).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      result => {
        this.wishlist = result.data;
        if (result?.included) {
          this.getItems(result.included);
        }
        this.itemsLoaded = true;
      },
    );
  }

  getLastUpdatedProduct(): void {
    this.wishlistFacade.selectLastUpdatedProduct().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      updatedProduct => {
        if (updatedProduct) {
          this.wishlistProducts = this.wishlistProducts.reduce((prev, curr: IWishlistProductDetails) => {
            const product = {...curr};
            if (curr.sku === updatedProduct.sku) {
              product.qty = updatedProduct.qty;
            }
            prev.push(product);
            return prev;
          }, []);
        }
      },
    );
  }

  /**
   * Get details of all shopping-list-items inside "included":
   * - quantity (always set to 1),
   * - externalUrlSmall for image,
   * - name,
   * - sku,
   * - materialNumber (non SAP - material_number, SAP - sap_material_number),
   * - productAbstractSku
   * - defaultPrice
   * - secondPrice
   *
   * @param included
   */
  getItems(included: any): void {
    const items = included.filter(item => item.type === 'shopping-list-items');

    this.wishlistProducts = items.reduce((acc, next) => {
      acc.push({
        ...PayloadUtils.getConcreteProductDetails(included, next.attributes.sku),
        qty: next.attributes.quantity,
        shoppingListItemId: next.id,
      });
      return acc;
    }, []);
  }

  /**
   * Return sum of all quantities (in template it is also used for different translations for product/products).
   *
   * @returns {number}
   */
  getProductsQty(): number {
    return this.wishlistProducts.reduce((prev: number, curr: IWishlistProductDetails) => {
      return prev + curr.qty;
    }, 0);
  }

  /**
   * Select/deselect product and add/remove it from "selectedProducts" array. If "selectedProducts" array is empty
   * then deselect "Select all" CheckBox.
   *
   * @param product
   */
  selectProduct(product: any): void {
    const index: number = this.selectedProducts.indexOf(product.sku);
    if (index > -1) {
      this.selectedProducts.splice(index, 1);
    } else {
      this.selectedProducts.push(product.sku);
    }

    if (this.selectedProducts.length === 0) {
      this.selectAllValue = false;
    }
  }

  selectAll(event: any): void {
    if (event.target.checked) {
      this.selectedProducts = this.wishlistProducts.map(product => product.sku);
    } else {
      this.selectedProducts = [];
    }
  }

  /**
   * Make 0.5s delay when changing products quantity. During this 0.5s there are disabled buttons for adding to cart
   * or removing from wishlist.
   *
   * @param {string} sku
   * @param qty
   */
  updateProductQty(sku: string, qty: any): void {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      const updatedQty: number = parseInt(qty, 10);
      if (!MathUtils.checkIfNumeric(updatedQty) || qty === 0) {
        return;
      }
      this.wishlistFacade.updateItem(this.wishlistId, this.getShoppingListId(sku), sku, updatedQty);
    }, 500);
  }

  /**
   * Calculate price of all wishlist products (item price * quantity).
   *
   * @returns {number}
   */
  getTotalPrice(): number {
    return this.wishlistProducts.reduce(
      (prev, curr) =>
        prev + (this.priceUtils.getPriceValue(curr.defaultPrice)) * curr.qty,
      0,
    );
  }

  /**
   * If user manually checks all products, then select automatically "Select all".
   *
   * @returns {boolean}
   */
  isAllProductsSelected(): boolean {
    return this.selectedProducts.length === this.wishlistProducts.length;
  }

  /**
   * Method to get all selected products which are available for online purchase
   *
   * @returns {IWishlistProductDetails[]}
   * @private
   */
  private getAvailableSelectedProducts(): IWishlistProductDetails[] {
    return this.wishlistProducts.filter(
      product =>
        this.checkIfProductIsAvailableForOnlinePurchase(product)
        && this.selectedProducts.includes(product.sku),
    );
  }

  /**
   * Method checks if of selected products is at least one which is available for online purchase
   *
   * @returns {boolean}
   * @private
   */
  areSelectedProductAvailable(): boolean {
    return this.getAvailableSelectedProducts().length > 0;
  }

  wishlistEdited(event: any): void {
    if (event !== 'error') {
      this.wishlist = {
        ...this.wishlist,
        attributes: {
          ...event.attributes,
        },
      };
    }
    this.editInProgress = false;
  }

  wishlistRemoved(): void {
    this.wishlistFacade.deleteList(this.wishlistId);
    this.router.navigate(['/wishlists']).then();
  }

  selectEquipment(): void {
    let commands: string = '/my-installed-base';
    let tab: EInstalledBaseTabs = EInstalledBaseTabs.EQUIPMENT;
    if (this.isBusinessPartner) {
      commands = '/equipment-selection';
      tab = null;
    }
    let queryParams: IInstallBaseQueryParams = {
      tab: tab,
    };
    if (this.pricingGroupToggle) {
      queryParams = {
        ...queryParams,
        pricing_group: this.productPricingGroup,
      };
    }
    this.router.navigate([commands], {
      queryParams: queryParams,
    }).then();
  }

  /**
   * Method to select Pricing Group toggle
   * @private
   */
  private selectPricingGroupToggle(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_PRICING_GROUP).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.pricingGroupToggle = value;
    });
  }


  selectCurrentCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
      if (cart) {
        this.currentCart = cart;
        this.currentCartId = cart.id;
      }
    });
  }

  selectItemsInQueueToCart(): void {
    this.marketingFacade.selectItemsInQueueToCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.itemsInQueueToCart = state;
      });
  }

  selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
        this.loadingCartDataInProgress = loadingCartDataInProgress;
      },
    );
  }

  selectWishlistInProgress(): void {
    this.wishlistFacade.selectListInProgress()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.wishlistInProgress = state;
      });
  }

  selectHasCartContract(): void {
    this.marketingFacade.selectHasCartContract().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
        this.hasCartContract = value;
      },
    );
  }

  isItemInProgress(sku: string): boolean {
    return this.itemsInQueueToCart.includes(sku)
      || this.itemsInQueueToRemove.includes(sku)
      || this.wishlistInProgress.find(list => list.sku === sku);
  }

  addProductToCart(item: IProductToCart): void {
    if (this.isItemInProgress(item.sku) || this.loadingCartDataInProgress) {
      return;
    }

    const quantity: number = this.wishlistProducts.find(product => product.sku === item.sku).qty;
    const systemDetails: ISystemDetails = this.currentCart.attributes?.systemDetails;

    if (this.isMyInstalledBaseFlowEnabled && !systemDetails?.siemensEquipmentId) {
      this.productPricingGroup = item?.pricingGroup;
      this.showModalSelectEquipment = true;
      return;
    }

    if (this.hasCartContract) {
      this.items = [{
        data: item,
        isConcrete: true,
        quantity: quantity,
      }];
      this.addItemToCartModalActive = true;
      return;
    }

    this.addToCart(item, quantity);
  }

  private addToCart(item: any, quantity: number): void {
    this.analyticsService.setProducts(item);
    this.analyticsService.trackCart('cart.add');
    this.marketingFacade.addProductToCart({sku: item.sku, name: item.name}, true, quantity);
  }

  /**
   * Remove specific item via "X" button.
   *
   * @param {string} sku
   */
  removeItemFromWishlist(sku: string): void {
    if (this.isItemInProgress(sku)) {
      return;
    }
    this.itemsInQueueToRemove.push(sku);
    this.removeItem(sku);
  }

  /**
   * Remove all selected items via "Remove" button.
   */
  removeAllSelectedItems(): void {
    if (this.selectedProducts.length > 0) {
      this.selectedProducts.forEach(sku => {
        this.itemsInQueueToRemove.push(sku);
        this.removeItem(sku);
      });
      this.selectedProducts = [];
    }
  }

  addAllSelectedToCart(): void {
    if (this.selectedProducts.length === 0 || this.loadingCartDataInProgress) {
      return;
    }
    const systemDetails: ISystemDetails = this.currentCart.attributes?.systemDetails;

    if (this.isMyInstalledBaseFlowEnabled && !systemDetails?.siemensEquipmentId) {
      this.showModalSelectEquipment = true;
      return;
    }

    if (this.hasCartContract) {
      this.items = [];
      this.selectedProducts.forEach(sku => {
        const item: IWishlistProductDetails = this.wishlistProducts.find(product => product.sku === sku);
        if (this.checkIfProductIsAvailableForOnlinePurchase(item)) {
          this.items.push({
            data: item,
            isConcrete: true,
            quantity: item.qty,
          });
        }
      });
      this.addItemToCartModalActive = true;
      return;
    }

    this.selectedProducts.forEach(sku => {
      const item: IWishlistProductDetails = this.wishlistProducts.find(product => product.sku === sku);
      if (this.checkIfProductIsAvailableForOnlinePurchase(item)) {
        this.addToCart(item, item.qty);
      }
    });
  }

  /**
   * Toggle for "disabled" attribute of "Add to cart" button.
   *
   * @returns {boolean}
   */
  isAddToCartDisabled(): boolean {
    return this.loadingCartDataInProgress || (!this.isSapP40Enable && (this.isUsStore || this.isCaStore));
  }

  getShoppingListId(sku: string): string {
    return this.wishlistProducts.find(product => product.sku === sku).shoppingListItemId;
  }

  /**
   * Called after removing item/items via "X" or "Remove" button. After successful deletion of item from wishlist remove
   * it also from "wishlistProducts" and "selectedProducts" arrays.
   *
   * @param {string} sku
   * @private
   */
  private removeItem(sku: string): void {
    this.wishlistFacade.removeItemFromWishlist(this.wishlistId, this.getShoppingListId(sku))
      .pipe(
        take(1),
        tap(() => {
          this.wishlistProducts = this.wishlistProducts.filter(product => product.sku !== sku);
          this.selectedProducts = this.selectedProducts.filter(selectedProductSku => selectedProductSku !== sku);
        }),
        catchError(err => {
          const message = err?.error.errors?.[0]?.detail ? err.error.errors[0].detail : err.message;
          this.configurationFacade.setAlert({type: 'error', message});
          return of();
        })
      )
      .subscribe();
  }

  /**
   * Method to select if customer is business partner
   */
  private selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  edit(): void {
    if (!this.editInProgress) {
      this.editModalActive = true;
    }
  }

  redirectToProductLists(): void {
    this.router.navigate(['/catalog']).then();
  }

  /**
   * Returns true if product is available for online purchase
   *  user has to be non-business partner
   *  FL has to be selected
   *  product is available for online purchase
   *    if product is consumable and system details is not isSparePartsAvailable
   *    or system details is isSparePartsAvailable
   *
   * @return {boolean}
   */
  checkIfProductIsAvailableForOnlinePurchase(product: IWishlistProductDetails): boolean {
    if (
      !this.isBusinessPartner
      && this.currentCart?.attributes?.systemDetails?.siemensEquipmentId
      && this._isPricingGroupAvailableForCurrentStore()
    ) {
      return (
        product.pricingGroup === this.pricingGroupForConsumables
        && !this.currentCart?.attributes?.systemDetails.isSparePartsAvailable
      ) || this.currentCart?.attributes?.systemDetails.isSparePartsAvailable;
    } else {
      return true;
    }
  }

  /**
   * In case the pricingGroupForConsumables is equal to its own translate key,
   * it means the pricing group is not set for current store
   *
   * @returns {boolean}
   * @private
   */
  private _isPricingGroupAvailableForCurrentStore(): boolean {
    return this.pricingGroupForConsumables !== CONFIG_PRICING_GROUP_FOR_CONSUMABLES;
  }

  /**
   * Method for retrieving IsMyInstalledBaseFlowEnabled feature toggle value
   * @private
   */
  private selectIsMyInstalledBaseFlowEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isMyInstalledBaseFlowEnabled => {
      this.isMyInstalledBaseFlowEnabled = isMyInstalledBaseFlowEnabled;
    });
  }
}
