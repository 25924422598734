import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICartItem } from '../../models/cart.models';
import { IMaterialMasterNumbersPerItem, IMaterialMasterNumbersToggles } from '../../models/common.models';
import { IOrderItem } from '../../models/order.models';
import { MarketingFacade } from '../../facades/marketing.facade';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-advanced-product-information',
  templateUrl: './advanced-product-information.component.html',
  styleUrls: ['./advanced-product-information.component.scss'],
})
export class AdvancedProductInformationComponent implements OnInit {

  materialMasterData: IMaterialMasterNumbersPerItem;
  materialMasterToggles: IMaterialMasterNumbersToggles;
  materialMasterNumbersPerItem: IMaterialMasterNumbersPerItem[] = [];

  sapFields: string[][] = [
    ['sap_p40_notif_flag', 'notificationNumber'],
    ['sap_p40_peak_flag', 'peakNumber'],
    ['sap_p40_serial_flag', 'serialNumber'],
    ['sap_p40_software_version_flag', 'softwareVersion'],
  ];

  @Input() isEditingEnabled: boolean = false;
  @Input() currentItem?: ICartItem;
  @Input() currentCartId?: string;
  @Input() orderItem?: IOrderItem;
  @Input() currentCartItems?: ICartItem[];
  @Input() isOrderReviewPage?: boolean = false;
  @Output() checkAllRequiredValues: EventEmitter<{fulfilled: boolean, itemId: string}> = new EventEmitter<{fulfilled: boolean, itemId: string}>();

  constructor(
    private marketingFacade: MarketingFacade) {
  }

  ngOnInit(): void {
    this.getMaterialMasterNumbersToggles();
    this.initMaterialMasterData();
    this.checkRequiredValues();
  }

  /**
   * This method is used for storing material master information into the application state.
   * Method is called when the customer enter value into some input and then unfocus from the input
   */
  updateInputValuesAndStoreIt(): void {
    this.marketingFacade.setMaterialMasterNumbers(this.materialMasterData, this.currentCartId);
    this.checkRequiredValues();
  }

  /**
   * This method check if all required values are filled for current product.
   */
  checkRequiredValues(): void {
    let isEveryRequiredValueFilled = true;
    Object.keys(this.materialMasterData).forEach((key) => {
      if (!this.materialMasterData[key]) {
        isEveryRequiredValueFilled = false;
      }
    });
    this.checkAllRequiredValues.emit({fulfilled: isEveryRequiredValueFilled, itemId: this.currentItem?.id});
  }

  /**
   * Initialize material master data
   * If editing is enabled then select data from app state. In other case set data from this.currentItem.attributes
   * @private
   */
  private initMaterialMasterData(): void {
    this.materialMasterData = {
      itemId: this.currentItem?.id ?? this.orderItem?.materialMasterNumbers?.itemId,
    };
    this.fillMasterNumbers().forEach((key) => {
      this.materialMasterData[key] = '';
    });

    if (this.isEditingEnabled || this.isOrderReviewPage) {
      this.marketingFacade.selectMaterialMasterNumbersByItemId(this.currentCartId, this.currentItem.id)
        .pipe(
          skipWhile(itemData => itemData === undefined),
          take(1))
        .subscribe(materialMasterNumbers => {
          this.materialMasterData = materialMasterNumbers;
        });
    } else {
      this.materialMasterData = {
        itemId: this.currentItem?.id ?? this.orderItem?.materialMasterNumbers?.itemId,
        notificationNumber: this.currentItem?.attributes?.attributes?.sap_p40_notif_flag ??
          this.orderItem?.materialMasterNumbers?.notificationNumber,
        peakNumber: this.currentItem?.attributes?.attributes?.sap_p40_peak_flag ??
          this.orderItem?.materialMasterNumbers?.peakNumber,
        softwareVersion: this.currentItem?.attributes?.attributes?.sap_p40_software_version_flag ??
          this.orderItem?.materialMasterNumbers?.softwareVersion,
        serialNumber: this.currentItem?.attributes?.attributes?.sap_p40_serial_flag ??
          this.orderItem?.materialMasterNumbers?.serialNumber,
      };
    }
  }

  /**
   * This method is used for get toggle values.
   */
  getMaterialMasterNumbersToggles(): void {
    this.marketingFacade.getMaterialMasterNumbersToggles()
      ?.pipe(take(1))
      .subscribe((materialMasterToggles) => {
        this.materialMasterToggles = materialMasterToggles;
        for (const [key, value] of Object.entries(this.materialMasterToggles)) {
          this.materialMasterToggles[key] = value === true || value === 'true';
        }
      });
  }

  /**
   * This method check whether input should be shown or not.
   * When editing is enabled input showing depends on sap attributes (attribute === x then show input)
   * When editing is not enabled input should be shown only if attribute is not equal to empty string
   * @param {string} sapAttribute
   * @returns {boolean}
   */
  shouldInputBeShown(sapAttribute: string): boolean {
    if (this.isEditingEnabled && sapAttribute?.toLowerCase() === 'x') {
      return true;
    }
    return !this.isEditingEnabled && sapAttribute !== null && sapAttribute?.toLowerCase() !== '' && sapAttribute?.toLowerCase() !== 'n';
  }

  /**
   * This method is used for fill masterNumbers.
   * sapField contains all keys for materialMasterNumbers.
   * According to the conditions, it checks whether the attributes (Peak N., Notification, Software Version and Serial N.) should be shown.
   * @returns {string[]}
   */
  fillMasterNumbers(): string[] {
    const masterNumbers = [];
    this.sapFields.forEach((field: string[]) => {
      if (this.shouldInputBeShown(this.currentItem?.attributes?.attributes?.[field[0]]
        ?? this.orderItem?.materialMasterNumbers?.[field[1]]) && this.materialMasterToggles?.[field[1]]) {
        masterNumbers.push(field[1]);
      }
    });
    return masterNumbers;
  }
}
