import { EGlueResource, EQueryParams } from '../configurations/common';
import { HttpParams } from '@angular/common/http';
import { IBaseModel, IPaginatedResponseLinks } from '../models/common.models';
import { IPaginationCurrent } from '../models/settings.model';

export class GlueUtils {
  static addIncludeParam(params: HttpParams, includes: EGlueResource[] = []): HttpParams {
    const includeValue = this.generateIncludeParam(includes);
    if (includeValue) {
      return params.set(EQueryParams.INCLUDE, includeValue);
    } else {
      return params;
    }
  }

  static parsePaginationLinks(links: IPaginatedResponseLinks): IPaginationCurrent {
    const selfUrl = new URL(links.self);
    // if limit param isn't set then it's likely the endpoint response is not paginated
    const currentOffset = this.getPageOffset(selfUrl);
    const limitPerPage = Number.parseInt(selfUrl.searchParams.get(EQueryParams.LIMIT) ?? '1');
    const currentPage = this.getPageNumber(currentOffset, limitPerPage);

    let [prevOffset, nextOffset, firstOffset, lastOffset] = [currentOffset, currentOffset, currentOffset, currentOffset];
    let [prevPage, nextPage, firstPage, lastPage] = [currentPage, currentPage, currentPage, currentPage];
    if (links.first && links.last) {
      firstOffset = this.getPageOffset(new URL(links.first));
      firstPage = this.getPageNumber(firstOffset, limitPerPage);
      lastOffset = this.getPageOffset(new URL(links.last));
      lastPage = this.getPageNumber(lastOffset, limitPerPage);

      prevOffset = links.prev ? this.getPageOffset(new URL(links.prev)) : Math.max(firstOffset, currentOffset - limitPerPage);
      nextOffset = links.next ? this.getPageOffset(new URL(links.next)) : Math.min(lastOffset, currentOffset + limitPerPage);
      prevPage = this.getPageNumber(prevOffset, limitPerPage);
      nextPage = this.getPageNumber(nextOffset, limitPerPage);
    }

    return {
      currentPage,
      currentOffset,
      prevPage,
      prevOffset,
      nextPage,
      nextOffset,
      firstPage,
      firstOffset,
      lastPage,
      lastOffset,
      limitPerPage,
    };
  }

  /**
   * Extracts all Glue resources having the passed type from the passed array of resources.
   *
   * @param {IBaseModel[]} resources
   * @param {EGlueResource} type
   * @returns {IBaseModel[]}
   */
  static filterResourcesByType(resources: IBaseModel[], type: EGlueResource): IBaseModel[] {
    return resources.filter(resource => resource.type === type);
  }

  private static generateIncludeParam(includes: EGlueResource[] = []): string {
    if (!includes) {
      return '';
    }
    return includes.join(',');
  }

  private static getPageOffset(url: URL): number {
    return Number.parseInt(url.searchParams.get(EQueryParams.OFFSET) ?? '0');
  }

  private static getPageNumber(offset: number, limit: number) {
    return (offset / limit) + 1;
  }
}
