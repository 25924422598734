import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, skipWhile, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { I18nService } from '../../../services';
import { AddressUtils } from '../../../utils/address.utils';
import { CustomerFacade } from '../../../facades/customer.facade';
import { notFoundValue, notInstallBaseRelated } from '../../../shared/ivk-selection-form/IvkSelectionFormConstants';
import { ArrayUtils } from '../../../utils/array.utils';
import { IAdditionalItemField, IAddress, ISelectEvent } from '../../../models/common.models';
import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { InstallBaseFacade } from '../../../facades/install-base.facade';
import { EUserRoles } from '../../../configurations/common';
import {
  IAddressReduced,
  IAddressSelectBox,
  ICustomerPreferences,
} from '../../../models/customer.models';
import { AppUtils } from '../../../utils/app.utils';
import { IShsEquipmentData } from '../../../models/installedbase.models';
import { EquipmentUtils } from '../../../utils/equipment.utils';
import { ObjectUtils } from '../../../utils/object.utils';

@Component({
  selector: 'app-order-details-section',
  templateUrl: 'order-details-section.component.html',
  styleUrls: ['order-details-section.component.scss'],
})
export class OrderDetailsSectionComponent implements OnInit, OnDestroy {
  @Input() loggedUserRoles: EUserRoles[];
  @Input() cartItemsWithDetails;
  @Input() cartRules;
  @Input() isCartEmpty;
  @Input() cartItems;
  @Input() cartId;
  @Input() requestQuoteFeatureActive: boolean;
  @Input() excludeTax: boolean;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetAddressesList: boolean = false;

  customerAddresses: IAddressSelectBox[] = [];
  businessAddresses: IAddress[] | IAddressReduced[] = [];
  ivkForm: UntypedFormGroup;
  deliveryForm: UntypedFormGroup;

  installBaseLoading: boolean = false;
  businessUnitLoading: boolean = false;
  businessAddressesLoading: boolean = false;
  systems = [];
  businessUnits = [] as Array<{name: string, value: string}>;
  addresses: Array<{name: string, value: any, addressFromSystem: boolean}> = [];
  formsAreValid: boolean = false;
  showModalAddressWrong: boolean = false;
  showModalAddress: boolean = false;
  additionalItemLevelFields: IAdditionalItemField[] = [] as Array<IAdditionalItemField>;
  preferredShipToAddress = null;
  allAddressesLoaded: boolean = false;
  isSapStore: boolean = false;
  customerPreferences: ICustomerPreferences;

  private itemFormMap = new Map();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private i18nService: I18nService,
              private customerFacade: CustomerFacade,
              private installBaseFacade: InstallBaseFacade,
              private customerCurrencyPipe: CustomerCurrencyPipe) {
  }

  ngOnInit(): void {
    this.initializeForm();

    this.getNotFoundLabels();
    this.beginGetBusinessUnitsAction();

    this.selectInstallBaseProducts();
    this.selectBusinessUnitLoading();
    this.selectBusinessAddresses();
    this.selectBusinessUnits();
    this.selectCustomerAddresses();
    this.selectCustomerPreferences();
    this.selectPreferredShipToAddress();

    this.prepareAdditionalFields();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm(): void {
    this.deliveryForm = this.formBuilder.group({
      deliveryAddress: ['', [Validators.required]],
    });

    this.revalidateForms();
  }

  ivkFormChanged(form: UntypedFormGroup): void {
    this.ivkForm = form;

    this.revalidateForms();
  }

  /**
   * Triggered when user change system in delivery details.
   * If there is address in "addresses", then replace it with "addressData" value. If not, then add this address
   * to "addresses" variable.
   * If user is using SAP store, then also preselect this address in SelectBox.
   *
   * @param {ISelectEvent} event
   */
  systemIdValueChanged(event: ISelectEvent): void {
    let addressData = null;
    if (event.additional?.shipToAddress) {
      const addressFromSystemIndex = this.addresses.findIndex(address => address.addressFromSystem);
      if (!ObjectUtils.isObjEmpty(event?.additional?.shipToAddress)) {
        addressData = {
          name: AddressUtils.createAddressString(event.additional.shipToAddress),
          value: {
            ...event.additional.shipToAddress,
            idCompanyUnitAddress: null,
            idCustomerAddress: null,
          },
          addressFromSystem: true,
        };
      }
      if (addressFromSystemIndex === -1 && addressData) {
        this.addresses.push(addressData);
      } else {
        this.addresses[addressFromSystemIndex] = addressData;
      }
    }

    if (addressData && !this.preferredShipToAddress) {
      this.deliveryForm.patchValue({
        deliveryAddress: addressData,
      });
    }

    this.revalidateForms();
  }

  /**
   * @param {ISelectEvent} event
   */
  selectDeliveryAddress(event: ISelectEvent): void {
    this.deliveryForm.patchValue({
      deliveryAddress: event,
    });

    this.revalidateForms();
  }

  /**
   * Method for setting manually created address as a delivery address
   *
   * @param {ISelectEvent} event
   */
  setCustomDeliveryAddress(event: ISelectEvent): void {
    this.addresses.push({
      name: event.name,
      value: event.value,
      addressFromSystem: false,
    });

    this.selectDeliveryAddress(event);
  }

  showModalNewAddress(): void {
    this.showModalAddress = true;
  }

  showModalWrongAddress(): void {
    this.showModalAddressWrong = true;
  }

  itemFormChanged(itemForm: any): void {
    this.itemFormMap.set(itemForm.itemId, itemForm.formGroup);

    this.revalidateForms();
  }

  itemFormHidden(itemId: any): void {
    this.itemFormMap.delete(itemId);

    this.revalidateForms();
  }

  revalidateForms(): void {
    this.formsAreValid = this.isFormValid(this.ivkForm) &&
      this.isFormValid(this.deliveryForm) &&
      this.areItemFormsValid();
  }

  proceedToNextStep(): void {
    const formData = {
      deliveryAddress: this.deliveryForm.value.deliveryAddress?.value,
      systemDetails: EquipmentUtils.convertIvkSelectionToSystemDetails(this.ivkForm.value),
      systemDetailsPerItem: [],
    };

    for (const [id, form] of this.itemFormMap) {
      formData.systemDetailsPerItem.push({
        itemId: id,
        systemDetails: EquipmentUtils.convertIvkSelectionToSystemDetails(form.value),
      });
    }
    this.formSubmitted.emit(formData);
  }

  backToCart(): void {
    this.router.navigate(['/shop-cart']).then();
  }

  isFormValid(form: UntypedFormGroup): boolean {
    return form?.status === 'VALID';
  }

  /**
   * Return array of addresses of systems (in "shipToAddress"). If user is not using SAP store, then set
   * "companyBusinessUnitNumber" as sapId.
   *
   * @param {any[]} sortedSystems
   * @returns {any[]}
   */
  selectAddressesFromInstallBase(sortedSystems: any[]): any[] {
    return ArrayUtils.removeDuplicates(sortedSystems
      .map(installBase => ({
        ...installBase?.attributes?.shipToAddress,
        sapId: AppUtils.isSapStore()
          ? installBase?.attributes?.shipToAddress.sapId
          : installBase?.attributes?.companyBusinessUnitNumber,
      }))).filter((el) => {
      return el.address1 != null;
    });
  }

  private getNotFoundLabels(): void {
    this.i18nService.getTranslationByKey([
      'request-details.system-not-found',
      'request-details.unit-not-found',
      'request-details.item-not-install-base-related',
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(labels => {
      this.systems.unshift({value: notFoundValue, name: labels['request-details.system-not-found']});
      this.systems.unshift({
        value: notInstallBaseRelated,
        name: labels['request-details.item-not-install-base-related'],
      });
      this.businessUnits.unshift({value: notFoundValue, name: labels['request-details.unit-not-found']});
    });
  }

  private beginGetBusinessUnitsAction(): void {
    this.customerFacade.beginGetBusinessUnitsAction();
  }

  /**
   * Load all installedBaseSystemData. Also get addresses from these systems to fill SelectBox for selecting
   * delivery address. If user is not using SAP store, then preselect preferred ship-to address in SelectBox with
   * one from My Profile page in Account Settings.
   *
   * @private
   */
  private selectInstallBaseProducts(): void {
    this.installBaseLoading = true;
    this.installBaseFacade.selectInstalledBaseSystemData().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: (data: IShsEquipmentData[]) => {
        this.installBaseLoading = false;
        if (data?.length) {
          const sortedSystems: IShsEquipmentData[] = ArrayUtils.sortByAttribute([...data], 'attributes.materialName');
          this.systems = this.systems.concat(sortedSystems.map(installBase => {
            return {
              name: installBase.attributes.materialName
                + (installBase.attributes.siemensEquipmentId
                    ? ` (${installBase.attributes.siemensEquipmentId})`
                    : ''
                ),
              value: installBase.attributes.siemensEquipmentId,
              additional: {...installBase.attributes},
            };
          }));
          this.businessUnits = this.businessUnits.concat(sortedSystems.reduce((acc, installBase) => {
            if (installBase?.attributes?.companyBusinessUnit && installBase.attributes.companyBusinessUnitNumber) {
              acc.push({
                name: installBase.attributes.companyBusinessUnit,
                value: installBase.attributes.companyBusinessUnitNumber,
                additional: {
                  institutionName: installBase.attributes.company,
                },
              });
            }
            return acc;
          }, []));

          AddressUtils.appendAddresses(
            this.addresses, this.selectAddressesFromInstallBase(sortedSystems), 'business', true,
          );

          this.prepareBusinessUnits();
        }
      }, error: () => {
        this.installBaseLoading = false;
      },
    });
  }

  private selectBusinessUnitLoading(): void {
    this.customerFacade.selectBusinessUnitLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoading => {
      this.businessUnitLoading = isLoading;
    });
  }

  private selectBusinessUnits(): void {
    this.customerFacade.selectBusinessUnits().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(units => {
      if (units) {
        this.businessUnits = this.businessUnits.concat(units.map(unit => {
          return {
            name: unit.name,
            value: unit.businessUnitNumber,
            additional: {
              institutionName: unit.institutionName,
            },
          };
        }));
        this.prepareBusinessUnits();
      }
    });
  }

  private selectBusinessAddresses(): void {
    this.businessAddressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: addresses => {
          if (addresses) {
            this.businessAddresses = addresses;
            AddressUtils.appendAddresses(this.addresses, this.businessAddresses, 'business');
            this.businessAddressesLoading = false;
          }
        },
        error: () => {
          this.businessAddressesLoading = false;
        },
      },
    );
  }

  private selectCustomerAddresses(): void {
    this.customerFacade.selectCustomerAddresses().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  /**
   * Method for preselecting delivery address if it is set as a preferred shipTo address or if it is the only address.
   * The preselection should start only when all types of addresses have already been loaded.
   *
   * Different types of addresses:
   * - customer addresses
   * - business addresses
   * - tpF addresses
   *
   * @private
   */
  private selectPreferredShipToAddress(): void {
    combineLatest([
      this.customerFacade.selectCustomerAddresses(),
      this.customerFacade.selectBusinessAddress(),
      this.installBaseFacade.selectInstalledBaseSystemData(),
    ]).pipe(
      skipWhile(([customerAddresses, businessAddresses, installBaseSystems]) => {
        return !customerAddresses || !businessAddresses || !installBaseSystems;
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.customerFacade.selectCustomerPreferences().pipe(
        skipWhile(customerPreferences => !customerPreferences),
        takeUntil(this.unsubscribe$),
      ).subscribe(customerPreferences => {
        this.preferredShipToAddress = this.addresses.find(address => {
          return address.value.sapId === customerPreferences.preferredShipToId
            || address.value.id === customerPreferences.preferredShipToId;
        });

        if (this.preferredShipToAddress) {
          this.deliveryForm.patchValue({deliveryAddress: this.preferredShipToAddress});
        }

        if (this.addresses.length === 1) {
          this.deliveryForm.patchValue({deliveryAddress: this.addresses[0]});
        }

        this.allAddressesLoaded = true;
        this.revalidateForms();
      });
    });
  }

  private prepareBusinessUnits(): void {
    this.businessUnits = ArrayUtils.removeDuplicates(this.businessUnits);
    // Skip first unit as it is not found option
    const sortedUnits = ArrayUtils.sortByAttribute(this.businessUnits.slice(1, this.businessUnits.length), 'name');
    this.businessUnits = [this.businessUnits[0], ...sortedUnits];
  }

  private areItemFormsValid(): boolean {
    for (const form of this.itemFormMap.values()) {
      if (form.status === 'INVALID') {
        return false;
      }
    }

    return true;
  }

  private prepareAdditionalFields(): void {
    this.additionalItemLevelFields = [{
      name: 'shop-cart.quantity',
      getValFunc: (item) => item.attributes.quantity,
      shouldDisplayFunc: (_) => true,
    }, {
      name: 'shop-cart.item-price',
      excludeTax: this.excludeTax,
      getValFunc: (item) => this.customerCurrencyPipe.transform(
        item.attributes.calculations.unitPrice,
        this.cartItems.currency,
      ),
      shouldDisplayFunc: (_) => true,
    }];
  }

  /**
   * Method for selecting customer preferences.
   */
  private selectCustomerPreferences(): void {
    this.customerFacade.selectCustomerPreferences().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(customerPreferences => {
      if (customerPreferences) {
        this.customerPreferences = customerPreferences;
      }
    });
  }

  /**
   * Return the address displayed in deliveryForm if exists, otherwise return the default address.
   * Set resetAddressesList as true for Report Wrong Address modal.
   * Set address id as businessAddress id for Report Wrong Address modal.
   *
   * @returns {IAddress[]|IAddressReduced[]}
   */
  getAddressesListReportWrongAddress(): IAddress[] | IAddressReduced[] {
    this.resetAddressesList = true;
    if (this.deliveryForm.value.deliveryAddress.value) {
      this.deliveryForm.value.deliveryAddress.value.id = this.businessAddresses[0]?.id;
    }
    return this.deliveryForm.value.deliveryAddress.value ?
      [this.deliveryForm.value.deliveryAddress.value] : this.businessAddresses;
  }
}
