<div class="accordion orders__table">
  <div class="orders-table__header"
       *ngIf="(orders | filterByAttributes: filters | searchFilter: searchValue).length > 0; else noOrders">
    <div *ngFor="let header of headers" class="display-flex" [ngClass]="header.class">
      <div>{{ header.label | translate }}</div>
      <app-sort-by
          [sort]="header.sortOptions"
          [cssClass]="'margin-0'"
          [current]="currentSort"
          [hasTitle]=false
          (selected)="sort($event)">
      </app-sort-by>
    </div>
    <div class="width-10"></div>
  </div>
  <ng-template #noOrders>
    <span>{{'orders-page.no-orders-matching-filter-message' | translate}}</span>
  </ng-template>
  <div class="order-history__table">
    <div class="table-items">
      <app-accordion
          *ngFor="let order of orders | filterByAttributes: filters | searchFilter: searchValue"
          [manualOpen]="tabsOpened.includes(order.id)"
          [attr.data-test]="'previousOrder-' + order.id">
        <ng-container ngProjectAs="[slot][header]">
          <div class="accordion__title icon-arrow-down" (click)="toggleTab(order.id)">
            <div class="order-info width-15 order-id">
              <span class="header__label" [attr.data-test]="'label-orderId'">
                {{ 'spare-part-list.order-id' | translate }}:
              </span>
              <span [attr.data-test]="'desc-orderId'">
                {{ order?.id | removeLeadingZero }}
              </span>
            </div>
            <div class="order-info width-20 order-date">
              <span class="header__label" [attr.data-test]="'label-orderDate'">
                {{ 'spare-part-list.date-ordered-by' | translate }}:
              </span>
              <span [attr.data-test]="'desc-orderDate'">
                {{ order.attributes?.createdAt | formatDate }}
              </span>
              <span class="greyed-out" [attr.data-test]="'desc-customer'">
                {{ order.attributes.customer?.firstName }} {{ order.attributes.customer?.lastName }}
              </span>
            </div>
            <div class="order-info width-25 order-equipment">
              <span class="header__label" [attr.data-test]="'label-orderEquipment'">
                {{ 'spare-part-list.equipment' | translate }}:
              </span>
              <span [attr.data-test]="'desc-orderEquipmentNumber'">
                {{ order.attributes.systemDetails?.siemensEquipmentId}}
              </span>
              <span class="greyed-out" [attr.data-test]="'desc-orderEquipmentName'">
                {{ order.attributes.systemDetails?.nameEnUs}}
              </span>
            </div>
            <div class="order-info width-15 order-status">
              <span class="header__label" [attr.data-test]="'label-orderStatus'">
                {{ 'spare-part-list.order-status' | translate }}:
              </span>
              <div class="display-flex justify-content-center flex-column">
                <div class="order-status__icon">
                  <span class="status__icon"
                        [attr.data-test]="'icon-orderStatus'"
                        [ngClass]="getIcon(order.attributes?.stateDisplayName)">
                  </span>
                  <span class="status__name" [attr.data-test]="'desc-orderStatus'">
                    {{ order.mappedStateDisplayName }}
                  </span>
                </div>
                <small class="user-name"
                       [attr.data-test]="'desc-approver'"
                       *ngIf="order.attributes?.approverDetails">
                  {{ order.attributes.approverDetails?.firstName }} {{ order.attributes.approverDetails?.lastName }}
                </small>
              </div>
            </div>
            <div class="order-info width-15 total">
              <span class="header__label" [attr.data-test]="'label-totalPrize'">
                {{ 'spare-part-list.total' | translate }}:
              </span>
              <div class="price">
                <span [attr.data-test]="'desc-totalPrize'">
                  {{ order.attributes.totals?.grandTotal | currency:order.attributes?.currencyIsoCode }}
                </span>
                <div class="order-items-count">
                  <ng-container *ngIf="order.attributes?.itemsTotalNumber > 1">
                    <span [attr.data-test]="'desc-quantity'">
                      {{ 'spare-part-list.item-plural' | translate:{number: order.attributes?.itemsTotalNumber} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="order.attributes?.itemsTotalNumber === 1">
                    <span [attr.data-test]="'desc-quantity'">
                      {{ 'spare-part-list.item-singular' | translate:{number: order.attributes?.itemsTotalNumber} }}
                    </span>
                  </ng-container>
                </div>
              </div>
              <span class="additional-info "></span>
            </div>
            <div class="width-10"></div>
          </div>
          <div class="price-disputing-message" *ngIf="isPriceDisputingActive && hasOrderDisputedPrice(order.id)">
            <app-message [message]="setPriceDisputingDataForMessageComponent(order.id)">
            </app-message>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content">
            <app-spare-part-item-details
                [isModalInProgress]="isModalInProgress"
                [orderDetails]="order"
                [isOpen]="tabsOpened.includes(order.id)"
                (addItemToCartModalActiveEvent)="addItemToCartModalActive = $event"
                (itemsToAddEvent)="itemsToAdd = $event"
                (flNumberOfReorderEvent)="flNumberOfReorder = $event"
                (orderIdEvent)="orderId = $event">
            </app-spare-part-item-details>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
<app-add-item-modal
    [isModalInitialized]="addItemToCartModalActive"
    [showModal]="addItemToCartModalActive"
    (closeModal)="addItemToCartModalActive = false"
    (isModalInProgress)="isModalInProgress = $event"
    [items]="itemsToAdd"
    [currentCartId]="currentCartId"
    [isReorderWorkflowSelected]="true"
    [flNumberOfReorder]="flNumberOfReorder"
    [orderId]="orderId"
    [isSapP40Enable]="isSapP40Enable">
</app-add-item-modal>
