<div class="quote">
  <div class="quote--loader" *ngIf="loadingQuote">
    <div class="loading-animation"></div>
  </div>
  <div class="quote--header">
    <h6 class="quote-name">{{ quoteName(quote) }}</h6>
    <button type="submit" class="button button--link button--delete-quote" 
        (click)="deleteQuote(quote.id, quote.attributes.totalItemsQuantity)">
      {{ 'multi-cart.delete-quote' | translate }} 
      <span class="icon-close"></span>
    </button>
  </div>
  <div class="quote--body">
    <div class="quote-price">
      {{ 'multi-cart.your-annual-price' | translate }}<br>
      <strong>
        {{ quote.attributes.totals?.cpqNetPriceTotal | currency: currency }}
      </strong>
    </div>
    <div *ngIf="quote.itemDetails[0]?.attributes.sku" class="quote-image">
      <img [src]="getImageForQuote(quote.itemDetails[0]?.attributes.sku)" alt="{{ quote.itemDetails[0]?.attributes.sku }}">
    </div>
    <div class="quote-items">
      <h6 class="quote-item-name">{{ quote.id }}</h6>
      <ng-container *ngIf="quote.itemDetails?.length > 0; else noItems">
        <strong>
          {{ singularOrPlural(quote.itemDetails.length, 'orders-page.item') | translate: {number: quote.itemDetails.length} }}
        </strong>
        <span>{{ 'mini-cart.equType' | translate:{count: calculateEquType(quote.itemDetails)} }}</span>
        <span>{{ 'mini-cart.fls' | translate:{count: quote.itemDetails.length} }}</span>
      </ng-container>
      <ng-template #noItems>
        <span>{{ 'multi-cart.no-quote-items' | translate }}</span>
      </ng-template>
    </div>
    <div class="quote-actions">
      <button type="submit" class="button button--primary" [attr.data-test]="'button-continue-' + quote.id"
          (click)="switchQuote(quote.id, true)">
        {{ 'multi-cart.continue' | translate }}
      </button>
      <button type="submit" class="button" [attr.data-test]="'button-select' + quote.id"
          (click)="switchQuote(quote.id)">
        {{ 'multi-cart.select' | translate }}
      </button>
    </div>
  </div>
</div>
