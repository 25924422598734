import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { environment } from '../../environments/environment';
import { AppUtils } from '../utils/app.utils';
import { LocalStorageUtils } from '../utils/localStorage.utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  static languageParamRegex = /^[a-z]{2}-[a-z]{2}$/;
  static languageUrlRegex = /^\/[a-z]{2}-[a-z]{2}\//;

  constructor(
    private translateService: TranslateService,
    private translateCacheService: TranslateCacheService,
  ) {
  }

  getTranslationByKey(key: string | Array<string>, params?: any): Observable<any> {
    return this.translateService.get(key, params);
  }

  validateLanguage(language: string): boolean {
    return this.translateService.langs.includes(language);
  }

  getDefaultLanguage(): string {
    return environment.defaultLang;
  }

  getCachedLanguage(): string {
    return this.translateCacheService.getCachedLanguage();
  }

  getCurrentLanguage(): string {
    const [defaultLangCode] = this.getDefaultLanguage().split('-');
    const [storageLangCode] = LocalStorageUtils.getKeyValue('lang').split('-');

    return storageLangCode || defaultLangCode;
  }

  getShopAllLanguages(): Array<any> {
    const allLanguages = environment.stores.map(store => store.languages.map(lang => lang.id));

    return allLanguages.length === 0 ? [] : [].concat.apply([], allLanguages).sort();
  }

  getCurrentLocale(): any {
    const currentStore = AppUtils.getCurrentStore();
    const currentLanguage = this.getCurrentLanguage();

    const language = currentStore.languages.find(lang => lang.code === currentLanguage);

    return this.buildLangParam(currentStore.marketCode, language.defaultCurrencyLocale);
  }

  getStoreByLocale(locale: string): any | null {
    const [_, marketCode] = locale.split('-');
    const currentStore = environment.stores.find(store => store.marketCode === marketCode);

    return !currentStore ? null : currentStore;
  }

  getShopAllMarkets(): Array<any> {
    const marketCodes = environment.stores.map(store => store.marketCode);

    return marketCodes.length === 0 ? [] : marketCodes;
  }

  validateMarket(marketCode: string): boolean {
    const marketCodes = this.getShopAllMarkets();
    return marketCodes.includes(marketCode);
  }

  getCurrentParam(): string {
    return this.buildLangParam(AppUtils.getCurrentMarket(), this.getCurrentLanguage());
  }

  buildLangParam(marketCode: string, language: string): string {
    return `${language}-${marketCode}`;
  }

  validateLangParam(param: Params): boolean {
    return param?.hasOwnProperty('lang') && param.lang.length > 0 && param.lang.indexOf('-') !== -1;
  }

  getLangParamData(langParam: string): string[] {
    return langParam.split('-');
  }

}
