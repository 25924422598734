import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrl: './radio-buttons.component.scss'
})
export class RadioButtonsComponent {
  @Input() form: UntypedFormGroup;
  @Input() values: string[];
  @Input() formControlName: string;
}
