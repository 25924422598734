@if (form && values) {
  <div class="radio-buttons-container" [formGroup]="form">
    @for (value of values; track value) {
      <div class="buttons-wrapper">
        <input
            type="radio"
            class="radio form-control"
            formControlName="{{formControlName}}"
            [id]="'radioButton' + value"
            [value]="value"
            [attr.data-test]="'radio-button-' + value">
        <label [for]="'radioButton' + value">
          {{ 'order-approve.' + value | translate }}
        </label>
      </div>
    }
  </div>
} @else {
  {{ 'order-approve.no-needed-data-provided' | translate }}
}
