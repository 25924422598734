import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { MarketingFacade } from '../../../facades/marketing.facade';
import { CartUtils } from '../../../utils/cart.utils';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { IModalitiesAndMaterialNumbers } from '../../../models/cpq.models';

@Component({
  selector: 'app-add-quote-modal',
  templateUrl: './add-quote-modal.component.html',
  styleUrls: ['./add-quote-modal.component.scss'],
})
export class AddQuoteModalComponent implements OnInit, OnDestroy {
  tabsOpened = [] as Array<string>;
  quoteSelected: string;
  isLoading: boolean;
  quotes: any;
  serviceType: string;
  cartName = CartUtils.getCartName;
  currentCartId: string;
  soldToIdFromFL: number;

  @Input() flNumber: string;
  @Input() materialNumber: string;
  @Input() productSku: string;
  @Input() soldToIdFromParams: number;
  @Input() modalitiesAndMaterialNumbers: IModalitiesAndMaterialNumbers[];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private marketingFacade: MarketingFacade,
    private catalogFacade: CatalogFacade,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getSoldToId();
    this.marketingFacade.selectCartId()
      .subscribe(id => this.currentCartId = id)
      .unsubscribe();
  }

  getSoldToId(): void {
    this.isLoading = true;
    if (![null, NaN, ''].includes(this.soldToIdFromParams)) {
      this.soldToIdFromFL = this.soldToIdFromParams;
      this.getCarts();
    } else {
      if (this.flNumber) {
        this.catalogFacade.getShsEquipmentInstalledBaseWithSoldTo(this.flNumber)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.soldToIdFromFL = +data.included[0].attributes.customerSoldToId;
            this.getCarts();
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hasTheSameSoldTo(quote: any): boolean {
    return quote.attributes.customerSoldToId ? this.soldToIdFromFL === quote.attributes.customerSoldToId : true;
  }

  getCarts(): void {
    if (this.soldToIdFromFL) {
      this.marketingFacade.getCarts().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
          next: carts => {
            this.quotes = [...carts.data];
            const cartItems = carts.included?.filter(c => c.type === 'items');
            this.serviceType = carts.included?.find(c => c.type === 'concrete-products').attributes.name;
            this.quotes.forEach(cart => {
              cart.itemDetails = [];
              cart.relationships?.items?.data.forEach(data => {
                  cart.itemDetails.push(...cartItems.filter(item => item.id === data.id));
                },
              );
              cart.hasTheSameSoldTo = this.hasTheSameSoldTo(cart);
              cart.hasSameFL = cart.itemDetails.length > 0 ?
                cart.itemDetails.find(i => i.attributes.systemDetails?.siemensEquipmentId === this.flNumber) : false;
            });
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
        },
      );
    }
  }

  getModality(materialNumber: string): string {
    return this.modalitiesAndMaterialNumbers.filter(item => item.materialNumber === materialNumber)[0]?.modality;
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex((tab) => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  startConfiguration(): void {
    this.isLoading = true;
    this.marketingFacade.createEmptyCart();
    this.marketingFacade.selectCartId().pipe(
      skipWhile(value => value === this.currentCartId),
      takeUntil(this.unsubscribe$),
    ).subscribe(id => {
      if (id !== this.currentCartId) {
        this.proceedWithConfiguration();
      }
    });
  }

  checkIfAccordionIsDisabled(quote: any): boolean {
    return !quote.hasTheSameSoldTo || quote.hasSameFL || this.checkIfSparePartsIncluded(quote);
  }

  checkIfSparePartsIncluded(quote: any): boolean {
    return !!quote.relationships && !quote.attributes.hasContractInCart;
  }

  addToCart(): void {
    this.isLoading = true;
    this.marketingFacade.switchDefaultCart(this.quoteSelected);
    this.marketingFacade.selectCartId().pipe(
      takeUntil(this.unsubscribe$),
      skipWhile(cartId => cartId !== this.quoteSelected)
    ).subscribe(() => {
      this.proceedWithConfiguration();
    });
    this.marketingFacade.getCartItems(this.quoteSelected);
  }

  private proceedWithConfiguration(): void {
    if (this.productSku) {
      this.marketingFacade.startContractConfiguration(this.productSku, this.flNumber, this.materialNumber);
    } else {
      this.router.navigate(['/catalog/service-contracts'], {
        queryParams: {
          'fl-number': this.flNumber,
          'rel-product-sysivk': this.materialNumber,
        },
      }).then();
    }
  }

}
