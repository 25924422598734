import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.scss']
})
export class TextfieldComponent implements OnInit, OnChanges {
    isActive = false;
    isInvalid = false;
    delayTimer = null;
    styles: any = {
        wrapper: '',
    };
    @Input() inputType = 'text';
    @Input() cssStyles: any;
    @Input() modelValue: any;
    @Input() icon = '';
    @Input() inputName: string;
    @Input() isRequired = false;
    @Input() label: string;
    @Input() hintText = '';
    @Input() hintOnError = false;
    @Input() public form: UntypedFormGroup;
    @Input() readOnly = false;
    @Input() trim?: boolean;
    @Input() multiCharToTrim?: string[];
    @Input() trimWhitespaces?: boolean;
    @Input() inputDescription: string;
    @Input() onKeyTimeout = 250;
    @Input() domain?: any;
    @Input() markInvalid = false;
    @Input() loading: boolean;
    @Input() maxLength: number;
    @Input() dataTestClass: string = '';
    @Output() fieldFilled = new EventEmitter<{ value: any, key: string, inputDescription: string, oldValue: string, inputType: string }>();

    ngOnInit(): void {
        this.styles = {...this.styles, ...this.cssStyles};
        if (this.domain && this.domain.name === 'int') {
          this.inputType = 'number';
        }
        if (!this.dataTestClass) {
          this.dataTestClass = 'input-' + this.inputType;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.modelValue && changes.modelValue.currentValue !== changes.modelValue.previousValue) {
            this.validateModel();
        }
        if (changes?.readOnly && changes.readOnly.currentValue !== changes.readOnly.previousValue) {
            this.readOnly = changes.readOnly.currentValue;
        }
    }

    activate(): void {
        if (this.readOnly) {
            this.setInputInReadOnlyState();
        } else {
            this.isActive = !this.isActive;
        }
    }

    onKey(event: any): void {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.fieldFilled.emit({
              value: event.target.value,
              key: this.inputName,
              inputDescription: this.inputDescription,
              oldValue: this.modelValue,
              inputType: 'textField',
            });
        }, this.onKeyTimeout);
    }

    getLabel(): string {
        return `${this.label}${this.isRequired ? '*' : ''}`;
    }

    onBlur(): void {
        if (this.readOnly) {
            this.setInputInReadOnlyState();
        } else {
            this.validateModel();
        }
        this.isActive = false;
    }

    validateModel(): void {
        this.isInvalid = !this.modelValue && this.isRequired && this.form.get(this.inputName).touched;
    }

    setInputInReadOnlyState(): void {
        this.isActive = false;
        this.isInvalid = false;
        this.form.get(this.inputName).setErrors(null);
        this.form.get(this.inputName).markAsUntouched();
        this.form.get(this.inputName).markAsPristine();
    }
}
