import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { IconType } from '../../../../models/settings.model';
import { ICustomerAddressPayloadData } from '../../../../models/customer.models';
import { EAddressType, EFeatureToggles } from '../../../../configurations/common';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
})
export class DeliveryAddressComponent implements OnInit, OnChanges {
  iconType = IconType;
  shipToAddressType = EAddressType.SHIP_TO;

  @Input() isSapStore: boolean;
  @Input() address: any;
  @Input() customerId: string;
  @Input() businessUnitName: string;
  @Input() businessUnitNumber: string;
  @Input() preferredShipToId: string;

  @Input() updateCustomerAddressesInProgress: boolean = true;
  @Input() updatePreferredAddressInProgress: boolean = true;

  @Output() sortAddressesEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePreferredAddressEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  showModalUpdateAddress: boolean = false;
  showModalDeleteAddress: boolean = false;
  showModalReportWrongAddress: boolean = false;
  updateInProgress: boolean = false;
  isAttentionToEnabled: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectIsAttentionToEnabled();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.updateCustomerAddressesInProgress?.currentValue === false && this.updateInProgress) {
      this.updateInProgress = false;
    }
  }

  /**
   * Check if address is set as preferred shipTo address.
   *
   * Firstly check if the preferred shipTo address is even configured (preferredShipToId is not empty).
   * Then check if there is any match between preferredShipToId and the id of the address.
   * (sapId - non-customer addresses, id - customer addresses)
   *
   * @return {boolean}
   */
  isPreferredAddress(): boolean {
    return !!this.preferredShipToId &&
      (this.address?.sapId === this.preferredShipToId || this.address?.id === this.preferredShipToId);
  }

  /**
   * Set address as preferred shipTo address.
   *
   * @param {string} preferredShipToId
   */
  setPreferredShipToAddress(preferredShipToId: string): void {
    if (!this.updateCustomerAddressesInProgress && !this.updatePreferredAddressInProgress) {
      this.updatePreferredAddressEmit.emit(true);
      this.updateInProgress = true;

      const data = {
        data: {
          type: 'customer-preferences',
          attributes: {
            preferredShipToId: preferredShipToId,
          },
        },
      };

      this.customerFacade.saveCustomerSettingPreferences(this.customerId, data).subscribe({
        next: response => {
          const responseData = response.data.attributes;

          if (responseData.preferredPayment && responseData.notifications) {
            this.customerFacade.updateCustomerPreferences(responseData);
          }

          this.updatePreferredAddressEmit.emit(false);
          this.updateInProgress = false;
          this.sortAddressesEmit.emit();
        },
        error: () => {
          this.updatePreferredAddressEmit.emit(false);
          this.updateInProgress = false;
        },
      });
    }
  }

  /**
   * Clear preferred shipTo address.
   */
  clearPreferredShipToAddress(): void {
    if (!this.updateCustomerAddressesInProgress && !this.updatePreferredAddressInProgress) {
      this.setPreferredShipToAddress('');
      this.sortAddressesEmit.emit();
    }
  }

  /**
   * Update address.
   *
   * @param {ICustomerAddressPayloadData} addressData
   */
  updateAddress(addressData: ICustomerAddressPayloadData): void {
    this.customerFacade.beginUpdateCustomerAddressAction(this.customerId, this.address?.id, addressData);
    this.updateInProgress = true;
    this.showModalUpdateAddress = false;
  }

  /**
   * Delete address.
   *
   * If deleted address was set as preferred shipTo address, preferred shipTo address is deleted as well.
   */
  deleteAddress(): void {
    this.customerFacade.beginDeleteCustomerAddressAction(this.customerId, this.address?.id, this.address.id === this.preferredShipToId);
    this.updateInProgress = true;
    this.showModalDeleteAddress = false;
  }

  /**
   * Get address title for report wrong address modal. (different format for different stores and addresses)
   *
   * @return {string}
   */
  getAddressForReportWrongAddressModal(): string {
    if (this.isSapStore) {
      return `${this.address?.sapId} - ${this.address?.name}, ${this.getAddressDetails()}`;
    }

    return this.address?.company
      ? `${this.address?.sapId} - ${this.address?.company}, ${this.getAddressDetails()}`
      : `${this.businessUnitNumber} - ${this.businessUnitName}, ${this.getAddressDetails()}`;
  }

  /**
   * Get address details as a string (individual address fields are separated by commas).
   *
   * @param {boolean} excludeAddress
   *
   * @return {string}
   */
  getAddressDetails(excludeAddress?: boolean): string {
    return [
      excludeAddress ? null : this.address.address1,
      this.address.city,
      this.address.state,
      this.address.zipCode,
    ].filter(Boolean).join(', ');
  }

  /**
   * Display "update address" modal if there is no customer address or preferred address update in progress.
   */
  showUpdateAddressModal(): void {
    if (!this.updateCustomerAddressesInProgress && !this.updatePreferredAddressInProgress) {
      this.showModalUpdateAddress = true;
    }
  }

  /**
   * Display "delete address" modal if there is no customer address or preferred address update in progress.
   */
  showDeleteAddressModal(): void {
    if (!this.updateCustomerAddressesInProgress && !this.updatePreferredAddressInProgress) {
      this.showModalDeleteAddress = true;
    }
  }

  /**
   * Display "report wrong address" modal if there is no customer address or preferred address update in progress.
   */
  showReportWrongAddressModal(): void {
    if (!this.updateCustomerAddressesInProgress && !this.updatePreferredAddressInProgress) {
      this.showModalReportWrongAddress = true;
    }
  }

  /**
   * Method for determining if attentionTo functionality is enabled (based on the ARAKH feature toggle).
   *
   * @private
   */
  private selectIsAttentionToEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.ATTENTION_TO).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: boolean) => {
      this.isAttentionToEnabled = value;
    });
  }
}
