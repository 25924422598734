@if (address) {
  <p *ngIf="!AddressUtils.isAddressEmpty(attentionTo)" [attr.data-test]="'desc-attentionTo'">
    {{ attentionTo }}
  </p>
  <p>
    <span *ngIf="!address?.isCustom && !AddressUtils.isAddressEmpty(address?.sapId)" [attr.data-test]="'desc-sapId'">
      {{ address.sapId }} -
    </span>
    <span *ngIf="!AddressUtils.isAddressEmpty(address?.company)" [attr.data-test]="'desc-company'">
      {{ address.company }}
    </span>
  </p>
  <p *ngIf="!AddressUtils.isAddressEmpty(address?.address1)" [attr.data-test]="'desc-address1'">
    {{ address.address1 }}
  </p>
  <p [attr.data-test]="'desc-address2'">
    <span *ngIf="!AddressUtils.isAddressEmpty(address?.zipCode)">{{ address.zipCode }} </span>
    <span *ngIf="!AddressUtils.isAddressEmpty(address?.city)">{{ address.city }}</span>
    <span *ngIf="!AddressUtils.isAddressEmpty(address?.state)">, {{ address.state }}</span>
  </p>
  <p *ngIf="!AddressUtils.isAddressEmpty(address?.country)" [attr.data-test]="'desc-country'">
    {{ address.country }}
  </p>
} @else {
  <span>-</span>
}
