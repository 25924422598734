import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ECompanyBUIncludes, EGlueResource } from '../configurations/common';
import {
  ICompanyBusinessUnits,
  ICustomerAddressData,
  ICustomerAddressesData,
  ICustomerAddressPayloadData,
} from '../models/customer.models';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  glueUrl: string = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private httpParamsForGetCompanyUsers = {
    params: new HttpParams()
      .set('include',
        `${ECompanyBUIncludes.COMPANY_ROLES},${
          ECompanyBUIncludes.CUSTOMERS},${
          ECompanyBUIncludes.COMPANY_BUSINESS_UNITS},${
          ECompanyBUIncludes.CUSTOMER_PREFERENCES}`,
      ),
  };

  private httpParamsForGetBusinessUnits = {
    params: new HttpParams()
      .set('include', 'company-business-unit-addresses,companies'),
  };

  private static handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  getCompanyUsers(): Observable<any> {
    const customerRolesUrl = this.glueUrl + '/company-users/mine';

    return this.http.get<any>(customerRolesUrl, this.httpParamsForGetCompanyUsers)
      .pipe(
        tap(),
        catchError(CustomerService.handleError),
      );
  }

  getBusinessUnits(): Observable<ICompanyBusinessUnits> {
    return this.http.get<any>(this.glueUrl + '/company-business-units/mine', this.httpParamsForGetBusinessUnits)
      .pipe(
        tap(),
        catchError(CustomerService.handleError),
      );
  }

  getBusinessUnitById(id: string): Observable<ICompanyBusinessUnits> {
    return this.http.get<ICompanyBusinessUnits>(this.glueUrl + `/company-business-units/${id}?include=company-business-unit-addresses`)
      .pipe(
        tap(),
      );
  }

  getCustomerAddresses(customerId: string): Observable<ICustomerAddressesData> {
    return this.http.get<ICustomerAddressesData>(
      `${this.glueUrl}/${EGlueResource.CUSTOMERS}/${customerId}/${EGlueResource.ADDRESSES}`,
    ).pipe(
      tap(),
      catchError(CustomerService.handleError),
    );
  }

  addCustomerAddress(customerId: string, payload: ICustomerAddressPayloadData): Observable<ICustomerAddressData> {
    return this.http.post<ICustomerAddressData>(
      `${this.glueUrl}/${EGlueResource.CUSTOMERS}/${customerId}/${EGlueResource.ADDRESSES}`,
      payload,
    ).pipe(
      catchError(CustomerService.handleError),
    );
  }

  updateCustomerAddress(customerId: string, addressId: string, payload: ICustomerAddressPayloadData): Observable<ICustomerAddressData> {
    return this.http.patch<ICustomerAddressData>(
      `${this.glueUrl}/${EGlueResource.CUSTOMERS}/${customerId}/${EGlueResource.ADDRESSES}/${addressId}`,
      payload,
    ).pipe(
      catchError(CustomerService.handleError),
    );
  }

  deleteCustomerAddress(customerId: string, addressId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.glueUrl}/${EGlueResource.CUSTOMERS}/${customerId}/${EGlueResource.ADDRESSES}/${addressId}`,
    ).pipe(
      catchError(CustomerService.handleError),
    );
  }

  saveCustomerSettingPreferences(customerId: string, data: any): Observable<any> {
    customerId = customerId.replace(/"+/g, '');

    const newBody = JSON.stringify(data);
    const customerUrl = `${this.glueUrl}/customer-preferences/${customerId}`;

    return this.http.patch<any>(customerUrl, newBody).pipe(
      map(response => response),
      catchError(CustomerService.handleError),
    );
  }

  deleteCustomerAccount(customerId: string): Observable<any> {
    customerId = customerId.replace(/"+/g, '');

    const customerUrl = `${this.glueUrl}/customers/${customerId}`;

    return this.http.delete<any>(customerUrl).pipe(
      catchError(CustomerService.handleError),
    );
  }

}
