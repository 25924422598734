import { Pipe, PipeTransform } from '@angular/core';
import { AdvancedFilter } from '../../models/advanced-filter';
import { RemoveLeadingZeroPipe } from './remove-leading-zero.pipe';
import { EEquipmentFilterAttributes, EEquipmentProductGroup } from '../../configurations/equipments';

@Pipe({
  name: 'filterByAttributes',
})
export class FilterByAttributesPipe implements PipeTransform {

  constructor(private removeLeadingZero: RemoveLeadingZeroPipe) {
  }

  countAppliedFilters(filters: AdvancedFilter[]): number {
    if (!filters) {
      return 0;
    }
    let howManyFilters = 0;
    filters.forEach(filter => {
      howManyFilters += filter.selected.length;
    });
    return howManyFilters;
  }

  filterByAttribute(items: any[], filter: AdvancedFilter): any[] {
    if (items || filter.selected) {
      return items.filter((item) => {
        let attribute;
        if (filter.secondAdditionalAttribute) {
          attribute = [item?.[filter.attribute]?.[filter.firstAdditionalAttribute]?.[filter.secondAdditionalAttribute].toString()];
        } else if (filter.firstAdditionalAttribute) {
          attribute = this.parseDataFromObject(item, filter.attribute, filter.firstAdditionalAttribute);
        } else {
          attribute = filter.attribute === 'id'
            ? [this.removeLeadingZero.transform(item?.[filter.attribute])]
            : [item?.[filter.attribute].toString()];
        }
        return this.checkForMatch(filter.name === EEquipmentFilterAttributes.PRODUCT_CATEGORY
            ? this.editSelectedFilterForConsumables(filter.selected)
            : filter.selected,
          attribute);
      });
    }
  }

  editSelectedFilterForConsumables(selected: string[]): string[] {
    return selected.map(s => {
      return (s.toLowerCase() !== EEquipmentProductGroup.CONSUMABLES.toLowerCase()).toString();
    });
  }

  checkForMatch(selectedFilter: string[], values: string[]): boolean {
    for (const element of values) {
      if (selectedFilter.indexOf(element) > -1) {
        return true;
      }
    }
    return false;
  }

  parseDataFromObject(object: any, attribute: string, additionalAttribute: string): string[] | boolean[] {
    switch (additionalAttribute) {
      case 'shippingAddress':
      case 'soldToAddress':
      case 'payerAddress':
      case 'billingAddress':
        return [this.addressToString(object?.[attribute]?.[additionalAttribute])];
      case 'shipTo':
      case 'soldTo':
      case 'billTo':
        return [this.hybrisAddressToString(object?.[attribute], additionalAttribute)];
      case 'items':
        return this.getMaterialNumbersOfItems(object);
      default:
        return [object?.[attribute]?.[additionalAttribute].toString()];
    }
  }

  addressToString(address: any): string {
    if (!address) {
      return '';
    }

    const addressDetails = [
      address.address1,
      address.city,
      address.zipCode,
    ].filter(addressField => !this.isEmptyAddress(addressField)).join(', ');

    return this.formatEmptyAddress(address.company) + (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  hybrisAddressToString(attributes: any, addressType: string): string {
    if (!attributes) {
      return '';
    }

    const addressDetails = [
      attributes[`${addressType}Address`],
      attributes[`${addressType}Town`],
      attributes[`${addressType}PostalOffice`],
    ].filter(addressField => !this.isEmptyAddress(addressField)).join(', ');

    return this.formatEmptyAddress(attributes[`${addressType}Name`]) + (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  getMaterialNumbersOfItems(order: any): string[] {
    return [
      ...new Set(
        order.type === 'orders'
          ? order.attributes.items.map(item => item.materialNumber)
          : order.attributes.items.map(item => item.productNumber.toString()),
      )] as string[];
  }

  isEmptyAddress(address: string): boolean {
    return address === '' || address === 'empty';
  }

  formatEmptyAddress(address: string): string {
    if (address === '' || address === 'empty') {
      return '';
    }

    return address;
  }

  intersect(first = [], ...rest): any[] {
    rest = rest.map(array => new Set(array));
    return first.filter(item => rest.every(set => set.has(item)));
  }

  transform(items: any[], filters: AdvancedFilter[]): any[] {
    const arrayOfFilterResults: any[][] = [];
    if (!items || !filters || this.countAppliedFilters(filters) === 0) {
      return items;
    }
    filters.forEach(filter => {
      const filterResults = this.filterByAttribute(items, filter);
      if (filter.selected.length !== 0) {
        arrayOfFilterResults.push(filterResults);
      }
    });
    return this.intersect(...arrayOfFilterResults);
  }
}
