import { Injectable } from '@angular/core';
import { ShsEquipmentService } from '../services/shs-equipment.service';
import { Observable } from 'rxjs';
import { IShsEquipment } from '../models/installedbase.models';

@Injectable({
  providedIn: 'root',
})
export class ShsEquipmentFacade {

  constructor(private shsEquipmentService: ShsEquipmentService) {
  }

  getShsEquipment(functionalLocation : string): Observable<IShsEquipment> {
    return this.shsEquipmentService.getShsEquipment(functionalLocation );
  }

}
