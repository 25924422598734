import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { MathUtils } from '../../../utils/math.utils';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { EProductDetails } from '../../../configurations/product-details';
import { environment } from '../../../../environments/environment';
import { AppUtils } from '../../../utils/app.utils';
import { PriceUtils } from '../../../utils/price.utils';
import { EFeatureToggles } from '../../../configurations/common';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { Observable, Subject } from 'rxjs';
import { ICart } from '../../../models/cart.models';
import { takeUntil } from 'rxjs/operators';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ISystemDetails } from '../../../models/common.models';

@Component({
  selector: 'app-basic-product-pdp',
  templateUrl: './basic-product-component-pdp.component.html',
  styleUrls: ['./basic-product-component-pdp.component.scss'],
})
export class BasicProductPdpComponent implements OnInit {
  currentCart: ICart;
  isMyInstalledBaseFlowEnabled$: Observable<boolean> = new Observable<boolean>();
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();
  isQuantityValid = true;
  openZoomedPicture = false;
  priceUtils = PriceUtils;
  systemDetails: ISystemDetails;

  @Input() abstractProduct;
  @Input() addNewModalActive;
  @Input() concreteSku;
  @Input() defaultImg;
  @Input() defaultPrice;
  @Input() displayConfig;
  @Input() installBaseProducts;
  @Input() isAddToCartInProgress$;
  @Input() isInStore;
  @Input() labels;
  @Input() largeUrls;
  @Input() loadingCartDataInProgress;
  @Input() mainPicture;
  @Input() productDetailsEnum;
  @Input() productInfoLoaded;
  @Input() productQuantity;
  @Input() secondPrice;
  @Input() showPriceDisclaimer;
  @Input() sku;
  @Input() smallUrls;
  @Input() wasRedirectedFromEquipmentPage: boolean;
  @Input() wishlists;
  @Output() addNewModalWishlist = new EventEmitter<any>();
  @Output() addProductToCartSubmit = new EventEmitter<any>();
  @Output() productQuantityChange = new EventEmitter<number>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCurrentCart();
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.isMyInstalledBaseFlowEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW);
  }

  trackInstalledBase(installedBase): void {
    this.catalogFacade.setInstalledBase(installedBase);
    this.analyticsService.trackInstalledBase(installedBase.attributes.materialName, installedBase.id);
  }

  tracking(name: string): void {
    this.analyticsService.setProducts(this.abstractProduct);
    this.analyticsService.trackAccordion(name);
  }

  selectCurrentCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
      if (cart) {
        this.currentCart = cart;
        this.systemDetails = cart?.attributes?.systemDetails;
      }
    });
  }

  addToWishlist(): void {
    this.addNewModalWishlist.emit();
  }

  displayProductDetail(section: EProductDetails): boolean {
    return this.displayConfig.includes(section);
  }

  setLargeUrl(index: number): void {
    this.mainPicture = this.largeUrls[index];
  }

  changeProductQuantity(productQuantity: number): void {
    this.checkIfPositiveInteger(productQuantity);
    if (this.isQuantityValid) {
      this.productQuantityChange.emit(productQuantity);
    }
  }

  private checkIfPositiveInteger(productQuantity: number): void {
    this.isQuantityValid = MathUtils.checkIfNumeric(productQuantity) && productQuantity > 0;
  }

  openZoomPicture(): void {
    if (this.mainPicture !== this.defaultImg) {
      this.openZoomedPicture = !this.openZoomedPicture;
      if (this.openZoomedPicture) {
        this.analyticsService.setProducts(this.abstractProduct);
        this.analyticsService.trackProduct('image.enlarge', this.abstractProduct);
      }
    }
  }

  addProductToCart(): void {
    this.addProductToCartSubmit.emit();
  }

  shouldShowAvailabilityDetails(): boolean {
    const currentStore = AppUtils.getCurrentStore();
    return !environment.features.excludeTax.includes(currentStore.storeId);
  }

  /**
   * Check if product is discontinued (sap_p40_sales_status is '01' or '02')
   *
   * @return {boolean}
   */
  isProductDiscontinued(): boolean {
    switch (this.abstractProduct?.attributes?.sap_p40_sales_status) {
      case '01':
        return true;
      case '02':
        return true;
      default:
        return false;
    }
  }

  /**
   * Check if product has subsequent material
   *
   * @return {boolean}
   */
  isSubsequentMaterialAvailable(): boolean {
    return !!(this.abstractProduct?.attributes?.sap_p40_sales_status === '02'
      && this.abstractProduct?.attributes?.sap_p40_subsequent_material);
  }

  /**
   * Get subsequent material for product
   * Handle multiple cases: no subsequent material/one subsequent material
   * Add leading zeros to subsequent material number if needed
   *
   * @return {string[]}
   */
  getSubsequentMaterial(): string[] {
    if (this.isSubsequentMaterialAvailable()) {
      return [this.abstractProduct.attributes.sap_p40_subsequent_material.padStart(8, '0')];
    }

    return [];
  }
}
