import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GlueConfigurationService } from './glue-configuration.service';
import { AppUtils } from '../utils/app.utils';
import { ICart, ICartPayload, ICartUpdateRequest, ICartUpdateRequestAttributes } from '../models/cart.models';
import { CartUtils } from '../utils/cart.utils';
import { EGlueResource } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private url: string = `${this.glueConfiguration.getEndpointUrl()}/${EGlueResource.CARTS}`;

  // NOTE: consider introducing context-specific (per-store? per-call?) includes
  // e.g. sap-* Glue resources don't make sense for non-US stores
  private httpParamsForGetCartItems = {
    params: new HttpParams().set(
      'include',
      [
        EGlueResource.CART_ITEMS,
        EGlueResource.CONCRETE_PRODUCTS,
        EGlueResource.CONCRETE_PRODUCT_IMAGE_SETS,
        EGlueResource.VOUCHERS,
        EGlueResource.CART_RULES,
        EGlueResource.RFQ_PRODUCTS,
        EGlueResource.SAP_MESSAGES,
        EGlueResource.SAP_ITEM_AVAILABILITIES,
        EGlueResource.SHIPMENTS,
      ].join(','),
    ),
  };

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleError(err): Observable<any> {
    let errorMessage: string;
    if (Array.isArray(err)) {
      return throwError(err);
    } else if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      return throwError(err.status);
    }
    return throwError(errorMessage);
  }

  private static handleErrorPromise(error: any): Promise<any> {
    return (error);
  }

  postCart(attributes: ICartUpdateRequestAttributes): Observable<ICartPayload> {
    const currentLangConfig = AppUtils.getCurrentStore();
    const postCartData = {
      data: {
        type: EGlueResource.CARTS,
        attributes: {
          name: new Date().getTime(),
          priceMode: currentLangConfig.storeDefaultPriceMode,
          currency: currentLangConfig.storeDefaultCurrency,
          store: currentLangConfig.storeId,
          ...attributes,
        },
      },
    };

    return this.httpClient.post<ICartPayload>(this.url, postCartData, {
      ...this.httpParamsForGetCartItems,
      observe: 'response',
    }).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  getCarts(): Observable<any> {
    return this.httpClient.get<any>(`${this.url}?include=items,concrete-product-image-sets,concrete-products`).pipe(
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  getCartById(cartId: string): Observable<ICartPayload> {
    return this.httpClient.get<any>(`${this.url}/${cartId}?include=vouchers`, {observe: 'response'}).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  deleteCartById(cartId: string): Observable<any> {
    const url = `${this.url}/${cartId}`;
    return this.httpClient.delete<any>(url).pipe(
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  addItemToCart(cartId: string, itemData: any): Observable<ICartPayload> {
    const url = `${this.url}/${cartId}/${EGlueResource.CART_ITEMS}`;
    return this.httpClient.post<ICartPayload>(
      url,
      itemData,
      {...this.httpParamsForGetCartItems, observe: 'response'},
    ).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors),
      ),
    );
  }

  postProductsFromCsvToCart(cartId: string, data: any): Observable<ICartPayload> {
    const url = `${this.url}/${cartId}/${EGlueResource.ITEM_LISTS}`;
    return this.httpClient.post<any>(
      url,
      data,
      {...this.httpParamsForGetCartItems, observe: 'response'})
      .pipe(
        map((res: any) => this._mapResponseWithETag(res)),
        catchError((error) => {
            return ShoppingCartService.handleError(error.error.errors[0]);
          },
        ),
      );
  }

  getCartItems(cartId: string): Observable<ICartPayload> {
    const url = `${this.url}/${cartId}`;
    return this.httpClient.get<any>(url, {...this.httpParamsForGetCartItems, observe: 'response'}).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  getCartShippingMethods(cartId: string): Observable<ICartPayload> {
    return this.httpClient.get<any>(`${this.url}/${cartId}?include=shipments`, {observe: 'response'}).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  deleteItemFromCart(cartId: string, itemId: string): Observable<{}> {
    const url: string = `${this.url}/${cartId}/${EGlueResource.CART_ITEMS}/${itemId}`;
    return this.httpClient.delete<any>(url).pipe(
      catchError((error) => ShoppingCartService.handleError(error.error)),
    );
  }


  deleteItemsFromCart(cartId: string): Observable<ICartPayload> {
    const url = `${this.url}/${cartId}/${EGlueResource.ITEM_LISTS}/${cartId}`;
    return this.httpClient.delete<ICartPayload>(url, {...this.httpParamsForGetCartItems, observe: 'response'}).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) => {
        return ShoppingCartService.handleError(error.error.errors);
      }),
    );
  }

  sequentialRequestPatchCartItemGetCartItems(cartId: string, itemId: string, itemData: any): Observable<ICartPayload> {
    const urlPatch: string = `${this.url}/${cartId}/${EGlueResource.CART_ITEMS}/${itemId}`;
    return this.httpClient.patch<any>(
      urlPatch,
      itemData,
      {...this.httpParamsForGetCartItems, observe: 'response'},
    ).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  postVoucher(cartId: string, code: any): Observable<ICartPayload> {
    const body = {
      data: {
        type: 'vouchers',
        attributes: {
          code,
        },
      },
    };
    return this.httpClient.post<any>(`${this.url}/${cartId}/vouchers`, body, {
      ...this.httpParamsForGetCartItems,
      observe: 'response',
    }).pipe(
      map(res => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ));
  }

  removeVoucher(cartId: string, voucherId: string): Observable<ICartPayload> {
    const urlDelete = `${this.url}/${cartId}/vouchers/${voucherId}`;
    return this.httpClient.delete<any>(urlDelete, {
      ...this.httpParamsForGetCartItems,
      observe: 'response',
    }).pipe(
      map(res => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      )
    );
  }

  updateCart(cartId: string, cartData: ICartUpdateRequest, eTag: string): Observable<ICartPayload> {
    const urlUpdate = `${this.url}/${cartId}`;
    return this.httpClient.patch<ICart>(
      urlUpdate,
      cartData,
      {
        ...this.httpParamsForGetCartItems,
        observe: 'response',
        headers: new HttpHeaders().set('If-Match', `${eTag}`),
      }).pipe(
      map((res: any) => this._mapResponseWithETag(res)),
      catchError((error) =>
        ShoppingCartService.handleError(error.error.errors[0]),
      ),
    );
  }

  /**
   * Get etag and add to the response
   * @param res
   * @returns {ICartPayload}
   * @private
   */
  private _mapResponseWithETag(res: any): ICartPayload {
    const eTag: string = res.headers.has('etag') ? CartUtils.getClearEtag(res.headers.get('etag')) : '';
    return {...res.body, eTag};
  }
}
