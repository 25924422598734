<div class="section-order-summary">
  <div class="table table--order-summary section-content-container">
    <div class="table-header customer-details justify-content-between">
      @if (pointOfContact) {
        <div class="point-of-contact">
          <strong>{{ 'order-review.contact' | translate }}:</strong>
          <p class="user-info">
            {{ pointOfContact?.firstName + ' ' + pointOfContact?.lastName }}
            <br>
            {{ pointOfContact?.phone }}
            <br>
            {{ pointOfContact?.email }}
          </p>
        </div>
      }
      <div *ngIf="!isCartStatusWaiting" class="modal-buttons">
        <button class="button button--link" [attr.data-test]="'link-reviewDelivery'"
                [routerLink]="'/delivery-details'"
                [queryParams]="{ 'redirect-to': EDeliveryInputNames.COMMENT }"
                data-target="#modal1">
          <span class="text">{{ 'order-review.review-delivery-instructions' | translate }}</span>
          <span class="icon icon-arrow-right-small color-orange"></span>
        </button>
        <button class="button button--link" [attr.data-test]="'link-changeAddress'"
                [disabled]="orderReviewService.isSaturdayShipmentSelected(selectedShipmentMethod.name)"
                [routerLink]="'/delivery-details'"
                [queryParams]="{ 'redirect-to': EDeliveryInputNames.SHIP_TO_ADDRESS }">
          <span class="text">{{ 'order-review.change-address' | translate }}</span>
          <span class="icon icon-arrow-right-small color-orange"></span>
        </button>
      </div>
    </div>
    <div class="table-body cart-items">
      @for (item of cartItemsWithDetails; track item.attributes.abstractSku) {
        <div class="cart-item-outer-container">
          <div class="cart-item" [attr.data-test]="'cart-item-' + item.attributes.name">
            <div class="cart-item--image">
              <img [src]="ImageUtils.getProductImageUrl(item)" [alt]="item.attributes.name"
                   [attr.data-test]="'img-productImage'">
            </div>
            <div class="cart-item--body">
              <div class="cart-item--info">
                <div class="info-header">
                  <h5 class="item-name" [attr.data-test]="'desc-productName'">
                    {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                  </h5>
                  @if ((isProductDiscontinuedStatusEnabled$ | async) && item.attributes.isDiscontinued) {
                    <span class="label discontinued-item" [attr.data-test]="'label-discontinuedItem'">
                             {{ 'order-review.discontinued-item' | translate }}
                            </span>
                  }
                  @if (item.attributes.availability?.isExchangeable) {
                    <span class="label item-exchange-material">
                             {{ 'order-review.exchangeable-item' | translate }}
                            </span>
                  }
                  @if (item.attributes.heavyweight) {
                    <span class="label heavyweight-item">
                             {{ 'order-review.heavyweight-item' | translate }}
                            </span>
                  }
                </div>
              </div>
              <div class="cart-item--additional-fields">
                <div class="additional-field-row">
                  <div class="item-material-number" [attr.data-test]="'desc-materialNumber'">
                    {{ item.attributes.materialNumber }}
                  </div>
                  <div class="item-delivery-availability">
                    <span *ngIf="isSapStore"
                          class="icon"
                          [ngClass]="orderReviewService.getAvailabilityIcon(item.attributes.availability?.isAvailable)">
                    </span>
                    <span [attr.data-test]="'desc-stockAvailability'">
                      @if (!isSapStore) {
                        {{ item.attributes.deliveryAvailability }}
                      } @else {
                        @if (item.attributes.availability?.isAvailable) {
                          {{ 'order-review.in-stock' | translate }}
                        } @else {
                          {{ 'order-review.out-of-stock' | translate }}
                        }
                      }
                    </span>
                  </div>
                </div>
                <div class="additional-field-row">
                  <div class="quantity-label" [attr.data-test]="'label-quantity'">
                    {{ 'order-review.quantity' | translate }}:
                  </div>
                  <div class="item-quantity" [attr.data-test]="'desc-quantity'">
                    {{ item.attributes.quantity }}
                  </div>
                </div>
                @if (item.attributes.calculations?.sumListPrice) {
                  <div class="additional-field-row">
                    <div class="list-price-label" [attr.data-test]="'label-listPrice'">
                      {{ 'order-review.list-price' | translate }}:
                    </div>
                    <div class="item-list-price" [attr.data-test]="'desc-listPrice'">
                      {{ item.attributes.calculations.sumListPrice | currency: currency }}
                    </div>
                  </div>
                }
                @if (item.attributes.calculations?.sumExchangePrice) {
                  <div class="additional-field-row">
                    <div class="exchange-credit-label" [attr.data-test]="'label-exchangeCredit'">
                      {{ 'order-review.exchange-credit' | translate }}:
                    </div>
                    <div class="item-exchange-credit" [attr.data-test]="'desc-exchangeCredit'">
                      {{ item.attributes.calculations.sumExchangePrice | currency: currency }}
                    </div>
                  </div>
                }
                @if (item.attributes.calculations?.sumPrice) {
                  <div class="additional-field-row">
                    <div class="total-price-label" [attr.data-test]="'label-totalPrice'">
                      {{ 'order-review.total-price' | translate }}:
                    </div>
                    <div class="item-total-price" [attr.data-test]="'desc-totalPrice'">
                      {{ item.attributes.calculations.sumPrice | currency: currency }}
                    </div>
                  </div>
                }
                <app-advanced-product-information
                    [currentItem]="item"
                    [currentCartId]="cartId"
                    [isEditingEnabled]="false"
                    [isOrderReviewPage]="true">
                </app-advanced-product-information>
              </div>
            </div>
          </div>
          @if ((isPriceDisputingActive$ | async) && isPriceDisputingSetForItem(item.id)) {
            <app-message [message]="orderReviewService.generatePriceDisputingMessage()"/>
          }
        </div>
      }
    </div>
  </div>
  @if (orderReviewService.isSaturdayShipmentSelected(selectedShipmentMethod.name)) {
    <p class="disclaimer-saturday-shipment-order-review">
      {{ 'order-review.saturday-delivery-method-disclaimer' | translate }}
    </p>
  }
</div>
