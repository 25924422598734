import { Component } from '@angular/core';

@Component({
  selector: 'app-request-quote-1-section',
  templateUrl: './request-quote-1-section.component.html',
  styleUrl: './request-quote-1-section.component.scss'
})
export class RequestQuote1SectionComponent {

}
