<div
    class="selectbox width-100"
    [ngClass]="[
      isOpen ? 'is-open has-focus' : '',
      !margin ? 'no-margin' : '',
      isActive() ? 'is-active' : '',
      readOnly ? 'read-only' : '',
      styles.wrapper,
      disabled ? 'disabled' : '',
      isInvalid ? 'is-invalid' : '']"
    [attr.data-test]="'selectbox-' + inputName"
    (click)="showOptions($event)">
  <div class="selectbox__select">{{ selectedOptionLabel | translate }}</div>
  <div class="menu selectbox__option-list">
    <div class="selectbox__search" *ngIf="search" #searchInput>
      <input
          type="text"
          [value]="searchText" (input)="updateSearch($event)"
          [placeholder]="searchPlaceholder">
      <label class="search__icon">
        <span class="icon-search"></span>
      </label>
    </div>
    <div class="option-list__all" [attr.data-test]="'selectbox-' + inputName + '-options'">
      <div
          class="menu__item selectbox__option"
          [ngClass]="{'disabled': option?.disabled}"
          *ngFor="let option of options | selectBoxFilter:searchText"
          (click)="selectOption(option)"
          [attr.data-test]="'selectbox-' + option[optionLabelKey]">
        {{ option[optionLabelKey] | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="showPlaceholder()" class="selectbox__label">{{ getLabel() }}</div>
  <div *ngIf="form" [formGroup]="form">
    <input formControlName="{{ inputName }}" [value]="modelValue" [name]="inputName" hidden [required]="isRequired">
  </div>
  <div class="selectbox__loader" *ngIf="loading">
    <div class="loading-animation"></div>
  </div>
</div>
