import {
  IAddress,
  IPriceDisputingPerItem,
  ISystemDetailsPerItem,
  ISystemDetails,
  IMaterialMasterNumbersPerItem,
  IPointOfContact,
} from './common.models';
import { EAddressType } from '../configurations/common';
import { IApproverData } from './customer.models';

export interface IConsentCheckbox {
  name: string,
  label: string,
  isChecked: boolean,
  isRequired: boolean
}

export interface ICheckoutInput {
  type: string;
  id?: string;
  attributes: {
    orderReference?: string;
    redirectUrl?: string | null;
    isExternalRedirect?: string;
    idCart?: string;
    isAddressSavingSkipped?: boolean;
    taxNumber?: string;
    stateDisplayName?: string;
    pointOfContact?: IPointOfContact;
    approverDetails?: IapproverDetails;
    customer: ICustomerCheckout,
    billingAddress?: IAddress;
    shippingAddress?: IAddress;
    soldToAddress?: IAddress;
    payerAddress?: IAddress;
    payments: IPaymentsData[];
    shipment: {
      idShipmentMethod: number;
    },
    termsAccessTime: Date,
    consentedTerms: string[];
    invoiceEmailCopy?: string;
    sapPoNumber?: string;
    orderEmailCopy?: string;
  };
}

export interface ICustomerCheckout {
  attentionTo?: string;
  email: string;
  salutation: string;
  firstName: string;
  idCustomer?: number;
  customerReference: string;
  idCompanyBusinessUnit?: string;
  uuidCompanyUser: string;
  lastName: string;
}

export interface ICheckout {
  type: string;
  id?: string;
  attributes: {
    orderReference?: string;
    redirectUrl?: string | null;
    isExternalRedirect?: string;
    idCart?: string;
    isAddressSavingSkipped?: boolean;
    taxNumber?: string;
    stateDisplayName?: string;
    pointOfContact?: IPointOfContact;
    approverDetails?: IApproverData;
    customer: ICustomerCheckout;
    billingAddress?: IAddress;
    shippingAddress: IAddress;
    soldToAddress?: IAddress;
    payerAddress?: IAddress;
    payments: IPaymentsData[];
    shipment: {
      idShipmentMethod: number;
    }
    termsAccessTime: Date;
    consentedTerms: string[];
    invoiceEmailCopy?: string;
    sapPoNumber?: string;
  };
}

export interface ICheckoutFormData {
  discount: string;
  orderEmailCopy: string;
  purchaseOrderNumber: string;
  taxNumber: string;
  paymentMethod: string;
}

export interface IAddressData {
  id?: string;
  name: string;
  value: IAddress;
  type?: EAddressType;
  isAddressValid?: boolean;
}

export interface ICheckoutData {
  data: {
    type: string;
    attributes: {
      idCart: string;
      shipment?: {
        idShipmentMethod: number;
      }
    }
  };
}

export interface ICheckoutUpdate {
  data: {
    type: string;
    attributes: {
      idCart: string;
      isAddressSavingSkipped?: boolean;
      systemDetails?: ISystemDetails;
      systemDetailsPerItem?: ISystemDetailsPerItem[];
      priceDisputingPerItem?: IPriceDisputingPerItem[];
      pointOfContact?: IPointOfContact;
      approverDetails?: IApproverData;
      materialMasterNumbers?: IMaterialMasterNumbersPerItem[];
      customer?: {
        email: string;
        salutation: string;
        firstName: string;
        idCustomer: number;
        customerReference: string;
        uuidCompanyUser: string;
        lastName: string;
      },
      billingAddress?: {
        salutation: string;
        firstName: string;
        lastName: string;
        address1: string;
        address2: string;
        address3?: string;
        zipCode: string;
        city: string;
        iso2Code: string;
        company: string;
        phone: string;
        state?: string;
        isDefaultBilling: boolean;
        isDefaultShipping: boolean;
      },
      shippingAddress?: {
        salutation: string;
        firstName: string;
        lastName: string;
        address1: string;
        address2: string;
        address3?: string;
        zipCode: string;
        city: string;
        country: string;
        iso2Code: string;
        company: string | null;
        phone: string | null;
        state?: string;
        isDefaultBilling: boolean;
        isDefaultShipping: boolean;
      },
      payments?: IPaymentsData[];
      shipment?: {
        idShipmentMethod: number;
      }
    }
  };
}

export interface IapproverDetails {
  approverId: string;
  firstName?: string;
  lastName?: string;
  comment?: string;
  dueDate?: string;
}

export interface IPaymentsData {
  paymentMethodName: IPaymentMethodName;
  paymentProviderName: IPaymentProviderName;
  paymentSelection?: IPaymentSelection;
}

export enum IPaymentMethodName {
  INVOICE   = 'Pay by invoice',
  CONTRACT  = 'contract',
  DUMMY     = 'invoice',
  CC_AVENUE = 'Online Payment CCAvenue'
}

export enum IPaymentProviderName {
  INVOICE   = 'InvoicePayment',
  CONTRACT  = 'ContractPayment',
  DUMMY     = 'DummyPayment',
  CC_AVENUE = 'CCAvenue'
}

export enum IPaymentSelection {
  INVOICE = 'payByInvoicePurchaseOrder'
}

export interface IAddressesDataIncluded {
  attributes: IAddress;
  id: string;
  links: {
    self: string;
  };
  type: string;
}

export interface ICustomAddressData {
  attributes: IAddress;
}

export interface IShipmentMethod {
  id: number;
  name: string;
  carrierName: string;
  price: number;
  taxRate: number;
  deliveryTime: number;
  currencyIsoCode: string;
  value?: number;
}

export interface ICCPaymentResponse {
  data: [{
    id: number;
    attributes: ICCPaymentData;
    links: {self: string};
    type: string;
  }];
  links: {
    self: string;
  };
}

export interface ICCPaymentData {
  accessCode: string;
  encryptedData: string;
}
