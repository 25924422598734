import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { ICurrentParams } from '../../models/catalog.models';
import { ObjectUtils } from '../../utils/object.utils';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent implements OnInit, OnChanges {
  @Input() sort: Array<any>;
  @Input() params: ICurrentParams;
  @Input() current: any;
  @Input() cssClass: string;
  @Input() hasTitle = true;
  @Input() isCatalogPage = false;
  @Output() selected = new EventEmitter<any>();

  active: any;
  isOpen = false;

  constructor(
    private select: ElementRef,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.active = this.params || this.current;
  }

  ngOnChanges(): void {
    this.active = this.params || this.current;
  }

  showTitle(): boolean {
    if (this.hasTitle) {
      return !(this.isCatalogPage && this.isActive());
    } else {
      return false;
    }
  }

  showOptions(): void {
    this.isOpen = !this.isOpen;
  }

  isActive(): boolean {
    if (this.current) {
      return this.sort.find(sort => ObjectUtils.isEqual(sort, this.active));
    }
    return this.sort.find(sort => sort.value === this.active.sort);
  }

  isSelected(option: string): boolean {
    const selectedOption = this.active.sort ?? this.active.name;

    return option === selectedOption;
  }

  activeSortLabel(): string {
    let activeSort;

    if (this.current) {
      activeSort = this.sort.find(sort => ObjectUtils.isEqual(sort, this.active));
    } else {
      activeSort = this.sort.find(sort => sort.value === this.active.sort);
    }

    return activeSort ? activeSort.name : '';
  }

  pageUrl(): string {
    return decodeURIComponent(this.router.url.split('?')[0]);
  }

  queryParams(option: any): any {
    // eslint-disable-next-line prefer-const
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams.sort = option.value;
    return urlTree.queryParams;
  }

  selectSort(option: any): any {
    this.active = option;

    this.selected.emit(this.current ? option : option.value);
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event): void {
    if (!this.select.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
}
