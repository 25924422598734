<div class="row border-orange cart-item-container" [attr.data-test]="'dp1-cart-item-' + item.attributes.name">
  <div class="col-12 col-md-4 border-green cart-item-image-container">
    <img [src]="getProductImageUrl()" [alt]="item.attributes.name" class="cart-item-image"
         [attr.data-test]="'img-productImage-' + item.attributes.name">
  </div>
  <div class="col-12 col-md-8 cart-item__body">
    <div class="row border-hotpink">
      <div class="row border-black">
        <div class="col-12 border-green cart-item-item-title" [attr.data-test]="'desc-item'">
          {{ item.attributes.name }}
        </div>
      </div>
      <div class="col-12 separate-system-form">
        <div class="row separate-system-checkbox">
          <input
              type="checkbox"
              class="checkbox"
              [id]="item.id + '-expandForm'"
              [name]="item.id + '-expandForm'"
              [checked]="separateSystemChecked"
              (click)="toggleForm($event)"
              [attr.data-test]="'input-separateSystem'">
          <label [for]="item.id + '-expandForm'" [attr.data-test]="'label-separateSystem'">{{ 'request-details.separate-system' | translate }}</label>
        </div>
        <app-ivk-selection-form *ngIf="separateSystemChecked"
                                [systems]="systems"
                                [installBaseLoading]="installBaseLoading"
                                [businessUnits]="businessUnits"
                                [businessUnitLoading]="businessUnitLoading"
                                (formChanged)="onFormChange($event)"></app-ivk-selection-form>
      </div>
      <div class="col-12 additional-fields">
        <div class="row" *ngFor="let additionalField of additionalFields">
          <ng-container *ngIf="additionalField.shouldDisplayFunc(item)">
            <span [attr.data-test]="'desc-' + additionalField.name">
              {{ additionalField.name | translate }}: {{ additionalField.getValFunc(item) }}
            </span>
            <span *ngIf="additionalField.excludeTax" class="tax-disclaimer" [attr.data-test]="'desc-exclude-tax'">
              {{ 'checkout-delivery-details.item-level-exclude-tax' | translate }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
