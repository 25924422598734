import { Component, OnInit } from '@angular/core';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { Alert } from '../../models/alert.models';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  alert: Alert;
  alertSubscription$: Subscription;

  constructor(private configurationFacade: ConfigurationFacade, private store: Store<State>) {
  }

  ngOnInit(): void {
    this.alertSubscription$ = this.alertSubscription();
  }

  alertSubscription(): Subscription {
    return this.configurationFacade.getAlert().subscribe(alert => {
      this.alert = alert;
    });
  }

  isAlert(): boolean {
    return !!this.alert?.message;
  }

  getAlertWidth(): string {
    if (this.alert.options?.alertWidth) {
      return this.alert.options?.alertWidth;
    }

    return 'fit-content';
  }

  getTitle(): string {
    if (this.alert.options?.title) {
      return this.alert.options?.title;
    }

    switch (this.alert.type) {
      case 'error':
        return `alert.error`;
      case 'warning':
        return `alert.warning`;
      default:
        return 'alert.success';
    }
  }

  getButtonLabel(): string {
    if (this.alert.options?.buttonLabel) {
      return this.alert.options?.buttonLabel;
    }

    return 'alert.ok';
  }

  getButtonPosition(): string {
    if (this.alert.options?.buttonPosition) {
      return this.alert.options?.buttonPosition;
    }

    return 'left';
  }

  handleAction(): void {
    if (this.alert?.action) {
      this.store.dispatch({type: this.alert.action, ...this.alert.props});
    }

    this.configurationFacade.clearAlert();
  }
}
