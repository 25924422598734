import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as CustomerActions from '../actions/customer.actions';
import { ECompanyBUIncludes, EGlueResource, EUserRoles } from '../configurations/common';
import { IAddress } from '../models/common.models';
import {
  ICompanyBusinessUnitAttributes,
  ICustomerExtended,
  ICustomerInfo,
  ICustomerPreferences,
} from '../models/customer.models';
import { ArrayUtils } from '../utils/array.utils';
import { LocalStorageUtils } from '../utils/localStorage.utils';
import { PayloadUtils } from '../utils/payload.utils';
import { IBusinessRoleAddressResource } from '../models/soldTo-selection.models';
import { IAddressesDataIncluded } from '../models/checkout.models';
import { environment } from '../../environments/environment.defaults';

export interface CustomerState {
  data: any;
  info: ICustomerInfo;
  customerAddresses: IAddress[];
  updateCustomerAddressesInProgress: boolean;
  shipToAddresses: IAddress[];
  customCustomerShipToAddress: IAddress;
  loaded: boolean;
  companyRoles: EUserRoles[];
  error: any;
  companyRolesLoading: boolean;
  store: any;
  consentedTerms: string[];
  businessUnits: ICompanyBusinessUnitAttributes[];
  businessUnitLoading: boolean;
  businessUnitBlockUsers: boolean;
  businessUnitActive: boolean;
  businessUnitName: string;
  businessUnitNumber: string;
  customerId: string;
  customerData: ICustomerExtended;
  customerPreferences: ICustomerPreferences;
  soldToAddresses: IAddress[];
}

// Initialize State start
export const initialState: CustomerState = {
  data: null,
  info: null,
  customerAddresses: undefined,
  updateCustomerAddressesInProgress: false,
  shipToAddresses: null,
  customCustomerShipToAddress: null,
  loaded: false,
  companyRoles: [EUserRoles.Guest],
  error: null,
  companyRolesLoading: false,
  store: null,
  consentedTerms: undefined,
  businessUnits: undefined,
  businessUnitLoading: false,
  businessUnitActive: true,
  businessUnitBlockUsers: false,
  businessUnitName: null,
  businessUnitNumber: null,
  customerId: null,
  customerData: null,
  customerPreferences: null,
  soldToAddresses: null,
};
// Initialize State end

// Selectors start
export const customerState = createFeatureSelector<CustomerState>('customer');

export const getConsentedTerms = createSelector(
  customerState,
  state => state.consentedTerms,
);

export const getCompanyRoles = createSelector(
  customerState,
  state => state.companyRoles,
);

export const isGuest = createSelector(
  customerState,
  state => state.companyRoles.includes(EUserRoles.Guest),
);

export const isViewer = createSelector(
  customerState,
  state => state.companyRoles.includes(EUserRoles.Viewer),
);

export const isApprover = createSelector(
  customerState,
  state => state.companyRoles.includes(EUserRoles.Approver),
);

export const isAdmin = createSelector(
  customerState,
  state => state.companyRoles.includes(EUserRoles.Admin),
);

export const isSPCViever = createSelector(
  customerState,
  state => state.companyRoles.includes(EUserRoles.SPCViewer),
);

export const isBusinessPartner = createSelector(
  customerState,
  state => isCustomerBusinessPartner(state),
);

export const isCustomerLoaded = createSelector(
  customerState,
  state => state.loaded,
);

export const accessControlData = createSelector(
  customerState,
  state => {
    return {
      isGuest: state.companyRoles.includes(EUserRoles.Guest),
      isViewer: state.companyRoles.includes(EUserRoles.Viewer),
      isAdmin: state.companyRoles.includes(EUserRoles.Admin),
      isApprover: state.companyRoles.includes(EUserRoles.Approver),
      companyRolesLoading: state.companyRolesLoading,
      businessUnitActive: state.businessUnitActive,
      businessUnitBlockUsers: state.businessUnitBlockUsers,
      businessUnitName: state.businessUnitName,
      loaded: state.loaded,
    };
  },
);

export const selectCustomerDataLoading = createSelector(
  customerState,
  state => !state.loaded,
);

export const selectCompanyRolesLoading = createSelector(
  customerState,
  state => state.companyRolesLoading,
);

export const getMyOrderPageData = createSelector(
  customerState,
  state => {
    return {
      companyRoles: state.companyRoles,
      isGuest: state.companyRoles.includes(EUserRoles.Guest),
      id: state.data?.id,
    };
  },
);

export const selectUserId = createSelector(
  customerState,
  state => state.data?.id,
);

export const getMyBusinessUnitName = createSelector(
  customerState,
  state => state.data?.attributes?.companyBusinessUnit,
);

export const getMyBusinessUnitAndUserRoles = createSelector(
  customerState,
  state => {
    return {
      customerBusinessUnit: state.data?.attributes?.companyBusinessUnit,
      companyRoles: state.companyRoles,
      customerDataLoaded: state.loaded,
    };
  },
);

export const getMyProfileInformation = createSelector(
  customerState,
  state => state.info,
);

export const selectCompanyUserId = createSelector(
  customerState,
  state => state.info?.companyUserUuid,
);

export const isSaturdayShipmentSelected = createSelector(
  customerState,
  state => state.info?.pointOfContact?.shipmentMethodName === environment.sparePartSaturdayDeliveryKey,
);

export const getStore = createSelector(
  customerState,
  state => state.store,
);

export const customerLoadedGuardData = createSelector(
  customerState,
  state => {
    return {
      loadFailed: !!state.error,
      isGuest: state.companyRoles.includes(EUserRoles.Guest),
      consentedTerms: state.consentedTerms,
    };
  },
);

export const selectBusinessAddress = createSelector(
  customerState,
  state => {
    return state?.businessUnits ? state.businessUnits.map(data => data.address) : undefined;
  },
);

export const selectBusinessUnits = createSelector(
  customerState,
  state => {
    return state.businessUnits || undefined;
  },
);

export const selectBusinessUnitLoading = createSelector(
  customerState,
  state => {
    return state.businessUnitLoading;
  },
);

export const selectCustomerAddresses = createSelector(
  customerState,
  state => state.customerAddresses,
);

export const selectUpdateCustomerAddressesInProgress = createSelector(
  customerState,
  state => state.updateCustomerAddressesInProgress,
);

export const selectCustomerShipToAddresses = createSelector(
  customerState,
  state => state.shipToAddresses,
);

export const selectCustomerSoldToAddresses = createSelector(
  customerState,
  state => state.soldToAddresses,
);

export const selectCustomCustomerShipToAddress = createSelector(
  customerState,
  state => state.customCustomerShipToAddress,
);

export const selectCustomerData = createSelector(
  customerState,
  state => {
    return {
      customerData: state.customerData,
      customerId: state.customerId,
      customerPreferences: state.customerPreferences,
      businessUnitName: state.businessUnitName,
      businessUnitNumber: state.businessUnitNumber,
      companyRoles: state.companyRoles,
    };
  },
);

export const selectCustomerPreferences = createSelector(
  customerState,
  state => state.customerPreferences,
);
// Selectors end

// Reducer start
export const CustomerReducer = createReducer(
  initialState,
  on(CustomerActions.CompanyUsersActionStart, (state) => {
    return {
      ...state,
      companyRolesLoading: true,
    };
  }),
  on(CustomerActions.CompanyUsersActionSuccess, (state, {payload}) => {
    const companyRoles = payload?.included.filter(data => data.type === 'company-roles').map(companyRoles => {
      return companyRoles.attributes.name.toUpperCase();
    });

    const userData = payload?.included.find(data => data.type === 'customers');
    const userPreferences = payload?.included.find(data => data.type === 'customer-preferences');

    const currentConsentedTerms = payload?.data[0]?.attributes?.consentedTerms || [];

    const companyBUData = payload?.included.find(data => data.type === ECompanyBUIncludes.COMPANY_BUSINESS_UNITS);
    const isBuActive = companyBUData?.attributes.active || false;
    const buBlockUsers = companyBUData?.attributes.blockUsers || false;
    const buName = companyBUData?.attributes.name || '';
    const buNumber = companyBUData?.attributes.businessUnitNumber || '';


    (window as any).selfJSON = {
      gid: userData?.id ?? '',
      country: LocalStorageUtils.getKeyValue('storeId'),
      language: LocalStorageUtils.getKeyValue('lang'),
    };

    return {
      ...state,
      data: payload?.data[0] || null,
      info: userData?.attributes ? {...userData.attributes, companyUserUuid: payload?.data[0]?.id} : null,
      loaded: true,
      companyRoles: companyRoles ?? [EUserRoles.Guest],
      companyRolesLoading: false,
      consentedTerms: currentConsentedTerms,
      businessUnitActive: isBuActive,
      businessUnitBlockUsers: buBlockUsers,
      businessUnitName: buName,
      businessUnitNumber: buNumber,
      customerId: userData?.id || null,
      customerData: userData?.attributes || null,
      customerPreferences: userPreferences?.attributes || null,
    };
  }),
  on(CustomerActions.CompanyUsersActionError, (state, error) => {
    return {
      ...state,
      error,
      companyRoles: [EUserRoles.Guest],
      companyRolesLoading: false,
      loaded: true,
    };
  }),
  on(CustomerActions.updateCustomerPreferences, (state, {data}) => {
    return {
      ...state,
      customerPreferences: data,
    };
  }),
  on(CustomerActions.setStore, (state, {store}) => {
    return {
      ...state,
      store,
    };
  }),
  on(CustomerActions.setPointOfContact, (state, {data}) => {
    return {
      ...state,
      info: {
        ...state.info,
        pointOfContact: data,
      },
    };
  }),
  on(CustomerActions.clearAttentionTo, (state) => {
    return {
      ...state,
      info: {
        ...state.info,
        pointOfContact: {
          ...state.info.pointOfContact,
          attentionTo: null,
        },
      },
    };
  }),
  on(CustomerActions.clearPointOfContact, (state) => {
    return {
      ...state,
      info: {
        ...state.info,
        pointOfContact: null,
      },
    };
  }),
  on(CustomerActions.loadCustomerData, (state) => {
    return {
      ...state,
      companyRolesLoading: true,
    };
  }),
  on(CustomerActions.loadCustomerDataError, (state, error) => {
    return {
      ...state,
      error,
      companyRoles: [EUserRoles.Guest],
      companyRolesLoading: false,
      loaded: true,
    };
  }),
  on(CustomerActions.setConsentedTerms, (state, {newConsentedTerms}) => {
    return {
      ...state,
      consentedTerms: newConsentedTerms,
    };
  }),
  on(CustomerActions.startGetBusinessUnitAction, (state) => {
    return {
      ...state,
      businessUnitLoading: true,
    };
  }),
  on(CustomerActions.successGetBusinessUnitAction, (state: CustomerState, {payload}) => {
    const businessUnits = [];
    payload.data.forEach(unit => {
      const company = PayloadUtils.getIncludedData(unit, payload.included, 'companies');
      const address = PayloadUtils.getIncludedData(unit, payload.included, 'company-business-unit-addresses');
      businessUnits.push(Object.assign({
        ...unit.attributes,
        institutionName: company && company.length > 0 ? company[0].attributes.name : '',
        address: address && address.length > 0 ? {id: address[0].id, ...address[0].attributes} : undefined,
      }));
    });
    return {
      ...state,
      businessUnits,
      businessUnitLoading: false,
      loaded: true,
    };
  }),
  on(CustomerActions.errorGetBusinessUnitAction, (state, {error}) => {
    return {
      ...state,
      error,
      businessUnits: null,
      businessUnitLoading: false,
      loaded: true,
    };
  }),
  on(CustomerActions.successGetCustomerAddressAction, (state: CustomerState, {payload}) => {
    const addresses = payload.data.map(address => {
      return convertCustomerAddressToAddress(address);
    });
    return {
      ...state,
      customerAddresses: addresses,
    };
  }),
  on(CustomerActions.beginAddCustomerAddressAction, (state: CustomerState) => {
    return {
      ...state,
      updateCustomerAddressesInProgress: true,
    };
  }),
  on(CustomerActions.successAddCustomerAddressAction, (state: CustomerState, {data}) => {
    const addresses = [...state.customerAddresses];
    addresses.push(convertCustomerAddressToAddress(data?.data));

    return {
      ...state,
      customerAddresses: addresses,
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.errorAddCustomerAddressAction, (state: CustomerState, {error}) => {
    return {
      ...state,
      error,
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.beginUpdateCustomerAddressAction, (state: CustomerState) => {
    return {
      ...state,
      updateCustomerAddressesInProgress: true,
    };
  }),
  on(CustomerActions.successUpdateCustomerAddressAction, (state: CustomerState, {data}) => {
    const addressIndex = state.customerAddresses?.findIndex(address => address?.id === data?.data?.id);
    const addresses = [...state.customerAddresses];

    if (addressIndex !== -1) {
      addresses[addressIndex] = convertCustomerAddressToAddress(data?.data);
    }

    return {
      ...state,
      customerAddresses: addresses,
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.errorUpdateCustomerAddressAction, (state: CustomerState, {error}) => {
    return {
      ...state,
      error,
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.beginDeleteCustomerAddressAction, (state: CustomerState) => {
    return {
      ...state,
      updateCustomerAddressesInProgress: true,
    };
  }),
  on(CustomerActions.successDeleteCustomerAddressAction, (state: CustomerState, {addressId}) => {
    return {
      ...state,
      customerAddresses: state.customerAddresses?.filter(address => address?.id !== addressId),
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.errorDeleteCustomerAddressAction, (state: CustomerState, {error}) => {
    return {
      ...state,
      error,
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.successDeletedPreferredShipToAddressRemovalAction, (state: CustomerState, {addressId, data}) => {
    return {
      ...state,
      customerAddresses: state.customerAddresses?.filter(address => address?.id !== addressId),
      updateCustomerAddressesInProgress: false,
      customerPreferences: data,
    };
  }),
  on(CustomerActions.errorDeletedPreferredShipToAddressRemovalAction, (state: CustomerState, {error}) => {
    return {
      ...state,
      error,
      updateCustomerAddressesInProgress: false,
    };
  }),
  on(CustomerActions.loadCustomerShipToAddressesSuccess, (state: CustomerState, {payload}) => {
    if (!isCustomerBusinessPartner(state)) {
      const allAddresses = payload.data[0].attributes?.addresses;
      const addressesWithNames = allAddresses.filter(address => address.name !== null);
      const uniqueAddresses = ArrayUtils.uniqueObjects(addressesWithNames, 'sapId');
      return {
        ...state,
        shipToAddresses: uniqueAddresses,
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(CustomerActions.loadCustomerSoldToAddressesSuccess, (state: CustomerState, {payload}) => {
    const soldToAddresses: IAddress[] = payload.data
      .map(soldToAddress => convertBusinessRoleAddressToAddress(soldToAddress));
    const included = payload.included ?? [];
    const shipToAddresses: IAddress[] = included
      .filter(includedAddress => includedAddress.type === EGlueResource.SHIP_TO_ADDRESSES)
      .map(shipToAddress => convertBusinessRoleAddressToAddress(shipToAddress));
    return {
      ...state,
      soldToAddresses,
      shipToAddresses,
    };
  }),
  on(CustomerActions.setCustomShipToAddress, (state: CustomerState, {data}) => {
    return {
      ...state,
      customCustomerShipToAddress: data,
    };
  }),
  on(CustomerActions.clearCustomShipToAddress, (state) => {
    return {
      ...state,
      customCustomerShipToAddress: null,
    };
  }),
);

// Reducer end

function convertCustomerAddressToAddress(address: IAddressesDataIncluded): IAddress {
  return {
    ...address?.attributes,
    id: address?.id,
    name: address?.attributes?.firstName,
    state: address?.attributes?.address2,
    isCustom: true,
  };
}

function convertBusinessRoleAddressToAddress(address: IBusinessRoleAddressResource<any>): IAddress {
  return {
    ...address.attributes,
    // backfill some missing fields from the BE response
    salutation: '',
    firstName: '',
    lastName: '',
    company: address.attributes.name,
    phone: '',
    isDefaultBilling: false,
    isDefaultShipping: false,
  };
}

function isCustomerBusinessPartner(state: CustomerState): boolean {
  return state.companyRoles.includes(EUserRoles.BusinessPartnerApprover)
    || state.companyRoles.includes(EUserRoles.BusinessPartnerBuyer);
}
