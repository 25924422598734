<app-access-control [notForViewer]="true">
  <ng-container ngProjectAs="[slot][nonViewerContent]">
    <app-sticker
        [product]="product"
        [isCatalog]="true"
        [labels]="labels"
        [prices]="product.prices">
    </app-sticker>
  </ng-container>
</app-access-control>
<div class="card basic-product"
     [ngClass]="{'in-progress': isItemInProgress(product), 'card-col': layoutType !== 'grid'}">
  <div class="card__content" [attr.data-test]="'catalog-item-' + product.abstractName">
    <div class="card__image">
      <a [attr.data-test]="'link-product-' + product.abstractSku" [routerLink]="['/product/', product.abstractSku]"
         [title]="product.abstractName || product.abstractSku">
        <div class="aspect-ratio-16x9 aspect-ratio--fill">
          <ng-container *ngIf="hasImages(product); else defaultImage">
            <img alt="{{ product.abstractName }}" [src]="product.images[0].externalUrlSmall"
                 [attr.data-test]="'img-productImage-' + product.abstractName"/>
          </ng-container>
          <ng-template #defaultImage>
            <img alt="{{ product.abstractName }}" [src]="defaultImg"/>
          </ng-template>
        </div>
      </a>
    </div>
    <div class="card__body">
      <h2 class="card__title">
        <a class="title-link"
           [routerLink]="['/product/', product.abstractSku]"
           [title]="product.abstractName || product.abstractSku"
           [attr.data-test]="'link-productName'">
          {{ product.abstractName || product.abstractSku }}
        </a>
      </h2>
      <div class="card__price">
        <app-access-control>
          <ng-container ngProjectAs="[slot][content]">
            <p class="price-info">
              <ng-container *ngIf="product.price | shouldDisplayPrice;else emptyLine">
                <span class="actual-price" [attr.data-test]="'desc-actualPrice'">
                  {{ product.price | currency: priceUtils.getStoreCurrencyCode() }}
                </span>
                <small *ngIf="hasGuestPrice(product)" class="second-price" [attr.data-test]="'desc-secondPrice'">
                  {{ getGuestPrice(product) | currency: priceUtils.getStoreCurrencyCode() }}
                </small>
                <span *ngIf="isExcludeTaxActive()" class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span>
              </ng-container>
              <ng-template #emptyLine>
                &nbsp;
              </ng-template>
            </p>
            <app-access-control [notForViewer]="true">
              <ng-container ngProjectAs="[slot][nonViewerContent]">
                <button [attr.data-test]="'button-addToCart'" class="button button--link add-to-cart"
                    appDebounceClick
                    [debounceTime]="300"
                    (debounceClick)="addToCart(product)"
                    [disabled]="loadingCartDataInProgress">
                  <span class="icons-group">
                    <span class="icon-basket"></span>
                  </span>
                  <span class="add-to-cart-label" [attr.data-test]="'button-addToCart ' + product.abstractName">
                    {{ 'app-product-page.add-to-cart' | translate }}
                  </span>
                </button>
              </ng-container>
            </app-access-control>
          </ng-container>
        </app-access-control>
      </div>
    </div>
  </div>
  <div class="loader__overlay">
    <span class="loading-animation"></span>
  </div>
  <app-access-control [notForViewer]="true">
    <ng-container ngProjectAs="[slot][nonViewerContent]">
      <app-add-to-wishlist
          [wishlists]="wishlists"
          [sku]="getItemConcreteSku(product)"
          [productName]="product.abstractName"
          [productKeywords]="product.attributes.mcm_keywords"
          [addNewModalActive]="addNewModalActive"
          (createNew)="addNewModalActive = true"
          (click)="openAddNewWishlistModal()">
      </app-add-to-wishlist>
    </ng-container>
  </app-access-control>
</div>
