import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, skipWhile, take, takeUntil } from 'rxjs/operators';

import { MarketingFacade } from '../../../facades/marketing.facade';
import { ICart, ICartUpdateItemData } from '../../../models/cart.models';
import { AppUtils } from '../../../utils/app.utils';
import { CartUtils } from '../../../utils/cart.utils';
import { State } from '../../../reducers';
import { DataSharedFacade } from '../../../facades/data-shared.facade';
import { IContractParams } from '../../../models/catalog.models';
import { IconType } from '../../../models/settings.model';
import { CpqFacade } from '../../../facades/cpq.facade';
import { ITotals } from '../../../models/common.models';
import { MathUtils } from '../../../utils/math.utils';
import {
  EFeatureToggles,
  EMultiCartTabs,
  EStoreFeatures,
  EStoreTypes,
  EUserRoles,
} from '../../../configurations/common';
import { CatalogActions, CustomerActions, WishlistActions } from '../../../actions';
import * as WishlistSelector from '../../../reducers/wishlist.reducer';
import * as ShopCartActions from '../../../actions/shop-cart.actions';
import * as ShopCartSelectors from '../../../reducers/shop-cart.reducer';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { PageTypes } from '../../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { CustomerFacade } from '../../../facades/customer.facade';
import { FormatDatePipe } from '../../../shared/pipes/format-date.pipe';
import { StoreConfigurationFacade } from '../../../facades/store-configuration.facade';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-multi-cart',
  templateUrl: './multi-cart.component.html',
  styleUrls: ['./multi-cart.component.scss'],
})
export class MultiCartComponent implements OnInit, OnDestroy {
  @ViewChild('currentCartRef') currentCartRef;
  @Input() isExportCartActive: boolean;

  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isJpStore: boolean = false;
  isAuStore: boolean = false;
  rfqActive: boolean = false;
  isRfqOnly: boolean = false;
  isCheckoutQuoteApprovalMandatory: boolean = false;
  isBusinessPartnerRole: boolean = false;

  isCartOperationInProgress: boolean = false;
  switchingCartInProgress: boolean = false;

  addWishlistModalActive: boolean = false;
  addNicknameModalActive: boolean = false;
  deleteCartModalActive: boolean = false;
  deleteQuoteModalActive: boolean = false;
  removeConfigurationModalActive: boolean = false;

  hasNickname: boolean = false;
  currentCartLoaded: boolean = false;
  currentCartName$ = new ReplaySubject<string>();
  currentCart: ICart;
  currentCartItems: any[] = [];
  cartIdOfSwitchingCart: string;
  cartIdOfCartWhichIsPrepareToDelete: string;
  quoteIdOfQuoteWhichIsPrepareToDelete: string;
  minimumOrderValue: number = 0;

  otherCarts: any[] = [];
  otherCartsImages: any[] = [];
  otherPartProductCarts: any[] = [];
  otherServiceProductCarts: any[] = [];

  isQuantityValid: boolean = true;
  updateItemInCartData: any;
  updateItemInCartData$: Observable<ICartUpdateItemData>;
  quoteItemToDelete: {
    sku: string;
    configId: string;
  };

  wishlists: any[] = [];
  totals: ITotals;
  currency: string;
  validUntilDate: string;

  viewAllPartsCarts: boolean = false;
  viewAllServicesQuotes: boolean = false;
  viewItemsForParts: number;
  viewItemsForServices: number;

  iconType = IconType;
  multiCartTabs = EMultiCartTabs;
  currentTab: string = EMultiCartTabs.PARTS;
  countOfCarts: number;
  countOfOtherCarts: number = 0;
  currentCartLoaded$: Observable<boolean> = new Observable<boolean>();
  companyRoles: EUserRoles[];
  isMyInstalledBaseFlowEnabled: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private initWidth: number = 0;
  private pageReadyEventFired: boolean = false;
  private loadingCartDataInProgress: boolean = false;
  private isRequiredToResetDeliveryPagesFields: boolean = true;

  constructor(
    private router: Router,
    private store: Store<State>,
    private cpqFacade: CpqFacade,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private dataSharedFacade: DataSharedFacade,
    private checkoutFacade: CheckoutFacade,
    private configurationFacade: ConfigurationFacade,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private analyticsService: AnalyticsService,
    private formatDatePipe: FormatDatePipe,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?): void {
    this.calculateNumberOfItems(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
    this.isAuStore = AppUtils.isStoreActive(EStoreTypes.AU);
    this.currentCartLoaded$ = this.marketingFacade.selectLoadingCartDataInProgress();
    this.selectIsMyInstalledBaseFlowEnabled();
    this.getCompanyRoles();
    this.selectRfqActive();
    this.selectIsRfqOnly();
    this.selectIsQuoteApprovalForCheckoutRequired();
    this.selectMinimumOrderValue();
    this.selectCartOperationInProgress();
    this.selectCurrentCart();
    this.selectIsBPRole();
    this.loadOtherCarts();
    this.calculateNumberOfItems(window.innerWidth);
    this.store.dispatch(WishlistActions.retrieveWishlists());
    this.getCountOfCarts();
    this.getWishlistsFromStore();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getWishlistsFromStore(): void {
    this.store.select(WishlistSelector.getWishlists).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(wishlists => {
      this.wishlists = wishlists;
    });
  }

  /**
   * This method get count of carts from store.
   */
  getCountOfCarts(): void {
    this.marketingFacade.selectCarts()
      .pipe()
      .subscribe(carts => {
        this.countOfCarts = carts.length;
      });
  }

  createNewEmptyCartAndRedirectTo(): void {
    if (this.switchingCartInProgress) {
      return;
    }
    this.switchingCartInProgress = true;
    this.clearDeliveryPagesData();
    this.marketingFacade.createEmptyCart();
    this.store.dispatch(CatalogActions.setCachedProductsToBeReloaded());
    this.marketingFacade.selectCart().pipe(
      skipWhile(cart => !cart || cart.id === this.currentCart.id),
      take(1),
    ).subscribe(cart => {
      const cartId = cart.id;
      let redirect;

      if (this.isBusinessPartnerRole) {
        redirect = '/equipment-selection';
      } else {
        redirect = this.isMyInstalledBaseFlowEnabled ? '/my-installed-base' : '/catalog/parts';
      }

      if (cart.attributes.hasContractInCart) {
        this.redirectWithParam(redirect, cartId);
      } else {
        this.router.navigate([redirect]);
      }
    });
  }

  deleteOtherCart(data: any): void {
    const {cartId, totalItemsQuantity} = data;
    this.cartIdOfCartWhichIsPrepareToDelete = cartId;

    if (totalItemsQuantity === 0) {
      this.marketingFacade.deleteCart(this.cartIdOfCartWhichIsPrepareToDelete);

      if (!this.currentCart.id) {
        this.marketingFacade.createEmptyCart();
      }
    } else {
      this.isRequiredToResetDeliveryPagesFields = false;
      this.deleteCartModalActive = true;
    }
  }

  deleteOtherQuote(data: any): void {
    const {cartId, totalItemsQuantity} = data;
    this.quoteIdOfQuoteWhichIsPrepareToDelete = cartId;

    if (totalItemsQuantity === 0) {
      this.marketingFacade.deleteCpqCart(this.quoteIdOfQuoteWhichIsPrepareToDelete);

      if (!this.currentCart.id) {
        this.marketingFacade.createEmptyCart();
      }
    } else {
      this.deleteQuoteModalActive = true;
    }
  }

  deleteCurrentCart(modalDeleteActive: boolean): void {
    this.deleteCartModalActive = modalDeleteActive;
    this.cartIdOfCartWhichIsPrepareToDelete = this.currentCart.id;
  }

  deleteCurrentQuote(modalDeleteActive: boolean): void {
    this.deleteQuoteModalActive = modalDeleteActive;
    this.quoteIdOfQuoteWhichIsPrepareToDelete = this.currentCart.id;
  }

  deleteCart(): void {
    //check if is the "delete" function triggered by button in the current cart component or in the other cart component
    this.getCountOfCarts();
    if (this.isRequiredToResetDeliveryPagesFields) {
      this.clearDeliveryPagesData();
    }
    this.isRequiredToResetDeliveryPagesFields = true;

    this.marketingFacade.deleteCart(this.cartIdOfCartWhichIsPrepareToDelete);

    if (!this.currentCart.attributes.approverDetails.approverId && !this.isApprover() && this.countOfOtherCarts === 0) {
      this.marketingFacade.createEmptyCart();
    }

    this.currentCartLoaded = this.countOfCarts > 1;
    this.cartIdOfCartWhichIsPrepareToDelete = null;
  }

  deleteQuote(): void {
    this.quoteIdOfQuoteWhichIsPrepareToDelete = this.currentCart.id;
    this.marketingFacade.deleteCpqCart(this.quoteIdOfQuoteWhichIsPrepareToDelete);

    if (!this.currentCart.attributes.approverDetails.approverId && !this.isApprover() && this.countOfOtherCarts === 0) {
      this.marketingFacade.createEmptyCart();
    }

    this.currentCartLoaded = false;
    this.quoteIdOfQuoteWhichIsPrepareToDelete = null;
  }

  removeCartItem(itemId: string): void {
    if (this.isQuantityValid) {
      this.store.dispatch(ShopCartActions.deleteItemFromCart({
        cartId: this.currentCart.id,
        itemId: itemId,
      }));
    }
  }

  removeQuoteItem(): void {
    this.isCartOperationInProgress = true;

    this.cpqFacade.deleteCpqQuoteItem(this.currentCart.id, this.quoteItemToDelete.sku, this.quoteItemToDelete.configId)
      .pipe(take(1)).subscribe({
      next: () => {
        this.cpqFacade.postCpqRecalculate(this.currentCart.id).pipe(take(1)).subscribe({
          next: () => this.marketingFacade.refreshCart(this.currentCart.id),
          error: () => this.isCartOperationInProgress = false,
        });
      },
      complete: () => this.isCartOperationInProgress = false,
    });
  }

  updateCartItemQuantity(data: any): void {
    const {oldQuantity, newQuantity, itemId, itemName} = data;
    const updatedQty = parseInt(newQuantity, 10);

    if (!MathUtils.checkIfNumeric(updatedQty)) {
      this.isQuantityValid = false;
      return;
    }

    this.isQuantityValid = true;

    const quantityDiff = +newQuantity - +oldQuantity;
    if (quantityDiff > 0) {
      this.trackIncreaseQuantity(itemId, itemName, quantityDiff);
    } else if (quantityDiff < 0) {
      this.trackDecreaseQuantity(itemId, itemName, -quantityDiff);
    }

    this.store.dispatch(ShopCartActions.setUpdateItemInCartData({quantity: updatedQty}));
    this.updateItemInCartData$ = this.store.select(ShopCartSelectors.getUpdateItemInCartData);
    this.updateItemInCartData$.pipe(takeUntil(this.unsubscribe$)).subscribe(itemData => this.updateItemInCartData = itemData);

    this.store.dispatch(ShopCartActions.updateItemInCartLoadCartItems({
      cartId: this.currentCart.id,
      itemId: itemId,
      updateItemInCartData: this.updateItemInCartData,
    }));
  }

  switchDefaultCart(data: any): void {
    this.switchingCartInProgress = true;

    const {cartId, redirect} = data;
    this.cartIdOfSwitchingCart = cartId;

    this.marketingFacade.switchDefaultCart(cartId);
    this.marketingFacade.selectCart().pipe(
      skipWhile(cart => cart?.id !== cartId),
      take(1),
    ).subscribe({
      next: cart => {
        if (redirect) {
          if (cart.attributes.hasContractInCart) {
            this.redirectWithParam('/quote-summary', cartId);
          } else {
            this.marketingFacade.selectCartItemsWithDetails().pipe(
              takeUntil(this.unsubscribe$),
            ).subscribe(items => {
              this.router.navigate([this.getCheckoutRoute(items)]);
            });
          }
        }
        this.switchingCartInProgress = false;
      },
      error: () => this.switchingCartInProgress = false,
    });
  }

  private getCheckoutRoute(items: any): string {
    if (AppUtils.isSapStore()) {
      return '/sap-delivery-details';
    }

    return ((this.rfqActive && !this.isRfqFlowInactive(items)) || this.isRfqOnly)
      ? '/request-quote'
      : '/delivery-details';
  }

  private isRfqFlowInactive(items: any): boolean {
    return !this.configurationFacade.isFeatureAvailable(EStoreFeatures.QUOTE_REQUEST)
      || CartUtils.isSetPricesForAllItems(items);
  }

  buildServiceParams(cartItem: any): IContractParams {
    return {
      'fl-number': cartItem.attributes.systemDetails.siemensEquipmentId,
      'rel-product-sysivk': cartItem.attributes.systemDetails.materialNumber,
      selectedService: cartItem.attributes.abstractSku,
      configId: cartItem.attributes.cpqConfigId,
    };
  }

  selectRfqActive(): void {
    this.marketingFacade.selectRfqActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.rfqActive = state;
    });
  }

  selectIsQuoteApprovalForCheckoutRequired(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_QUOTE_APPROVAL_MANDATORY).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCheckoutQuoteApprovalMandatory = state;
    });
  }

  selectIsBPRole(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBpRole => {
      this.isBusinessPartnerRole = isBpRole;
    });
  }

  isContractInCart(): boolean {
    return this.currentCart?.attributes.hasContractInCart;
  }

  generatePdfFile(): void {
    this.checkoutFacade.getCartDetailPdfFile(this.currentCart.id).pipe(take(1))
      .subscribe(file => {
        FileUtils.saveAndOpenFile(file, FileType.PDF, `Cart_details_${this.currentCart.id}`, this.formatDatePipe.transform(new Date().toDateString()));
        this.currentCartRef.fileGenerationInProgress = false;
      });
  }

  private selectCurrentCart(): void {
    combineLatest([
      this.marketingFacade.selectCart(),
      this.marketingFacade.selectCartItemsWithDetails(),
      this.marketingFacade.getCurrentCartItems(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([cart, cartItemsWithDetails, cartItems]) => ({cart, cartItemsWithDetails, cartItems}),
      )).pipe(debounceTime(0)).subscribe(data => {
      this.loadingCartDataInProgress = false;
      if (data.cart) {
        this.analyticsService.setProducts(data.cartItemsWithDetails);
        this.trackPageReady();
        this.currency = data.cart.attributes.currency;
        this.currentCart = data.cart;
        this.currentCartItems = data.cartItemsWithDetails;
        this.totals = data.cart.attributes.totals;
        this.validUntilDate = data.cart.attributes.validUntil ? formatDate(data.cart.attributes.validUntil, 'MM/dd/yyyy', 'en') : null;
        this.hasNickname = !MathUtils.checkIfNumeric(this.currentCart.attributes?.name);
        this.currentCartName$.next(this.hasNickname ? this.currentCart.attributes.name : '');
        this.currentCartItems = CartUtils.mapAvailabilitiesToCartItems(
          this.currentCartItems, data.cartItems?.included,
        );
        this.cartIdOfCartWhichIsPrepareToDelete = this.currentCart.id;
        this.quoteIdOfQuoteWhichIsPrepareToDelete = this.currentCart.id;
        this.currentCartLoaded = true;

        if (this.isContractInCart() && this.otherServiceProductCarts?.length > 0) {
          this.currentTab = EMultiCartTabs.SERVICES;
        } else {
          this.currentTab = EMultiCartTabs.PARTS;
        }
      }
    });
  }

  private getCompanyRoles(): void {
    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  private isApprover(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver);
  }

  private loadOtherCarts(): void {
    this.marketingFacade.selectCarts().pipe(
      skipWhile(val => val.length === 0),
      takeUntil(this.unsubscribe$),
    ).subscribe(carts => {
      if (!carts) {
        this.otherCarts = [];
        return;
      }

      this.otherCarts = [...carts.filter(c => !c.attributes.isDefault)].map(data => ({...data, itemDetails: []}));

      if (!this.isApprover() || this.isCheckoutQuoteApprovalMandatory) {
        // filter out carts that are pending orders (for non-approvers and for specific stores regardless of role)
        this.otherCarts = this.otherCarts.filter(cart => !cart.attributes.approverDetails.approverId).map(data => data);
      }

      if (this.isRfqOnly) {
        this.otherCarts = this.otherCarts.filter(cart => !cart.attributes.pointOfContact).map(data => data);
      }

      this.countOfOtherCarts = this.otherCarts.length;

      this.marketingFacade.selectAllCartsItems().pipe(take(1)).subscribe(items => {
        const partProductCartsSet = new Set();
        const serviceProductCartsSet = new Set();
        const includedItems = items?.filter(include => include.type === 'items');

        this.otherCartsImages = items?.filter(i => i.type === 'concrete-product-image-sets');

        this.otherCarts.forEach(cart => {
          cart.relationships?.items?.data.forEach(data =>
            cart.itemDetails.push(...includedItems.filter(item => item.id === data.id)),
          );

          if (!cart.attributes.hasContractInCart) {
            partProductCartsSet.add(cart);
          } else {
            serviceProductCartsSet.add(cart);
          }
        });

        this.otherPartProductCarts = [...partProductCartsSet];
        this.otherServiceProductCarts = [...serviceProductCartsSet];
      });
    });
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inProgress => {
      this.isCartOperationInProgress = inProgress;
    });
  }

  private redirectWithParam(path: string, cartId?: string, item?: any): void {
    let cartItem;

    if (!cartId) {
      cartId = this.currentCart.id;
      cartItem = item || this.currentCartItems[0];
    } else {
      cartItem = this.otherCarts.find(cart => cart.id === cartId)?.itemDetails[0];
    }

    const serviceParams = this.buildServiceParams(cartItem);
    this.dataSharedFacade.setCartId(cartId);
    this.router.navigate([path], {queryParams: serviceParams}).then();
  }

  private calculateNumberOfItems(innerWidth: number): void {
    if (this.initWidth !== innerWidth) {
      this.initWidth = innerWidth;
      this.viewItemsForParts = this.initWidth < 576 ? 2 : 3;
      this.viewItemsForServices = this.initWidth < 576 ? 2 : 4;
    }
  }

  private trackPageReady(): void {
    if (!this.pageReadyEventFired && !this.loadingCartDataInProgress) {
      this.analyticsService.trackPageReady('Shopping Cart Page', PageTypes.SHOPPING_CART_PAGE, 'shopping-cart');
      this.pageReadyEventFired = true;
    }
  }

  private trackIncreaseQuantity(itemId: string, itemName: string, quantityDiff: number): void {
    this.analyticsService.setProducts({
      sku: itemId,
      name: itemName,
      quantity: quantityDiff,
    });

    this.analyticsService.trackCart('cart.add');
  }

  private trackDecreaseQuantity(itemId: string, itemName: string, quantityDiff: number): void {
    this.analyticsService.setProducts({
      sku: itemId,
      name: itemName,
      quantity: quantityDiff,
    });

    this.analyticsService.trackCart('cart.remove');
  }

  clearDeliveryPagesData(): void {
    this.store.dispatch(CustomerActions.clearCustomShipToAddress());
    this.store.dispatch(CustomerActions.clearPointOfContact());
    this.store.dispatch(ShopCartActions.clearPriceDisputing());
  }

  /**
   * Method to select if only Rfq workflow is enabled for current store
   * @private
   */
  private selectIsRfqOnly(): void {
    this.storeConfigurationFacade.selectIsRfqOnly().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isRfqOnly = state;
    });
  }

  /**
   * Method to select minimal order value for current store
   * @private
   */
  private selectMinimumOrderValue(): void {
    this.storeConfigurationFacade.selectMinimumOrderValue().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.minimumOrderValue = state;
    });
  }

  /**
   * Method for retrieving IsMyInstalledBaseFlowEnabled feature toggle value
   * @private
   */
  private selectIsMyInstalledBaseFlowEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isMyInstalledBaseFlowEnabled => {
      this.isMyInstalledBaseFlowEnabled = isMyInstalledBaseFlowEnabled;
    });
  }
}
