<div class="order-review container">
  <ng-container *ngIf="areAllDataLoaded()">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-lg-1 hidden-md-down"></div>
          <div class="col-12 col-lg-10">
            <h3 class="page-title" [attr.data-test]="'title-orderReview'">
              {{ 'order-approve.page-title' | translate }}
            </h3>
            <app-sap-message-controller [messages]="cartSapMessages">
            </app-sap-message-controller>
            <section class="invoice-addresses">
              <h6 class="section-title" [attr.data-test]="'title-invoiceAddresses'">
                {{ 'order-approve.review-invoice-addresses' | translate }}
              </h6>
              <div class="addresses-group">
                <div class="address-field--sold-to-address">
                  <div class="address-label">
                    <span class="font-weight-bold">{{ 'quote-summary.sold-to-address' | translate }}</span>
                  </div>
                  <div class="address-value">
                    <div class="input" [ngClass]="{'invalid': !isSoldToAddressValid}">
                      <span [attr.data-test]="'input-reportWrongSoldToAddress'">{{ soldToAddressString }}</span>
                      <span class="icon-ivalid icon-close icon-close__circle"></span>
                    </div>
                    <div class="report-wrong-address">
                      <button class="button button--link"
                              [attr.data-test]="'button-reportWrongSoldToAddress'"
                              (click)="showReportWrongAddressModal('quote-summary.sold-to-address', EAddressType.SOLD_TO)">
                        <span class="icon icon-arrow-right-small color-orange"></span>
                        <span class="text-underline">{{ 'quote-summary.report-wrong-address' | translate }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="address-invalid" *ngIf="!isSoldToAddressValid">
                    <div class="icon">
                      <span class="icon-close icon-close__circle"></span>
                    </div>
                    <div class="text">
                      <span>{{ 'quote-summary.sold-to-error'| translate }}</span>
                    </div>
                  </div>
                </div>
                <div class="address-field--bill-to-address">
                  <div class="address-label">
                    <span class="font-weight-bold">{{ 'quote-summary.bill-to-address' | translate }}</span>
                  </div>
                  <div class="address-value">
                    <div class="input" [ngClass]="{'invalid': !isBillToAddressValid}">
                      <span [attr.data-test]="'input-reportWrongBillToAddress'">{{ billToAddressString }}</span>
                      <span class="icon-ivalid icon-close icon-close__circle"></span>
                    </div>
                    <div class="report-wrong-address">
                      <button class="button button--link"
                              [attr.data-test]="'button-reportWrongBillToAddress'"
                              (click)="showReportWrongAddressModal('quote-summary.bill-to-address', EAddressType.BILL_TO)">
                        <span class="icon icon-arrow-right-small color-orange"></span>
                        <span class="text-underline">{{ 'quote-summary.report-wrong-address' | translate }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="address-invalid" *ngIf="!isBillToAddressValid">
                    <div class="icon">
                      <span class="icon-close icon-close__circle"></span>
                    </div>
                    <div class="text">
                      <span>{{ 'quote-summary.bill-to-error'| translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="payment-method">
              <h6 class="section-title" [attr.data-test]="'title-paymentMethod'">
                {{ 'order-approve.select-payment-title' | translate }}
              </h6>
              <div class="payment-method-option">
                <div class="form-group">
                  <div class="radio-button">
                    <input id="radio-1"
                           type="radio"
                           name="paymentMethod"
                           class="radio form-control"
                           checked
                           [attr.data-test]="'input-paymentMethod'">
                    <label for="radio-1" [attr.data-test]="'label-paymentMethod'">
                      {{ 'order-approve.payment-method-invoice' | translate }}
                    </label>
                  </div>
                  <app-textfield
                      class="purchase-order-number"
                      label="{{ 'order-approve.enter-your-order-number' | translate }}"
                      [form]="orderReviewForm"
                      [modelValue]="orderReviewForm.value.purchaseOrderNumber"
                      [inputName]="'purchaseOrderNumber'"
                      [isRequired]="true"
                      (fieldFilled)="setFormValue($event)">
                  </app-textfield>
                </div>
                <p class="disclaimer--mandatory-fields">
                  <small [attr.data-test]="'desc-PONumberField'">
                    {{ ((orderReviewForm.value.purchaseOrderNumber && isTotalPriceToPayZero())
                      ? 'order-approve.default-purchase-order-number-notification'
                      : 'checkout-delivery-details.mandatory-fields-required') | translate }}
                  </small>
                </p>
              </div>
            </section>
            <section class="order-details">
              <h6 class="section-title" [attr.data-test]="'title-reviewDetails'">
                {{ 'order-approve.review-delivery-addresses-and-shipment' | translate }}
              </h6>
              <div class="copy-confirmation-mail-container">
                <div class="copy-confirmation-mail-container__label">
                  {{ 'order-approve.copy-of-confirmation-mail' | translate }}
                </div>
                <app-textfield
                    label="{{ 'order-approve.enter-email-optional' | translate }}"
                    [inputType]="'email'"
                    [inputName]="'orderEmailCopy'"
                    [form]="orderReviewForm"
                    [modelValue]="orderReviewForm.value.orderEmailCopy"
                    [ngClass]="{'is-invalid': orderReviewForm.get('orderEmailCopy').touched && orderReviewForm.get('orderEmailCopy').errors}">
                </app-textfield>
                <div class="checkout__alerts">
                  <ng-container
                      *ngIf="orderReviewForm.get('orderEmailCopy').touched && orderReviewForm.get('orderEmailCopy').hasError('email')">
                    <small class="textfield__hint">
                      {{ 'request-details.email-pattern' | translate }}
                    </small>
                  </ng-container>
                </div>
              </div>
              <div class="table table--order-summary">
                <div class="table-header customer-details">
                  <div class="shipping-address" *ngIf="!!shipToAddress">
                    <p class="address-info" *ngIf="!loadingDataInProgress; else loadingAnimation">
                      <ng-container *ngIf="pointOfContact?.attentionTo && pointOfContact?.attentionTo !== ''">
                        {{ pointOfContact.attentionTo }}<br>
                      </ng-container>
                      <ng-container *ngIf="!shipToAddress?.isCustom && shipToAddress?.sapId">
                        {{ shipToAddress.sapId }} -
                      </ng-container>
                      <ng-container *ngIf="shipToAddress?.company && shipToAddress?.company !== 'empty'">
                        {{ shipToAddress.company }}<br>
                      </ng-container>
                      <ng-container *ngIf="shipToAddress?.address1 !== 'empty'">
                        {{ shipToAddress.address1 }}<br>
                      </ng-container>
                      <ng-container *ngIf="shipToAddress?.city !== 'empty'">
                        {{ shipToAddress.city }}
                      </ng-container>
                      <ng-container *ngIf="shipToAddress?.state || shipToAddress?.address2 !== 'empty'">
                        {{ shipToAddress.state || shipToAddress.address2 }}<br>
                      </ng-container>
                      <ng-container *ngIf="shipToAddress?.zipCode || shipToAddress?.zipCode !== ''">
                        {{ shipToAddress.zipCode }}
                      </ng-container>
                    </p>
                    <ng-template #loadingAnimation>
                      <div class="stripe-loading-animation">
                        <div class="stripe stripe-half"></div>
                        <div class="stripe stripe-half"></div>
                        <div class="stripe stripe-half"></div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="point-of-contact" *ngIf="!!pointOfContact">
                    <strong>{{ 'order-approve.contact' | translate }}:</strong>
                    <p class="user-info" *ngIf="!loadingDataInProgress; else loadingAnimation">
                      {{ pointOfContact?.firstName + ' ' + pointOfContact?.lastName }}<br>
                      {{ pointOfContact?.phone }}<br>
                      {{ pointOfContact?.email }}
                    </p>
                    <ng-template #loadingAnimation>
                      <div class="stripe-loading-animation">
                        <div class="stripe stripe-half"></div>
                        <div class="stripe stripe-half"></div>
                        <div class="stripe stripe-half"></div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="modal-buttons">
                    <button class="button button--link" [attr.data-test]="'link-reviewDelivery'"
                            (click)="showModalToDeliveryInstructions()" data-target="#modal1">
                      <span class="text">{{ 'order-approve.review-delivery-instructions' | translate }}</span>
                      <span class="icon icon-arrow-right-small color-orange"></span>
                    </button>
                    <button class="button button--link" [attr.data-test]="'link-changeAddress'"
                            [disabled]="isSaturdayShipmentSelected()"
                            (click)="redirectToDeliveryDetails()">
                      <span class="text">{{ 'order-approve.change-address' | translate }}</span>
                      <span class="icon icon-arrow-right-small color-orange"></span>
                    </button>
                  </div>
                </div>
                <div class="table-body cart-items">
                  <ng-container *ngFor="let item of cartItemsWithDetails">
                    <div class="cart-item-outer-container">
                      <div class="cart-item" [attr.data-test]="'cart-item-' + item.attributes.name">
                        <div class="cart-item--image">
                          <img [src]="getProductImageUrl(item)" [alt]="item.attributes.name"
                               [attr.data-test]="'img-productImage'">
                        </div>
                        <div class="cart-item--body">
                          <div class="cart-item--info">
                            <div class="info-header">
                              <h5 class="item-name" [attr.data-test]="'desc-productName'">
                                {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                              </h5>
                              <span
                                  *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && item.attributes.isDiscontinued"
                                  class="label discontinued-item"
                                  [attr.data-test]="'label-discontinuedItem'">
                                {{ 'spare-parts.discontinued-item' | translate }}
                              </span>
                              <span *ngIf="item.attributes.availability?.isExchangeable"
                                    class="label item-exchange-material">
                                {{ 'spare-parts.exchangeable-item' | translate }}
                              </span>
                              <span *ngIf="item.attributes.heavyweight"
                                    class="label heavyweight-item">
                                {{ 'spare-parts.heavyweight-item' | translate }}
                              </span>
                            </div>
                          </div>
                          <div class="cart-item--additional-fields">
                            <div class="additional-field-row">
                              <div class="item-material-number" [attr.data-test]="'desc-materialNumber'">
                                {{ item.attributes.materialNumber }}
                              </div>
                              <div class="item-delivery-availability">
                                <span class="icon"
                                      [ngClass]="getAvailabilityIcon(item.attributes.availability?.isAvailable)"></span>
                                <span *ngIf="item.attributes.availability?.isAvailable; else outOfStock"
                                      [attr.data-test]="'desc-stockAvailability'">
                                  {{ 'spare-parts.in-stock' | translate }}
                                </span>
                                <ng-template #outOfStock>
                                  <span>
                                    {{ 'spare-parts.out-of-stock' | translate }}
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <div class="additional-field-row">
                              <div class="quantity-label" [attr.data-test]="'label-quantity'">
                                {{ 'order-approve.quantity' | translate }}:
                              </div>
                              <div class="item-quantity" [attr.data-test]="'desc-quantity'">
                                {{ item.attributes.quantity }}
                              </div>
                            </div>
                            <div class="additional-field-row">
                              <div class="list-price-label" [attr.data-test]="'label-listPrice'">
                                {{ 'order-approve.list-price' | translate }}:
                              </div>
                              <div class="item-list-price" [attr.data-test]="'desc-listPrice'">
                                {{ item.attributes.calculations.sumListPrice | currency: currentCart.attributes.currency }}
                              </div>
                            </div>
                            <div class="additional-field-row">
                              <div class="exchange-credit-label" [attr.data-test]="'label-exchangeCredit'">
                                {{ 'order-approve.exchange-credit' | translate }}:
                              </div>
                              <div class="item-exchange-credit" [attr.data-test]="'desc-exchangeCredit'">
                                {{ item.attributes.calculations.sumExchangePrice | currency: currentCart.attributes.currency  }}
                              </div>
                            </div>
                            <div class="additional-field-row">
                              <div class="total-price-label" [attr.data-test]="'label-totalPrice'">
                                {{ 'order-approve.total-price' | translate }}:
                              </div>
                              <div class="item-total-price" [attr.data-test]="'desc-totalPrice'">
                                {{ item.attributes.calculations.sumPrice | currency: currentCart.attributes.currency }}
                              </div>
                            </div>
                            <app-advanced-product-information
                                [currentItem]="item"
                                [currentCartId]="cartId"
                                [isEditingEnabled]="false"
                                [isOrderReviewPage]="true">
                            </app-advanced-product-information>
                          </div>
                        </div>
                      </div>
                      <app-message *ngIf="isPriceDisputingActive && isPriceDisputingSetForItem(item.id)"
                                   [message]="itemDisputedPricingMessage">
                      </app-message>
                    </div>
                  </ng-container>
                </div>
              </div>
              <p class="disclaimer-saturday-shipment-order-review" *ngIf="isSaturdayShipmentSelected()">
                {{ 'checkout-delivery-details.saturday-delivery-method-disclaimer' | translate }}
              </p>
            </section>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 order-summary">
        <div class="row">
          <div class="col-12">
            <p class="disclaimer--finish-order">
              {{ 'order-approve.submit-order-title' | translate}}<br>
              {{ 'order-approve.submit-order-subtitle' | translate}}
            </p>
            <div class="buttons-group">
              <button
                  appDebounceClick
                  type="submit"
                  class="button button--primary"
                  [disabled]="!formIsValid()"
                  [debounceTime]="300"
                  (debounceClick)="proceedToSummaryPage()"
                  [attr.data-test]="'button-proceedToSummaryPage'">
                {{ 'order-approve.submit-order-button' | translate }}
              </button>
              <button
                  *ngIf="!fileGenerationInProgress; else loadingSpinner"
                  type="button"
                  class="button button--secondary print-as-pdf"
                  (click)="generatePdfFile()">
                {{ 'order-approve.print-pdf-button' | translate }}
              </button>

              <ng-template #loadingSpinner>
                <div class="btn-spinner">
                  <div class="loading-animation"></div>
                </div>
              </ng-template>
            </div>
            <section class="checkboxes-container" [formGroup]="orderReviewForm">
              <div class="checkbox-row terms-and-conditions">
                <input type="checkbox"
                       id="terms-and-conditions-consent"
                       name="terms-and-conditions-consent"
                       class="checkbox"
                       [required]="true"
                       formControlName="termsAndConditionsConsent"
                       [attr.data-test]="'checkbox-termsAndConditions'">
                <label for="terms-and-conditions-consent" [attr.data-test]="'label-termsAndConditions'">
                  {{ 'order-approve.terms-and-conditions-legal-consent' | translate }}
                  <a *ngIf="termsAndConditionsUrl.type === 'internal'" class="link-underline"
                     [routerLink]="termsAndConditionsUrl.value" target="_blank">
                    {{ 'order-approve.terms-and-conditions-link-title' | translate }}
                  </a>
                  <a *ngIf="termsAndConditionsUrl.type === 'external'" class="link-underline"
                     [href]="termsAndConditionsUrl.value" target="_blank">
                    {{ 'order-approve.terms-and-conditions-link-title' | translate }}
                  </a>
                </label>
              </div>
              <div class="checkbox-row tax">
                <input type="checkbox"
                       id="tax-consent"
                       name="tax-consent"
                       class="checkbox"
                       [required]="true"
                       formControlName="taxConsent"
                       [attr.data-test]="'checkbox-taxConsent'">
                <label for="tax-consent" [attr.data-test]="'label-taxConsent'">
                  {{ getTaxDisclaimerLabel() | translate }}
                </label>
              </div>
              <div *ngIf="isCanadaStore()" class="checkbox-row po">
                <input type="checkbox"
                       id="po-consent"
                       name="po-consent"
                       class="checkbox"
                       [required]="true"
                       formControlName="poConsent"
                       [attr.data-test]="'checkbox-poConsent'">
                <label for="po-consent" [attr.data-test]="'label-poConsent'">
                  {{ 'order-approve.po-confirmation-link-title' | translate }}
                </label>
              </div>
            </section>
            <section class="cart-pricelist">
              <div class="cart--subtotal-price">
                <span [attr.data-test]="'label-subtotalPrice'">
                  {{ 'checkout-delivery-details.subtotal' | translate }}
                </span>
                <span [attr.data-test]="'desc-subtotalPrice'">
                  {{ currentCart?.attributes?.totals.subtotal | currency: currentCart?.attributes?.currency }}
                </span>
              </div>
              <div class="cart--shipping-price">
                <span [attr.data-test]="'label-shippingAndHandlingPrice'">
                  {{ 'checkout-delivery-details.shipping-and-handling' | translate }}
                </span>
                <span [attr.data-test]="'desc-shippingAndHandlingPrice'">
                  {{ currentCart?.attributes?.totals.expenseTotal | currency: currentCart?.attributes?.currency }}
                </span>
              </div>
              <div class="cart--order-total-price">
                <strong [attr.data-test]="'label-orderTotalPrice'">
                  {{ 'checkout-delivery-details.order-total' | translate }}
                </strong>
                <strong [attr.data-test]="'desc-orderTotalPrice'">
                  {{ currentCart?.attributes?.totals.priceToPay | currency: currentCart?.attributes?.currency }}
                </strong>
              </div>
            </section>
            <app-cart-rules
                *ngIf="cartRules?.length > 0"
                [cartRules]="cartRules"
                [priceToPay]="currentCart?.attributes?.totals.priceToPay"
                [currency]="currentCart?.attributes?.currency">
            </app-cart-rules>
            <section class="shipment-and-payment">
              <strong class="shipment-method" [attr.data-test]="'desc-shippingMethod'">
                {{ selectedShipmentMethod?.name }}
              </strong>
              <p class="disclaimer--payment-info" [attr.data-test]="'label-paymentInfoDisclaimer'">
                {{ "order-approve.disclaimer-payment-info" | translate }}
              </p>
            </section>
            <hr class="bottom-line">
            <a class="button button--link button-back-to-cart"
               [routerLink]="['/shop-cart']"
               [queryParams]=setQueryParams()
               [attr.data-test]="'link-backToCart'">
              <span class="icon-arrow-left color-orange"></span>
              {{ 'checkout-delivery-details.back-to-cart' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="loader__overlay" *ngIf="!areAllDataLoaded()">
    <div class="loading-animation"></div>
  </div>
</div>
<app-delivery-instructions-modal
    [showModal]="showModalDeliveryInstructions"
    (reloadCheckoutData)="getCurrentCartData()"
    (closeModal)="showModalDeliveryInstructions = false"
    [isSaturdayShipment]="isSaturdayShipmentSelected()">
</app-delivery-instructions-modal>
<app-report-wrong-address-modal
    [userLoggedInRoles]="loggedUserRoles"
    [roleSpecificMessage]="true"
    [addressesList]="addressesToReport"
    [cpqOnly]="true"
    [quoteId]="cartId"
    [cpqLabelName]="addressLabelName"
    [cpqAddressType]="addressType"
    [functionalLocations]="[currentCart?.attributes.systemDetails.siemensEquipmentId]"
    [showModal]="showModalReportWrongAddress"
    [resetAddressList]="true"
    (closeModal)="showModalReportWrongAddress = false">
</app-report-wrong-address-modal>
<app-modal
    class="unavailable-shipment-method-modal"
    [id]="'unavailable-shipment-method-modal'"
    [type]="iconType.WARNING"
    [open]="isUnavailableShipmentMethodModalOpen"
    title="{{ 'order-approve.unavailable-shipment-method-modal.title' | translate }}"
    (crossClick)="closeUnavailableShipmentMethodModal()"
    (outsideClick)="closeUnavailableShipmentMethodModal()">
  <ng-container ngProjectAs="[slot][body]">
    <p>{{ 'order-approve.unavailable-shipment-method-modal.text' | translate }}</p>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <div class="row">
      <div class="col-12">
        <button type="button"
                class="button button--primary btn-submit"
                data-dismiss="modal-dialog"
                (click)="closeUnavailableShipmentMethodModal()">
          {{ 'contact-us.button-ok' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</app-modal>
