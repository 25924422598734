import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageUtils } from '../../../../utils/image.utils';
import { CartUtils } from '../../../../utils/cart.utils';
import { UiUtils } from '../../../../utils/ui.utils';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { CatalogActions } from '../../../../actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { AppUtils } from '../../../../utils/app.utils';

@Component({
  selector: 'app-other-cart',
  templateUrl: './other-cart.component.html',
  styleUrls: ['./other-cart.component.scss'],
})
export class OtherCartComponent {

  isSapStore: boolean = AppUtils.isSapStore();

  @Input() loadingCart: boolean;
  @Input() cart: any;
  @Input() cartImages: any[];

  @Output() switchCartEmit = new EventEmitter<{cartId: string, redirect: boolean}>();
  @Output() deleteCartEmit = new EventEmitter<{cartId: string, totalItemsQuantity: number}>();

  constructor(
    private customerFacade: CustomerFacade,
    private store: Store<State>,
  ) {
  }

  cartName = CartUtils.getCartName;
  singularOrPlural = UiUtils.singularOrPlural;

  getImageForCart(itemId: string): string {
    return ImageUtils.getProductImageUrl(this.cartImages.find(imageSet => imageSet.id === itemId));
  }

  switchCart(cartId: string, redirect?: true): void {
    this.customerFacade.clearPointOfContact();
    this.customerFacade.clearCustomCustomerShipToAddress();
    this.store.dispatch(CatalogActions.setCachedProductsToBeReloaded());
    this.switchCartEmit.emit({cartId, redirect});
  }

  deleteCart(cartId: string, totalItemsQuantity: number): void {
    this.deleteCartEmit.emit({cartId, totalItemsQuantity});
  }
}
