import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import { AnalyticsService } from '../../analytics/analytics.service';
import { CatalogFacade } from '../../facades/catalog.facade';
import { MarketingFacade } from '../../facades/marketing.facade';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  active: boolean;
  suggestions = null;
  delayTimer = null;
  suggestionTitle = ['categories', 'abstractProducts'];
  visibleResults = ['categories', 'abstractProducts', 'completion'];
  maxResultsPerSuggestion: number = 8;
  loading: boolean = false;
  query: string = '';
  abstractProducts: string = 'abstractProducts';

  @Input() cartSearch: boolean = false;
  @Input() disabled: boolean = false;
  @Output() product = new EventEmitter<any>();
  @Output() loadingButton = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private search: ElementRef,
    private router: Router,
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private marketingFacade: MarketingFacade,
  ) {}

  ngOnInit(): void {
    this.selectSearchQuery();

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe((event: NavigationStart) => {
      if (!event.url.includes('/search-results') && this.query !== '') {
        this.catalogFacade.setSearchQuery('');
        this.suggestions = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectSearchQuery(): void {
    this.catalogFacade.selectSearchQuery().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(query => {
      if (!this.cartSearch) {
        this.query = query;
      }
    });
  }

  onKey(event: any): void {
    event.preventDefault();
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      if (this.cartSearch) {
        this.query = event.target.value;
      } else {
        this.catalogFacade.setSearchQuery(event.target.value);
      }

      if (!this.hasValue()) {
        return;
      }

      if (event.code === 'Enter') {
        this.proceedToSearchPage(event.target.value);
        this.analyticsService.setCategory(null);
      } else {
        this.callApiForSuggestions();
        this.analyticsService.setCategory(null);
        this.active = true;
      }
    }, 500);
  }

  suggestionsKeys(): Array<any> {
    return Object.keys(this.suggestions);
  }

  hasValue(): boolean {
    return this.query && this.query.length > 1;
  }

  hasSuggestions(): boolean {
    if (!this.suggestions) {
      return false;
    }

    for (const key in this.suggestions) {
      if (this.cartSearch) {
        if (key === 'abstractProducts' && this.suggestions[key].length > 0) {
          return true;
        }
      } else {
        if (this.suggestions[key].length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  boldString(str, query): string {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    const l = q.length;
    return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
  }

  selectSuggestion(value: string, url = null): void {
    this.catalogFacade.setSearchQuery(value);
    this.query = value;
    this.active = false;
    if (url) {
      const {commands, extras} = url;
      this.router.navigate(commands, extras);
    }
  }

  proceedToSearchPage(value): void {
    this.selectSuggestion(value, {commands: ['/search-results'], extras: {queryParams: {q: value}}});
    this.active = false;
  }

  proceedToCategoryPage(value): void {
    const categories = value.url.split('/');
    const url = categories.length > 0 ? categories[categories.length - 1] : '';
    this.selectSuggestion(value.name, {commands: ['/catalog/', url]});
    this.active = false;
  }

  proceedToProductPage(abstractProduct): void {
    if (this.cartSearch) {
      this.active = false;
      this.loadingButton.emit(true);
      this.marketingFacade.getProductBySku(abstractProduct.abstractSku).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(product => {
        product = product.data.attributes;
        product.abstractSku = abstractProduct.abstractSku;
        product.abstractName = abstractProduct.abstractName;
        product.price = abstractProduct.price;
        product.images = abstractProduct.images;
        product.url = abstractProduct.url;
        this.product.emit(product);
        this.loadingButton.emit(false);
        this.query = this.getCartSearchSuggestion(abstractProduct);
      });
    } else {
      this.selectSuggestion(abstractProduct.abstractName, {commands: ['/product', abstractProduct.abstractSku]});
    }
  }

  clearQuery(): void {
    this.catalogFacade.clearSearchQuery();
    if (this.cartSearch) {
      this.query = '';
      this.product.emit(null);
    }
    this.suggestions = null;
  }

  callApiForSuggestions(): void {
    this.loading = true;
    this.catalogFacade.getSuggestionsFromService(this.query).pipe(
      takeUntil(this.unsubscribe$),
      map((res) => {
        this.suggestions = res.data[0].attributes;
        this.loading = false;
      }),
      catchError(() => {
        this.loading = false;
        this.suggestions = [];
        return of([]);
      }),
    ).subscribe();
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event): void {
    if (!this.search.nativeElement.contains(event.target)) {
      this.active = false;
    }
  }

  getCartSearchSuggestion(suggestion) {
    return suggestion.abstractName + (this.cartSearch ? ' (' + suggestion.abstractSku.replace('A_', '') + ')' : '');
  }
}
