import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccordionComponent implements OnInit, OnChanges {
  active: boolean;
  defaultCssStyles: object = {
    header: '',
    panel: '',
  };

  @Input() cssStyles: any;
  @Input() openOnIcon = false;
  @Input() manualOpen: boolean;
  @Input() isActiveOnStart = false;
  @Input() showIcon = false;
  @Input() equipmentSelection: boolean = false;
  @Output() panelOpened = new EventEmitter<any>();

  ngOnInit(): void {
    this.active = this.manualOpen ?? this.isActiveOnStart;
    this.cssStyles = {...this.defaultCssStyles, ...this.cssStyles};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.manualOpen && this.manualOpen !== undefined) {
      this.active = changes.manualOpen.currentValue;
    }
  }

  activePanel(): void {
    if (this.manualOpen !== undefined || this.openOnIcon) {
      return;
    }
    this.active = !this.active;
    if (this.active) {
      this.panelOpened.emit();
    }
  }

  activePanelOnIcon(): void {
    this.active = !this.active;
    this.panelOpened.emit();
  }
}
