import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IInstallBaseProducts,
  IInstalledBaseSystemsFilter,
  IShsEquipments,
} from '../models/installedbase.models';
import { GlueConfigurationService } from './glue-configuration.service';
import { EGlueResource } from '../configurations/common';
import { GlueUtils } from '../utils/glue.utils';

@Injectable({
  providedIn: 'root',
})
export class InstallBaseService {
  private shsEquipmentMaterialsEndpoint: string = `/${EGlueResource.SHS_EQUIPMENT_MATERIALS}`;
  private shsEquipmentsInstalledBaseEndpoint: string = `/${EGlueResource.SHS_EQUIPMENTS_INSTALLED_BASE}`;
  private shsEquipmentsInstalledBaseUrl: string = `${this.glueConfiguration.getEndpointUrl()}${this.shsEquipmentsInstalledBaseEndpoint}`;
  private installedBaseUrl: string = `${this.glueConfiguration.getEndpointUrl()}${this.shsEquipmentMaterialsEndpoint}`;


  constructor(private httpClient: HttpClient,
              private glueConfiguration: GlueConfigurationService) {
  }

  private static handleError(err): Observable<any> {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      return throwError(err);
    }
    return throwError(errorMessage);
  }

  getInstalledBaseProducts(): Observable<IInstallBaseProducts> {
    return this.httpClient.get<IInstallBaseProducts>(this.installedBaseUrl)
      .pipe(
        catchError(error => InstallBaseService.handleError(error)),
      );
  }

  getShsEquipmentInstalledBaseSystems(
    installedBaseSystemFilter: IInstalledBaseSystemsFilter = null,
    includes: EGlueResource[]                              = [],
  ): Observable<IShsEquipments> {
    let params = new HttpParams();
    installedBaseSystemFilter = installedBaseSystemFilter ?? {};
    for (const key in installedBaseSystemFilter) {
      params = params.set(key, installedBaseSystemFilter[key]);
    }
    params = GlueUtils.addIncludeParam(params, includes);
    return this.httpClient.get<IShsEquipments>(this.shsEquipmentsInstalledBaseUrl, {params})
      .pipe(
        catchError(err => InstallBaseService.handleError(err)),
      );
  }
}
