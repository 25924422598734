<ng-container *ngIf="hasProducts">
  <div class="featured-products">
    <div class="featured-products-wrapper">
      <h4>{{ 'featured-products.title' | translate }}</h4>
      <app-layout-options
          class="sm-hidden"
          [currentLayoutType]="currentLayoutType"
          (selected)="selectLayoutOption($event)">
      </app-layout-options>
    </div>
    <ng-container ngProjectAs="[slot][content]">
      <div [appLoadingAnimation]="!isLoaded">
        <ng-container *ngIf="currentLayoutType === 'grid'">
          <app-slick-carousel
              class="carousel-grid"
              [config]="slideConfigHorizontal">
            <div
                class="col-grid"
                appSlickItem
                *ngFor="let product of featuredProductsWithPricesAndImages">
              <div class="card" [ngClass]="{'in-progress': isItemInProgress(product.attributes.sku)}">
                <app-access-control [notForViewer]="true">
                  <ng-container ngProjectAs="[slot][nonViewerContent]">
                    <app-sticker
                        [product]="product"
                        [labels]="labels"
                        [prices]="product.attributes.prices">
                    </app-sticker>
                    <app-add-to-wishlist
                        [ngClass]="'add-to-wishlist-container'"
                        [wishlists]="wishlists"
                        [maxLength]="3"
                        [sku]="product.attributes?.concreteSku[0]"
                        [productName]="product.attributes?.name"
                        [productKeywords]="product.attributes?.keywords"
                        [addNewModalActive]="addNewModalActive"
                        (createNew)="addNewModalActive = true">
                    </app-add-to-wishlist>
                  </ng-container>
                </app-access-control>
                <div class="card-content" [attr.data-test]="'featured-list-item-' + product.attributes.name">
                  <div class="card__image" [title]="product.attributes.name || product.attributes.sku">
                    <a [routerLink]="['/product/', product.attributes.sku]"
                       [title]="product.attributes.name || product.attributes.sku"
                       [attr.data-test]="'link-product-' + product.attributes.name">
                      <div class="aspect-ratio-16x9 aspect-ratio--fill"
                           [attr.data-test]="'link-refToProductPdpFromImage-' + product.attributes.name">
                        <ng-container *ngIf="product.attributes.externalUrlSmall">
                          <img class="fit-card" alt="{{product.attributes.name}}"
                               [src]="product.attributes.externalUrlSmall"
                               [attr.data-test]="'img-productImage-' + product.attributes.name"/>
                        </ng-container>
                        <ng-container *ngIf="!product.attributes.externalUrlSmall">
                          <img class="fit-card" alt="{{product.attributes.name}}" [src]="defaultImg"/>
                        </ng-container>
                      </div>
                    </a>
                  </div>
                  <div class="card__body">
                    <h2 class="card__title"
                        [attr.data-test]="'link-refToProductPdpFromTitle ' + product.attributes.name">
                      <a [routerLink]="['/product/', product.attributes.sku]"
                         [title]="product.attributes.name || product.attributes.sku">
                        {{ product.attributes.name || product.attributes.sku }}
                      </a>
                    </h2>
                    <ng-container *ngIf="!checkIfProductIsAvailableForOnlinePurchase(product); else productAvailable">
                      <div class="notification__header" [attr.data-test]="'desc-productIsNotAvailable'">
                        <p class="font-weight-bold product-not-available">
                          <span class="icon-info icon-info__circle icon-orange-white"></span>
                          {{ 'app-product-page.product-is-not-available-for-online-purchase' | translate }}
                        </p>
                      </div>
                    </ng-container>
                    <ng-template #productAvailable>
                      <app-access-control>
                        <ng-container ngProjectAs="[slot][content]">
                          <div *ngIf="product.attributes.price | shouldDisplayPrice" class="fit-price">
                            <p class="card__price" [attr.data-test]="'desc-actualPrice'">
                              {{ product.attributes.price | currency: priceUtils.getStoreCurrencyCode() }}
                              <ng-container *ngIf="hasGuestPrice(product)">
                                <small
                                    class="product__description__price-alternative">
                                  {{ getGuestPrice(product) | currency: priceUtils.getStoreCurrencyCode() }}
                                </small>
                              </ng-container>
                            </p>
                          </div>
                          <app-access-control [notForViewer]="true">
                            <ng-container ngProjectAs="[slot][nonViewerContent]">
                              <button
                                  appDebounceClick
                                  class="button button--link add-to-cart"
                                  [debounceTime]="300"
                                  (click)="addToCart({
                                    sku: product.attributes?.sku,
                                    name: product.attributes?.name || product.attributes?.sku,
                                    productPricingGroup: product.attributes?.pricingGroup
                                  })"
                                  [disabled]="isAddToCartBtnDisabled()"
                                  [attr.data-test]="'button-addToCart'">
                              <span class="icons-group">
                                <span class="icon-basket"></span>
                              </span>
                                <span [attr.data-test]="'button-addToCart ' + product.attributes.name">
                                {{ 'app-product-page.add-to-cart' | translate }}
                              </span>
                              </button>
                            </ng-container>
                          </app-access-control>
                        </ng-container>
                      </app-access-control>
                    </ng-template>
                  </div>
                </div>
                <div class="loader__overlay">
                  <span class="loading-animation"></span>
                </div>
              </div>
            </div>
          </app-slick-carousel>
        </ng-container>

        <ng-container *ngIf="currentLayoutType === 'list'">
          <app-slick-carousel class="carousel-list"
                              [config]="slideConfigVertical">
            <div appSlickItem *ngFor="let product of featuredProductsWithPricesAndImages" class="row col-12">
              <app-access-control [notForViewer]="true">
                <ng-container ngProjectAs="[slot][nonViewerContent]">
                  <app-sticker
                      [product]="product"
                      [labels]="labels"
                      [prices]="product.attributes.prices">
                  </app-sticker>
                  <app-add-to-wishlist
                      [ngClass]="'add-to-wishlist-container'"
                      [wishlists]="wishlists"
                      [sku]="product.attributes?.concreteSku[0]"
                      [productName]="product.attributes?.name"
                      [productKeywords]="product.attributes?.keywords"
                      [addNewModalActive]="addNewModalActive"
                      (createNew)="addNewModalActive = true">
                  </app-add-to-wishlist>
                </ng-container>
              </app-access-control>
              <div class="card" [ngClass]="{'in-progress': isItemInProgress(product.attributes.sku)}">
                <div class="card-content" [attr.data-test]="'featured-2x2-item-' + product.attributes.name">
                  <div class="card__image">
                    <a [routerLink]="['/product/', product.attributes.sku]"
                       [title]="product.attributes.name || product.attributes.sku"
                       [attr.data-test]="'link-product-' + product.attributes.name">
                      <div class="aspect-ratio-16x9 aspect-ratio--fill"
                           [attr.data-test]="'link-refToProductPdpFromImage-' + product.attributes.name">
                        <ng-container *ngIf="product.attributes.externalUrlSmall">
                          <img class="fit-card" alt="{{product.attributes.name}}"
                               [src]="product.attributes.externalUrlSmall"
                               [attr.data-test]="'img-productImage-' + product.attributes.name"/>
                        </ng-container>
                        <ng-container *ngIf="!product.attributes.externalUrlSmall">
                          <img class="fit-card" alt="{{product.attributes.name}}" [src]="defaultImg"/>
                        </ng-container>
                      </div>
                    </a>
                  </div>
                  <div class="card__body">
                    <h2 class="card__title"
                        [attr.data-test]="'link-refToProductPdpFromTitle ' + product.attributes.name">
                      <a
                          [routerLink]="['/product/', product.attributes.sku]"
                          [title]="product.attributes.name || product.attributes.sku">
                        {{ product.attributes.name || product.attributes.sku }}
                      </a>
                    </h2>
                    <app-access-control>
                      <ng-container ngProjectAs="[slot][content]">
                        <div *ngIf="checkIfProductIsAvailableForOnlinePurchase(product) && product.attributes.price | shouldDisplayPrice">
                          <p class="card__price" [attr.data-test]="'desc-actualPrice'">
                            {{ product.attributes.price | currency: priceUtils.getStoreCurrencyCode() }}
                            <ng-container *ngIf="hasGuestPrice(product)">
                              <small
                                  class="product__description__price-alternative">
                                {{ getGuestPrice(product) | currency: priceUtils.getStoreCurrencyCode() }}
                              </small>
                            </ng-container>
                          </p>
                        </div>
                        <app-access-control [notForViewer]="true">
                          <ng-container ngProjectAs="[slot][nonViewerContent]">
                            <ng-container
                                *ngIf="!checkIfProductIsAvailableForOnlinePurchase(product); else productAvailable">
                              <div class="notification__header" [attr.data-test]="'desc-productIsNotAvailable'">
                                <p class="font-weight-bold product-not-available justify-content-end">
                                  <span class="icon-info icon-info__circle icon-orange-white"></span>
                                  {{ 'app-product-page.product-is-not-available-for-online-purchase' | translate }}
                                </p>
                              </div>
                            </ng-container>
                            <ng-template #productAvailable>
                              <button
                                  appDebounceClick
                                  class="button button--link add-to-cart"
                                  [debounceTime]="300"
                                  (debounceClick)="addToCart({sku: product.attributes.sku, name: product.attributes.name || product.attributes.sku})"
                                  [disabled]="isAddToCartBtnDisabled()">
                              <span class="icons-group">
                                <span class="icon-basket"></span>
                              </span>
                                <span>
                                {{ 'app-product-page.add-to-cart' | translate }}
                              </span>
                              </button>
                            </ng-template>
                          </ng-container>
                        </app-access-control>
                      </ng-container>
                    </app-access-control>
                  </div>
                </div>
                <div class="loader__overlay">
                  <span class="loading-animation"></span>
                </div>
              </div>
            </div>
          </app-slick-carousel>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <app-add-new-wishlist
      [showModal]="addNewModalActive"
      (closeModal)="addNewModalActive = false"
      (created)="wishlistCreated($event)">
  </app-add-new-wishlist>
  <app-add-item-modal
      [showModal]="addItemToCartModalActive"
      (closeModal)="addItemToCartModalActive = false"
      [items]="itemToAdd"
      [currentCartId]="currentCartId">
  </app-add-item-modal>
  <app-modal-confirm
      [type]="iconType.WARNING"
      modalTitle="{{ 'catalog.select-equipment-modal.title' | translate }}"
      notifyText="{{ 'catalog.select-equipment-modal.text' | translate }}"
      cancelBtnText="{{ 'catalog.select-equipment-modal.cancel-btn' | translate }}"
      primaryBtnText="{{ 'catalog.select-equipment-modal.submit-btn' | translate }}"
      [showModalConfirm]="showModalSelectEquipment"
      (closeModalConfirm)="showModalSelectEquipment = false"
      (primaryActionConfirm)="selectEquipment()">
  </app-modal-confirm>
</ng-container>
