<div class="notification"
     [ngClass]="!margin ? 'margin-bottom-0' : ''"
     [attr.data-test]="'material-master-numbers-product-notification'">
  <div class="notification__header">
    <span class="icon-warning icon-warning__circle "></span>
    <h5 [attr.data-test]="'material-master-numbers-product-notification-text'"
        class="notification__title">
      {{ 'material-master-number.notification' | translate }}
    </h5>
  </div>
</div>
