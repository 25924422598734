<div class="cart" [ngClass]="{'no-items': cart.itemDetails?.length === 0}">
  <div class="cart--loader" *ngIf="loadingCart">
    <div class="loading-animation"></div>
  </div>
  <div class="cart--header">
    <h6 class="cart-name">{{ cartName(cart) }}</h6>
    <button type="submit" class="button button--link button--delete-cart" 
        (click)="deleteCart(cart.id, cart.attributes.totalItemsQuantity)">
      {{ 'multi-cart.delete-cart' | translate }}
      <span class="icon-close"></span>
    </button>
  </div>
  <div class="cart--body">
    <div *ngIf="cart.itemDetails[0]?.id" class="cart-image">
      <img [src]="getImageForCart(cart.itemDetails[0]?.id)" alt="{{ cart.itemDetails[0]?.attributes.abstractSku }}">
    </div>
    <div class="cart-items">
      <h6 class="cart-item-name">{{ cart.id }}</h6>
      <ng-container *ngIf="cart.itemDetails?.length > 0; else noItems">
        <strong>
          {{ singularOrPlural(cart.itemDetails.length, 'orders-page.item') | translate: {number: cart.itemDetails.length} }}
        </strong>
        <ng-container *ngIf="isSapStore">
          <span *ngIf="cart.attributes.systemDetails?.nameEnUs">
            {{ cart.attributes.systemDetails?.nameEnUs }}
          </span>
          <span *ngIf="cart.attributes.systemDetails?.siemensEquipmentId">
            FL: {{ cart.attributes.systemDetails?.siemensEquipmentId }}
          </span>
        </ng-container>
      </ng-container>
      <ng-template #noItems>
        <span>{{ 'multi-cart.no-cart-items' | translate }}</span>
      </ng-template>
    </div>
    <div class="cart-actions">
      <button *ngIf="cart.itemDetails?.length > 0" 
          type="submit" class="button button--primary" [attr.data-test]="'button-continue-' + cart.id"
          (click)="switchCart(cart.id, true)">
        {{ 'multi-cart.continue' | translate }}
      </button>
      <button type="submit" class="button" [attr.data-test]="'button-select' + cart.id"
          (click)="switchCart(cart.id)">
        {{ 'multi-cart.select' | translate }}
      </button>
    </div>
  </div>
</div>
