import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WishlistFacade } from '../facades/wishlist.facade';
import { AppActions, WishlistActions } from '../actions';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { IWishlists } from '../models/wishlist.models';


@Injectable()
export class WishlistEffects {
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private actions$: Actions,
    private wishlistFacade: WishlistFacade,
  ) {
  }

  retrieveWishlistsForUser$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(WishlistActions.retrieveWishlists),
        mergeMap(() => this.wishlistFacade.getWishlists()
          .pipe(
            takeUntil(this.unsubscribe$),
            map((list: IWishlists) => WishlistActions.retrieveWishlistsSuccess({wishlists: list})),
            catchError(error => of(WishlistActions.retrieveWishlistsFail({error}))),
          )),
      );
    },
  );

  addNewWishlist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WishlistActions.addNewWishlist),
      mergeMap(action =>
        this.wishlistFacade.createWishlist(action.wishlistName).pipe(
          takeUntil(this.unsubscribe$),
          map(result => WishlistActions.addNewWishlistSuccess({newWishlist: result})),
          catchError(error => of(WishlistActions.addNewWishlistFailed({error: error.error.errors[0]}))),
        ),
      ),
    );
  });

  addNewWishlistFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WishlistActions.addNewWishlistFailed),
      map(error =>
        AppActions.setAlert({
          alert: {
            type: 'warning',
            message: error.error.detail,
          },
        }),
      ));
  });

  deleteWishlist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WishlistActions.deleteWishlist),
      mergeMap(action =>
        this.wishlistFacade.deleteWishlist(action.wishlistID).pipe(
          map(() => WishlistActions.deleteWishlistSuccess({wishlistID: action.wishlistID})),
          catchError(err => of(WishlistActions.deleteWishlistFailed({error: err}),
            ),
          ),
        ),
      ),
    );
  });

  addProductToWishlist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WishlistActions.addNewProductToWishlist),
      mergeMap(action =>
        this.wishlistFacade.addNewItemToWishlist(action.wishlistId, action.sku).pipe(
          map(result => WishlistActions.addNewProductToWishlistSuccess({
            wishlistResponse: result,
            wishlistID: action.wishlistId,
          })),
          catchError(error => of(this.addNewProductToWishlistFailed(action.wishlistId, action.sku, error))),
        ),
      ),
    );
  });

  removeProductFromWishlist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WishlistActions.removeProductFromWishlist),
      mergeMap(action =>
        this.wishlistFacade.removeItemFromWishlist(action.wishlistId, action.shoppingListItemId).pipe(
          map(() => WishlistActions.removeProductFromWishlistSuccess({
            wishlistId: action.wishlistId,
            shoppingListItemId: action.shoppingListItemId,
            sku: action.sku,
          })),
          catchError(error => of(this.addNewProductToWishlistFailed(action.wishlistId, action.sku, error))),
        ),
      ),
    );
  });

  updateProductQtyInWishlist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WishlistActions.updateProductFromWishlist),
      switchMap(action =>
        this.wishlistFacade.updateItemFromWishList(action.wishlistId, action.shoppingListItemId, action.sku, action.qty)
          .pipe(
            map(() =>
              WishlistActions.updateProductFromWishlistSuccess({
                wishlistId: action.wishlistId,
                shoppingListItemId: action.shoppingListItemId,
                sku: action.sku,
                qty: action.qty,
              })),
            catchError(error => of(WishlistActions.updateProductFromWishlistFailed({
              wishlistId: action.wishlistId,
              sku: action.sku,
              error,
            }))),
          )),
    );
  });

  private addNewProductToWishlistFailed(wishlistId: string, sku: string, error): any {
    return WishlistActions.addNewProductToWishlistFailed({
      wishlistId,
      sku,
      error,
    });
  }
}
