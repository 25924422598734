import { formatDate } from '@angular/common';

export enum FileType {
  PDF   = 'application/pdf',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
}

export class FileUtils {

  static saveAndOpenFile(file: Blob, fileType: FileType, fileName: string, createdAt?: string, openFile: boolean = true): void {
    const date = createdAt ? formatDate(new Date().toDateString(), 'dd-MM-yyyy', 'en') : '';

    const newBlob = new Blob([file], {type: fileType});
    const fileUrl = window.URL.createObjectURL(newBlob);
    const nFileName = createdAt ? `${fileName}_${date}` : `${fileName}`;

    const fileLink = document.createElement('a');
    fileLink.href = fileUrl;
    fileLink.download = nFileName;
    fileLink.click();

    if (openFile) {
      const openNewTab = window.open(fileLink.href, '_blank');
      if (openNewTab) {
        openNewTab.focus();
      }
    }

    window.URL.revokeObjectURL(fileUrl);
  }

  static openFile(file: Blob, fileType: FileType): void {
    const newBlob = new Blob([file], {type: fileType});
    const fileUrl = window.URL.createObjectURL(newBlob);

    const fileLink = document.createElement('a');
    fileLink.href = fileUrl;

    const openNewTab = window.open(fileLink.href, '_blank');
    if (openNewTab) {
      openNewTab.focus();
    }

    window.URL.revokeObjectURL(fileUrl);
  }
}
