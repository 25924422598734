import { Injectable, Injector } from '@angular/core';
import { ContactUsService } from '../services/contact-us.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactUsFacade {

  private pContactUsService: ContactUsService;

  public get contactUsService(): ContactUsService {
    if (!this.pContactUsService) {
      this.pContactUsService = this.injector.get(ContactUsService);
    }
    return this.pContactUsService;
  }

  constructor(
    private injector: Injector,
  ) {
  }

  postContactUsData(data: any): Observable<any> {
    return this.contactUsService.postContactUsData(data);
  }

  postContactUsGeneralData(data: any): Observable<any> {
    return this.contactUsService.postContactUsGeneralData(data);
  }
}
