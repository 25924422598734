@for (address of checkoutAddresses; track address.type) {
  <div
      [ngClass]="'section-content-container addresses-group address-field address-field--'+ address.type + '-address'">
    <div class="address-label">
      <span class="font-weight-bold">
        {{ 'order-review.checkout-' + address.type + '-address' | translate }}
      </span>
    </div>
    <div class="address-value">
      <div class="input" [ngClass]="{'invalid': !address.isAddressValid}">
        <span [attr.data-test]="'input-reportWrongSoldToAddress'">{{ address.name }}</span>
        <span class="icon-ivalid icon-close icon-close__circle"></span>
      </div>
      <div class="report-wrong-address">
        @if (!isCartStatusWaiting && address.type === EAddressType.SHIP_TO) {
          <button class="button button--link padding-right-1" [attr.data-test]="'link-changeAddress'"
                  [disabled]="orderReviewService.isSaturdayShipmentSelected(selectedShipmentMethodName)"
                  [routerLink]="'/delivery-details'"
                  [queryParams]="{ 'redirect-to': EDeliveryInputNames.SHIP_TO_ADDRESS }">
            <span class="icon icon-arrow-right-small color-orange"></span>
            <span class="text">{{ 'order-review.change-address' | translate }}</span>
          </button>
        }
        <button class="button button--link"
                [attr.data-test]="'button-reportWrong' + address.type + 'Address'"
                (click)="openReportWrongAddressModal(address)">
          <span class="icon icon-arrow-right-small color-orange"></span>
          <span>{{ 'order-review.report-wrong-address' | translate }}</span>
        </button>
      </div>
    </div>
    @if (!address.isAddressValid) {
      <div class="address-invalid">
        <div class="icon">
          <span class="icon-close icon-close__circle"></span>
        </div>
        <div class="text">
          <span>{{ 'order-review.sold-to-error'| translate }}</span>
        </div>
      </div>
    }
  </div>
}
@if (isReportWrongAddressModalVisible) {
  <app-harmonized-report-wrong-address-modal
      [showModal]="isReportWrongAddressModalVisible"
      [cartId]="cartId"
      [addresses]="[reportedAddress]"
      [addressType]="reportedAddress.type"
      [systemDetails]="systemDetails"
      [label]="'quote-summary.checkout-' + reportedAddress.type + '-address'"
      (closeModal)="closeReportWrongAddressModal()"
  />
}
