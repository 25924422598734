import { Component, Input, OnInit } from '@angular/core';
import { EOrderStatus, EScenarioType } from '../../../../configurations/order-status';
import { Subject } from 'rxjs';
import { OrdersFacade } from '../../../../facades/orders.facade';
import { takeUntil } from 'rxjs/operators';
import { IOrder, IOrderById, IOrderItem } from '../../../../models/order.models';
import { FileType, FileUtils } from '../../../../utils/file.utils';
import { CpqFacade } from '../../../../facades/cpq.facade';
import { AppUtils } from '../../../../utils/app.utils';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {
  scenarioType = EScenarioType.CPQ;
  ecommerceTeamEmailAddress: string;
  functionalLocationData: any[];
  data: IOrder;
  isLoading = true;
  orderStatus = EOrderStatus;

  private unsubscribe$ = new Subject<void>();

  @Input() quoteID: string;

  constructor(
    private ordersFacade: OrdersFacade,
    private cpqFacade: CpqFacade) {
  }

  ngOnInit(): void {
    this.getStoreEmailAddress();
    this.ordersFacade.getOrderById(this.quoteID).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((quote: IOrderById) => {
      this.data = quote.data;
      this.functionalLocationData = this.data.attributes.items.map((item: IOrderItem) => this.buildFlData(item));
      this.isLoading = false;
    });
  }

  buildFlData(item: IOrderItem): any {
    return {
      functionalLocation: item.systemDetails.siemensEquipmentId,
      equipmentType: item.systemDetails.nameEnUs,
      servicePlan: item.name,
      contractStartDate: formatDate(item.systemDetails.contractStartDate, 'MM/dd/yyyy', 'en'),
      contractEndDate: formatDate(item.systemDetails.contractExpirationDate, 'MM/dd/yyyy', 'en'),
      price: item.unitPrice
    };
  }

  downloadDocumentation(): void {
    this.cpqFacade.getCpqQuotePdfFile(this.data.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(file => {
        FileUtils.saveAndOpenFile(file, FileType.PDF, `Quote_${this.quoteID}.pdf`);
      });
  }

  displayDownloadButton(): boolean {
    return this.data.attributes.stateDisplayName !== EOrderStatus.CPQ_ORDER_DECLINED;
  }

  private getStoreEmailAddress(): void {
    this.ecommerceTeamEmailAddress = AppUtils.getCurrentStore().ecommerceTeamEmailAddress;
  }

}
