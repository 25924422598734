import { PathLocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { I18nService } from '../services';
import { lmExclusions } from './languageMarketExclusions';

@Injectable({
  providedIn: 'root',
})
export class LanguageLocationStrategy extends PathLocationStrategy {
  service = I18nService.prototype;
  currentLanguage: string;

  prepareExternalUrl(internal: string): string {
    const reg = I18nService.languageUrlRegex;
    let url = internal;
    if (url !== '/' && !lmExclusions.includes(url.substring(1)) && !reg.exec(url) && !`${url}/`.match(reg)) {
      url = `/${this.currentLanguage || this.service.getCurrentParam()}${url}`;
    } else {
      if (!url.includes('page-not-found')) {
        const urlArray = reg.exec(url) || `${url}/`.match(reg);
        if (!this.currentLanguage) {
          this.currentLanguage = urlArray ? urlArray[0].replace(/\//gi, '') : undefined;
        }
      }
    }

    return url;
  }

}
