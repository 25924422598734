<div class="delivery-details container"
     [appLoadingAnimation]="isUserDataLoading || isCartEmpty || loadingCartDataInProgress">
  <app-spare-part-order-details-section
      *ngIf="cartDataLoaded && cartItemsDataLoaded && !loadingCartDataInProgress"
      [ngClass]="{'hidden': isOnContactDetailsSection}"
      [cartId]="cartId"
      [cartAttributes]="cartAttributes"
      [cartItemsWithDetails]="cartItemsWithDetails"
      [cartRules]="cartRules"
      [systemDetails]="systemDetails"
      [loggedUserRoles]="userData.companyRoles"
      [userLoggedData]="userData"
      [isSaturdayShipment]="isSaturdayShipment"
      [isPriceDisputingActive]="isPriceDisputingActive"
      (formSubmitted)="proceedToContactDetails($event)"
      (addressChangedEmitter)="addressChanged = $event">
  </app-spare-part-order-details-section>
  <app-spare-part-contact-details-section
      *ngIf="cartDataLoaded && cartItemsDataLoaded && !loadingCartDataInProgress"
      [ngClass]="{'hidden': !isOnContactDetailsSection}"
      [cartId]="cartId"
      [cartAttributes]="cartAttributes"
      [cartItemsWithDetails]="cartItemsWithDetails"
      [cartRules]="cartRules"
      [creatingOrderInProgress]="creatingOrderInProgress"
      [isOnContactDetailsSection]="isOnContactDetailsSection"
      [addressChanged]="addressChanged"
      [isSaturdayShipment]="isSaturdayShipment"
      [isPriceDisputingActive]="isPriceDisputingActive"
      [shipToAddress]="orderDetailsData?.shipToAddress"
      (backToOrderDetails)="proceedToOrderDetails()"
      (formSubmitted)="createOrder($event)">
  </app-spare-part-contact-details-section>
</div>
