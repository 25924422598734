import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, skipWhile, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AppUtils } from '../../utils/app.utils';
import { EStoreTypes } from '../../configurations/common';
import { IInstallBaseProducts, ISimpleInstallBaseProduct } from 'src/app/models/installedbase.models';
import { InstallBaseFacade } from '../../facades/install-base.facade';
import { CatalogFacade } from '../../facades/catalog.facade';
import { I18nService } from '../../services';

@Component({
  selector: 'app-install-base',
  templateUrl: './install-base.component.html',
  styleUrls: ['./install-base.component.scss'],
})
export class InstallBaseComponent implements OnInit, OnDestroy {
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isJpStore: boolean = false;
  currentLang: string;
  categories: string[];
  installBaseProductsResponse: IInstallBaseProducts;
  installBaseProducts: ISimpleInstallBaseProduct[];
  installBaseProductsCount: number = 0;
  hasProducts: boolean = false;
  currentTab = null;
  currentTabInstallBaseProducts: ISimpleInstallBaseProduct[];
  active: boolean;
  installBaseProductsLoading$: Observable<boolean> = new Observable<boolean>();
  isGuest: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private catalogFacade: CatalogFacade,
    private installBaseFacade: InstallBaseFacade,
    private analyticsService: AnalyticsService,
    private i18nService: I18nService
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
    this.installBaseProductsLoading$ = this.installBaseFacade.selectInstalledBaseProductDataLoading();
    this.currentLang = this.i18nService.getCurrentLanguage();
    this.categories = [];
    this.installBaseProducts = [];
    this.currentTabInstallBaseProducts = [];
    this.selectInstalledBaseSystem();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTitle(): string {
    return this.isUsStore || this.isCaStore
      ? 'install-base.cpq-title'
      : 'install-base.title';
  }

  changeTab(tabName: string): void {
    this.currentTab = tabName;
    this.getCurrentTabInstalledBasedProducts(tabName);
    this.active = false;
  }

  private getCategories(): void {
    this.categories = [
      ...new Set(this.installBaseProducts.map(item => {
        return item.attributes.category;
      }))
    ].sort((a, b) => a.localeCompare(b));
    if (this.hasProducts) {
      this.currentTab = this.categories[0];
    } else {
      this.currentTab = null;
    }
  }

  private getCurrentTabInstalledBasedProducts(currentTab: string): void {
    this.currentTabInstallBaseProducts = [
      ...new Set(this.installBaseProducts.filter(item => {
        return item.attributes.category === currentTab;
      }))
    ];
  }

  selectInstalledBaseSystem(): void {
    this.installBaseFacade.selectInstalledBaseProductData()
      .pipe(
        skipWhile((installBaseProductData: IInstallBaseProducts | undefined) => installBaseProductData === null),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        this.installBaseProductsResponse = data;
        this.installBaseProducts = this.installBaseProductsResponse.data ? this.installBaseProductsResponse.data: [];
        this.hasProducts = this.installBaseProducts.length > 0;
        this.installBaseProductsCount = this.installBaseProducts.length;
        this.getCategories();
        this.getCurrentTabInstalledBasedProducts(this.currentTab);
      });
  }

  proceedToInstalledBase(installedBase: ISimpleInstallBaseProduct): void {
    this.analyticsService.trackInstalledBase(installedBase.attributes.materialName, installedBase.id);
    this.catalogFacade.setInstalledBase(installedBase);
  }

  activeDropdown(): void {
    this.active = !this.active;
  }
}
