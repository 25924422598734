<ng-container *ngIf="addressesLoading; else addressesLoaded">
  <div class="loading-animation"></div>
</ng-container>
<ng-template #addressesLoaded>
  <div class="address-menu">
    <div class="only-customer-addresses">
      <input id="show-customer-addresses-checkbox"
             type="checkbox"
             name="only-custom-addresses-checkbox"
             class="checkbox"
             [disabled]="addressesLoading"
             [checked]="onlyCustomerAddresses"
             (change)="selectOnlyCustomerAddresses()"
             [attr.data-test]="'checkbox-showOnlyCustomerAddresses'"/>
      <label for="show-customer-addresses-checkbox">
        {{ 'my-profile.delivery-addresses.only-customer-addresses' | translate }}
      </label>
    </div>
    <div class="js-search-field search-field textfield textfield--with-prefix">
      <span class="textfield__prefix icon-search"></span>
      <input id="search"
             name="search"
             type="search"
             class="textfield__input search-field__input"
             spellcheck="false"
             autocomplete="off"
             placeholder="{{ 'my-profile.delivery-addresses.search' | translate }}"
             [disabled]="addressesLoading"
             [(ngModel)]="searchAddress"
             [attr.data-test]="'field-searchInput'"/>
    </div>
  </div>
  <ng-container *ngIf="addresses | searchFilter: searchAddress as filteredAddresses">
    <div class="rows">
      <div class="col">
        <div *ngIf="addAddressInProgress" class="loader__overlay">
          <span class="loading-animation"></span>
        </div>
        <div class="add-new-address"
             [attr.data-test]="'tile-addNewAddress'"
             [ngClass]="{'disabled': updateCustomerAddressesInProgress || updatePreferredAddressInProgress}"
             (click)="showAddAddressModal()">
          <h6>{{ 'my-profile.delivery-addresses.add-new-address' | translate }}</h6>
        </div>
      </div>
      <div class="col"
           *ngFor="let address of filteredAddresses | slice:0:(viewAllAddresses ? filteredAddresses.length : 8)">
        <app-delivery-address
            [isSapStore]="isSapStore"
            [customerId]="customerId"
            [businessUnitName]="businessUnitName"
            [businessUnitNumber]="businessUnitNumber"
            [preferredShipToId]="customerPreferences.preferredShipToId"
            [address]="address"
            (sortAddressesEmit)="sortAddresses()"
            [updateCustomerAddressesInProgress]="updateCustomerAddressesInProgress"
            [updatePreferredAddressInProgress]="updatePreferredAddressInProgress"
            (updatePreferredAddressEmit)="updatePreferredAddressInProgress = $event">
        </app-delivery-address>
      </div>
    </div>
    <button *ngIf="filteredAddresses.length > 8 && !viewAllAddresses"
            type="button" class="button button--link icon-arrow-long-right"
            (click)="viewAllAddresses = true">
      {{ 'my-profile.delivery-addresses.button-view-all' | translate }}
    </button>
    <button *ngIf="filteredAddresses.length > 8 && viewAllAddresses"
            type="button" class="button button--link icon-arrow-long-left"
            (click)="viewAllAddresses = false">
      {{ 'my-profile.delivery-addresses.button-show-less' | translate }}
    </button>
  </ng-container>
</ng-template>
<app-add-edit-delivery-address-modal
    [showModal]="showModalAddAddress"
    (closeModal)="showModalAddAddress = false"
    (newAddress)="addAddress($event)">
</app-add-edit-delivery-address-modal>
