import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as ValidationPatterns from '../../configurations/validations';
import { CpqFacade } from '../../facades/cpq.facade';
import { IWrongAddressForCpq } from '../../models/cpq.models';
import {IAddress} from "../../models/common.models";
import { take } from 'rxjs/operators';
import { EGlueResource } from '../../configurations/common';

@Component({
  selector: 'app-report-wrong-bp-address-modal',
  templateUrl: './report-wrong-bp-address-modal.component.html',
  styleUrls: ['./report-wrong-bp-address-modal.component.scss'],
})
export class ReportWrongBpAddressModalComponent implements OnInit, OnChanges {
  @Input() showModal: boolean;
  @Input() billOrPayer: boolean = false;
  @Input() functionalLocations: string[];
  @Input() quoteId: string;
  @Input() addressType = '';
  @Input() resetAddressList: boolean = false;
  @Input() addressValue: any;
  @Input() title: string;
  @Output() closeModal = new EventEmitter<any>();

  wrongAddressForm: UntypedFormGroup;
  dataToSendForCpq: IWrongAddressForCpq;
  addresses: IAddress[] = [];
  loading: boolean;
  addressesLoading: boolean;
  maxLengthComment = 500;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cpqFacade: CpqFacade,
  ) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.initializeForm();
  }

  initializeForm(): void {
    this.wrongAddressForm = this.formBuilder.group({
      deliveryAddress: this.addressValue ?? '',
      comment: ['', [Validators.required, Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue !== changes.showModal.previousValue) {
      if (this.wrongAddressForm && this.addressValue) {
        this.wrongAddressForm.patchValue({
          deliveryAddress: this.addressValue,
        });
      }
    }
    this.addressesLoading = false;
  }

  reportAddress(): void {
    if (this.wrongAddressForm.status === 'VALID') {
      this.dataToSendForCpq = {
        type: EGlueResource.CPQ_ADDRESS_REPORT_WRONG,
        attributes: {
          addressType: this.addressType,
          functionalLocations: this.functionalLocations,
          address: this.wrongAddressForm.value.deliveryAddress,
          quoteUuid: this.quoteId,
          comment: this.wrongAddressForm.value.comment,
        },
      };

      this.cpqFacade?.postCpqWrongAddress({data: this.dataToSendForCpq}).pipe(
        take(1)
      ).subscribe(
        () => this.postDataActions(),
      );

      this.wrongAddressForm.reset();
    }
  }

  showLoader(): void {
    this.loading = true;
  }

  resetForm(): void {
    this.closeModal.emit();
    this.wrongAddressForm.reset();
  }

  formIsValid(): boolean {
    return this.wrongAddressForm.status === 'VALID';
  }

  private postDataActions(): void {
    this.loading = false;
    this.closeModal.emit();
    this.cpqFacade.showAdditionalDeliverableNotification(
      'success',
      'report-wrong-address.reported',
      [{
        key: 'report-wrong-address.reported-desc',
        params: {},
        css: [],
      }],
      [],
    );
  }
}
