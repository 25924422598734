import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  IAbstractProduct,
  IAbstractProductIncluded,
  ICachedProductSkuAndRequiredReload,
  IProductLabel,
} from '../../models/abstract-product.models';
import { IContractParams, IInstallBaseQueryParams } from '../../models/catalog.models';
import { IImage, IPrice, ISystemDetails } from '../../models/common.models';
import { MarketingFacade } from '../../facades/marketing.facade';
import { CatalogFacade } from '../../facades/catalog.facade';
import { SeoFacade } from '../../facades/seo.facade';
import { ESeoData, ISeoPageData } from '../../configurations/seo';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { environment } from '../../../environments/environment';
import { WishlistFacade } from '../../facades/wishlist.facade';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { AppUtils } from '../../utils/app.utils';
import { EProductCategoryDetails, EProductDetails } from '../../configurations/product-details';
import { ProductUtils } from '../../utils/product.utils';
import {
  EFeatureToggles,
  EGlueResource,
  EInstalledBaseTabs,
  EStoreTypes,
  EUserRoles,
} from '../../configurations/common';
import { IAddToCartAdditionalFields } from '../../models/cart.models';
import { IconType } from '../../models/settings.model';
import { ISimpleInstallBaseProduct } from '../../models/installedbase.models';
import { CustomerFacade } from '../../facades/customer.facade';
import { I18nService } from '../../services';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { PriceUtils } from '../../utils/price.utils';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent implements OnInit, OnDestroy {
  iconType = IconType;
  productInfoLoaded: boolean = false;
  isAddToCartInProgress$: Observable<any> = this.marketingFacade.isAddItemOperationInProgress();
  loadingCartDataInProgress: boolean = true;
  product: IAbstractProduct;
  productQuantity: number = 1;
  largeUrls = [];
  smallUrls = [];
  sku: string;
  defaultPrice: IPrice;
  mainPicture: string = environment.defaultImg;
  secondPrice: IPrice;
  installBaseProducts: ISimpleInstallBaseProduct[];
  isQuantityValid: boolean = true;
  abstractProduct = null;
  isAbstractProductLoading$: Observable<boolean> = new Observable<boolean>();
  recentlyOpenedProducts: ICachedProductSkuAndRequiredReload[] = [];
  concreteProduct: IAbstractProductIncluded;
  error: any;
  defaultImg: string = environment.defaultImg;
  addNewModalActive: boolean = false;
  addItemToCartModalActive: boolean = false;
  concreteSku: string;
  wishlists = [];
  labels: IProductLabel[];
  displayConfig: EProductDetails[];
  productDetailsEnum = EProductDetails;
  materialNumber: string;
  flNumber: string;
  stepNumber: number;
  showPriceDisclaimer: boolean;
  showModalSelectEquipment: boolean = false;
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isSparePartsEnabled: boolean = false;
  isCpqEnabled: boolean = false;
  currentCartId: string;
  hasCartContract: boolean = false;
  itemToAdd: any;
  isInStore: boolean = false;
  isSparePart: boolean = false;
  wasRedirectedFromEquipmentPage: boolean = false;
  hasContractParams: boolean = false;
  systemDetails: ISystemDetails;
  contractParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': '',
  };
  companyRoles: EUserRoles[];
  pricingGroupToggle: boolean = false;
  isMyInstalledBaseFlowEnabled: boolean = false;

  private installBaseType: string = 'install-base';
  private concreteProductType: string = 'concrete-products';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private marketingFacade: MarketingFacade,
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private customerFacade: CustomerFacade,
    private seoFacade: SeoFacade,
    private wishlistFacade: WishlistFacade,
    private authorizationFacade: AuthorizationFacade,
    private i18nService: I18nService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isAbstractProductLoading$ = this.catalogFacade.selectIsAbstractProductLoading();
    this.selectIsMyInstalledBaseFlowEnabled();
    this.selectUsFeatures();

    this.selectPricingGroupToggle();
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isInStore = AppUtils.isStoreActive(EStoreTypes.IN);
    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;

    this.selectLoadingCartDataInProgress();
    this.selectCartAndSetParamsForFL();

    if (this.isUsStore) {
      this.selectHasCartContract();
    }

    this.getSkuFromRouteAndGetProductBySku();
    this.getCustomerCompanyRoles();
  }

  selectUsFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSparePartsEnabled = value;
    });
  }

  /**
   * Method to select Pricing Group toggle
   * @private
   */
  private selectPricingGroupToggle(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_PRICING_GROUP).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.pricingGroupToggle = value;
    });
  }

  getWishlistsFromStore(): void {
    this.wishlistFacade.selectUsersWishlists().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      wishlists => {
        this.wishlists = wishlists;
      },
    );
  }

  selectHasCartContract(): void {
    this.marketingFacade.selectHasCartContract().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
        this.hasCartContract = value;
      },
    );
  }

  getDisplayConfig(webshopProductCategory: string): void {
    const mappedWebshopProductCategory = EProductCategoryDetails[webshopProductCategory] ?? 'default';
    this.i18nService.getTranslationByKey(`accordion.${mappedWebshopProductCategory}`).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(productCategoryValue => {
      this.displayConfig = productCategoryValue.split(';').map(value => value.trim());
    });
  }

  selectAbstractProductError(): void {
    this.catalogFacade.selectAbstractProductError()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(error => {
        if (!this.abstractProduct && error) {
          this.router.navigate(['page-not-found']).then(() => this.catalogFacade.clearAbstractProduct());
        }
      });
  }

  initAbstractProductPrices(): void {
    const abstractProductPrices = this.abstractProduct.included.find(
      item => item.type === EGlueResource.ABSTRACT_PRODUCT_PRICES,
    );
    if (abstractProductPrices) {
      const defaultPrice = PriceUtils.getDefaultPrice(abstractProductPrices.attributes.prices);
      this.defaultPrice = defaultPrice ? defaultPrice : {} as IPrice;
      this.secondPrice = PriceUtils.getGuestPrice(abstractProductPrices.attributes.prices) ?? null;
    }
  }

  initAbstractProductImages(): void {
    const abstractProductImages = this.abstractProduct.included.find(
      item => item.type === EGlueResource.ABSTRACT_PRODUCT_IMAGE_SETS,
    );
    if (abstractProductImages?.attributes?.imageSets[0]) {
      const photosUrls: IImage[] = abstractProductImages.attributes.imageSets[0].images as IImage[];
      this.largeUrls = photosUrls.map(photo => photo.externalUrlLarge);
      this.smallUrls = photosUrls.map(photo => photo.externalUrlSmall);
      this.mainPicture = this.largeUrls[0];
      this.seoFacade.handleProductSchema(this.abstractProduct, this.largeUrls);
    }
  }

  addProductToCart(additionalFields: IAddToCartAdditionalFields = {}): void {
    if (this.isQuantityValid && !this.loadingCartDataInProgress) {
      const systemDetails: ISystemDetails = this.systemDetails;

      let data = {
        sku: this.concreteSku || this.abstractProduct.sku,
        name: this.abstractProduct.name,
      };

      if (this.hasCartContract) {
        this.itemToAdd = [{
          data: data,
          isConcrete: !!this.concreteSku,
          quantity: this.productQuantity,
        }];
        this.addItemToCartModalActive = true;
        return;
      }

      if (this.isMyInstalledBaseFlowEnabled) {
        if (!systemDetails?.siemensEquipmentId) {
          this.showModalSelectEquipment = true;
          return;
        }
      }

      if (additionalFields.addSystemDetails) {
        data['systemDetails'] = systemDetails;
        data['systemDetailsPerItem'] = [{
          itemId: data.sku,
          systemDetails,
        }];
      }

      this.analyticsService.setProducts(this.abstractProduct);
      this.analyticsService.trackCart('cart.add');
      this.marketingFacade.addProductToCart(
        data,
        !!this.concreteSku,
        this.productQuantity,
      );
    }
  }

  getItemConcreteSku(): string | null {
    if (this.abstractProduct.attributeMap?.product_concrete_ids.length > 0) {
      return this.abstractProduct.attributeMap.product_concrete_ids[0];
    } else {
      return null;
    }
  }

  getDetailAttribute(attributeValue: string | null): string {
    return ProductUtils.getDetailAttribute(attributeValue);
  }

  selectEquipment(): void {
    let commands: string = '/my-installed-base';
    let tab: EInstalledBaseTabs = EInstalledBaseTabs.EQUIPMENT;

    if (this.companyRoles.includes(EUserRoles.BusinessPartnerApprover) ||
      this.companyRoles.includes(EUserRoles.BusinessPartnerBuyer)) {
      commands = '/equipment-selection';
      tab = null;
    }

    let queryParams: IInstallBaseQueryParams = {
      tab: tab,
      lastSku: this.sku,
    };

    if (this.pricingGroupToggle) {
      queryParams = {
        ...queryParams,
        pricing_group: this.abstractProduct.attributes.sap_p40_material_pricing_group_3,
      };
    }

    this.router.navigate([commands], {
      queryParams: queryParams,
    }).then();
  }

  setContractParams(params: Params): void {
    this.contractParams['fl-number'] = params['fl-number'] ?? '';
    this.contractParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
    this.hasContractParams = !!this.contractParams['rel-product-sysivk'].length && !!this.contractParams['fl-number'].length;
  }

  private selectCartAndSetParamsForFL(): void {
    combineLatest([
      this.marketingFacade.selectCart(),
      this.route.queryParams,
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([cart, params]) => {
      if (cart) {
        this.currentCartId = cart.id;
        this.systemDetails = cart.attributes?.systemDetails;

        this.stepNumber = 1;
        this.materialNumber = this.systemDetails?.materialNumber ?? params['rel-product-sysivk'];
        this.flNumber = this.systemDetails?.siemensEquipmentId ?? params['fl-number'];
      }

      this.setContractParams(params);

      if (this.hasContractParams) {
        this.wasRedirectedFromEquipmentPage = true;
        setTimeout(() => this.wasRedirectedFromEquipmentPage = false, 5000);
      }
    });
  }

  private selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
      this.loadingCartDataInProgress = loadingCartDataInProgress;
    });
  }

  getCustomerCompanyRoles(): void {
    this.customerFacade.getCustomerCompanyRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((companyRoles: EUserRoles[]) => {
        this.companyRoles = companyRoles;
      });
  }

  /**
   * Get sku from actual route and get/select Abstract product by the given SKU
   */
  getSkuFromRouteAndGetProductBySku(): void {
    this.route.params.subscribe(params => {
      this.sku = params.sku;
      if (params.sku) {
        if (!this.hasProductBeenOpened(params.sku)) {
          this.catalogFacade.loadAbstractProductData(params.sku);
          this.selectRecentlyOpenedProductBySku(params.sku);
        }
        this.selectRecentlyOpenedProductBySku(params.sku);
        this.selectAbstractProductError();

        this.authorizationFacade.selectIsUserLoggedIn().pipe(takeUntil(this.unsubscribe$)).subscribe(isLoggedIn => {
          if (isLoggedIn) {
            this.wishlistFacade.dispatchUsersWishlistRetrieve();
            this.getWishlistsFromStore();
          }
        });
      }
    });
  }

  selectRecentlyOpenedProductBySku(sku: string): void {
    this.catalogFacade.selectRecentlyOpenedProductBySku(sku)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(abstractProduct => {
        if (abstractProduct && abstractProduct.sku === this.sku) {
          this.productInfoLoaded = true;
          this.abstractProduct = abstractProduct;
          this.getDisplayConfig(this.abstractProduct.attributes.mcm_webshop_product_category);
          this.concreteProduct = abstractProduct.included?.find(item => item.type === this.concreteProductType);
          this.isSparePart = !this.concreteProduct?.attributes.productConfigurationInstance;
          this.concreteSku = this.getItemConcreteSku();
          this.analyticsService.setProducts(abstractProduct);
          this.analyticsService.trackPageReady('product detail', PageTypes.PRODUCT_PAGE, 'product-page');
          this.installBaseProducts = abstractProduct.included?.filter(item => item.type === this.installBaseType);
          this.labels = abstractProduct.included?.filter(item => item.type === 'product-labels');
          this.seoFacade.setMetaFromComponent({
            titleSource: this.route.snapshot.data[ESeoData.seoTitleSource],
            titles: [abstractProduct.metaTitle],
            description: abstractProduct.metaDescription,
            keywords: abstractProduct.metaKeywords,
            canonicalUrl: {
              rel: 'canonical',
              href: abstractProduct.canonicalUrl || this.router.url,
            },
          } as ISeoPageData);
          this.initAbstractProductPrices();
          this.initAbstractProductImages();
        }
      });
  }

  /**
   * Method checks whether the product has been recently opened in the session
   *
   * @param {string} sku
   * @returns {boolean}
   */
  hasProductBeenOpened(sku: string): boolean {
    this.catalogFacade.selectRecentlyOpenedProductsSkus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((productSkus: ICachedProductSkuAndRequiredReload[]) => {
        this.recentlyOpenedProducts = productSkus;
      });
    return this.recentlyOpenedProducts.some((product => product.sku === sku));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Method for retrieving IsMyInstalledBaseFlowEnabled feature toggle value
   * @private
   */
  private selectIsMyInstalledBaseFlowEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isMyInstalledBaseFlowEnabled => {
      this.isMyInstalledBaseFlowEnabled = isMyInstalledBaseFlowEnabled;
    });
  }
}
